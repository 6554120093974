import React, { useEffect } from 'react';

import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import TimerIcon from '@mui/icons-material/Timer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import colors from '@packs/assets/theme/base/colors';
import { Loader } from '@packs/components';
import { BreadCrumbsComp } from '@packs/components/UI';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { VIEW_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';
import { useStore } from '@packs/stores';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { OfferContent } from './components/OfferContent';
import { OfferSidebar } from './components/OfferSidebar';
import { OtherOffers } from './components/OtherOffers';
import useStyles from './styles';

import size from 'lodash-es/size';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import Countdown from 'react-countdown';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

const MyOffersDetailed = observer(() => {
  const { offersStore } = useStore();
  const { MY_HOME_PATH, MY_OFFERS_PATH, MY_OFFERS_DETAILED_PATH_FN } = usePath();
  const { isMobile } = useWindowSize();
  const styles = useStyles();
  const { toggleShareSocialModal } = useModalsContext();

  const { id } = useParams();
  const offer = offersStore?.offerDetailed;
  const { category } = offer || {};
  const shareLink = window?.location?.origin + MY_OFFERS_DETAILED_PATH_FN(id);

  const countdownRenderer = ({ hours, minutes, seconds, days }) => {
    return <span>{`${days} days ${hours}:${minutes}:${seconds}`}</span>;
  };

  useEffect(() => {
    if (id) {
      offersStore.loadDetailed({ id: +id });
    }
  }, [id]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  if (offersStore?.loader || !size(offer)) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{offer?.offerMessage}</title>
        <meta name='description' content={offer?.offerDetails} />
        <meta name='og:description' content={offer?.offerDetails} />
      </Helmet>
      <Zoom in={true} timeout={500}>
        <Box sx={styles.container}>
          <Box sx={{ mb: { xs: 3, lg: 8 } }}>
            <BreadCrumbsComp
              maxItems={isMobile ? 2 : 3}
              list={[
                { label: 'My Home', to: MY_HOME_PATH },
                { label: 'My Offers', to: MY_OFFERS_PATH },
                { label: offer?.offerMessage }
              ]}
            />
          </Box>
          <Box sx={{ mb: 4 }}>
            <Typography variant='h2'>{offer?.offerMessage}</Typography>
          </Box>
          <Box sx={styles.topBar}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: { xs: 'column', lg: 'row' },
                gap: 2,
                flexWrap: 'wrap'
              }}
            >
              {offer?.untilDate && (
                <Box sx={styles.untilDate}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <Typography variant='body1' sx={{ color: colors.text.secondary }}>
                      End date:
                    </Typography>
                    <Typography variant='body1'>{moment(offer?.untilDate).format(VIEW_DATE_FORMAT)}</Typography>
                  </Box>
                  <Box sx={styles.timerWrapper}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontSize: '24px' }}>
                      <TimerIcon />
                      <Typography variant='body1'>Time left:</Typography>
                    </Box>
                    <Typography variant='body2' component='div'>
                      <Countdown date={offer?.untilDate} renderer={countdownRenderer} />
                    </Typography>
                  </Box>
                </Box>
              )}
              {category && (
                <Box sx={styles.categoryWrapper}>
                  <Typography variant='body1' sx={{ color: colors.text.secondary }}>
                    Offer category:
                  </Typography>
                  <Box sx={styles.categoryContainer}>
                    <IconBox
                      containerSize='32px'
                      iconSize='24px'
                      borderRadius='8px'
                      backgroundColor='transparent'
                      color={colors.dark.main}
                      icon={SUB_ICONS({ category, fill: colors.dark.main })}
                    />
                    <Typography variant='body1'>{category.name}</Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Button
              variant='text'
              color='dark'
              startIcon={<ShareRoundedIcon />}
              onClick={() =>
                toggleShareSocialModal({
                  modalProps: {
                    shareLink,
                    shareMessage: 'Share this offer with your friends! Spread the word and let them enjoy it too.'
                  }
                })
              }
              sx={{
                display: {
                  xs: 'none',
                  lg: 'flex'
                }
              }}
            >
              Share with friends
            </Button>
          </Box>
          <Box sx={{ display: 'flex', gap: 6, flexDirection: { xs: 'column', lg: 'row' } }}>
            <Box sx={styles.contentWrapper}>
              <OfferContent offer={offer} />
              <OtherOffers offer={offer} />
            </Box>
            <Box sx={styles.sidebar}>
              <OfferSidebar offer={offer} />
            </Box>
          </Box>
        </Box>
      </Zoom>
    </>
  );
});

export default MyOffersDetailed;
