import colors from '@crm/assets/theme/base/colors';

export default () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: {
      xs: 2,
      md: 3
    },
    borderRadius: '12px',
    p: 2,
    border: `2px solid ${colors.borderColor.main}`,
    backgroundColor: '#FFF'
  },
  avatarContainer: {
    border: `1px solid ${colors.borderColor.main}`,
    borderRadius: '8px'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5
  }
});
