import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import AutoQuotes from '@packs/components/AutoQuotes';
import { DateFieldSelect } from '@packs/components/DateFieldSelect';
import { SelectField } from '@packs/components/SelectField';
import { usePath } from '@packs/layouts/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { recurringOptions } from '@packs/models/company/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import { Link } from 'react-router-dom';
import { Field } from 'redux-form';

export const WithAutoQuotes = ({ formAttributes, change, handleSubmit }) => {
  const { currentCategory } = useCategories();
  const { LEADERBOARD_PATH } = usePath();
  const { currentPolicy } = useDetails();

  const disabled =
    formAttributes?.isAutoQuote &&
    !formAttributes?.topProvider &&
    !formAttributes?.topAgencyBroker &&
    !formAttributes?.topComparisonSite;

  const onOpenAutoQuote = e => {
    if (!formAttributes?.isAutoQuote) {
      e.stopPropagation();
      e.preventDefault();
      change('isAutoQuote', true);
    }
  };

  return (
    <div>
      <Typography variant='body2' sx={{ mb: 2, textAlign: 'center' }}>
        Do you wish to schedule quotes in the future?
      </Typography>
      <div className='d-flex align-items-center justify-content-center gap-8 mb-32'>
        {SUB_ICONS({ category: currentCategory, fill: colors?.category[currentCategory?.root?.enName]?.main })}
        <div className='fs-16 secondary-text'>{currentCategory.name}</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='form-body'>
          <div className='date-list'>
            <Field
              component={DateFieldSelect}
              className='white-bg with-light-border no-scale'
              classNamePrefix='Select'
              name='triggeredAt'
              monthLabel='Renewal Month'
              dateLabel='Renewal Date'
            />
          </div>
          <div className='input-field mb-16'>
            <span className='input-label'>Select renewal period</span>
            <Field
              component={SelectField}
              className='white-bg with-light-border no-scale'
              classNamePrefix='Select'
              name='recurringTime'
              options={recurringOptions}
              placeholder='Select period'
              isSearchable={false}
            />
          </div>
          <AutoQuotes formAttributes={{ ...formAttributes, categoryId: currentCategory.id }} {...{ change }} />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mt: 4 }}>
            <Button fullWidth component={Link} to={LEADERBOARD_PATH} variant='filledTonal' color='primary'>
              No thanks!
            </Button>
            <Button
              fullWidth
              variant='contained'
              type={formAttributes?.isAutoQuote ? 'submit' : 'button'}
              disabled={disabled}
              onClick={onOpenAutoQuote}
            >
              {currentPolicy?.smartReminder?.id ? 'Update' : 'Yes Please!'}
            </Button>
          </Box>
        </div>
      </form>
    </div>
  );
};
