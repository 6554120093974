import React, { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { avatarPlaceholder, stringToColor } from '@shared/helpers/additionalHelpers';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

export const ReviewForm = ({
  placeholder = 'Add a comment...',
  commentableType,
  commentableId,
  postId,
  submitCallback,
  createComment,
  currentUser,
  toggleSignUpModal
}) => {
  const styles = useStyles();
  const { isDesktop } = useWindowSize();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const avatarUrl = currentUser?.company?.avatarUrl || currentUser?.avatarUrl || '';
  const displayName = currentUser?.company?.name || currentUser?.displayName || '';

  const onSubmit = () => {
    const onSuccess = () => {
      setIsLoading(false);
      setMessage('');
      submitCallback && submitCallback();
    };

    const attrs = {
      message,
      renewalDetailId: postId,
      replayCommentId: commentableId,
      authorId: currentUser.id
    };

    const doSubmit = () => {
      if (isLoading && createComment) {
        return false;
      }

      setIsLoading(true);

      createComment(attrs, postId, commentableType)
        .then(onSuccess)
        .catch(() => setIsLoading(false));
    };

    if (currentUser?.id) {
      doSubmit();
    } else {
      toggleSignUpModal({ onSignUpSuccess: doSubmit });
    }
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();
      if (message) {
        onSubmit();
      }
    }
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        {isDesktop && currentUser?.id && (
          <Avatar
            src={avatarUrl}
            placeholder={displayName}
            sx={{ width: '40px', height: '40px', backgroundColor: stringToColor(displayName) }}
            variant='rounded'
          >
            {avatarPlaceholder(displayName)}
          </Avatar>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            fullWidth
            placeholder={placeholder}
            value={message}
            onKeyDown={handleKeyDown}
            size='small'
            onChange={e => setMessage(e.target.value)}
          />
          <Button
            variant='contained'
            color='primary'
            size='small'
            sx={{ mt: 1 }}
            disabled={message?.length === 0}
            onClick={onSubmit}
          >
            Send
          </Button>
        </Box>
      </Box>
    </>
  );
};
