import colors from '@packs/assets/theme/base/colors';

const { white, dark } = colors;

export default () => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: white.main,
    zIndex: 1000
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderBottom: `1px solid ${dark.transparent}`,
    py: 2
  },
  stepperWrapper: {
    width: '100%',
    maxWidth: '620px',
    mx: 'auto'
  },
  contentWrapper: {
    flexGrow: 1,
    overflowY: 'auto'
  },
  stepWrapper: {
    '& .MuiStepConnector-root': {
      '& .MuiStepConnector-line': {
        borderColor: 'rgba(0, 0, 0, 0.08)'
      }
    }
  },
  stepButtonWrapper: {
    '&.Mui-completed': {
      '& .MuiStepLabel-iconContainer': {
        position: 'relative',
        // Add custom black icon
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 2,
          left: 2,
          width: 20,
          height: 20,
          backgroundColor: '#000',
          borderRadius: '50%',
          zIndex: -1
        }
      }
    }
  },
  stepButton: {
    '& .MuiStepLabel-root': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: {
        xs: 'column',
        lg: 'row'
      }
    },

    '& .MuiStepLabel-label': {
      fontSize: '14px',
      fontWeight: 'bold'
    },
    '& .Mui-disabled': {
      '& .MuiSvgIcon-root .MuiStepIcon-text': {
        fill: '#fff'
      },
      '& .MuiStepLabel-label': {
        fontWeight: 'regular'
      }
    }
  }
});
