import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import colors from '@packs/assets/theme/base/colors';
import { All_CATEGORIES_INDEX } from '@packs/lib/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { CategoryItem } from './components/CategoryItem';

import _orderBy from 'lodash-es/orderBy';
import Slider from 'react-slick';

const { white } = colors;

const arrowStyles = {
  position: 'absolute',
  top: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '167px',
  height: '420px',
  cursor: 'pointer',
  color: white.main,
  zIndex: 2
};

export const DiscoverAll = () => {
  const { windowSize, isMobile } = useWindowSize();
  const { categories } = useCategories();
  const sortedCategories = _orderBy(categories.slice(), cat => All_CATEGORIES_INDEX[cat?.code]);
  const slidesCount = windowSize / (340 + 20);

  const SampleNextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        sx={{
          ...arrowStyles,
          right: '0',
          backgroundImage: 'linear-gradient(272deg, #186DE0 8.28%, rgba(1, 103, 205, 0.00) 97.86%)'
        }}
        onClick={onClick}
      >
        <ArrowForwardIcon />
      </Box>
    );
  };

  const SamplePrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        sx={{
          ...arrowStyles,
          left: '0',
          backgroundImage: 'linear-gradient(272deg, rgba(1, 103, 205, 0.00) 8.28%, #186DE0 97.86%)'
        }}
        onClick={onClick}
      >
        <ArrowBackIcon />
      </Box>
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: slidesCount || 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  if (isMobile) {
    return (
      <Stack direction='column' alignItems='center' spacing={2} px={2}>
        {sortedCategories.map(item => (
          <CategoryItem key={item.id} category={item} />
        ))}
      </Stack>
    );
  }

  return (
    <Slider {...settings}>
      {sortedCategories.map(item => (
        <CategoryItem key={item.id} category={item} />
      ))}
    </Slider>
  );
};
