import React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import colors from '@packs/assets/theme/base/colors';
import { Switcher } from '@packs/components/Switcher';

const { dark } = colors;
export const CheckBoxField = ({
  input: { name, value, onChange },
  meta: { error, touched },
  controlError,
  label,
  disabled,
  isSwitcher,
  primary,
  size,
  labelWidth = 'auto',
  textOn = null,
  textOff = null,
  labelPlacement = 'end',
  color = dark.main,
  alignCheckbox = 'center'
}) => {
  const spanError = error => error && <div className='error-hint pl-30 mt-10'>{error}</div>;

  if (isSwitcher) {
    const onChangeSwitcher = () => {
      onChange(!value);
    };

    return (
      <Switcher
        {...{
          size,
          value,
          label,
          primary,
          textOn,
          textOff
        }}
        onChange={onChangeSwitcher}
      />
    );
  }

  return (
    <Box
      sx={{
        '& .MuiFormControlLabel-label': {
          color,
          width: labelWidth
        }
      }}
    >
      <FormControlLabel
        control={<Checkbox name={name} checked={!!value} disabled={disabled} />}
        label={label}
        sx={{ alignItems: alignCheckbox, width: labelWidth }}
        labelPlacement={labelPlacement}
        onChange={onChange}
      />
      {controlError === undefined ? touched && spanError(error) : touched && spanError(controlError)}
    </Box>
  );
};
