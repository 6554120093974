import React from 'react';

import Button from '@mui/material/Button';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { saveState } from '@packs/lib/utils/localStorage';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { useStore } from '@packs/stores';
import { rootCategoriesLanding } from '@shared/constants/categories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { NavLink } from 'react-router-dom';

export const LeaderboardCTA = ({
  item,
  userNotConfirmed,
  disabledCategory,
  selectedPolicy,
  categoryRedirect,
  category
}) => {
  const { currentUser } = useUserSelectors();
  const { categoryStore } = useStore();
  const { isDesktop } = useWindowSize();
  const { DASHBOARD_MY_RESULTS_PATH_FN, DASHBOARD_LANDING_PATH_FN, DASHBOARD_ROOT_LANDING_PATH_FN } = usePath();

  const categoryIsRootLanding = rootCategoriesLanding.some(item => item === category.code);

  const categoryUrl = () => {
    let url = DASHBOARD_LANDING_PATH_FN(category);
    if (categoryIsRootLanding) {
      url = DASHBOARD_ROOT_LANDING_PATH_FN(category);
    } else if (categoryHavePolicy(category, categoryStore.categoriesWithPolicy, currentUser)) {
      url = DASHBOARD_MY_RESULTS_PATH_FN(category);
    }

    return url;
  };

  if (disabledCategory(item?.root?.code)) {
    return (
      <Button
        variant='filledTonal'
        size={isDesktop ? 'small' : 'smallToMedium'}
        disabled
        sx={{ whiteSpace: 'nowrap' }}
        fullWidth={!isDesktop}
      >
        Coming Soon
      </Button>
    );
  }
  return (
    <Button
      component={NavLink}
      disabled={userNotConfirmed}
      to={categoryUrl().concat(selectedPolicy[item?.id] ? `?policyId=${selectedPolicy[item?.id]}` : '')}
      onClick={() => {
        if (!userNotConfirmed) {
          categoryRedirect(category);
          saveState('openDashboardItemId', item.id);
        }
      }}
      variant='filledTonal'
      size={isDesktop ? 'small' : 'smallToMedium'}
      sx={{ whiteSpace: 'nowrap' }}
      fullWidth={!isDesktop}
    >
      See Leaderboard
    </Button>
  );
};
