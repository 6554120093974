import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { additionalApi } from '@packs/apis';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import BankingPartnerIcon from '@packs/images/svg/ui/bankingPartner.svg';
import BecomeAPartnerIcon from '@packs/images/svg/ui/becomeParntnerIcon.svg';
import MediaPartnerIcon from '@packs/images/svg/ui/mediaPartnerIcon.svg';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { countryPlanPrices, plans } from '@packs/lib/helpers/payment';
import { useStore } from '@packs/stores';

import { BankingPartners } from './components/Tabs/BankingPartners';
import { BusinessPartners } from './components/Tabs/BusinessPartners';
import { MediaPartners } from './components/Tabs/MediaPartners';

import { observer } from 'mobx-react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const tabsValues = {
  business: 'business',
  media: 'media',
  banking: 'banking'
};

const BecomeAPartner = observer(() => {
  const { type, plan } = queryString.parse(location.search);
  const { countryCode } = useStore();
  const { BECOME_A_PARTNER_PATH } = usePath();
  const navigate = useNavigate();

  const [inProcess, setInProcess] = useState(false);
  const [activeTab, setActiveTab] = useState(tabsValues[type] || tabsValues.business);
  const [selectedAdds, setSelectedAdds] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(plans[plan] || null);

  const shortCountryCode = countryCode.replace('en-', '');

  const { currentUser } = useUserSelectors();
  const { defaultMessages, formatMessage } = useTranslationMessages();

  const onSelectPlan = plan => {
    setSelectedPlan(plan);
    let newQuery = '?type=business';
    if (plan) {
      newQuery = newQuery + `&plan=${plan}`;
    }
    navigate(BECOME_A_PARTNER_PATH.concat(newQuery));
  };

  const onChangeTab = (e, value) => {
    onSetQueryTab(value);
    setActiveTab(value);
    setSelectedAdds([]);
    setSelectedPlan(null);
  };

  const submitHandler = variables => {
    const newData = { ...variables };
    newData.country = newData?.country?.value || null;

    const callback = () => {
      toastr.success(formatMessage(defaultMessages.becomeAPartnerPageSuccessfullyEmailSend));
      setInProcess(false);
      setSelectedAdds([]);
      setSelectedPlan(null);
    };
    const errorCallback = () => {
      // errors && toastr.error(errors);
      setInProcess(false);
    };

    if (!inProcess) {
      setInProcess(true);
      if (selectedPlan) {
        newData.subscriptionType = selectedPlan;

        if (selectedPlan !== plans.free) {
          newData.cost =
            countryPlanPrices[shortCountryCode][selectedPlan] + countryPlanPrices[shortCountryCode].currency;
        }
      }

      if (selectedAdds.length) {
        newData.addOns = selectedAdds;
      }

      additionalApi.becomeAPartnerSendMail(newData).then(resp => {
        if (resp.errors?.length) {
          return errorCallback(resp.errors);
        }
        callback(resp);
      });
    }
  };

  const onCheckAdds = name => {
    let newData = [...selectedAdds];
    if (selectedAdds.includes(name)) {
      newData = newData.filter(item => item !== name);
    } else {
      newData.push(name);
    }

    setSelectedAdds(newData);
  };

  const onSetQueryTab = name => {
    navigate(BECOME_A_PARTNER_PATH.concat(`?type=${name}`));
  };

  const partnerTabs = useMemo(() => {
    return [
      {
        value: tabsValues.business,
        label: 'Business Partners',
        icon: BecomeAPartnerIcon
      },
      {
        value: tabsValues.media,
        label: 'Media Partners',
        icon: MediaPartnerIcon
      },
      {
        value: tabsValues.banking,
        label: 'Banking Partners',
        icon: BankingPartnerIcon
      }
    ];
  }, [selectedAdds, selectedPlan, currentUser]);

  useEffect(() => {
    if (!type) {
      navigate(BECOME_A_PARTNER_PATH.concat('?type=business'));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Become a partner</title>
      </Helmet>
      <div className='app-main-container'>
        <div className='main-wrapper'>
          <Box sx={{ px: 2, pb: 5 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Tabs value={activeTab} onChange={onChangeTab}>
                {partnerTabs.map(item => (
                  <Tab key={item.value} label={item.label} value={item.value} />
                ))}
              </Tabs>
            </Box>
            {activeTab === tabsValues.business && (
              <BusinessPartners
                submitHandler={submitHandler}
                {...{
                  inProcess,
                  selectedAdds,
                  onCheckAdds,
                  selectedPlan,
                  onSelectPlan
                }}
              />
            )}
            {activeTab === tabsValues.media && <MediaPartners submitHandler={submitHandler} inProcess={inProcess} />}
            {activeTab === tabsValues.banking && (
              <BankingPartners submitHandler={submitHandler} inProcess={inProcess} />
            )}
          </Box>
        </div>
      </div>
    </>
  );
});

export default BecomeAPartner;
