import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import RequestsItem from '@packs/components/UI/Requests/RequestsItem';
import RequestsSkeleton from '@packs/components/UI/Requests/RequestsSkeleton';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useProfileContext } from '@packs/layouts/ProfileLayout/context';
import { ProfileFilters } from '@packs/layouts/ProfileLayout/ProfileFilters';
import { EmptyState } from '@packs/screens/Profile/components/EmptyState';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select';

const perPage = 10;
const allYears = { value: 'all', label: 'All years' };

const Requests = observer(() => {
  const context = useProfileContext();
  const { currentUser } = useUserSelectors();
  const { requestsStore } = useStore();
  const [page, setPage] = useState(1);

  const yearsList = useMemo(() => {
    const yearRangeCount = moment().format('YYYY') - (requestsStore?.createdAtFirstYear || moment().format('YYYY'));
    if (yearRangeCount > 0) {
      return [
        allYears,
        ...[...Array(yearRangeCount + 1)].map((_, index) => {
          return {
            value: moment().format('YYYY') - index,
            label: moment().format('YYYY') - index
          };
        })
      ];
    }

    return [allYears];
  }, [requestsStore.createdAtFirstYear]);
  const [selectedYear, setSelectedYear] = useState(yearsList[0]);

  const handleChangeYear = data => {
    setSelectedYear(data);
  };

  const fetchRequests = (page, more = false) => {
    const attr = {
      page,
      perPage,
      categories: context?.categoriesFilter.length ? context?.categoriesFilter : null,
      years: selectedYear.value === 'all' ? null : [selectedYear.value]
    };

    requestsStore.load(attr, more);

    setPage(page);
  };

  const loadMorePage = () => {
    fetchRequests(page + 1, true);
  };

  useEffect(() => {
    fetchRequests(1);
  }, [context?.categoriesFilter, selectedYear]);

  return (
    <div className='profile-wrapper'>
      <div className='profile-page-content position-relative'>
        {!requestsStore.loading && requestsStore.requests.length === 0 ? (
          <EmptyState
            title='You have not requested any quotes yet!'
            description='Your quote requests will appear here…'
          />
        ) : (
          <InfiniteScroll
            dataLength={requestsStore.totalCount}
            next={loadMorePage}
            hasMore={requestsStore.totalCount > requestsStore.requests.length}
          >
            {requestsStore.requests.map((item, index) => (
              <RequestsItem key={item?.id} request={item} owner={currentUser} first={index === 0} postType='request' />
            ))}
            {requestsStore.loading && <RequestsSkeleton />}
          </InfiniteScroll>
        )}
      </div>
      <div className='profile-filters'>
        <ProfileFilters />
        <div className='fs-11 fw-700 tt-uc mb-16 mt-24'>Year:</div>
        <Select
          classNamePrefix='Select'
          className='select-ui gray-bg'
          placeholder='Select'
          options={yearsList}
          value={selectedYear}
          isSearchable={false}
          onChange={handleChangeYear}
        />
      </div>
    </div>
  );
});

export default Requests;
