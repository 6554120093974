import React, { useState } from 'react';

import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { BASE_APP_URL } from '@packs/lib/constants';

import Clipboard from 'react-clipboard.js';
import { EmailShareButton } from 'react-share';

export const CopyOrShare = ({ modalTooltip, onlyIcon, title }) => {
  const [isOpenCopiedMessageTooltip, setIsOpenCopiedMessageTooltip] = useState();
  const { defaultMessages, formatMessage } = useTranslationMessages();

  const toggleTooltip = () => {
    if (!isOpenCopiedMessageTooltip) setTimeout(toggleTooltip, 3000);
    setIsOpenCopiedMessageTooltip(!isOpenCopiedMessageTooltip);
  };

  const inviteMessage = formatMessage(defaultMessages.inviteMessage);

  return (
    <div className='public-link'>
      <div className='copy'>
        <span id={modalTooltip ? 'modal-cliapboardIcon' : 'clipboardIcon'} className='icon'>
          <Clipboard data-clipboard-text={`${inviteMessage} ${BASE_APP_URL}`} className='copy-icon' />
        </span>
        {title && <span className='text'>{title}</span>}
      </div>
      {!onlyIcon && <span>Or share this website via a &nbsp;</span>}
      {!onlyIcon && (
        <EmailShareButton
          className='email-link'
          url={BASE_APP_URL}
          subject={formatMessage(defaultMessages.inviteMailSubject)}
          body={`${inviteMessage} ${BASE_APP_URL}`}
        >
          public share link
        </EmailShareButton>
      )}
    </div>
  );
};
