import React from 'react';

import CheckGreenIcon from '@packs/images/svg/ui/check-green.svg';

export const RequestDetailsItem = ({ label, answer }) => {
  if (!answer) {
    return null;
  }

  const answerValue = Array.isArray(answer) ? answer.join(', ') : answer;

  return (
    <div className='request-details-item d-flex align-items-center'>
      <div className='img-container'>
        <img src={CheckGreenIcon} alt='Check' />
      </div>
      <div className='d-flex flex-column'>
        <div className='fs-13 fw-700 lh-9'>{label}</div>
        <div className='fs-11 lh-5 secondary-text'>{answerValue}</div>
      </div>
    </div>
  );
};
