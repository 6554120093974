import React from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import useStyles from '@packs/components/MaterialUi/Groups/styles';

export const CustomCampaignCardSkeleton = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.wrapper}>
      <Skeleton variant='rounded' width='100%' height={132} sx={{ mb: 1 }} />
      <Skeleton variant='rounded' width='100%' height={42} sx={{ mb: 0.5 }} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
        <Skeleton variant='rounded' width='50%' height={18} />
        <Skeleton variant='rounded' width='50%' height={18} />
      </Box>
      <Skeleton variant='rounded' width='100%' height={24} />
    </Box>
  );
};
