import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Link } from 'react-router-dom';

export const BreadCrumbsComp = ({ list, maxItems = 5 }) => {
  const { isMobile } = useWindowSize();

  return (
    <Breadcrumbs aria-label='breadcrumb' maxItems={maxItems} className={isMobile ? 'mt-16 mb-16' : 'mt-25 mb-32'}>
      {list.map(item => {
        if (item.to) {
          return (
            <Link key={item.to + item.label} to={item.to} className='secondary-text fs-12'>
              {item.label}
            </Link>
          );
        }

        return (
          <div key={item.to + item.label} className='fs-12 fw-500 main-text'>
            {item.label}
          </div>
        );
      })}
    </Breadcrumbs>
  );
};
