import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Button, ButtonProps, Typography, TypographyProps } from '@mui/material';
import { FeaturedOffers } from '@packs/components/MaterialUi/Offers/FeaturedOffers/FeaturedOffers';
import { MyOffersActionPanel } from '@packs/components/MaterialUi/Offers/MyOffersActionPanel/MyOffersActionPanel';
import { MyOffersFilter } from '@packs/components/MaterialUi/Offers/MyOffersFilters/MyOffersFilter';
import { MyOffersTopBar } from '@packs/components/MaterialUi/Offers/MyOffersTopBar/MyOffersTopBar';
import { OffersList } from '@packs/components/MaterialUi/Offers/OffersList/OffersList';
import { PageTitleWrapper } from '@packs/components/MaterialUi/PageTitleWrapper/PageTitleWrapper';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { getGroupsOptions, groupDefaultOption } from '@packs/lib/helpers/groupsHelper';
import { defaultLocality } from '@packs/models/company/constants';
import { currentCountryRegionOptions } from '@packs/models/company/currentCountryRegionOptions';
import { offersTabs } from '@packs/models/offer/constants';
import { useStore } from '@packs/stores';
import { defaultCategory, INIT_FILTERS } from '@packs/stores/OffersStore';
import useLeaderboardStyles from '@packs/styles/Leaderboard/leaderboardWrapper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import _isEqual from 'lodash-es/isEqual';
import _pick from 'lodash-es/pick';
import size from 'lodash-es/size';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const showFilters = [
  'offerType',
  'categoryId',
  'keyWord',
  'rootCategoryCode',
  'customerType',
  'location',
  'locality',
  'campaignGroupId'
];

const MyOffers = observer(() => {
  const query = queryString.parse(location.search);
  const { isDesktop } = useWindowSize();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { offersStore, currentCategory, userStore, currentCountry } = useStore();
  const styles = useStyles();
  const stylesLeaderboard = useLeaderboardStyles();
  const { currentUser } = useUserSelectors();
  const navigate = useNavigate();
  const { MY_OFFERS_PATH } = usePath();
  const filters = offersStore?.filters;

  const [activeOfferTab, setActiveOfferTab] = useState(offersTabs[0].value);

  const regionOptions = currentCountryRegionOptions(currentCountry);

  const handleChangeTab = (_, tab) => {
    setActiveOfferTab(tab);
    const filters: {
      location: null | { label: string; value: string };
      locality: { label: string; value: string };
      campaignGroupId: number | null;
    } = {
      location: null,
      locality: defaultLocality,
      campaignGroupId: null
    };
    if (tab === 'local') {
      if (currentUser?.state) {
        filters.location = { value: currentUser.state, label: currentUser.state };
      }

      if (currentUser?.locality?.name) {
        filters.locality = { value: currentUser.locality.name, label: currentUser.locality.name };
      }

      offersStore.load(filters);
    } else {
      offersStore.load(filters);
    }
  };

  const handleScrollToBegin = () => {
    if (!wrapperRef?.current) {
      return;
    }
    window.scrollTo({
      top: wrapperRef?.current?.offsetTop - 98,
      behavior: 'smooth'
    });
  };

  const defaultFilters = {
    categoryId: defaultCategory,
    location: regionOptions[0],
    locality: defaultLocality,
    campaignGroupId: groupDefaultOption
  };

  const disabledRemove = useMemo(() => {
    return _isEqual(
      _pick(
        { ...INIT_FILTERS, location: regionOptions[0], locality: defaultLocality, campaignGroupId: groupDefaultOption },
        showFilters
      ),
      _pick({ ...offersStore.filters, categoryId: defaultCategory }, showFilters)
    );
  }, [offersStore.filters]);

  const onResetFilters = () => {
    if (!disabledRemove) {
      setActiveOfferTab(offersTabs[0].value);
      offersStore.load({ ...INIT_FILTERS, ...defaultFilters });
      navigate(MY_OFFERS_PATH);
    }
  };

  useEffect(() => {
    const newFilters = {
      location: regionOptions[0],
      locality: defaultLocality,
      campaignGroupId: groupDefaultOption
    };
    if (query?.campaignGroupId) {
      const options = getGroupsOptions(userStore?.currentUserGroups, currentCategory);
      newFilters.campaignGroupId = options.find(
        item => item.value === (query?.campaignGroupId ? +query.campaignGroupId : null)
      );
    }
    if (query?.location) {
      newFilters.location = {
        value: query.location.toString(),
        label: query.location.toString()
      };
    }
    if (query?.locality) {
      newFilters.locality = {
        value: query.locality.toString(),
        label: query.locality.toString()
      };
    }
    offersStore.load(newFilters, false);
    offersStore.loadFeatured();
  }, []);

  useEffect(() => {
    handleScrollToBegin();
  }, [offersStore.filters.page]);

  useEffect(() => {
    const queryFilters = Object.keys(filters).reduce((acc, item) => {
      if (['campaignGroupId', 'locality', 'location'].includes(item)) {
        const value = filters[item];

        if (value?.value && value?.value !== 'all' && value?.value !== 'All') {
          acc[item] = value.value;
        }
      }

      return acc;
    }, {});

    if (size(queryFilters)) {
      navigate(
        MY_OFFERS_PATH.concat(
          `?${Object.keys(queryFilters)
            .map(key => `${key}=${queryFilters[key]}`)
            .join('&')}`
        )
      );
    } else {
      navigate(MY_OFFERS_PATH);
    }
  }, [filters]);

  return (
    <>
      <Helmet>
        <title>My Offers</title>
      </Helmet>
      <Box sx={{ width: '100%' }}>
        <PageTitleWrapper
          homeIsLanding
          title='All the best national and local offers in one place!'
          currentPage='Offers'
          subTitle='Your one stop shop for great deals!'
          sx={{ mb: 0 }}
        />
        <Box sx={styles.featuredWrapper}>
          <Box sx={styles.featureContainer}>
            <FeaturedOffers categoryId={currentCategory?.id} containerStyles={styles.sliderWrapper} />
          </Box>
        </Box>

        <Box sx={styles.offersWrapper} ref={wrapperRef}>
          <MyOffersTopBar
            title={''}
            defaultCategory={''}
            showFilters={showFilters}
            {...{
              onResetFilters,
              disabledRemove,
              defaultFilters
            }}
          />
          <Box sx={stylesLeaderboard.container}>
            {isDesktop && (
              <Box sx={stylesLeaderboard.filterContainer}>
                <Box sx={stylesLeaderboard.filterContainerInner}>
                  <Box sx={stylesLeaderboard.filterTitle}>
                    <Typography variant={'body4' as TypographyProps['variant']}>Filter by:</Typography>
                    <Button
                      variant='text'
                      size={'xs' as ButtonProps['size']}
                      color={'dark' as ButtonProps['color']}
                      sx={{ minWidth: '44px' }}
                      disabled={disabledRemove}
                      onClick={onResetFilters}
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>
                <MyOffersFilter {...{ defaultFilters, showFilters, setActiveOfferTab }} sidebarFilters />
              </Box>
            )}
            <Box sx={stylesLeaderboard.content}>
              <MyOffersActionPanel
                {...{ showFilters, onResetFilters, disabledRemove, activeOfferTab, handleChangeTab }}
              />
              <OffersList openFilters disabledRemove={disabledRemove} onResetFilters={onResetFilters} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default MyOffers;
