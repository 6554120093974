import { useMemo } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { numberWithCommas } from '@packs/lib/constants';
import { useCategories } from '@packs/models/category/useCategories';
import useStyles from '@packs/screens/MyHome/components/MyRenewals/styles';
import { categoriesWithLowestPrice } from '@packs/screens/MyHome/constants';
import { useStore } from '@packs/stores';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

import _find from 'lodash-es/find';
import { observer } from 'mobx-react';

const { white } = colors;

export const MyRenewalsTotal = observer(({ selectedCost, selectedPolicy, currency, renewalsCollection }) => {
  const styles = useStyles();
  const { homeStore } = useStore();
  const details = homeStore.userDetails;
  const renewalsPriceData = homeStore.renewalsPriceData;

  const { flattenCategories } = useCategories();
  const isPerMonth = selectedCost?.value === 'renewalCostMonthly';

  const renewals = renewalsCollection.filter(item => !item?.hide);

  const calcTotal = useMemo(() => {
    return renewals.reduce((acc, item) => {
      const { id } = item;
      const selectedDetailId = selectedPolicy[id];

      const renewalDetail = details.find(detail => detail?.id === selectedDetailId) || details[0] || {};

      const cost = Number(renewalDetail[selectedCost.value] || 0);

      return acc + cost;
    }, 0);
  }, [renewals, selectedPolicy, selectedCost.value, details]);

  const calcTotalAverage = () => {
    return renewals.reduce((acc, item) => {
      const { id } = item;
      const averagePriceData = renewalsPriceData[id]?.averagePriceData;
      const minPriceData = renewalsPriceData[id]?.minPriceData;
      const renewalDetail =
        details.find(detail => detail?.id === selectedPolicy[item?.id]) || (details.length && details[0]) || {};
      const category = _find(flattenCategories, { id });
      const isCategoryLowestPrice = categoriesWithLowestPrice.includes(category?.root?.code);
      const currentPrice = +renewalDetail[selectedCost.value] || 0;

      const averagePrice = isPerMonth ? averagePriceData?.averageMonthly || 0 : averagePriceData?.averageYearly || 0;
      const minPrice = isPerMonth ? minPriceData?.minMonthly || 0 : minPriceData?.minYearly || 0;

      if (currentPrice <= (isCategoryLowestPrice ? minPrice : averagePrice)) {
        return acc;
      }

      if (isCategoryLowestPrice) {
        return acc + (minPrice ? currentPrice - (minPrice || 0) : 0);
      }

      return acc + (averagePrice ? currentPrice - (averagePrice || 0) : 0);
    }, 0);
  };

  const savings = calcTotalAverage();

  return (
    <Box sx={styles.totalWrapper}>
      <Box sx={styles.totalBox}>
        <Typography variant='body6' sx={{ color: white.main }}>
          My Costs
        </Typography>
        <Typography variant='h6' sx={{ color: white.main }}>
          {currency + numberWithCommas(calcTotal.toFixed(0))}
        </Typography>
      </Box>
      <Box sx={styles.separator} />
      <Box sx={styles.totalBox}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
          <Typography variant='body6' sx={{ color: white.main }}>
            Potential savings
          </Typography>
          <Box>
            <TooltipMUI
              title='Your potential savings indicator provides you with a guide on the potential money you could save by shopping around!'
              arrow
              placement='top'
            >
              <InfoOutlinedIcon sx={{ fontSize: '16px', color: '#fff' }} />
            </TooltipMUI>
          </Box>
        </Box>
        <Typography variant='h6' sx={{ color: white.main }}>
          {currency + (savings > 0 ? numberWithCommas(savings.toFixed(0)) : 0)}
        </Typography>
      </Box>
    </Box>
  );
});
