import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SignUpModalLink } from '@packs/components';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';

export const GetStarted = ({
  iconWidth = '557px',
  icon,
  blocksAboveEmpty = false,
  title = 'Ready to get started?',
  description = 'Sign up your business today and one of our account managers will contact you shortly, thank you!'
}) => {
  const { currentUser } = useUserSelectors();

  return (
    <Box sx={{ backgroundImage: 'linear-gradient(180deg, #FFD029 0%, #FB0 100%)' }}>
      <Box
        display='flex'
        width='100%'
        maxWidth='1160px'
        mx='auto'
        px={2}
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row'
          },
          pt: {
            xs: 0,
            md: blocksAboveEmpty ? '200px' : 0
          }
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            alignItems: { xs: 'center', md: 'flex-start' },
            pt: 6,
            pb: { xs: 0, md: 6 },
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          <Typography variant='h3' mb={2}>
            {title}
          </Typography>
          <Typography variant='subtitle1' mb={4}>
            {description}
          </Typography>
          {!currentUser?.id && (
            <SignUpModalLink variant='contained' size='medium' color='white' sx={{ position: 'relative', zIndex: '2' }}>
              Register Now!
            </SignUpModalLink>
          )}
        </Box>
        <Box
          position='relative'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: iconWidth,
            maxWidth: '100%',
            minWidth: {
              md: iconWidth
            },
            mx: {
              xs: 'auto',
              md: 0
            },
            mt: { xs: -2, md: 0 }
          }}
        >
          <Box sx={{ position: { xs: 'static', md: 'absolute' }, left: 0, bottom: 0, width: '100%' }}>
            <img src={icon} alt='started' style={{ width: '100%' }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
