import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import { ReplyItemInsight } from '@shared/components/CompanyPage/components/Review/ReplyItemInsight/ReplyItemInsight';

import get from 'lodash-es/get';
import { observer } from 'mobx-react';

export const RepliesListInsight = observer(({ postId, commentId, insightsCompanyStore, currentUser }) => {
  const replies = useMemo(() => {
    const comments = get(insightsCompanyStore.answers, `${postId}`, []);
    const comment = comments.find(item => item?.id === commentId);
    return get(comment, 'replies', []);
  }, [insightsCompanyStore.answers, postId, commentId]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 2 }}>
      {replies.map(reply => (
        <ReplyItemInsight
          key={reply?.id}
          postId={postId}
          answer={reply}
          commentId={commentId}
          insightsCompanyStore={insightsCompanyStore}
          currentUser={currentUser}
        />
      ))}
    </Box>
  );
});
