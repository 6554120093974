import React, { useState } from 'react';
import cx from 'classnames';

import SentIcon from '@packs/images/icons/UI/SentIcon';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

import flatten from 'lodash-es/flatten';
import size from 'lodash-es/size';
import { observer } from 'mobx-react';
import TagsInput from 'react-tagsinput';

const prettifyTags = tags => {
  const separators = [',', ' ', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r'];

  let emails = tags.map(tag =>
    tag
      .split(new RegExp(separators.join('|')))
      .filter(item => !!item)
      .map(d => d.trim())
  );
  emails = flatten(emails);

  return emails;
};

const correctEmailFormat = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

export const EmailInvite = observer(() => {
  const { userStore, currentCategory } = useStore();
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const disabled = size(emails) === 0 && !inputValue;

  const handleChange = tags => {
    const emails = prettifyTags(tags);

    setEmails(emails);
  };

  const onChangeInput = value => {
    if (value.indexOf(',') > -1 && correctEmailFormat.exec(inputValue)) {
      handleChange([...emails, value]);
      setInputValue('');
    } else {
      setInputValue(value.trim());
    }
  };

  const onSubmit = () => {
    let newEmails = [...emails];

    if (size(emails) === 0) {
      if (inputValue) {
        const validEmail = correctEmailFormat.exec(inputValue);

        if (validEmail) {
          newEmails = [inputValue.trim()];
        } else {
          toastr.error('Incorrect email format');
          return null;
        }
      } else {
        return null;
      }
    }
    const body =
      `Where did you buy your ‘${currentCategory?.name}’?\n` +
      'We are crowdsourcing the best businesses\n' +
      'Add your details here!';

    const newAttributes = {
      emails: newEmails,
      body,
      categoryId: currentCategory?.id
    };

    const callback = () => {
      setEmails([]);
      setInputValue('');
      toastr.success('Email was successfully invited');
    };

    userStore.inviteUsers({ attributes: newAttributes, callback });
  };

  return (
    <div className='email-invite-container d-flex align-items-center position-relative'>
      <TagsInput
        value={emails}
        inputValue={inputValue}
        inputProps={{ placeholder: 'Enter emails…' }}
        validationRegex={correctEmailFormat}
        onChangeInput={onChangeInput}
        onChange={handleChange}
        onValidationReject={() => toastr.error('Incorrect email format')}
      />
      <button className={cx('btn full primary white', { disabled })} disabled={disabled} onClick={onSubmit}>
        <SentIcon />
      </button>
    </div>
  );
});
