import React, { useEffect, useState } from 'react';

import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import api from '@packs/apis/myOffers';
import { OfferItem } from '@packs/components/MaterialUi/Offers/OfferItem/OfferItem';

import Slider from 'react-slick';

const arrowStyles = {
  position: 'absolute',
  bottom: 'calc(100% + 12px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  background: '#EEE',
  cursor: 'pointer',
  zIndex: 2
};

export const OtherOffers = ({ offer }) => {
  const [otherOffers, setOtherOffers] = useState([]);
  const [loader, setLoader] = useState(false);

  const SampleNextArrow = props => {
    const { onClick } = props;

    return (
      <Box
        sx={{
          ...arrowStyles,
          opacity: onClick ? '1' : '0.3',
          cursor: onClick ? 'pointer' : 'auto',
          right: '24px'
        }}
        onClick={onClick}
      >
        <KeyboardArrowRightRoundedIcon />
      </Box>
    );
  };

  const SamplePrevArrow = props => {
    const { onClick } = props;

    return (
      <Box
        sx={{
          ...arrowStyles,
          opacity: onClick ? '1' : '0.3',
          cursor: onClick ? 'pointer' : 'auto',
          right: '64px'
        }}
        onClick={onClick}
      >
        <KeyboardArrowLeftRoundedIcon />
      </Box>
    );
  };

  const settings = {
    className: 'slider variable-width',
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const fetchData = async fetchMore => {
    setLoader(true);
    const resp = await api.fetchOtherOffers({ id: offer.id, page: 1, perPage: 9 });

    if (fetchMore) {
      setOtherOffers([...otherOffers, ...resp.list]);
    } else {
      setOtherOffers(resp.list);
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchData(false);
  }, []);

  if (otherOffers.length === 0 && !loader) return null;

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mt: 5, mb: 2 }}>
        <Typography variant='h6'>Other company offers:</Typography>
      </Box>
      <Box
        sx={{
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            width: '80px',
            background: 'linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)'
          },
          '& .slick-slide': {
            pr: 2,
            '&:last-child': {
              pr: 0
            }
          }
        }}
      >
        <Slider {...settings}>
          {otherOffers.map(offer => (
            <Box key={offer.id}>
              <OfferItem offer={offer} darkMode fixedWidth />
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};
