import { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, Button, ButtonProps, Stack, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import MemberBgIcon from '@packs/images/svg/ui/member-bg.svg';

const { primary, grey, dark, text } = colors;

function TabPanel({ value }) {
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    setIsExpanded(!value?.numerate);
  }, [value]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          height: '400px',
          borderRadius: '16px',
          backgroundImage: `url(${MemberBgIcon})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          '& img': {
            width: '100%',
            height: '100%'
          }
        }}
        mb={3}
      >
        <img src={value.imgSrc} alt={value.title} style={{ borderRadius: 'inherit' }} />
      </Box>
      <Box>
        <Typography variant='h5'>{value.title}</Typography>
        <Typography variant='body1' color={text.secondary} mt={2}>
          {value.description}
        </Typography>
        {isExpanded && value.list && (
          <Stack spacing={2} sx={{ mt: 2 }}>
            {value.list.map((el, index) => (
              <Stack key={el.title} spacing={1}>
                <Stack direction='row' spacing={1}>
                  {value.numerate ? (
                    <Typography variant='body2'>{index + 1}.</Typography>
                  ) : (
                    <CheckIcon color='primary' />
                  )}
                  <Typography
                    variant={value.numerate ? 'body2' : 'body1'}
                    color={!value.numerate ? text.secondary : undefined}
                  >
                    {el.title}
                  </Typography>
                </Stack>
                {el.desc && (
                  <Typography variant='body1' color={text.secondary}>
                    {el.desc}
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack>
        )}
        {isExpanded && value?.description2 && (
          <Typography variant='body1' color={text.secondary} sx={{ mt: 2 }}>
            {value.description2}
          </Typography>
        )}
        {!isExpanded && (
          <Button
            variant='text'
            color={'dark' as ButtonProps['color']}
            size='small'
            endIcon={<ExpandMoreIcon />}
            sx={{ mt: 2 }}
            onClick={() => setIsExpanded(true)}
          >
            Show more
          </Button>
        )}
      </Box>
    </Box>
  );
}

export const MembersDesktop = ({ members, value, handleChange }) => {
  return (
    <Stack direction='row' spacing={5}>
      <TabPanel value={members[value]} />
      <Box sx={{ minWidth: '330px', width: '330px' }} pt={3}>
        <Stack
          direction='column'
          sx={{
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              left: '0',
              top: '0',
              height: '100%',
              width: '8px',
              borderRadius: '8px',
              backgroundColor: grey[200]
            }
          }}
        >
          {members.map((item, index) => {
            const active = value === index;

            return (
              <Box
                key={item.label}
                sx={{
                  position: 'relative',
                  p: 2,
                  pl: 3,
                  cursor: 'pointer',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '8px',
                    height: '100%',
                    borderRadius: '8px',
                    backgroundColor: primary.main,
                    opacity: value === index ? '1' : '0',
                    transition: 'opacity 0.3s'
                  }
                }}
                onClick={handleChange(index)}
              >
                <Typography variant='body2' color={active ? dark.main : text.secondary}>
                  {item.label}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Stack>
  );
};
