import React from 'react';

import { Box, Button, ButtonProps, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { InfoBox } from '@packs/components/MaterialUi/InfoBox/InfoBox';
import Boost1Icon from '@packs/images/svg/ui/boost-1.svg';
import Boost2Icon from '@packs/images/svg/ui/boost-2.svg';
import Boost3Icon from '@packs/images/svg/ui/boost-3.svg';
import BoostSalesIcon from '@packs/images/svg/ui/boost-sales.webp';
import { businessPageUrl } from '@shared/helpers/urlHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Link } from 'react-router-dom';

const { white, secondary, text } = colors;

const benefits = [
  {
    id: 1,
    icon: Boost1Icon,
    title: 'Obtain higher conversion and retention rates!',
    description: 'Winning businesses receive awards which are powered by our community!'
  },
  {
    id: 2,
    icon: Boost2Icon,
    title: 'Generate targeted leads with our custom filters!',
    description: 'Obtain targeted leads for your desired customers!'
  },
  {
    id: 3,
    icon: Boost3Icon,
    title: 'Build & display the social reputation of your business!',
    description: 'You can now aggregate all your ratings and sample reviews in one place!'
  }
];

export const BoostSales = () => {
  const { isMobile } = useWindowSize();

  return (
    <Box sx={{ pt: { xs: 6, md: 15 }, pb: { xs: 6, md: 17 } }}>
      <Box width='100%' maxWidth='1128px' mx='auto'>
        <Typography variant='h2' textAlign='center' mb={2}>
          Does your business offer excellent customer service & value for money?{' '}
        </Typography>
        <Typography variant='subtitle1' textAlign='center' color={text.secondary}>
          If yes, we would love to partner with your business! See the benefits below!
        </Typography>
      </Box>
      <InfoBox
        title={isMobile ? '' : 'Boost your sales!'}
        description={isMobile ? '' : 'Get ready to amplify your sales results with the Bill Winner Team!'}
        infoIcon={BoostSalesIcon}
        infoIconWidth='525px'
        position='left'
        infoIconPosition='90px'
      >
        <Box
          display='grid'
          width='100%'
          maxWidth='998px'
          mx='auto'
          p={{ xs: 3, md: 4 }}
          position='relative'
          zIndex='3'
          sx={{
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
            gap: {
              xs: 2,
              md: 5
            },
            mt: { xs: '-105px', md: '-96px' },
            borderRadius: '24px',
            border: '1px solid rgba(0, 0, 0, 0.16)',
            backgroundColor: white.main
          }}
        >
          {benefits.map(({ id, icon, title, description }) => (
            <Box key={id}>
              <Box
                display='flex'
                gap={{ xs: 1, md: 2 }}
                sx={{
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: 'flex-start',
                  mb: { xs: 1, md: 2 }
                }}
              >
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  width='48px'
                  minWidth='48px'
                  height='48px'
                  borderRadius='8px'
                  sx={{ backgroundColor: secondary.transparent }}
                >
                  <img src={icon} alt='i' />
                </Box>
                <Typography variant='h6'>{title}</Typography>
              </Box>
              <Typography>{description}</Typography>
            </Box>
          ))}
        </Box>
      </InfoBox>
      <Box display='flex' justifyContent='center' mt={{ xs: 3, md: 4 }}>
        <Link to={businessPageUrl()} target='_blank'>
          <Button
            variant='contained'
            size={'xl' as ButtonProps['size']}
            sx={{ minWidth: { xs: '256px', md: '280px' } }}
          >
            Register your business!
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
