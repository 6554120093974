import React from 'react';

import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { SidebarTitle } from '@packs/components/MaterialUi/CompanyPage/components/Sidebar/components/SidebarTitle/SidebarTitle';
import { divideStarRating } from '@shared/helpers/ratings';
import CupIcon from '@shared/images/company/cup.svg';

import useStyles from './styles';

import map from 'lodash-es/map';

const ratingList = [
  { label: 'Customer service', key: 'customerServiceRate' },
  { label: 'Value for money', key: 'valueForMoneyRate' },
  { label: 'Staff knowledge', key: 'staffKnowledgeRate' },
  { label: 'Claims experience', key: 'claimRate' }
];

export const Performance = ({ insightsCompanyStore }) => {
  const styles = useStyles();
  const ratingsPerformanceData = insightsCompanyStore?.object?.ratingsPerformanceData;

  return (
    <SidebarTitle icon={<img src={CupIcon} alt='Cup' />} title='Bill Winner ratings performance'>
      <Box sx={styles.listWrapper}>
        {map(ratingList, item => {
          const rating = divideStarRating(ratingsPerformanceData && ratingsPerformanceData[item.key]);

          return (
            <Box key={item.key} sx={styles.ratingItem}>
              <Typography variant='body1'>{item.label}</Typography>
              <Box sx={styles.ratingSection}>
                <Rating value={1} max={1} readOnly />
                <Typography variant='body3'>{rating ? rating.toFixed(1) : '0.0'}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </SidebarTitle>
  );
};
