import React from 'react';

import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { LeafCategoryElement } from '@packs/components/CategoriesTree/components/CategoryElement/components';
import { EXCLUDED_SUB_CATEGORIES } from '@packs/models/category/constants';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { ICONS } from '@shared/helpers/categoriesIconsHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { MacScrollbar } from 'mac-scrollbar';

export const CategoriesTreeContent = ({ currentCategory, subCategories, handleUpdateCategory }) => {
  const { isDesktop } = useWindowSize();

  return (
    <>
      {currentCategory && (
        <Box>
          {isDesktop && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
              <IconBox
                containerSize='48px'
                iconSize='32px'
                icon={currentCategory.code === 'popular' ? <StarIcon /> : ICONS[currentCategory.enName]}
                borderRadius='8px'
                color={colors.secondary.main}
                backgroundColor={colors.white.main}
                rootIcon
              />
              <Typography variant='h6'>What categories do you wish to save money on?</Typography>
            </Box>
          )}
          <MacScrollbar
            style={{
              flex: 1,
              height: isDesktop ? '440px' : 'calc(100vh - 300px)',
              backgroundImage: colors.modalBg.gradient,
              marginBottom: '16px',
              paddingTop: '16px',
              paddingBottom: '16px'
            }}
            trackStyle={horizontal => ({
              [horizontal ? 'height' : 'width']: 0,
              right: 2,
              border: 0
            })}
            thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
            suppressAutoHide
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' },
                alignItems: { xs: 'flex-start', lg: 'center' },
                gap: { xs: 0, lg: 1.5 },
                rowGap: 1.5,
                flexWrap: 'wrap'
              }}
            >
              {subCategories.length > 0 &&
                subCategories.map(category => {
                  if (EXCLUDED_SUB_CATEGORIES.includes(category.code)) {
                    return null;
                  }
                  return (
                    <LeafCategoryElement
                      key={category.id}
                      {...{ category, handleUpdateCategory }}
                      parentCode={currentCategory.code}
                      code={category.code}
                    />
                  );
                })}
            </Box>
          </MacScrollbar>
        </Box>
      )}
    </>
  );
};
