export const seasonOrder = ['year', 'spring', 'summer', 'autumn', 'winter'];

export const badgeTypeColors = {
  most_popular: {
    dark: '#B5E6FD',
    light: '#E3F1FC'
  },
  best_avg_prices: {
    dark: '#B2DFDB',
    light: '#E0F4F3'
  },
  customer_service: {
    dark: '#D2C5EB',
    light: '#EDE7F6'
  },
  staff_knowledge: {
    dark: '#FECDD2',
    light: '#FFEBEE'
  }
};

export const SORT_TITLE = {
  most_popular: 'Most Popular',
  best_avg_prices: 'Best Priced',
  customer_service: 'Best Customer Service',
  staff_knowledge: 'Top Experts'
};

export const SOURCE_TYPE = {
  provider: 'Provider',
  best_avg_prices: 'Agencies / Brokers',
  comparison_site: 'Comparison Sites'
};

export const SORT_LIST = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'best_avg_prices',
    label: 'Best Priced'
  },
  {
    value: 'most_popular',
    label: 'Most Popular'
  },
  {
    value: 'customer_service',
    label: 'Customer Service'
  },
  {
    value: 'staff_knowledge',
    label: 'Staff Knowledge'
  }
];

export const SEASONS = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'winter',
    label: 'Winter'
  },
  {
    value: 'spring',
    label: 'Spring'
  },
  {
    value: 'summer',
    label: 'Summer'
  },
  {
    value: 'autumn',
    label: 'Autumn'
  }
];
