import React, { lazy, Suspense } from 'react';

import { Loader } from '@packs/components';
import { Navigator } from '@packs/layouts/Navigator';
import AboutUs from '@packs/screens/AboutUs/AboutUs';
import Associations from '@packs/screens/Associations/Associations';
import BecomeAPartner from '@packs/screens/BecomeAPartner/becomeAPartner';
import Business from '@packs/screens/Business';
import CategoryPage from '@packs/screens/CategoryPage/CategoryPage';
import CompanyPayment from '@packs/screens/CompanyPayment/CompanyPayment';
import CompanyPaymentComplete from '@packs/screens/CompanyPayment/CompanyPaymentComplete';
import ConfirmEmail from '@packs/screens/ConfirmEmail/ConfirmEmail';
import CustomCampaign from '@packs/screens/CustomCampaign/CustomCampaign';
import DashboardLayout from '@packs/screens/DashboardLayout/DashboardLayout';
// import Details from '@packs/screens/Details/Details';
import ForgotPasswordUpdate from '@packs/screens/ForgotPasswordUpdate/forgotPasswordUpdate';
import Groups from '@packs/screens/Groups/Groups';
import InvitationAccept from '@packs/screens/InvitationAccept/invitationAccept';
import InviteFriends from '@packs/screens/InviteFriends/InviteFriends';
import { Landing } from '@packs/screens/Landing/landing';
import { MyOffers } from '@packs/screens/MaterialUi/MyOffers/MyOffers';
import { MyResults } from '@packs/screens/MaterialUi/MyResults/MyResults';
import DashboardMyStats from '@packs/screens/MaterialUi/MyStats/MyStats';
import MyAssociations from '@packs/screens/MyAssociations/MyAssociations';
import MyGroups from '@packs/screens/MyGroups/MyGroups';
import MyHome from '@packs/screens/MyHome/MyHome';
import MyNotesDashboard from '@packs/screens/MyNotesDashboard/MyNotesDashboard';
import MyNotesGeneral from '@packs/screens/MyNotesGeneral/MyNotesGeneral';
import MyOffersGlobal from '@packs/screens/MyOffers/MyOffers';
import MyOffersDetailed from '@packs/screens/MyOffersDetailed/MyOffersDetailed';
import { MyQuoteRequests } from '@packs/screens/MyQuoteRequests/MyQuoteRequests';
import OurSharedMission from '@packs/screens/OurSharedMission/ourSharedMission';
import Product from '@packs/screens/Product';
import AllContributions from '@packs/screens/Profile/AllContributions/AllContributions';
import MyActivity from '@packs/screens/Profile/MyActivity/MyActivity';
import ProfileMyReminders from '@packs/screens/Profile/MyReminders/MyReminders';
import ProfileMyReviews from '@packs/screens/Profile/MyReviews';
import QA from '@packs/screens/Profile/QA/QA';
import Requests from '@packs/screens/Profile/Requests/Requests';
import QAGeneral from '@packs/screens/QAGeneral/QAGeneral';
import RequestQuotes from '@packs/screens/RequestQuotes/RequestQuotes';
import ResendConfirmation from '@packs/screens/ResendConfirmation/resendConfirmation';
import ResendUnlock from '@packs/screens/ResendUnlock/resendUnlock';
import RootCategoryLanding from '@packs/screens/RootCategoryLanding/RootCategoryLanding';
import SignIn from '@packs/screens/SignIn/signIn';
import SubCategoryLanding from '@packs/screens/SubCategoryLanding/SubCategoryLanding';
import TipsGeneral from '@packs/screens/TipsGeneral/TipsGeneral';
import Unsubscribe from '@packs/screens/Unsubscribe/Unsubscribe';
// import UpdateDetails from '@packs/screens/UpdateDetails/UpdateDetails';
import Vote from '@packs/screens/Vote/Vote';
import { useStore } from '@packs/stores';

import { AppLayer } from './AppLayer';
import { paths } from './constants';
import { ProfileLayout } from './ProfileLayout';
import SmartReminder from './SmartReminder';

import { observer } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';

const Details = lazy(() =>
  import('@packs/screens/Details/Details')
    .then(({ Details }) => ({ default: Details }))
    .catch(() => ({ default: <div /> }))
);

const UpdateDetails = lazy(() =>
  import('@packs/screens/UpdateDetails/UpdateDetails')
    .then(({ UpdateDetails }) => ({ default: UpdateDetails }))
    .catch(() => ({ default: <div /> }))
);

const homeRoutes = () => (
  <>
    <Route path={paths.MY_HOME} element={<SmartReminder />}>
      <Route path={paths.MY_HOME} element={<MyHome />} exact />
    </Route>
    <Route path={paths.OUR_SHARED_MISSION} element={<OurSharedMission />} exact />
    <Route path={paths.ABOUT_US} element={<AboutUs />} exact />
    <Route path={paths.BECOME_A_PARTNER} element={<BecomeAPartner />} exact />
    <Route path={paths.ASSOCIATION} element={<Associations />} exact />
    <Route path={paths.MY_ASSOCIATIONS} element={<MyAssociations />} exact />
    <Route path={paths.MY_NOTES} element={<MyNotesGeneral />} exact />

    <Route
      path={paths.PROFILE}
      element={
        <div className='app-main-container'>
          <div className='main-wrapper'>
            <ProfileLayout />
          </div>
        </div>
      }
    >
      <Route path={paths.PROFILE_MY_ALL_CONTRIBUTIONS} element={<AllContributions />} exact />
      <Route path={paths.PROFILE_MY_REVIEWS} element={<ProfileMyReviews />} exact />
      <Route path={paths.PROFILE_QA} element={<QA />} exact />
      <Route path={paths.PROFILE_REQUESTS} element={<Requests />} exact />
      <Route path={paths.PROFILE_MY_ACTIVITY} element={<MyActivity />} exact />

      <Route path={paths.PROFILE} element={<Navigator to={paths.PROFILE_MY_ALL_CONTRIBUTIONS} replace />} />
    </Route>

    <Route path={paths.PROFILE_MY_REMINDERS} element={<ProfileMyReminders />} exact />
  </>
);

const categoriesRoutes = () => (
  <Route path={paths.CATEGORY} element={<SmartReminder />}>
    <Route path={paths.LEADERBOARD} element={<DashboardLayout />}>
      <Route path={paths.LEADERBOARD_MY_RESULTS} element={<MyResults />} exact />
      <Route path={paths.LEADERBOARD_MY_STATS} element={<DashboardMyStats />} exact />
      <Route path={paths.LEADERBOARD_MY_OFFERS} element={<MyOffers />} exact />
      <Route path={paths.LEADERBOARD_TIPS} element={<TipsGeneral />} exact />
      <Route path={paths.LEADERBOARD_QA} element={<QAGeneral />} exact />
      <Route path={paths.LEADERBOARD_MY_NOTES} element={<MyNotesDashboard />} exact />
      <Route path={paths.LEADERBOARD_MY_QUOTE_REQUESTS} element={<MyQuoteRequests />} exact />
      {/* <Route path={paths.DASHBOARD_MY_RESULTS} element={<DashboardMyResults />} exact />*/}

      <Route path={paths.LEADERBOARD} element={<Navigator to={paths.LEADERBOARD_MY_RESULTS} replace />} />
    </Route>

    <Route path={paths.DETAILS} element={<UpdateDetails />} exact />
    <Route path={paths.PRODUCT} element={<Product />} exact />
    <Route path={paths.ADD_DETAILS} element={<Details />} exact />
    <Route path={paths.REQUEST_QUOTES} element={<RequestQuotes />} exact />
  </Route>
);

export const AppRoutes = observer(({ campaignPage }) => {
  const { userStore } = useStore();
  const { currentUser } = userStore;

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path={paths.COUNTRY_PREFIX} element={<AppLayer />}>
          <Route index element={<Landing />} exact />

          {/* # Residential */}
          {/* # "groups/:county/:city" # dashboard */}
          {/* # "groups/:county/:city/:category" # leaderboard */}
          {/* # "groups/:county/:city/:category/offers" # offers page */}
          {/* # "groups/:county/:city/:category/personalize" # request a quote */}
          {/* # "groups/:county/:city/:category/:company_name/:offer_name" # offer page */}
          {/* # */}
          <Route path={`${paths.COUNTRY_PREFIX}/groups`}>
            <Route path={':county'}>
              <Route path={':city'}>
                <Route path={''} exact element={<CustomCampaign targetGroupPage={campaignPage} />} />
                <Route path={':category'}>
                  <Route path={''} exact element={<CustomCampaign targetGroupPage={campaignPage} />} />
                  <Route path={'business'} element={<CustomCampaign targetGroupPage={campaignPage} />} />
                  <Route path={'offers'} element={<CustomCampaign targetGroupPage={campaignPage} />} />
                  <Route path={'personalize'} element={<CustomCampaign targetGroupPage={campaignPage} />} />
                  <Route path={':company_name'}>
                    <Route path={':offer_name'} element={<CustomCampaign targetGroupPage={campaignPage} />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          {/* # # Association */}
          {/* # "associations/:association_name/offers" # offers page */}
          {/* # "associations/:association_name/business" # Business CRM */}
          {/* # "associations/:association_name/:category" # leaderboard */}
          {/* # "associations/:association_name/:category/personalize" # request a quote */}
          {/* # "associations/:association_name/:company_name/:offer_name" # offer page */}
          {/* # */}
          <Route path={`${paths.COUNTRY_PREFIX}/associations`}>
            <Route path={':association_name'}>
              <Route path={'offers'} element={<CustomCampaign targetGroupPage={campaignPage} />} />
              <Route path={'business'} element={<CustomCampaign targetGroupPage={campaignPage} />} />
              <Route path={':category'}>
                <Route path={''} exact element={<CustomCampaign targetGroupPage={campaignPage} />} />
                <Route path={'personalize'} element={<CustomCampaign targetGroupPage={campaignPage} />} />
              </Route>
              <Route path={':company_name'}>
                <Route path={':offer_name'} element={<CustomCampaign targetGroupPage={campaignPage} />} />
              </Route>
            </Route>
          </Route>
          {/* # # Campaign */}
          {/* # "campaign/:campaign_name/:category" # leaderboard */}
          {/* # "campaign/:campaign_name/:category/offers" # offers page */}
          {/* # "campaign/:campaign_name/:category/personalize" # RAQ */}
          {/* # "campaign/:campaign_name/:category/:company_name/:offer_name" # offer page */}
          <Route path={`${paths.COUNTRY_PREFIX}/campaign`}>
            <Route path={':campaign_name'}>
              <Route path={':category'}>
                <Route path={''} exact element={<CustomCampaign targetGroupPage={campaignPage} />} />
                <Route path={'offers'} element={<CustomCampaign targetGroupPage={campaignPage} />} />
                <Route path={'personalize'} element={<CustomCampaign targetGroupPage={campaignPage} />} />
                <Route path={':company_name'}>
                  <Route path={':offer_name'} element={<CustomCampaign targetGroupPage={campaignPage} />} />
                </Route>
              </Route>
            </Route>
          </Route>

          {homeRoutes()}

          {categoriesRoutes()}

          <Route path={paths.DASHBOARD_ROOT_LANDING} element={<RootCategoryLanding />} exact />
          <Route path={paths.DASHBOARD_LANDING} element={<SubCategoryLanding />} exact />

          <Route path={paths.VOTE} element={<Vote />} exact />
          <Route path={paths.USERS_RESEND_CONFIRMATION} element={<ResendConfirmation />} exact />
          <Route path={paths.USERS_RESEND_CONFIRMATION_ERROR} element={<ResendConfirmation />} exact />
          <Route path={paths.USERS_RESEND_UNLOCK} element={<ResendUnlock />} exact />
          <Route path={paths.USERS_RESEND_UNLOCK_ERROR} element={<ResendUnlock />} exact />
          <Route path={paths.BUSINESS} element={<Business />} exact />
          <Route path={paths.CHARGE} element={<CompanyPayment />} exact />
          <Route path={paths.CHARGE_COMPLETE} element={<CompanyPaymentComplete />} exact />
          <Route path={paths.CONFIRM_EMAIL} element={<ConfirmEmail />} exact />
          <Route path={paths.UNSUBSCRIBE} element={<Unsubscribe />} exact />
          <Route path={paths.INVITE_FRIENDS} element={<InviteFriends />} exact />
          <Route path={paths.MY_OFFERS} element={<MyOffersGlobal />} exact />
          <Route path={paths.MY_OFFERS_DETAILED} element={<MyOffersDetailed />} exact />
          <Route path={paths.CATEGORY_PAGE} element={<CategoryPage />} exact />
          <Route path={paths.GROUPS_GENERAL_PAGE} element={<Groups />} exact />
          <Route path={paths.MY_GROUPS_PAGE} element={<MyGroups />} exact />

          {!currentUser.id && (
            <>
              <Route path={paths.USERS_SIGN_IN} element={<SignIn />} exact />
              <Route path={paths.USERS_FORGOT_PASSWORD_UPDATE} element={<ForgotPasswordUpdate />} exact />
              <Route path={paths.USERS_INVITATION_ACCEPT} element={<InvitationAccept />} exact />
            </>
          )}
        </Route>
      </Routes>
    </Suspense>
  );
});
