import React, { useMemo } from 'react';

import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import Slider from 'react-slick';

const arrowStyles = {
  position: 'absolute',
  bottom: 'calc(100% + 16px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  background: '#EEE',
  cursor: 'pointer',
  zIndex: 2
};

export const BadgesSlider = ({ badges, season, category, year = '2024' }) => {
  const { windowSize } = useWindowSize();

  const slidesToShow = useMemo(() => {
    if (windowSize <= 1100 && windowSize >= 992) {
      return 3;
    }
    if (windowSize <= 992 && windowSize >= 768) {
      return 2;
    }
    if (windowSize <= 767 && windowSize >= 580) {
      return 3;
    }
    if (windowSize < 580) {
      return 2;
    }
    return 4;
  }, [windowSize]);

  const badgesList = useMemo(() => {
    if (category?.value !== 'all') {
      return badges.filter(item => item.categoryId === category.value);
    }

    return badges;
  }, [category, badges]);

  const disableSlider = slidesToShow >= badgesList.length;

  const SampleNextArrow = props => {
    const { onClick } = props;

    return (
      <Box
        sx={{
          ...arrowStyles,
          opacity: onClick ? '1' : '0.3',
          cursor: onClick ? 'pointer' : 'auto',
          right: '24px'
        }}
        onClick={onClick}
      >
        <KeyboardArrowRightRoundedIcon />
      </Box>
    );
  };

  const SamplePrevArrow = props => {
    const { onClick } = props;

    return (
      <Box
        sx={{
          ...arrowStyles,
          opacity: onClick ? '1' : '0.3',
          cursor: onClick ? 'pointer' : 'auto',
          right: '64px'
        }}
        onClick={onClick}
      >
        <KeyboardArrowLeftRoundedIcon />
      </Box>
    );
  };

  const settings = {
    className: 'slider variable-width',
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  if (badgesList.length === 0) {
    return null;
  }

  return (
    <Box sx={{ position: 'relative', pb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
          px: 3,
          mb: 2,
          minHeight: '32px'
        }}
      >
        <Box>
          <Typography variant='caption'>
            Winner of the {season}, {year}
          </Typography>
        </Box>
        <Divider orientation='horizontal' flexItem sx={{ flexGrow: 1, alignSelf: 'center', height: '1px' }} />
        {!disableSlider && <Box sx={{ minWidth: '72px', height: '32px' }} />}
      </Box>
      <Box sx={{ pl: { xs: 1, lg: 3 } }}>
        {disableSlider ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {badgesList.map(badge => (
              <Box key={badge.id} sx={{ px: 0.5 }}>
                <img src={badge.url} alt='Slider' />
              </Box>
            ))}
          </Box>
        ) : (
          <Slider {...settings}>
            {badgesList.map(badge => (
              <Box key={badge.id} sx={{ px: 0.5 }}>
                <img src={badge.url} alt='Slider' />
              </Box>
            ))}
          </Slider>
        )}
      </Box>
    </Box>
  );
};
