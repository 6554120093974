import colors from '@packs/assets/theme/base/colors';

import Decor from './assets/groups-general-decor.svg';

export default () => ({
  wrapper: {
    backgroundImage: colors.secondary.gradient
  },
  innerWrapper: {
    pt: 3,
    pb: { xs: 10, lg: 18 },
    backgroundImage: `url(${Decor})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'contained'
  },
  groupsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: 4, lg: 6 },
    mt: { xs: 5, lg: 8 }
  },
  groupsSectionList: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(1, 1fr)',
      sm: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)',
      lg: 'repeat(4, 1fr)'
    },
    gap: 3
  },
  emptyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pb: 10,
    textAlign: 'center'
  }
});
