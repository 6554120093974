import React from 'react';

import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LinkMUI from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { SidebarTitle } from '@packs/components/MaterialUi/CompanyPage/components/Sidebar/components/SidebarTitle/SidebarTitle';
import { Avatar } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { pathGen, usePath } from '@packs/layouts/constants';
import { divideStarRating, toastr } from '@packs/lib/helpers';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import { rootCategoriesLanding } from '@shared/constants/categories';
import { local_services } from '@shared/constants/offers';
import { addHttps } from '@shared/helpers/urlHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import map from 'lodash-es/map';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const ratingList = [
  { label: 'Review ratings', key: 'reviewRating' },
  { label: 'Purchase ratings', key: 'purchaseRating' }
];

const ContactItem = ({ label, value, link, children }) => {
  return (
    <Box>
      <Typography variant='body5' sx={{ display: 'block', mb: 0.5, color: 'rgba(0, 0, 0, 0.60)' }}>
        {label}
      </Typography>
      {children ? (
        children
      ) : link ? (
        <Link href={addHttps(value)} target='_blank' sx={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }}>
          <Typography variant='body1'>{value}</Typography>
        </Link>
      ) : (
        <Typography variant='body1'>{value}</Typography>
      )}
    </Box>
  );
};

export const OfferSidebar = observer(({ offer }) => {
  const { countryCode, categoryStore } = useStore();
  const { leaderboardStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { flattenCategories } = useCategories();
  const {
    DASHBOARD_LANDING_PATH_FN,
    DASHBOARD_ROOT_LANDING_PATH_FN,
    DASHBOARD_MY_RESULTS_PATH_FN,
    ADD_DETAILS_PATH_FN
  } = usePath();
  const { isMobile } = useWindowSize();
  const styles = useStyles();

  const { category, company, code } = offer || {};
  const currentCategory = flattenCategories.find(item => item.id === category.id);
  const havePolicy = categoryHavePolicy(category, categoryStore.categoriesWithPolicy, currentUser);
  const { region, address, postalCode, email, phoneNumber, websiteUrl, locality } = offer?.companyContact || {};

  const offerLink = offer?.customWebLink || '';
  const affinityLink = company?.affinityLink?.url || '';
  const websiteLink = offerLink || affinityLink || company.url;
  const rating = divideStarRating(company?.additionalData?.rating) || 0;
  const isCompanyLocal = company?.businessType === local_services;
  const isPopularRootCategory = rootCategoriesLanding.some(popular => popular === category?.root?.code);

  const onClickRequestQuotes = () => {
    categoryStore.setCurrentCategory(currentCategory);
    leaderboardStore.addRequestBusiness(company);
  };

  const copyToClipboard = () => {
    if (code) {
      navigator.clipboard.writeText(code).then(() => {
        toastr.success(`Code copied: ${code}`);
      });
    }
  };

  const categoryUrl = category => {
    let url = DASHBOARD_LANDING_PATH_FN(category);

    if (categoryHavePolicy(category, categoryStore.categoriesWithPolicy, currentUser)) {
      url = DASHBOARD_MY_RESULTS_PATH_FN(category);
    } else if (isPopularRootCategory) {
      url = DASHBOARD_ROOT_LANDING_PATH_FN(category).concat(category.code ? `?initialCategory=${category.code}` : '');
    }

    return url;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={styles.companyWrapper}>
        <Box>
          <Avatar
            avatarUrl={company?.avatarUrl}
            placeholder={company?.name}
            size={isMobile ? '60px' : '120px'}
            square
            fullSize
            withBorder
          />
        </Box>
        <Typography variant='h4' sx={{ mb: 1, textAlign: 'center' }}>
          {company?.name}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <RatingMUI value={rating} size='large' />
          <Typography variant='body4'>{`${rating.toFixed(1)} out of ${5}`}</Typography>
        </Box>
        {code && (
          <Box sx={styles.clipboardWrapper} onClick={copyToClipboard}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Typography variant='body5'>Use a unique code to get a discount on the company`s website.</Typography>
              <Typography variant='body1' sx={{ wordBreak: 'break-all' }}>
                {code}
              </Typography>
            </Box>
            <IconButton onClick={copyToClipboard}>
              <FileCopyRoundedIcon />
            </IconButton>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          {websiteLink && (
            <Button
              component={Link}
              variant='filledTonal'
              size='smallToMedium'
              fullWidth
              href={addHttps(websiteLink)}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='direct-text'>Go to Website</div>
            </Button>
          )}
          {company?.quoteAgent && !isCompanyLocal && (
            <>
              {havePolicy ? (
                <Button
                  component={Link}
                  to={pathGen('REQUEST_QUOTES', { countryCode, category })}
                  variant='contained'
                  size='smallToMedium'
                  color='primary'
                  fullWidth
                  onClick={onClickRequestQuotes}
                >
                  <span>Request Quote</span>
                </Button>
              ) : (
                <Button
                  component={Link}
                  variant='contained'
                  size='smallToMedium'
                  color='primary'
                  to={ADD_DETAILS_PATH_FN(category)}
                  onClick={onClickRequestQuotes}
                >
                  <span>Request quotes</span>
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
      <Divider sx={{ mb: 4 }} />
      <SidebarTitle icon={<StarRateRoundedIcon />} title='Ratings Performance'>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {map(ratingList, item => {
            const rating = divideStarRating(
              offer?.company?.ratingsPerformanceData && offer?.company?.ratingsPerformanceData[item.key]
            );

            return (
              <Box
                key={item.key}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}
              >
                <Typography variant='body1'>{item.label}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <RatingMUI value={1} max={1} />
                  <Typography variant='body1' sx={{ mb: 0.5 }}>
                    {rating ? rating.toFixed(1) : '0.0'}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </SidebarTitle>
      {(region || address || postalCode || email || phoneNumber || websiteUrl || locality) && (
        <>
          <SidebarTitle icon={<LocalPhoneRoundedIcon />} title='Contact'>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {region && <ContactItem label='County:' value={region} />}
              {locality && <ContactItem label='Locality:' value={locality} />}
              {address && <ContactItem label='Address:' value={address} />}
              {postalCode && <ContactItem label='Eircode:' value={postalCode} />}
              {email && (
                <ContactItem label='Email:'>
                  <LinkMUI href={`mailto:${email}`} color='secondary' sx={{ display: 'inline-block' }}>
                    <Typography variant='body1'>{email}</Typography>
                  </LinkMUI>
                </ContactItem>
              )}
              {phoneNumber && (
                <ContactItem label='Mobile phone:'>
                  <LinkMUI href={`tel:${phoneNumber}`} color='secondary' sx={{ display: 'inline-block' }}>
                    <Typography variant='body1'>{phoneNumber}</Typography>
                  </LinkMUI>
                </ContactItem>
              )}
              {websiteUrl && <ContactItem label='Website:' value={websiteUrl} link />}
            </Box>
          </SidebarTitle>
        </>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, px: 3 }}>
        <Button
          component={Link}
          variant='contained'
          size='smallToMedium'
          color='primary'
          fullWidth
          to={categoryUrl(category)}
          onClick={() => {
            category && categoryStore.setCurrentCategory(category);
          }}
        >
          Go to the Leaderboard
        </Button>
        <Typography variant='body3' sx={{ textAlign: 'center', color: colors.text.secondary }}>
          Go to the leaderboard to get <br /> personalized offers!
        </Typography>
      </Box>
    </Box>
  );
});
