import React from 'react';

export const HomeServices = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          id='Vector'
          d='M12 2C11.8678 2.00002 11.7368 2.02626 11.6148 2.07722C11.4928 2.12818 11.382 2.20284 11.2891 2.29688L1.20312 11.0977C1.14013 11.1441 1.08894 11.2048 1.05366 11.2746C1.01838 11.3445 0.999997 11.4217 1 11.5C1 11.6326 1.05268 11.7598 1.14645 11.8536C1.24021 11.9473 1.36739 12 1.5 12H4V20C4 20.552 4.448 21 5 21H9C9.552 21 10 20.552 10 20V14H14V20C14 20.552 14.448 21 15 21H19C19.552 21 20 20.552 20 20V12H22.5C22.6326 12 22.7598 11.9473 22.8536 11.8536C22.9473 11.7598 23 11.6326 23 11.5C23 11.4217 22.9816 11.3445 22.9463 11.2746C22.9111 11.2048 22.8599 11.1441 22.7969 11.0977L12.7168 2.30273C12.7149 2.30077 12.7129 2.29882 12.7109 2.29688C12.618 2.20284 12.5072 2.12818 12.3852 2.07722C12.2632 2.02626 12.1322 2.00002 12 2Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
