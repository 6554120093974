import { useMemo } from 'react';

import colors from '@crm/assets/theme/base/colors';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Avatar } from '@packs/components/UI';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores/base';
import { groupTypeLabel } from '@shared/constants/group';

import useStyles from './syles';

import { Link } from 'react-router-dom';

export const GroupItem = ({ group }) => {
  const { MY_OFFERS_PATH, DASHBOARD_MY_RESULTS_PATH_FN } = usePath();
  const { currentCountry } = useStore();
  const { toggleShareSocialModal } = useModalsContext();
  const styles = useStyles();
  const shareLink = `${window?.location?.origin}/en-${currentCountry?.isoA2Code}/${group?.route}`;

  const actionButton = useMemo(() => {
    if (group?.campaignType === 'business_product') {
      return {
        title: 'See leaderboard',
        url: DASHBOARD_MY_RESULTS_PATH_FN(group)
      };
    } else {
      let url = MY_OFFERS_PATH.concat(`?campaignGroupId=${group.id}`);
      if (group?.campaignType === 'residential') {
        url = url.concat(`&location=${group?.countryRegion?.name}&locality=${group?.locality?.name}`);
      }
      return {
        title: 'See offers',
        url
      };
    }
  }, [group]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={[styles.nameContainer, { width: { xs: '100%', lg: '38%' } }]}>
        <Box sx={styles.avatarContainer}>
          <Avatar avatarUrl={group.backgroundImageUrl} size='64px' />
        </Box>
        <Box>
          <Box>
            <Typography variant='body4'>{group.name}</Typography>
          </Box>
          <Box>
            <Typography variant='body5' sx={{ color: colors.text.secondary }}>{`${
              group?.membersCount || 0
            } members`}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: { xs: '100%', lg: '18%' } }}>
        <Typography variant='body3'>{groupTypeLabel[group?.campaignType] || ''} Group</Typography>
      </Box>

      <Box sx={[styles.locationContainer, { width: { xs: '100%', lg: '32%' } }]}>
        {(group?.countryRegion?.name || group?.locality?.name) && (
          <>
            <PlaceRoundedIcon sx={{ color: colors.text.secondary, fontSize: '16px' }} />
            <Typography variant='body3'>{`${group?.countryRegion?.name ? group?.countryRegion?.name + ',' : ''} ${
              group?.locality?.name ? group?.locality?.name : ''
            }`}</Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 2,
          width: { xs: '100%', lg: '22%' },
          minWidth: '200px'
        }}
      >
        <Button
          component={Link}
          to={actionButton.url}
          variant='filledTonal'
          color='primary'
          size='small'
          sx={{ width: { xs: '100%', md: 'auto' } }}
        >
          {actionButton.title}
        </Button>
        <IconButton
          size='small'
          onClick={() =>
            toggleShareSocialModal({
              modalProps: { shareLink, shareMessage: 'Invite friends to join this group!' }
            })
          }
        >
          <PersonAddRoundedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
