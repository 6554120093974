import React from 'react';

import colors from '@packs/assets/theme/base/colors';
import { pathGen } from '@packs/layouts/constants';
import { divideStarRating } from '@packs/lib/helpers';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import { Link } from 'react-router-dom';

export const InsightCompany = ({ company, category }) => {
  const { countryCode } = useStore();

  const onVisitCompanyPage = () => {
    handleVisitCompany(company);
  };

  if (!company) {
    return null;
  }

  return (
    <Link to={pathGen('BUSINESS', { countryCode, company })} className='review-company' onClick={onVisitCompanyPage}>
      <div className='d-flex align-items-center justify-content-between mb-16'>
        <div className='d-flex align-items-center'>
          <div className='company-avatar'>
            <img src={company.avatarUrl} alt='Avatar' />
          </div>
          <div className='name'>{company.name}</div>
        </div>
        <div>{SUB_ICONS({ category, fill: colors?.dark.main })}</div>
      </div>
      <div className='rating-container'>
        <div className='review-rating d-flex align-items-center'>
          <RatingMUI value={divideStarRating(company.insightsRating) || 0} />
          <div className='rating-number ml-4'>{divideStarRating(company.insightsRating) || 0}</div>
        </div>
      </div>
    </Link>
  );
};
