import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import { TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { OfferItem } from '@packs/components/MaterialUi/Offers/OfferItem/OfferItem';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import { observer } from 'mobx-react';
import AnimateHeight from 'react-animate-height';
import Slider from 'react-slick';

const { grey, primary } = colors;

type Props = {
  categoryId?: number;
  rootCategoryCode?: string;
  containerStyles?: Record<string, any>;
  darkMode?: boolean;
  featuredTitleStyles?: Record<string, any>;
  featuredArrowsStyles?: Record<string, any>;
  openFilters?: boolean;
};

export const FeaturedOffers: FC<Props> = observer(
  ({
    categoryId,
    rootCategoryCode,
    containerStyles = {},
    darkMode = true,
    featuredTitleStyles = {},
    featuredArrowsStyles = {},
    openFilters = false
  }) => {
    const { isMobile, isDesktop } = useWindowSize();
    const { offersStore } = useStore();

    const styles = useStyles();
    const [activeSlide, setActiveSlide] = useState(0);

    const offers = offersStore?.featuredOffers;

    const SampleNextArrow = props => {
      const { className, onClick } = props;
      return (
        <Box
          className={cx(className, 'arrow')}
          sx={{ ...styles.sliderArrow, ...styles.nextArrow, ...featuredArrowsStyles }}
          onClick={onClick}
        >
          <ChevronRightRoundedIcon />
        </Box>
      );
    };

    const SamplePrevArrow = props => {
      const { className, onClick } = props;
      return (
        <Box
          className={cx(className, 'arrow')}
          sx={{ ...styles.sliderArrow, ...styles.prevArrow, ...featuredArrowsStyles }}
          onClick={onClick}
        >
          <KeyboardArrowLeftRoundedIcon />
        </Box>
      );
    };

    const slidesToShow = isMobile ? 1 : isDesktop ? (openFilters ? 3 : 4) : 3;
    const showSlider = offers?.length >= slidesToShow;
    const wideItem = !isMobile && offers.length === 1;

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow,
      dots: !offersStore?.featuredLoader && isMobile,
      arrows: !offersStore?.featuredLoader && !isMobile,
      beforeChange: (current, next) => setActiveSlide(next),
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

    useEffect(() => {
      offersStore.loadFeatured(categoryId, rootCategoryCode);
    }, [categoryId, rootCategoryCode]);

    if (!offersStore?.featuredLoader && offers.length === 0) {
      return null;
    }

    return (
      <AnimateHeight duration={250} height={offers?.length ? 'auto' : 0}>
        <Box sx={{ ...styles.container, ...containerStyles }}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.titleWrapper}>
              <Typography variant='h6' sx={featuredTitleStyles}>
                Featured Offers
              </Typography>
              {offers?.length > 1 && !isMobile && offers?.length !== slidesToShow && (
                <Typography variant={'body4' as TypographyProps['variant']}>
                  {`${activeSlide + 1}/${offers?.length}`}
                </Typography>
              )}
            </Box>
            {offersStore?.featuredLoader ? (
              <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  '& .slick-dots': {
                    left: '50%',
                    bottom: '-36px',
                    width: '100%',
                    transform: 'translateX(-50%)',
                    '& li': {
                      '& button': {
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        backgroundColor: grey[500],
                        '&:before': {
                          content: 'none'
                        }
                      },
                      '&.slick-active button': {
                        backgroundColor: primary.main
                      }
                    }
                  }
                }}
              >
                {showSlider ? (
                  <Slider {...settings}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {offers.map((offer, index) => (
                      <Box
                        key={offer.id}
                        justifyContent='center'
                        sx={{ display: 'flex !important', px: { xs: 0, lg: 2 } }}
                      >
                        <OfferItem offer={offer} isSliderType index={index} darkMode={darkMode} />
                      </Box>
                    ))}
                  </Slider>
                ) : (
                  <Box sx={{ display: 'flex' }}>
                    {offers.map((offer, index) => (
                      <Box
                        key={offer.id}
                        justifyContent='center'
                        sx={{ display: 'flex !important', width: wideItem ? '100%' : 'auto', px: { xs: 0, lg: 2 } }}
                      >
                        <OfferItem offer={offer} isSliderType index={index} darkMode={darkMode} wideItem={wideItem} />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </AnimateHeight>
    );
  }
);
