import camelCase from 'lodash-es/camelCase';
import isArray from 'lodash-es/isArray';
import isObjectLike from 'lodash-es/isObjectLike';
import isPlainObject from 'lodash-es/isPlainObject';
import set from 'lodash-es/set';
import snakeCase from 'lodash-es/snakeCase';
import transform from 'lodash-es/transform';

const convertedKey = (key, converter) => {
  return converter(key);
};

const createIteratee = (converter, self) => {
  return (result, value, key) => set(result, convertedKey(key, converter), isObjectLike(value) ? self(value) : value);
};

const createHumps = keyConverter => {
  return function humps(node) {
    if (isArray(node)) {
      return node.map(humps);
    }
    if (isPlainObject(node)) {
      return transform(node, createIteratee(keyConverter, humps));
    }
    return node;
  };
};

export const toCamelCase = value => {
  switch (typeof value) {
    case 'object':
      return createHumps(camelCase)(value);
    case 'string':
      return convertedKey(value, camelCase);
    default:
      return value;
  }
};

export const toSnakeCase = value => {
  switch (typeof value) {
    case 'object':
      return createHumps(snakeCase)(value);
    case 'string':
      return convertedKey(value, snakeCase);
    default:
      return value;
  }
};

export const avatarPlaceholder = displayName => {
  if (!displayName) {
    return '';
  }

  return displayName[0].toUpperCase();
};

export const stringToColor = str => {
  if (!str) {
    return '#ced4da';
  }
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).slice(-2);
  }
  return color;
};
