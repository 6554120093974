import { questionsQueries } from '@packs/graphql/queries';
import { graphQLRequest } from '@packs/lib/utils';

import omit from 'lodash-es/omit';

export const questionsApi = {
  list: variables =>
    graphQLRequest({
      query: questionsQueries.qaPostList,
      variables
    }),
  listAnswers: variables =>
    graphQLRequest({
      query: questionsQueries.qaAnswerList,
      variables
    }),
  create: variables =>
    graphQLRequest({
      query: questionsQueries.createPost,
      type: 'multipart',
      data: [
        ...Object.keys(omit(variables, ['files', 'deleteFilesIds', 'mentionedCompanyIds', 'keywords'])).map(key => ({
          key: `variables[input][${key}]`,
          value: variables[key]
        })),
        ...(variables?.files || []).map(file => ({ key: 'variables[input][files][]', value: file })),
        ...(variables?.mentionedCompanyIds || []).map(mention => ({
          key: 'variables[input][mentionedCompanyIds][]',
          value: mention
        })),
        ...(variables?.keywords || []).map(keyword => ({ key: 'variables[input][keywords][]', value: keyword }))
      ]
    }),
  update: variables =>
    graphQLRequest({
      query: questionsQueries.updatePost,
      type: 'multipart',
      data: [
        ...Object.keys(omit(variables, ['files', 'deleteFilesIds', 'mentionedCompanyIds', 'keywords'])).map(key => ({
          key: `variables[input][${key}]`,
          value: variables[key]
        })),
        ...(variables?.files || []).map(file => ({ key: 'variables[input][files][]', value: file })),
        ...(variables?.deleteFilesIds || []).map(file => ({ key: 'variables[input][deleteFilesIds][]', value: file })),
        ...(variables?.mentionedCompanyIds || []).map(mention => ({
          key: 'variables[input][mentionedCompanyIds][]',
          value: mention
        })),
        ...(variables?.keywords || []).map(keyword => ({ key: 'variables[input][keywords][]', value: keyword }))
      ]
    }),
  createComment: variables =>
    graphQLRequest({
      query: questionsQueries.createComment,
      variables: { input: variables }
    }),
  createHelpful: variables =>
    graphQLRequest({
      query: questionsQueries.createHelpful,
      variables: { input: variables }
    }),
  destroyHelpful: variables =>
    graphQLRequest({
      query: questionsQueries.destroyHelpful,
      variables: { input: variables }
    }),
  report: variables =>
    graphQLRequest({
      query: questionsQueries.createAbuseReport,
      variables: { input: variables }
    }),
  destroy: variables =>
    graphQLRequest({
      query: questionsQueries.destroyPost,
      variables: { input: variables }
    })
};
