import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { useStore } from '@packs/stores';
import { rootCategoriesLanding } from '@shared/constants/categories';

import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

const { secondary, dark } = colors;

export const SubCategoryMenu = observer(({ subCategory, onRedirect }) => {
  const { currentUser } = useUserSelectors();
  const { categoryStore } = useStore();
  const { DASHBOARD_MY_RESULTS_PATH_FN, DASHBOARD_LANDING_PATH_FN, DASHBOARD_ROOT_LANDING_PATH_FN } = usePath();

  const categoryIsRootLanding = rootCategoriesLanding.some(item => item === subCategory.code);

  const categoryUrl = () => {
    let url = DASHBOARD_LANDING_PATH_FN(subCategory);
    if (categoryIsRootLanding) {
      url = DASHBOARD_ROOT_LANDING_PATH_FN(subCategory);
    } else if (categoryHavePolicy(subCategory, categoryStore.categoriesWithPolicy, currentUser)) {
      url = DASHBOARD_MY_RESULTS_PATH_FN(subCategory);
    }

    return url;
  };

  return (
    <Box px={2}>
      <Stack
        component={NavLink}
        to={categoryUrl()}
        onClick={onRedirect}
        direction='row'
        spacing={1}
        sx={{ py: 1 }}
        color={dark.main}
        fontWeight='700'
      >
        <CategoryIconBox category={subCategory} color={secondary.main} backgroundColor={secondary.transparent} />
        <Typography variant='body4'>{subCategory.name}</Typography>
      </Stack>
    </Box>
  );
});
