import colors from '@packs/assets/theme/base/colors';

const { grey } = colors;

export default () => ({
  requestTopBar: {
    top: {
      xs: '80px',
      lg: '56px'
    },
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 8px 4px 0px rgba(0, 0, 0, 0.04)'
  },
  contactInfoWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 4
  },
  contactInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    flexGrow: 1
  },
  uploadWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 2,
    py: 2,
    px: 3,
    backgroundColor: grey[100]
  },
  uploadTitleSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    flexGrow: 1,
    maxWidth: '485px'
  },
  uploadLabel: {
    cursor: 'pointer'
  },
  btnContainer: {
    maxWidth: {
      xs: '100%',
      lg: 'calc(100% - 387px)'
    },
    mt: 3
  }
});
