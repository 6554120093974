import React, { FC, useEffect } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ButtonProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { OfferItem } from '@packs/components/MaterialUi/Offers/OfferItem/OfferItem';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import slice from 'lodash-es/slice';
import { observer } from 'mobx-react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const { white } = colors;

type Props = {
  categoryId?: number;
  rootCategoryCode?: string;
  withBottomGap?: boolean;
};

export const LandingOffers: FC<Props> = observer(({ categoryId, rootCategoryCode, withBottomGap = false }) => {
  const styles = useStyles();
  const { isMobile, windowSize } = useWindowSize();
  const { MY_OFFERS_PATH } = usePath();
  const { offersStore } = useStore();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: isMobile ? 0.2 : 0.5
  });

  const offers = slice(offersStore?.featuredOffers, 0, 4);
  const slidesCount = windowSize > 576 ? 2 : 1;

  const settings = {
    infinite: true,
    speed: 500,
    centerMode: !isMobile,
    slidesToShow: offersStore?.featuredOffers.length === 1 ? 1 : Math.floor(slidesCount) || 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true
  };

  useEffect(() => {
    offersStore.loadFeatured(categoryId, rootCategoryCode);
  }, []);

  if (!offersStore?.featuredLoader && offers.length === 0) {
    if (withBottomGap) {
      return <Box sx={{ display: { xs: 'none', md: 'block' }, height: '269px' }} />;
    }
    return null;
  }

  return (
    <Box sx={styles.wrapper} ref={ref}>
      <Box width='100%' maxWidth='1128px' mx='auto'>
        <Typography variant='h2' color={white.main} sx={styles.title}>
          Our featured offers
        </Typography>
        {offersStore?.featuredLoader && (
          <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
            <CircularProgress />
          </Box>
        )}
        {isMobile ? (
          <Box sx={styles.sliderWrapper}>
            <Slider {...settings}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {offers.map((offer, index) => (
                <Box key={offer.id} justifyContent='center' px={2} sx={{ display: 'flex !important' }}>
                  <OfferItem offer={offer} isSliderType fullWidthItem index={index} />
                </Box>
              ))}
            </Slider>
          </Box>
        ) : (
          <Stack direction='row' justifyContent='flex-start' spacing={4}>
            {offers.map((offer, index) => (
              <OfferItem key={offer.id} {...{ offer, index, inView }} />
            ))}
          </Stack>
        )}
        <Box sx={styles.viewMoreBtn}>
          <Button
            component={Link}
            to={MY_OFFERS_PATH}
            variant='contained'
            color={'white' as ButtonProps['color']}
            endIcon={<ChevronRightIcon />}
          >
            View All Offers
          </Button>
        </Box>
      </Box>
      {withBottomGap && <Box sx={styles.footerGap} />}
    </Box>
  );
});
