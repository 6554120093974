import colors from '@packs/assets/theme/base/colors';

const { grey } = colors;

export default () => ({
  featuredWrapper: {
    backgroundColor: grey[100]
  },
  featureContainer: {
    width: '100%',
    maxWidth: '1160px',
    mx: 'auto',
    pt: 3,
    pb: 2
  },
  featureToSection: {
    px: 2
  },
  title: withMargin => ({
    color: 'inherit',
    mt: {
      xs: 3,
      lg: 8
    },
    mb: {
      xs: withMargin ? 3 : 0,
      lg: withMargin ? 7 : 0
    },
    transition: 'all .2s'
  }),
  sliderWrapper: {
    background: 'none'
  },
  offersWrapper: {
    width: '100%',
    maxWidth: '1160px',
    mx: 'auto',
    px: 2,
    py: {
      xs: 4,
      lg: 6
    }
  }
});
