import React, { useMemo } from 'react';
import cx from 'classnames';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { CheckBoxField, InfoComponent } from '@packs/components';
import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import PhoneInput from '@packs/components/PhoneInput/phoneInput';
import { CategoryTopBar } from '@packs/components/RequestQuoteModal/components/Form/CategoryTopBar/CategoryTopBar';
import useStyles from '@packs/components/RequestQuoteModal/components/Form/styles';
import { RequestSidebar } from '@packs/components/RequestQuoteModal/components/RequestSidebar';
import { SelectField } from '@packs/components/SelectField';
import AttachedDocument from '@packs/components/UI/MyNotes/components/attachedDocument';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import PhoneProtectIcon from '@packs/images/svg/ui/phone-protect.svg';
import { useCategories } from '@packs/models/category/useCategories';
import { useDetails } from '@packs/models/policy/useDetails';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import size from 'lodash-es/size';
import { Field } from 'redux-form';

const { text } = colors;

export const ContactInfo = ({
  files,
  category,
  onFileAdd,
  removeFile,
  showVerificationForm,
  submittedForm,
  policyOptions
}) => {
  const styles = useStyles();
  const { isMobile, isDesktop } = useWindowSize();
  const { currentUser } = useUserSelectors();
  const { currentCategory } = useCategories();
  const { currentPolicy } = useDetails();
  const defaultCountry = currentUser?.country?.isoA2Code || 'ie';

  const disabledUpload = files.length > 0;

  const hideUpload = ['subscriptions'].some(item => item === category.code || item === category.root.code);

  const postalCodePlaceholder = useMemo(() => {
    switch (currentUser?.country?.isoA2Code) {
      case 'en-ie':
        return 'Eircode ';
      case 'en-us':
        return 'Zip Code';
      default:
        return 'Post Code';
    }
  }, [currentUser?.country?.isoA2Code]);

  return (
    <div>
      <CategoryTopBar description='Please add your contact information!' />
      <Box sx={styles.contactInfoWrapper}>
        <Box sx={styles.contactInfoContainer}>
          <Field
            name='address'
            component={FieldWithErrors}
            type='text'
            label='Address'
            disableFocusReset
            material
            variant='outlined'
          />
          <div className={cx('d-flex gap-24', isMobile ? 'flex-column' : 'align-items-start')}>
            <div className='input-field'>
              <Field
                type='text'
                component={PhoneInput}
                name='phoneNumber'
                placeholder='Phone (ex. +353 123...)'
                countryCallingCodeEditable={false}
                defaultCountry={defaultCountry}
              />
              <div className='d-flex'>
                <InfoComponent
                  popoverId='phone-protection'
                  placement='top'
                  popoverTrigger={
                    <div className='phone-protect d-flex align-items-center gap-4 fs-13 mt-4 secondary-text'>
                      <img src={PhoneProtectIcon} alt='Privacy' />
                      Phone protection
                    </div>
                  }
                  fade={false}
                  dark
                  infoComponentClassName='justify-content-start'
                >
                  <div>We mask the users phone number.</div>
                </InfoComponent>
              </div>
            </div>
            <div className='input-field'>
              <Field
                name='postalCode'
                component={FieldWithErrors}
                type='text'
                label={postalCodePlaceholder}
                disableFocusReset
                material
                variant='outlined'
              />
            </div>
          </div>
          {!hideUpload && (
            <Box sx={styles.uploadWrapper}>
              <Box sx={styles.uploadTitleSection}>
                <Typography variant='body2'>Upload your bill (Optional)</Typography>
                <Typography variant='body1'>
                  If you have an existing contract or policy, you can upload it here to obtain a more accurate quote!
                </Typography>
              </Box>
              <div>
                {files.length > 0 ? (
                  <div className='attached-files d-flex align-items-center justify-content-center --new pt-0 mt-20'>
                    {files.map((file, index) => (
                      <AttachedDocument
                        key={index}
                        isNew
                        {...{
                          index,
                          file,
                          removeFile
                        }}
                      />
                    ))}
                  </div>
                ) : (
                  <>
                    <Button
                      as='label'
                      htmlFor='atach'
                      variant='filledTonal'
                      color='primary'
                      size='smallToMedium'
                      sx={styles.uploadLabel}
                    >
                      Upload PDF or Photo
                    </Button>
                    {!disabledUpload && (
                      <input
                        className='hidden d-none'
                        type='file'
                        accept='image/*, .pdf'
                        name='atach'
                        id='atach'
                        onChange={onFileAdd}
                      />
                    )}
                  </>
                )}
              </div>
            </Box>
          )}
          <Field
            component={FieldWithErrors}
            name='comment'
            label='Enter your comments...'
            disableFocusReset
            material
            multiline
            variant='outlined'
            minRows={5}
          />
          {size(policyOptions) > 1 ? (
            <div className='policy-select'>
              <Field
                name='renewalDetailId'
                classNamePrefix='Select'
                placeholder='Select policy'
                options={policyOptions}
                component={SelectField}
                material
              />
            </div>
          ) : (
            <Typography>{`Your policy: ${policyOptions[0]?.label}`}</Typography>
          )}
          <Field
            component={CheckBoxField}
            name='check'
            alignCheckbox={'flex-start'}
            label='By checking this box and clicking ‘submit’ you agree for your contact and policy details to be shared with your chosen businesses so that they can contact you and provide you with a quote!'
            color={text.secondary}
          />
        </Box>
        {isDesktop && !(showVerificationForm || submittedForm) && (
          <RequestSidebar category={currentCategory} renewalDetail={currentPolicy} />
        )}
      </Box>
    </div>
  );
};
