import { ArtsAndEntertainment } from '@shared/images/categories/ArtsAndEntertainment';
import { BankingIcon } from '@shared/images/categories/BankingIcon';
import { BeautyAndPersonalCare } from '@shared/images/categories/BeautyAndPersonalCare';
import { BroadbandIcon } from '@shared/images/categories/BroadbandIcon';
import { BusinessIcon } from '@shared/images/categories/BusinessIcon';
import { CarServices } from '@shared/images/categories/CarServices';
import { ClothingAndAccessories } from '@shared/images/categories/ClothingAndAccessories';
import { CommunityAndGovernment } from '@shared/images/categories/CommunityAndGovernment';
import { EnergyIcon } from '@shared/images/categories/EnergyIcon';
import { FinancialServices } from '@shared/images/categories/FinancialServices';
import { FoodIcon } from '@shared/images/categories/FoodIcon';
import { GeneralIcon } from '@shared/images/categories/GeneralIcon';
import { GovernmentIcon } from '@shared/images/categories/GovernmentIcon';
import { HomeServices } from '@shared/images/categories/HomeServices';
import { InsuranceIcon } from '@shared/images/categories/InsuranceIcon';
import { LegalServices } from '@shared/images/categories/LegalServices';
import { LocalSearch } from '@shared/images/categories/LocalSearch';
import { LocalServicesIcon } from '@shared/images/categories/LocalServicesIcon';
import { LocalShopping } from '@shared/images/categories/LocalShopping';
import { MedicalServices } from '@shared/images/categories/MedicalServices';
import { MobileIcon } from '@shared/images/categories/MobileIcon';
import { PetServices } from '@shared/images/categories/PetServices';
import { ProfessionalServices } from '@shared/images/categories/ProfessionalServices';
import { SubscriptionsIcon } from '@shared/images/categories/SubscriptionsIcon';
import { TravelIcon } from '@shared/images/categories/TravelIcon';
import { WasteCleaningIcon } from '@shared/images/categories/WasteCleaningIcon';
import { WeddingServices } from '@shared/images/categories/WeddingServices';

export const ICONS = {
  insurance: <InsuranceIcon />,
  broadband: <BroadbandIcon />,
  energy: <EnergyIcon />,
  subscriptions: <SubscriptionsIcon />,
  business: <BusinessIcon />,
  mobile: <MobileIcon />,
  government: <GovernmentIcon />,
  banking: <BankingIcon />,
  waste_collection: <WasteCleaningIcon />,
  general: <GeneralIcon />,
  local_services: <LocalServicesIcon />,
  travel: <TravelIcon />,
  food: <FoodIcon />,
  local_search: <LocalSearch />,
  home_services: <HomeServices />,
  medical_services: <MedicalServices />,
  car_services: <CarServices />,
  legal_services: <LegalServices />,
  pet_services: <PetServices />,
  beauty_and_personal_care: <BeautyAndPersonalCare />,
  arts_and_entertainment: <ArtsAndEntertainment />,
  clothing_and_accessories: <ClothingAndAccessories />,
  financial_services: <FinancialServices />,
  community_and_government: <CommunityAndGovernment />,
  professional_services: <ProfessionalServices />,
  wedding_services: <WeddingServices />,
  local_shopping: <LocalShopping />
};

export const defaultLocalSearchCategory = { id: 'local_search', code: 'local_search', name: 'Local Search' };
