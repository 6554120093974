import React, { FC, SyntheticEvent, useState } from 'react';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, Tab, Tabs, TabsProps, Typography, TypographyProps } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { AppliedFilters } from '@packs/components/MaterialUi/Leaderboard/AppliedFilters/AppliedFilters';
import { OffersSearch } from '@packs/components/MaterialUi/Offers/MyOffersFilters/components/OffersSearch/OffersSearch';
import { appliedFiltersLabels, offerTypes } from '@packs/models/company/constants';
import { offersTabs } from '@packs/models/offer/constants';
import { useStore } from '@packs/stores';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

type Props = {
  showFilters: any[];
  defaultCategory?: number | string;
  activeOfferTab?: string;
  handleChangeTab: (event: SyntheticEvent, value: any) => void;
};

export const MyOffersActionPanel: FC<Props> = observer(
  ({ showFilters, defaultCategory, activeOfferTab, handleChangeTab }) => {
    const styles = useStyles();
    const { offersStore } = useStore();
    const { isDesktop } = useWindowSize();

    const [showSearch, setShowSearch] = useState(false);

    const toggleShowSearch = () => {
      setShowSearch(prevState => !prevState);
    };

    return (
      <Box sx={styles.actionPanelWrapper}>
        <Box sx={styles.container}>
          <Box sx={styles.searchWrapper}>
            {isDesktop &&
              (!showSearch ? (
                <Box sx={styles.searchButton} onClick={toggleShowSearch}>
                  <SearchRoundedIcon />
                </Box>
              ) : (
                <OffersSearch {...{ defaultCategory }} />
              ))}
            <Typography variant={'body3' as TypographyProps['variant']} sx={styles.resultsTitle}>
              {`${offersStore?.totalCount} offers found`}
            </Typography>
            <Box sx={{ ml: { xs: 0, lg: 'auto' } }}>
              <Tabs
                value={activeOfferTab}
                onChange={handleChangeTab}
                variant={'buttonTabs' as TabsProps['variant']}
                sx={{ backgroundColor: '#EEE' }}
              >
                {offersTabs.map(({ label, value }) => (
                  // 'Variant' is custom param here
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <Tab
                    key={value}
                    label={label}
                    value={value}
                    variant={'buttonTabs'}
                    sx={{ color: `${colors.dark.main} !important` }}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
        </Box>
        <AppliedFilters
          filters={appliedFiltersLabels(
            { ...offersStore.filters, categoryId: defaultCategory },
            showFilters,
            offersStore.filters?.criteriaQuestions,
            [...offerTypes]
          )}
        />
      </Box>
    );
  }
);
