import React, { useEffect, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { MaterialRadioGroup } from '@packs/components/MaterialUi/MaterialRadioGroup/MaterialRadioGroup';
import { ProfileSkeleton, QuestionsCreteButton } from '@packs/components/UI';
import { DashboardGroupsFilter } from '@packs/components/UI/Dashboard/DashboardGroupsFilter/DashboardGroupsFilter';
import { DashboardLocalityFilter } from '@packs/components/UI/Dashboard/DashboardLocalityFilter/DashboardLocalityFilter';
import { QuestionsActionPanel } from '@packs/components/UI/Questions/QuestionsActionPanel/QuestionsActionPanel';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useProfileContext } from '@packs/layouts/ProfileLayout/context';
import { defaultLocality } from '@packs/models/company/constants';
import { EmptyState } from '@packs/screens/Profile/components/EmptyState';
import { useStore } from '@packs/stores';
import useLeaderboardStyles from '@packs/styles/Leaderboard/leaderboardWrapper';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { Pagination } from '@shared/components/Pagination/Pagination';
import { QuestionItem } from '@shared/components/QA/QuestionItem';
import { TypeFilter } from '@shared/components/QA/TypeFilter/TypeFilter';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { allTypes, postTypes } from '@shared/lib/constants';

import { MyQATopBar } from './MyQATopBar/MyQATopBar';
import useStyles from './styles';

import get from 'lodash-es/get';
import _isEqual from 'lodash-es/isEqual';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const questionsPerPage = 10;
const ALL_COUNTRY_REGIONS = 'all';
const trustNetworkFilterOptions = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: true,
    label: 'My Trust Network'
  }
];

export const Questions = observer(
  ({
    postType,
    category,
    companyId,
    initialTab = '',
    emptyTitle = '',
    emptyDescription = '',
    myProfile = null,
    typeFilter = false,
    withOutCreate = false,
    withOutCategoryMode = false,
    showAllTypes = false,
    withSideBar = false,
    isSingleButton = false
  }) => {
    const location = useLocation();
    const query = queryString.parse(location.search);
    const { isDesktop } = useWindowSize();
    const wrapperRef = useRef(null);
    const styles = useStyles();
    const stylesLeaderboard = useLeaderboardStyles();
    const context = useProfileContext();
    const { currentUser } = useUserSelectors();
    const { toggleCreateQuestionModal, toggleConfirmModalOpen, toggleSignUpModal } = useModalsContext();
    const questionsContainerRef = useRef(null);
    const { questionsStore, currentCountry } = useStore();

    const defaultCountryRegionOption = {
      label: currentCountry?.name,
      value: ALL_COUNTRY_REGIONS
    };

    const INITIAL_FILTERS = {
      selectedType: query?.selectedType ? postTypes.find(item => item.value === query?.selectedType) : allTypes,
      countryRegionName: defaultCountryRegionOption,
      trustNetwork: trustNetworkFilterOptions[0],
      page: 1,
      locality: defaultLocality,
      campaignGroupId: null
    };

    const [filters, setFilters] = useState(INITIAL_FILTERS);
    const { selectedType, countryRegionName, trustNetwork, page, locality, campaignGroupId } = filters;
    const { postId } = queryString.parse(location.search);
    const isTips = postType === 'tip';
    const regionOptions = useMemo(() => {
      return [defaultCountryRegionOption].concat(
        get(currentCountry, 'regions', []).map(option => {
          return {
            label: option.name,
            value: option.name
          };
        })
      );
    }, [currentCountry]);
    const postTypesOptions = useMemo(() => {
      return myProfile || showAllTypes ? postTypes : postTypes.filter(item => item.value !== 'tip');
    }, [postTypes]);
    const questionsList = questionsStore.questions;
    const typesOptions = [allTypes, ...postTypesOptions];
    const postTypeFilter = useMemo(() => {
      return isTips
        ? ['tip']
        : selectedType.value !== 'all'
        ? [selectedType.value]
        : postTypesOptions.map(item => item.value);
    }, [selectedType, isTips]);
    const emptyListPlaceholder = useMemo(() => {
      if (selectedType?.value === 'tip') return 'There are no tips yet, be the first to create a great tip!';
      if (selectedType?.value === 'compliment') {
        return 'There are no compliments yet, be the first to create a great compliment!';
      }
      if (selectedType?.value === 'complaint') return 'There are no complaints yet, be the first to make a complaint!';
      if (selectedType?.value === 'business_review') {
        return 'There are no business reviews yet, be the first to create a great business review!';
      }
      if (selectedType?.value === 'product_review') {
        return 'There are no product reviews yet, be the first to create a great product review!';
      }
      if (selectedType?.value === 'recommendation') {
        return 'No one has asked for a recommendation yet, be the first to ask our community for a great recommendation!';
      }
      if (selectedType?.value === 'news_story') {
        return 'There are no news stories yet, be the first to create a great news story!';
      }
      if (selectedType?.value === 'event') return 'There are no events yet, be the first to share a great event!';

      return 'There are no posts yet, be the first to create a great post!';
    }, [selectedType]);
    const count = Math.ceil(questionsStore.questionsTotalCount / questionsPerPage);

    const handleChangeFilter = key => data => {
      const newFilters = {
        [key]: data,
        page: 1
      };

      if (key === 'countryRegionName') {
        newFilters.locality = defaultLocality;
      }

      if (key === 'campaignGroupId') {
        const countryRegion = data?.group?.countryRegion;
        const locality = data?.group?.locality;
        if (countryRegion) {
          newFilters.countryRegionName = { value: countryRegion.name, label: countryRegion.name };
        }
        if (data?.group?.locality) {
          newFilters.locality = {
            value: locality.name,
            label: locality.name
          };
        }
      }

      setFilters(prevState => ({
        ...prevState,
        ...newFilters
      }));
    };
    const handleChangeType = data => {
      setFilters(prevState => ({
        ...prevState,
        selectedType: data,
        page: 1
      }));
    };

    const disabledRemove = useMemo(() => {
      return _isEqual(filters, INITIAL_FILTERS);
    }, [filters]);

    const onResetFilters = () => {
      if (!disabledRemove) {
        handleResetFilters();
      }
    };

    const handleResetFilters = () => {
      setFilters(INITIAL_FILTERS);
    };

    const handleScrollToBegin = () => {
      window.scrollTo({
        top: wrapperRef?.current?.offsetTop - 98,
        behavior: 'smooth'
      });
    };

    useEffect(() => {
      handleScrollToBegin();
    }, [filters.page]);

    const questionsFilter = withSideBar => (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <AutocompleteMUI
          value={typesOptions.find(item => item.value === selectedType.value)}
          options={typesOptions}
          getOptionLabel={option => option.label}
          onChange={handleChangeFilter('selectedType')}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          label='Type:'
          size={withSideBar ? 'small' : 'medium'}
          withTitle={withSideBar}
          clearIcon={null}
          mobileSelect
        />
        <>
          {!withSideBar && <Divider />}
          <DashboardGroupsFilter
            size={withSideBar ? 'small' : 'medium'}
            onChange={handleChangeFilter('campaignGroupId')}
            withTitle={withSideBar}
            value={filters?.campaignGroupId?.value}
          />
        </>
        {!withSideBar && <Divider />}
        <AutocompleteMUI
          value={regionOptions.find(item => item.value === countryRegionName.value)}
          options={regionOptions}
          getOptionLabel={option => option.label}
          onChange={handleChangeFilter('countryRegionName')}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          label='Location:'
          size={withSideBar ? 'small' : 'medium'}
          withTitle={withSideBar}
          clearIcon={null}
          mobileSelect
          filter
          disabled={filters?.campaignGroupId?.group?.campaignType === 'residential'}
        />
        {!withSideBar && <Divider />}
        <Box>
          <DashboardLocalityFilter
            size={withSideBar ? 'small' : 'medium'}
            onChange={handleChangeFilter('locality')}
            withTitle={withSideBar}
            location={countryRegionName.value}
            locality={filters?.locality}
            disabled={filters?.campaignGroupId?.group?.campaignType === 'residential'}
          />
        </Box>
        {!withSideBar && <Divider />}
        <Box>
          <Typography variant='body6'>Community posts:</Typography>
          <MaterialRadioGroup
            row={false}
            name='trustNetwork'
            options={trustNetworkFilterOptions}
            value={trustNetworkFilterOptions.find(item => item.value === trustNetwork.value)}
            onChange={data => handleChangeFilter('trustNetwork')(data)}
          />
        </Box>
      </Box>
    );

    const fetchQuestions = page => {
      questionsStore.load(
        {
          categoryId: withOutCategoryMode ? null : category?.id,
          companyId,
          my: myProfile,
          postType: postType
            ? postType === 'all'
              ? postTypesOptions.map(item => item.value)
              : postType
            : postTypeFilter,
          page,
          locality: !locality?.value || locality?.value === 'all' ? null : locality?.value,
          campaignGroupId: campaignGroupId?.value || null,
          perPage: questionsPerPage,
          postId: postId || null,
          trustNetwork: trustNetwork.value === 'all' ? false : trustNetwork.value,
          categories: context?.categoriesFilter.length ? context?.categoriesFilter : null,
          countryRegionName: countryRegionName?.value !== ALL_COUNTRY_REGIONS ? countryRegionName?.value : null
        },
        false
      );
    };

    const handlePagination = page => {
      fetchQuestions(page);
      setFilters(prevState => ({
        ...prevState,
        page
      }));
    };

    useEffect(() => {
      fetchQuestions(1);
    }, [
      category?.id,
      companyId,
      selectedType,
      postType,
      context?.categoriesFilter,
      countryRegionName,
      trustNetwork,
      locality,
      campaignGroupId
    ]);

    return (
      <>
        <Box sx={styles.wrapper} ref={wrapperRef}>
          {withSideBar && (
            <MyQATopBar {...{ questionsFilter, filters, INITIAL_FILTERS, onResetFilters, disabledRemove }} />
          )}
          <Box sx={stylesLeaderboard.container}>
            {isDesktop && withSideBar && (
              <Box sx={stylesLeaderboard.filterContainer}>
                <Box sx={stylesLeaderboard.filterContainerInner}>
                  <Box sx={stylesLeaderboard.filterTitle}>
                    <Typography variant='body4'>Filter by:</Typography>
                    <Button
                      variant='text'
                      size='xs'
                      color='dark'
                      sx={{ minWidth: '44px' }}
                      disabled={disabledRemove}
                      onClick={onResetFilters}
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>
                {questionsFilter(withSideBar)}
              </Box>
            )}
            <Box sx={stylesLeaderboard.content}>
              {withSideBar && (
                <QuestionsActionPanel
                  {...{ questionsFilter, filters, INITIAL_FILTERS }}
                  createButton={
                    <QuestionsCreteButton
                      isSingleButton={isSingleButton}
                      {...{
                        category,
                        myProfile,
                        companyId,
                        initialTab,
                        selectedType,
                        postTypeFilter,
                        withOutCategoryMode,
                        filters
                      }}
                      size='smallToMedium'
                      postType={postType || selectedType?.value}
                    />
                  }
                />
              )}
              <Typography variant='h6' sx={{ mb: 2 }}>
                Community Support!
              </Typography>
              <Box sx={styles.questionsWrapper}>
                {(typeFilter || !withOutCreate) && (
                  <Box sx={styles.questionsFiltersWrapper}>
                    {typeFilter && (
                      <TypeFilter
                        options={typesOptions}
                        value={typesOptions.find(item => item.value === selectedType.value)}
                        onChange={handleChangeType}
                      />
                    )}
                    {typeFilter && !withOutCreate && <Divider flexItem />}
                    {!withOutCreate && (
                      <QuestionsCreteButton
                        isSingleButton={isSingleButton}
                        {...{
                          category,
                          myProfile,
                          companyId,
                          initialTab,
                          selectedType,
                          postTypeFilter,
                          withOutCategoryMode,
                          filters
                        }}
                        postType={postType || selectedType?.value}
                      />
                    )}
                  </Box>
                )}
                <div className='position-relative' ref={questionsContainerRef}>
                  {!questionsStore.loading && questionsList.length === 0 ? (
                    <>
                      {myProfile ? (
                        <EmptyState
                          title={emptyTitle || 'You have not created any post!'}
                          description={emptyDescription || emptyListPlaceholder}
                        />
                      ) : (
                        <EmptyPlaceholder description={emptyListPlaceholder} />
                      )}
                    </>
                  ) : questionsStore.loading ? (
                    <ProfileSkeleton />
                  ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      {questionsList.map((item, index) => (
                        <QuestionItem
                          key={item?.id}
                          question={item}
                          owner={myProfile ? currentUser : null}
                          first={index === 0}
                          questionsContainerOffset={questionsContainerRef?.current?.offsetTop}
                          {...{
                            withOutCategoryMode,
                            questionsStore,
                            currentUser,
                            toggleCreateQuestionModal,
                            toggleConfirmModalOpen,
                            toggleSignUpModal
                          }}
                        />
                      ))}
                    </Box>
                  )}
                </div>
              </Box>
              <Pagination count={count} page={page} onChange={handlePagination} />
            </Box>
          </Box>
        </Box>
      </>
    );
  }
);
