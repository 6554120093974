import colors from '@packs/assets/theme/base/colors';

const { borderColor, primary, black } = colors;

export default () => ({
  wrapper: {
    minWidth: '355px',
    width: '355px',
    borderRadius: '8px',
    border: `1px solid ${borderColor.main}`,
    overflow: 'hidden'
  },
  wrapperItem: {
    p: 2
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    '& a': {
      color: primary.main,
      ml: 1
    }
  },
  updateLink: {
    ml: 1,
    color: black.main,
    textDecoration: 'underline',
    fontWeight: 'bold',
    fontSize: 12
  }
});
