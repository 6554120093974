import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import useStyles from '@packs/screens/Groups/styles';

export const GroupsListSection = ({ title, children }) => {
  const styles = useStyles();

  return (
    <Box>
      <Typography variant='h5' sx={{ mb: 3, color: colors.white.main }}>
        {title}
      </Typography>
      <Box sx={styles.groupsSectionList}>{children}</Box>
    </Box>
  );
};
