import React, { useEffect, useState } from 'react';

import api from '@packs/apis/company';
import { Avatar } from '@packs/components/UI';
import CheckIcon from '@packs/images/svg/ui/check-circle-filed-primary.svg';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { CheckoutForm } from './CheckoutForm';

import upperCase from 'lodash-es/upperCase';
import { useParams } from 'react-router-dom';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = import.meta.env.VITE_SKIP_STRIPE_PAYMENT
  ? new Promise(resolve => resolve())
  : loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

const CompanyPayment = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [amount, setAmount] = useState(0);
  const [succeeded, setSucceeded] = useState(false);
  const [currency, setCurrency] = useState('usd');
  const [company, setCompany] = useState(null);
  const token = new URLSearchParams(location.search).get('token');
  const params = useParams();

  if (import.meta.env.VITE_SKIP_STRIPE_PAYMENT) {
    return null;
  }

  useEffect(() => {
    api.companyCreatePayment({ companyId: params.company, token }).then(({ data, company }) => {
      setAmount(data.amount);
      setCurrency(data.currency || 'usd');
      setCompany(company);
      setClientSecret(data.clientSecret);

      if (data.status === 'succeeded') {
        setSucceeded(true);
      }
    });
  }, []);

  const appearance = { theme: 'stripe' };
  const options = {
    clientSecret,
    appearance
  };

  if (succeeded) {
    return (
      <div className='checkout-container'>
        <div className='payment-wrapper'>
          <div className='success-info d-flex flex-column align-items-center w-100 justify-content-center'>
            <div className='success-icon'>
              <img src={CheckIcon} alt='success' />
            </div>
            <div className='success-charged'>Charged Successfully</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='checkout-container'>
      <div className='payment-wrapper'>
        <div className='d-flex align-items-center w-100 justify-content-center'>
          <div className='payment-info'>
            <div className='d-flex flex-column align-items-center payment-info-item justify-content-center'>
              {company?.avatarUrl && <Avatar avatarUrl={company?.avatarUrl} size={70} withBorder fullSize square />}

              <div className='fw-700 fs-22 mt-15 lh-5'> {company?.name}</div>
            </div>

            <div className='d-flex align-items-center mt-5 payment-info-item justify-content-center lh-5'>
              <div className='fs-17'>You will pay:</div>
              <span className='fw-700 fs-17 ml-5'>
                {' '}
                {amount} {upperCase(currency)}{' '}
              </span>
            </div>
          </div>
          {succeeded && <h1 className='success-charged'>Charged Successfully</h1>}
        </div>

        {clientSecret && !succeeded && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm params={params} />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default CompanyPayment;
