import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { SelectMUI } from '@packs/components/MaterialUi/SelectMUI/SelectMUI';
import { useDetails } from '@packs/models/policy/useDetails';
import { defaultPolicy, defaultYear } from '@packs/screens/MyQuoteRequests/MyQuoteRequests';

import dayjs from 'dayjs';
import _filter from 'lodash-es/filter';
import _get from 'lodash-es/get';
import { observer } from 'mobx-react';

export const Filter = observer(
  ({ filters, sidebarFilter = true, withTitle = false, fetchData, createdAtFirstYear }) => {
    const { userRenewalDetails } = useDetails();

    const onChangePolicy = data => {
      fetchData({ renewalDetailId: data, page: 1 });
    };

    const onChangeYear = (_, data) => {
      fetchData({ years: data, page: 1 });
    };

    const policyOptions = useMemo(() => {
      return [
        defaultPolicy,
        ..._filter(userRenewalDetails || [], el => el.id).map(detail => {
          return {
            value: detail.id,
            label: _get(detail, 'smartReminder.name')
          };
        })
      ];
    }, [userRenewalDetails]);

    const yearOptions = useMemo(() => {
      if (!createdAtFirstYear) {
        return [];
      }
      const currentYear = dayjs().year();

      const years = [defaultYear];
      for (let year = createdAtFirstYear; year <= currentYear; year++) {
        years.push({ label: year.toString(), value: year });
      }
      return years;
    }, [createdAtFirstYear]);

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <AutocompleteMUI
          value={filters.renewalDetailId}
          options={policyOptions}
          onChange={onChangePolicy}
          label='Policy'
          size={sidebarFilter ? 'small' : 'medium'}
          withTitle={withTitle}
          mobileSelect
          filter
        />
        {yearOptions.length > 2 && (
          <SelectMUI
            value={filters.years}
            options={yearOptions}
            onChange={onChangeYear}
            label='Year'
            withTitle={withTitle}
            size={sidebarFilter ? 'small' : 'medium'}
          />
        )}
      </Box>
    );
  }
);
