export default () => ({
  wrapper: (offerColor, withTooltip) => ({
    maxWidth: withTooltip ? '50%' : 'auto',
    borderRadius: '4px',
    py: 0.5,
    px: 1,
    backgroundColor: offerColor?.tonal,
    color: offerColor.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
});
