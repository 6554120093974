import React, { useMemo } from 'react';

import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { useDetails } from '@packs/models/policy/useDetails';

import _filter from 'lodash-es/filter';
import _get from 'lodash-es/get';
import { observer } from 'mobx-react';

export const DashboardPolicyFilter = observer(({ onChange, size = 'medium', withTitle }) => {
  const { currentPolicy, userRenewalDetails } = useDetails();

  const policyOptions = useMemo(() => {
    return _filter(userRenewalDetails || [], el => el.id).map((detail, inx) => {
      detail.index = inx;

      return {
        value: detail.id,
        object: detail,
        label: _get(detail, 'smartReminder.name')
      };
    });
  }, [userRenewalDetails]);

  const value = policyOptions.find(item => item.value === currentPolicy?.id) || policyOptions[0];

  const handleChangePolicy = data => {
    const policy = policyOptions.find(item => item.value === data.value);
    onChange(policy);
  };

  if (policyOptions.length <= 1) {
    return null;
  }

  return (
    <>
      <AutocompleteMUI
        value={value}
        options={policyOptions}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        onChange={handleChangePolicy}
        label='Policy'
        size={size}
        withTitle={withTitle}
        mobileSelect
        filter
      />
    </>
  );
});
