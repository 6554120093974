import React, { useCallback, useEffect, useMemo, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import debounce from 'lodash-es/debounce';
import omit from 'lodash-es/omit';
import { observer } from 'mobx-react';

const defaultSelectValue = { value: '', label: '' };

export const SearchCompanies = observer(({ width = '440px', maxWidth = '100%' }) => {
  const { companiesStore, currentCategory } = useStore();
  const { isMobile } = useWindowSize();

  const [value, setValue] = useState(companiesStore?.filters?.keyWord || '');
  const [selectValue, setSelectValue] = useState(defaultSelectValue);

  const handleClearSearch = () => {
    setValue('');
    setSelectValue(defaultSelectValue);
    companiesStore.load();
  };

  const handleCompanyListKeyWordFilter = (data = defaultSelectValue) => {
    setValue(data?.label);
    setSelectValue(data);
  };

  const changeInputHandler = value => {
    setValue(value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Backspace') {
      handleClearSearch();
    }
    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  useEffect(() => {
    return () => {
      if (companiesStore.filters.keyWord) {
        handleClearSearch();
      }
    };
  }, []);

  useEffect(() => {
    if (value) {
      fetchData(value);
    }
  }, [value]);

  useEffect(() => {
    setValue('');
    setSelectValue(defaultSelectValue);
  }, [JSON.stringify(omit(companiesStore.filters, ['keyWord', 'page', 'companyType']))]);

  const fetchData = useCallback(
    debounce(keyWord => {
      companiesStore.setFilters({ companyType: 'all' });
      companiesStore.loadAutocompleteCompanies({ categoryId: currentCategory.id, keyWord });
    }, 1000),
    []
  );

  const items = useMemo(() => {
    return (companiesStore?.listAutocomplete || []).map(({ id, name }) => ({ label: name, value: id.toString() }));
  }, [companiesStore?.listAutocomplete]);

  if (isMobile) {
    return (
      <TextField
        variant='outlined'
        placeholder='Search business...'
        fullWidth
        value={value}
        InputProps={{
          startAdornment: <SearchIcon />
        }}
        onChange={event => setValue(event.target.value)}
      />
    );
  }

  return (
    <Box sx={{ width: '100%', maxWidth }}>
      <AutocompleteMUI
        width={width}
        size={isMobile ? 'medium' : 'small'}
        options={items}
        value={selectValue}
        onChange={data => handleCompanyListKeyWordFilter(data)}
        onInputChange={(_, value) => changeInputHandler(value)}
        noOptionsText='No results'
        freeSolo
        variant='outlined'
        label=''
        placeholder='Search business...'
        onKeyDown={handleKeyDown}
        onFilter={fetchData}
        InputProps={{
          startAdornment: <SearchIcon />
        }}
        filterOptions={x => x}
        widthOutReCache
      />
    </Box>
  );
});
