import React from 'react';

import colors from '@/packs/assets/theme/base/colors';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import useStyles from '@packs/components/MaterialUi/Groups/styles';
import { useStore } from '@packs/stores/base';

import { Link } from 'react-router-dom';

export const CustomCampaignGroupCard = ({ group, isJoined }) => {
  const { currentCountry } = useStore();
  const styles = useStyles();

  return (
    <Box sx={styles.wrapper} component={Link} to={`/en-${currentCountry?.isoA2Code}/${group.route}`}>
      <Box sx={{ position: 'relative', mb: 1 }}>
        <Avatar
          sx={{ width: '100%', height: '132px', borderRadius: '4px', border: `1px solid ${colors.borderColor.main}` }}
          src={group?.backgroundImageUrl}
        />
      </Box>
      <Box sx={styles.locationWrapper}>
        <PlaceRoundedIcon sx={styles.locationIcon} />
        <Typography variant='body5'>{`${group?.countryRegion}, ${group?.locality?.name}`}</Typography>
      </Box>
      <Box sx={{ mb: 0.5 }}>
        <Typography variant='body4' sx={styles.title}>
          {group.name}
        </Typography>
      </Box>
      <Box sx={{ mt: 'auto' }}>
        {group.category && (
          <Box sx={{ display: 'flex', mt: 1 }}>
            <Box sx={styles.categoryContainer}>
              <CategoryIconBox
                containerSize='16px'
                iconSize='16px'
                category={group.category}
                backgroundColor='transparent'
                color='#000'
              />
              <Typography variant='caption'>{group.category.name}</Typography>
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
          {group?.campaignGroup?.membersCount ? (
            <Typography variant='body5'>{`${group?.campaignGroup?.membersCount || 0}`} members</Typography>
          ) : (
            <Box />
          )}
          {isJoined && (
            <Chip label='Joined' color='primary' size='small' sx={{ px: 0.5, backgroundColor: '#F5E1AC' }} />
          )}
        </Box>
        <Box sx={{ mt: 1 }}>
          <Button size='xs' variant={isJoined ? 'filledTonal' : 'contained'} color='primary' fullWidth>
            {isJoined ? 'View' : 'Join now'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
