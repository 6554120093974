import React from 'react';
import cx from 'classnames';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { SvgIcons } from '@packs/components';
import CloseIcon from '@packs/images/svg/close-dark.svg';
import { satisfactionStars } from '@packs/lib/constants';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import { ICONS } from '@shared/helpers/categoriesIconsHelper';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import _get from 'lodash-es/get';
import _map from 'lodash-es/map';

export const DrawerSelect = ({
  onClose,
  isOpen,
  objValue,
  name,
  categoryName,
  title,
  center,
  withIcon,
  disabled,
  options,
  countrySelect,
  handleChange
}) => {
  return (
    <SwipeableDrawer anchor='bottom' open={!!isOpen} disableSwipeToOpen={disabled} onClose={onClose}>
      <div className={cx('drawer-container select-drawer-container', categoryName)}>
        <div className='drawer-header'>
          <div className='close' onClick={onClose}>
            <img src={CloseIcon} alt='Close' />
          </div>
          {title ? <div className='title'>{title}</div> : <div />}
          <div className='apply' onClick={onClose}>
            Done
          </div>
        </div>
        <div className='drawer-body'>
          <div className={cx('radio-list', { center })}>
            {_map(options, (obj, index) => {
              const { value: val, label, rootCode, code, iconType } = obj;
              const checked = val === objValue.value;

              return (
                <label key={`${index} ${checked}`} className='custom-radio'>
                  <input
                    type='radio'
                    value={val}
                    name={name}
                    checked={checked}
                    onChange={() => {
                      handleChange(obj);
                      onClose();
                    }}
                  />
                  {/* <span className='radio-mark' />*/}
                  <span className='radio-mark-check' />
                  {withIcon && (
                    <div className='icon-container mr-20'>
                      {rootCode ? SUB_ICONS({ category: { code, root: { enName: rootCode } } }) : ICONS[code]}

                      {iconType && <SvgIcons {...{ iconType }} />}
                    </div>
                  )}
                  {_get(satisfactionStars, `[${val}]`, false) && (
                    <>
                      <div>{satisfactionStars[val]}</div>
                      <RatingMUI value={1} max={1} />
                    </>
                  )}
                  <span className='label-text'>{label}</span>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  );
};
