import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CustomCheckBox } from '@packs/components/UI/CustomCheckBox';
import CheckIcon from '@packs/images/svg/ui/check-circle-primary.svg';
import { countryPlanPrices, plans } from '@packs/lib/helpers/payment';
import { useStore } from '@packs/stores';

const subscription = [
  {
    id: plans.free,
    title: 'Small business',
    date: null,
    expired: 'And it always will be!',
    description: 'Our Bill Winner National Awards! Invite your customers to vote for your business!',
    options: ['Basic Business page', 'Basic Bill Winner Widget'],
    btnText: 'Talk to us!'
  },
  {
    id: plans.small,
    title: 'Small to medium sized business',
    date: '/mo',
    expired: 'Billed annually',
    description: 'Our Bill Winner National Awards! Invite your customers to vote for your business!',
    options: [
      'Premium Business page',
      'Premium Bill Winner Widget!',
      'Tips & Q&A Management',
      'Aggregate reviews',
      'Reputation Management',
      'Analytics',
      'Unlimited Badge Licence!',
      'Request for Quote',
      'Dedicated Account Manager',
      '10% discount for staff members participating on our Bill Winner sales & marketing online training course!'
    ],
    btnText: 'Talk to us!'
  },
  {
    id: plans.enterprise,
    title: 'Enterprise',
    date: '/mo',
    expired: 'Billed annually',
    description: 'Our Bill Winner National Awards! Invite your customers to vote for your business!',
    options: [
      'Platinum Business page',
      'Premium Bill Winner Widget!',
      'Tips & Q&A Management',
      'Aggregate reviews pro',
      'Reputation Management pro',
      'Analytics Pro',
      'Unlimited Badge Licence!',
      'Request for Quote',
      'Dedicated Account Manager',
      '10% discount for staff members participating on our Bill Winner sales & marketing online training course!'
    ],
    btnText: 'Talk to us!'
  }
];

const adds = [
  {
    value: 'featured_expert',
    label: 'Become A Featured Expert!'
  },
  {
    value: 'targeted_offers',
    label: 'Create Targeted Offers! '
  },
  {
    value: 'online_training',
    label: 'Bill Winner Sales & Marketing Online Training Course!'
  }
];

export const PaymentPlans = ({ selectedAdds, onCheckAdds, onSelectPlan }) => {
  const { countryCode } = useStore();

  const onCheck = name => {
    return () => {
      onCheckAdds(name);
    };
  };

  const handleSelectPlan = plan => {
    return () => {
      onSelectPlan(plan);
    };
  };

  return (
    <Box sx={{ pt: { xs: 3, md: 4 } }}>
      <Typography variant='body1' sx={{ mb: 2, textAlign: 'center' }}>
        Plans & Pricing
      </Typography>
      <Typography variant='h4' sx={{ textAlign: 'center' }}>
        Find the right plan for you!
      </Typography>
      <div className='payment-plans-wrapper'>
        {subscription.map(({ id, title, date, expired, description, options, btnText }) => {
          const priceCurrency = ['us', 'ca', 'au', 'sg', 'nz'].includes(countryCode)
            ? `${countryPlanPrices[countryCode].currency}${countryPlanPrices[countryCode][id]}`
            : `${countryPlanPrices.ie[id]} ${countryPlanPrices.ie.currency}`;
          const isEnterprise = id === plans.enterprise;
          return (
            <div key={id} className='payment-plan'>
              <div className='primary-text fs-12 lh-5 fw-700 tt-uc'>{title}</div>
              {!isEnterprise ? (
                <div className='d-flex align-items-center'>
                  <div className='price'>{plans[id] === plans.free ? 'Free' : priceCurrency}</div>
                  {date && <div className='fs-14 mt-10'>{date}</div>}
                </div>
              ) : (
                <Typography variant='body1' sx={{ fontWeight: 700 }}>
                  Our sales team will discuss the best options with you for your company.
                </Typography>
              )}
              <div className='fs-14'>{expired}</div>
              <div className='fs-12 description'>{description}</div>
              {options && (
                <div className='price-options-list'>
                  {options.map((item, index) => (
                    <div key={item + index} className='price-option d-flex align-items-start'>
                      <img src={CheckIcon} alt='check' className='check-icon' />
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
              )}
              {btnText && (
                <div className='btn full white mt-auto' onClick={handleSelectPlan(id)}>
                  <span>{btnText}</span>
                </div>
              )}
            </div>
          );
        })}
        <div className='payment-plan-adds'>
          <div className='primary-text fs-12 lh-5 fw-700 tt-uc'>EXTRA</div>
          <div className='d-flex align-items-center'>
            <span className='price'>Add ons </span>
          </div>
          <div className='fs-14' />
          <div className='fs-12 lh-5 description'>You can add these options to your selected plan!</div>
          <div className='text-left'>
            {adds.map(({ value, label }) => {
              const checked = selectedAdds.includes(value);
              return (
                <div key={value} className='checkbox-item'>
                  <CustomCheckBox label={label} checked={checked} primary onChange={onCheck(value)} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Box>
  );
};
