import React, { FC } from 'react';

import { Box, Button, Typography, TypographyProps, Zoom } from '@mui/material';
import Chip from '@mui/material/Chip';
import colors from '@packs/assets/theme/base/colors';
import OfferPlaceholderIcon from '@packs/images/svg/ui/offer-placeholder-icon.svg';
import { IOffer } from '@packs/interfaces/rootInterfaces';
import { usePath } from '@packs/layouts/constants';
import { OfferType } from '@shared/components/OfferType/OfferType';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';
import { general } from '@shared/constants/offers';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import useStyles from './styles';

import { Link } from 'react-router-dom';

const { white, dark } = colors;

type OfferItemProps = {
  offer: IOffer;
  isSliderType?: boolean;
  inView?: boolean;
  index: number;
  darkMode?: boolean;
  wideItem?: boolean;
  fullWidthItem?: boolean;
  fixedWidth?: boolean;
};

export const OfferItem: FC<OfferItemProps> = ({
  offer,
  isSliderType = false,
  inView = true,
  index,
  darkMode = false,
  wideItem = false,
  fullWidthItem = false,
  fixedWidth = false
}) => {
  const styles = useStyles();
  const { MY_OFFERS_DETAILED_PATH_FN } = usePath();
  const { offerMessage, offerDetails, bannerUrl } = offer;

  const ItemComponent = () => {
    if (wideItem) {
      return (
        <Box component={Link} to={MY_OFFERS_DETAILED_PATH_FN(offer.id)} sx={styles.wrapper(darkMode, wideItem)}>
          <Box sx={styles.imgContainer(darkMode, bannerUrl, wideItem)}>
            <img src={bannerUrl || OfferPlaceholderIcon} alt='Offer image' />
          </Box>
          <Box sx={[styles.wideContentWrapper, { flexGrow: 1 }]}>
            <Box sx={styles.typeWrapper}>
              <TooltipMUI title={offer?.category?.name} placement='top' arrow>
                <Box sx={styles.categoryWrapper(false)}>
                  {SUB_ICONS({ category: offer?.category, size: '16px' })}
                  <Typography variant='caption' color={darkMode ? dark.main : white.main} sx={styles.categoryText}>
                    {offer?.category.name}
                  </Typography>
                </Box>
              </TooltipMUI>
              {offer?.offerType !== general && <OfferType offerType={offer?.offerType} />}
              {offer?.code && (
                <Chip
                  label='Discount Code'
                  size='small'
                  sx={[styles.discountCode, { position: 'static', ml: 'auto' }]}
                />
              )}
            </Box>

            <Box sx={{ mb: 1, mt: 1 }}>
              <Typography
                variant={'body4' as TypographyProps['variant']}
                color={darkMode ? dark.main : white.main}
                sx={styles.offerMessage}
              >
                {offerMessage}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant={'body5' as TypographyProps['variant']}
                color={darkMode ? dark.main : white.main}
                sx={styles.offerMessage}
              >
                {offerDetails}
              </Typography>
            </Box>
            <Box>
              <Button variant='contained' color='primary' size='small' fullWidth>
                See Offer
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }
    return (
      <Box
        component={Link}
        to={MY_OFFERS_DETAILED_PATH_FN(offer.id)}
        sx={styles.wrapper(darkMode, wideItem, fullWidthItem, fixedWidth)}
      >
        <Box sx={styles.imgContainer(darkMode, bannerUrl, wideItem)}>
          <img src={bannerUrl || OfferPlaceholderIcon} alt='Offer image' />
          {offer?.code && <Chip label='Discount Code' size='small' sx={styles.discountCode} />}
        </Box>
        <Box sx={styles.typeWrapper}>
          {offer?.offerType !== general ? (
            <TooltipMUI title={offer?.category?.name} placement='top' arrow>
              <Box sx={styles.categoryWrapper(true)}>
                {SUB_ICONS({ category: offer?.category, size: '16px' })}
                <Typography variant='caption' color={darkMode ? dark.main : white.main} sx={styles.categoryText}>
                  {offer?.category.name}
                </Typography>
              </Box>
            </TooltipMUI>
          ) : (
            <Box sx={styles.categoryWrapper()}>
              {SUB_ICONS({ category: offer?.category, size: '16px' })}
              <Typography variant='caption' color={darkMode ? dark.main : white.main} sx={styles.categoryText}>
                {offer?.category.name}
              </Typography>
            </Box>
          )}
          {offer?.offerType !== general && <OfferType offerType={offer?.offerType} />}
        </Box>
        <Box sx={{ mb: 1, mt: 1 }}>
          <Typography
            variant={'body4' as TypographyProps['variant']}
            color={darkMode ? dark.main : white.main}
            sx={styles.offerMessage}
          >
            {offerMessage}
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography
            variant={'body5' as TypographyProps['variant']}
            color={darkMode ? dark.main : white.main}
            sx={styles.offerMessage}
          >
            {offerDetails}
          </Typography>
        </Box>
        <Box mt='auto'>
          <Button variant='contained' color='primary' size='small' fullWidth>
            See Offer
          </Button>
        </Box>
      </Box>
    );
  };

  if (isSliderType) {
    return <ItemComponent />;
  }

  return (
    <Zoom in={inView} timeout={500} style={{ transitionDelay: `${(index ? index : 1) * 100}ms` }}>
      <Box>
        <ItemComponent />
      </Box>
    </Zoom>
  );
};
