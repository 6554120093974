import colors from '@packs/assets/theme/base/colors';

const { primary } = colors;
const select = {
  styleOverrides: {
    root: {
      '&:hover': {
        backgroundColor: `${primary.tonal} !important`
      },
      '&.Mui-selected': {
        backgroundColor: `${primary.main} !important`
      }
    }
  }
};

export default select;
