import React from 'react';

import { ReminderConfirmModal } from '@packs/components';
import { CLOSED_ALL_MODALS } from '@packs/layouts/AppLayer/constants';
import { AppModalsContext } from '@packs/layouts/AppLayer/context';
import { useStore } from '@packs/stores';

export const AppModalsProvider = ({ children, modalState, setModalState, toggleDoudleModal }) => {
  const { modalStore } = useStore();

  const toggleSignUpModal = ({ onSignUpSuccess = null, modalAuthProps = {} } = {}) => {
    toggleDoudleModal(onSignUpSuccess, 'signup', modalAuthProps);
  };

  const toggleSignInModal = ({ onSignUpSuccess = null } = {}) => {
    toggleDoudleModal(onSignUpSuccess, 'login');
  };

  const toggleResendConfirmationModal = () => {
    setModalState({ ...CLOSED_ALL_MODALS, isOpenResendConfirmationModal: !modalState.isOpenResendConfirmationModal });
  };

  const toggleChangePasswordModal = () => {
    setModalState({ ...CLOSED_ALL_MODALS, isChangePasswordModalOpen: !modalState.isChangePasswordModalOpen });
  };

  const toggleReminderFormModal = (reminderFormModalProps = {}) => {
    setModalState({ isOpenReminderFormModal: !modalState.isOpenReminderFormModal, reminderFormModalProps });

    if (modalState.isOpenReminderFormModal) {
      setModalState({ reminderFormModalProps: {} });
    }
  };

  const toggleRequestQuoteModal = (requestQuoteModalProps = {}) => {
    setModalState({ isOpenRequestQuoteModal: !modalState.isOpenRequestQuoteModal });

    setModalState({ requestQuoteModalProps });
  };

  const toggleTermsModal = () => {
    setModalState({ isOpenTermsModal: !modalState.isOpenTermsModal });
  };

  const togglePrivacyModal = () => {
    setModalState({ isOpenPrivacyModal: !modalState.isOpenPrivacyModal });
  };

  const toggleNewCompanyModal = (setProps = {}) => {
    setModalState({ isNewCompanyModalOpen: !modalState.isNewCompanyModalOpen, newCompanyModalProps: setProps });
  };

  const toggleNewAssociationModal = (setProps = {}) => {
    setModalState({
      isNewAssociationModalOpen: !modalState.isNewAssociationModalOpen,
      newAssociationModalProps: setProps
    });
  };

  // AUTH toggles modal
  const toggleSettingsModal = () => {
    setModalState({ isOpenSettingsModal: !modalState.isOpenSettingsModal });
  };

  const toggleDeleteConfirmModal = () => {
    setModalState({ isOpenDeleteConfirmModal: !modalState.isOpenDeleteConfirmModal });
  };
  const toggleThankModal = () => {
    setModalState({ isThankModalOpen: !modalState.isThankModalOpen });
  };
  const toggleMyBillDetailsModal = (modalProps = {}) => {
    setModalState({ isOpenMyBillDetails: !modalState.isOpenMyBillDetails, modalProps });
  };
  const toggleSendFeedback = () => {
    setModalState({ isOpenShoppingCartMobileModal: !modalState.isOpenShoppingCartMobileModal });
  };
  const togglePersonalizationFlowModal = () => {
    setModalState({ isPersonalizationFlowModalOpen: !modalState.isPersonalizationFlowModalOpen });
  };
  const togglePersonalizeUnAuthorizedModal = value => {
    setModalState({ isPersonalizeUnAuthorizedModalOpen: value });
  };
  const togglePersonalizeConfirmModal = () => {
    setModalState({ isPersonalizeConfirmModalOpen: !modalState.isPersonalizeConfirmModalOpen });
  };

  const toggleBusinessVoteModal = (modalPropsVote = {}, forceOpen) => {
    setModalState({
      isBusinessVoteModalOpen: forceOpen ? true : !modalState.isBusinessVoteModalOpen,
      modalPropsVote
    });
  };

  const toggleCreateQuestionModal = (modalProps = {}) => {
    setModalState({ isCreateQuestionOpen: !modalState.isCreateQuestionOpen, modalProps });
  };

  const toggle = key => setModalState({ [key]: !modalState[key] });

  const toggleDrawer = drawerProps => {
    setModalState({ isDrawerOpen: !modalState.isDrawerOpen, drawerProps });
  };

  const toggleThankVoteModal = ({ modalProps = {} }) => {
    setModalState({ isThankVoteModalOpen: !modalState.isThankVoteModalOpen, modalProps });
  };

  const toggleConnectSocialModalOpen = (modalProps = {}) => {
    setModalState({
      connectSocialModalOpen: !modalState.connectSocialModalOpen,
      connectModalProps: modalProps
    });
  };

  const toggleMyAssociationsModalOpen = ({ modalProps }) => {
    setModalState({
      myAssociationsModalOpen: !modalState.myAssociationsModalOpen,
      modalProps
    });
  };

  const toggleConfirmModalOpen = ({ modalProps = {} }) => {
    setModalState({
      confirmModalOpen: !modalState.confirmModalOpen,
      modalProps
    });
  };

  const toggleNoteModalOpen = ({ modalProps = {} }) => {
    setModalState({
      noteModalIsOpen: !modalState.noteModalIsOpen,
      modalProps
    });
  };

  const toggleWikiTableModalOpen = ({ productsProps = {} }) => {
    setModalState({
      isOpenWikiTableModal: !modalState.isOpenWikiTableModal,
      productsProps
    });
  };

  const toggleAcceptInviteModalOpen = ({ modalProps = {} }) => {
    setModalState({
      isOpenAcceptInviteModal: !modalState.isOpenAcceptInviteModal,
      modalProps
    });
  };

  const toggleInviteFriendsModal = ({ modalProps = {} }) => {
    setModalState({
      isOpenInviteFriends: !modalState.isOpenInviteFriends,
      modalProps
    });
  };

  const toggleMobileFilterOpen = ({ drawerProps = {} }, close) => {
    setModalState({
      isOpenMobileFilter: close ? false : !modalState.isOpenMobileFilter,
      drawerProps
    });
  };

  const toggleWinnerModal = ({ modalProps = {} }) => {
    setModalState({
      winnerModalIsOpen: !modalState?.winnerModalIsOpen,
      modalProps
    });
  };

  const toggleShareSocialModal = ({ modalProps = {} }) => {
    setModalState({
      shareSocialModalIsOpen: !modalState?.shareSocialModalIsOpen,
      modalProps
    });
  };

  // AUTH toggles reminder modal
  const onDeleteReminder = (item, handleDeleteReminder) => {
    modalStore.open({
      type: ReminderConfirmModal,
      props: {
        item,
        handleDeleteReminder,
        onClose: () => {
          modalStore.close();
        }
      }
    });
  };

  // AUTH toggles reminder modal
  const onEditReminder = (
    item,
    {
      category,
      company,
      onSuccess,
      shouldShowDetails = true,
      parentType,
      currentCategory,
      disabledCategory,
      hidePolicy = false
    } = {}
  ) => {
    setModalState({
      reminderFormModalProps: {
        category,
        company,
        parentType,
        disabledCategory,
        currentCategory,
        shouldShowDetails,
        selectedReminder: item,
        onSuccess,
        hidePolicy
      }
    });
    toggle('isOpenReminderFormModal');
  };

  const onAddNewReminder = (date, reminderFormModalProps) => {
    setModalState({
      reminderFormModalProps: {
        selectedReminder: null,
        selectedDate: date,
        shouldShowDetails: true,
        ...reminderFormModalProps
      }
    });
    toggle('isOpenReminderFormModal');
  };

  const toggleVerificationModalOpen = ({ modalProps = {} }, close) => {
    setModalState({
      isOpenVerificationModal: close ? false : !modalState.isOpenVerificationModal,
      modalProps
    });
  };

  const contextValues = () => ({
    modalState,
    onSignUpSuccess: modalState.onSignUpSuccess,
    onAddNewReminder,
    onDeleteReminder,
    onEditReminder,
    toggleNewAssociationModal,
    toggleSignUpModal,
    toggleSignInModal,
    toggleReminderFormModal,
    toggleRequestQuoteModal,
    toggleResendConfirmationModal,
    toggleNewCompanyModal,
    toggleTermsModal,
    togglePrivacyModal,
    toggleChangePasswordModal,
    toggleSettingsModal,
    toggleDeleteConfirmModal,
    toggleThankModal,
    toggleMyBillDetailsModal,
    toggleSendFeedback,
    togglePersonalizationFlowModal,
    togglePersonalizeUnAuthorizedModal,
    togglePersonalizeConfirmModal,
    toggleBusinessVoteModal,
    toggleCreateQuestionModal,
    toggleDrawer,
    toggleThankVoteModal,
    toggleMyAssociationsModalOpen,
    toggleConfirmModalOpen,
    toggleConnectSocialModalOpen,
    toggleNoteModalOpen,
    toggleWikiTableModalOpen,
    toggleAcceptInviteModalOpen,
    toggleMobileFilterOpen,
    toggleWinnerModal,
    toggleVerificationModalOpen,
    toggleInviteFriendsModal,
    toggleShareSocialModal
  });

  return <AppModalsContext.Provider value={contextValues()}>{children}</AppModalsContext.Provider>;
};
