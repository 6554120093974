import React, { useState } from 'react';
import cx from 'classnames';

import { Popover } from '@packs/components/index';
import InfoIcon from '@packs/images/icons/UI/InfoIcon';
import InfoIconLarge from '@packs/images/icons/UI/InfoIconLarge';

export const InfoComponent = ({
  children,
  className,
  popoverId,
  fade = true,
  large = false,
  withoutBorder = false,
  theme = false,
  dark = false,
  placement = 'top',
  rootCategoryCode = '',
  circle = false,
  onClick = () => {},
  trigger = 'click, hover',
  popoverTrigger = null,
  infoComponentClassName = '',
  withOutPopover = false
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <div className='popover-wrapper' id={popoverId} onClick={onClick}>
      <div className={cx('info-component', theme && 'theme', infoComponentClassName, { circle, withoutBorder })}>
        {popoverTrigger ? popoverTrigger : large ? <InfoIconLarge /> : <InfoIcon />}
        {!withOutPopover && (
          <Popover
            {...{
              fade,
              placement,
              trigger
            }}
            isOpen={popoverOpen}
            target={popoverId}
            toggle={() => setPopoverOpen(!popoverOpen)}
            className={cx('info-popover', className, { [rootCategoryCode]: rootCategoryCode, dark })}
          >
            {children}
          </Popover>
        )}
      </div>
    </div>
  );
};
