const colors = {
  background: {
    default: '#f0f2f5',
    gray: '#f4f4f4'
  },

  text: {
    main: '#7b809a',
    focus: '#7b809a',
    secondary: 'rgba(0, 0, 0, 0.60)'
  },

  transparent: {
    main: 'transparent'
  },

  white: {
    main: '#ffffff',
    focus: '#ffffff',
    tonal: 'rgba(255, 255, 255, 0.20)'
  },

  black: {
    light: '#000000',
    main: '#000000',
    focus: '#000000'
  },

  primary: {
    main: '#F8B500',
    light: '#F5EDD9',
    focus: '#F5D47C',
    tonal: 'rgba(248, 181, 0, 0.20)',
    secondary: '#AD7E00'
  },

  secondary: {
    main: '#0055D9',
    light: '#3377E0',
    dark: '#003B97',
    focus: '#1A73E8',
    tonal: 'rgba(1, 103, 205, 0.20)',
    transparent: 'rgba(0, 102, 204, 0.08)',
    gradient: 'linear-gradient(180deg, #3273F5 0%, #06C 100%)',
    hover: 'rgba(0, 85, 217, 0.08)',
    filledTonal: '#E4F1FF',
    filledTonalHover: '#92ACFF'
  },

  info: {
    main: '#3D88DF',
    focus: '#1A73E8'
  },

  success: {
    main: '#00C7A6',
    focus: '#67bb6a'
  },

  warning: {
    main: '#FDB400',
    focus: '#fc9d26'
  },

  error: {
    main: '#FC4D70',
    focus: '#f65f53',
    transparent: 'rgba(244, 67, 54, 0.08)'
  },

  light: {
    main: '#f0f2f5',
    focus: '#f0f2f5'
  },

  dark: {
    main: 'rgba(0, 0, 0, 0.87)',
    focus: '#2c3c58',
    tonal: 'rgba(0, 0, 0, 0.20)',
    transparent: 'rgba(0, 0, 0, 0.08)'
  },

  defaultGrey: {
    main: 'rgba(0, 0, 0, 0.87)',
    focus: '#2c3c58',
    tonal: 'rgba(0, 0, 0, 0.20)',
    transparent: 'rgba(0, 0, 0, 0.08)'
  },

  category: {
    insurance: {
      main: '#00C7A6',
      light: '#DEF3ED',
      dark: '#51B096'
    },
    broadband: {
      main: '#8860D0',
      light: '#E6DFF4',
      dark: '#7558B4'
    },
    mobile: {
      main: '#E05BB8',
      light: '#F6E0F0',
      dark: '#BB59A2'
    },
    energy: {
      main: '#F19B78',
      light: '#FCEBE4',
      dark: '#D88B6C'
    },
    subscriptions: {
      main: '#489FF8',
      light: '#DAECFE',
      dark: '#418FDF'
    },
    business: {
      main: '#3274D9',
      light: '#D6E3F7',
      dark: '#2D68C3'
    },
    government: {
      main: '#456f93',
      light: '#D6E3F7',
      dark: '#2D68C3'
    },
    banking: {
      main: '#5AC47B',
      light: '#DEF3E5',
      dark: '#51B06E'
    },
    waste_collection: {
      main: '#00C7BB',
      light: '#DEF3F0',
      dark: '#00AFA7'
    },
    general: {
      main: '#00AFC7',
      light: '#DEF3F7',
      dark: '#0099B3'
    },
    food: {
      main: '#F44336',
      light: '#F6685E',
      dark: '#AA2E25'
    },
    travel: {
      main: '#9C27B0',
      light: '#AF52BF',
      dark: '#6D1B7B'
    },
    local_services: {
      main: '#FFC107',
      light: '#FFCD38',
      dark: '#B28704'
    }
  },

  offers: {
    general: {
      main: 'rgba(0, 0, 0, 0.87)',
      tonal: '#E0E0E0'
    },
    discount_code: {
      main: '#2E7D32',
      tonal: '#C9E7CA'
    },
    trust_network: {
      main: '#F67C01',
      tonal: '#FFE1B3'
    },
    association: {
      main: '#7B20A2',
      tonal: '#D2C5EB'
    },
    group: {
      main: '#0497A7',
      tonal: '#B4ECF3'
    }
  },

  rangeIndicator: {
    default: {
      main: '#E0E0E0'
    },
    low: {
      main: '#1bd185'
    },
    average: {
      main: '#ED6C02'
    },
    high: {
      main: '#D32F2F'
    }
  },

  borderColor: {
    main: 'rgba(0, 0, 0, 0.16)',
    secondary: 'rgba(0, 0, 0, 0.24)'
  },

  modalBg: {
    gradient: 'linear-gradient(252deg, #F5EEF2 0.94%, #EBF0F4 24.02%, #E7F3F5 52.74%, #E5F1FB 80.82%, #E7F3F5 103.57%)'
  },

  statuses: {
    active: '#D4F8D3',
    archived: '#FBE7E9',
    draft: '#E8E8E8'
  },

  reminderStatus: {
    green: {
      main: '#1B5E20',
      light: '#4CAF50'
    },
    amber: {
      main: '#E65100',
      light: '#FF9800'
    },
    red: {
      main: '#C62828',
      light: '#EF5350'
    },
    completed: {
      main: '#8C8C8C',
      light: '#E0E0E0'
    },
    empty: {
      main: '#ccdbe9',
      light: 'transparent'
    }
  },

  grey: {
    50: '#FAFAFA',
    100: '#F4F4F4',
    200: '#EEE',
    300: '#E0E0E0',
    400: '#BCBCBC',
    500: '#adb5bd',
    600: '#6c757d',
    700: '#495057',
    800: '#343a40',
    900: '#212529'
  },

  green: {
    500: '#4caf50'
  },

  blue: {
    main: '#E4F1FF',
    secondary: '#A9CCFF',
    contrastText: '#fff',
    twitter: '#1D9BF0'
  },

  orange: {
    main: '#F8B500',
    focus: '#f3bd3f'
  },

  gradients: {
    primary: {
      main: '#4C8CFF',
      state: '#3969EC'
    },

    secondary: {
      main: 'rgba(0, 0, 0, 0.6)',
      state: '#495361'
    },

    info: {
      main: '#49a3f1',
      state: '#1A73E8'
    },

    success: {
      main: '#66BB6A',
      state: '#43A047'
    },

    warning: {
      main: '#FFA726',
      state: '#FB8C00'
    },

    error: {
      main: '#EF5350',
      state: '#E53935'
    },

    light: {
      main: '#EBEFF4',
      state: '#CED4DA'
    },

    dark: {
      main: 'rgba(0, 0, 0, 0.87)',
      state: '#191919'
    }
  },

  socialMediaColors: {
    facebook: {
      main: '#4285F4',
      dark: '#4285F4'
    },

    twitter: {
      main: '#121212',
      dark: '#121212'
    },

    linkedin: {
      main: '#4285F4',
      dark: '#4285F4'
    },

    whatsApp: {
      main: '#4caf50',
      dark: '#4caf50'
    },

    instagram: {
      main: '#125688',
      dark: '#0e456d'
    },

    pinterest: {
      main: '#cc2127',
      dark: '#b21d22'
    },

    youtube: {
      main: '#e52d27',
      dark: '#d41f1a'
    },

    vimeo: {
      main: '#1ab7ea',
      dark: '#13a3d2'
    },

    slack: {
      main: '#3aaf85',
      dark: '#329874'
    },

    dribbble: {
      main: '#ea4c89',
      dark: '#e73177'
    },

    github: {
      main: '#24292e',
      dark: '#171a1d'
    },

    reddit: {
      main: '#ff4500',
      dark: '#e03d00'
    },

    tumblr: {
      main: '#35465c',
      dark: '#2a3749'
    }
  },

  badgeColors: {
    primary: {
      background: '#EEF5FF',
      text: '#3969EC'
    },

    secondary: {
      background: '#d7d9e1',
      text: '#6c757d'
    },

    info: {
      background: '#aecef7',
      text: '#095bc6'
    },

    success: {
      background: '#bce2be',
      text: '#339537'
    },

    warning: {
      background: '#ffd59f',
      text: '#c87000'
    },

    error: {
      background: '#fcd3d0',
      text: '#f61200'
    },

    light: {
      background: '#ffffff',
      text: '#c7d3de'
    },

    dark: {
      background: '#8097bf',
      text: '#1e2e4a'
    }
  },

  coloredShadows: {
    primary: '#3969EC',
    secondary: '#110e0e',
    info: '#00bbd4',
    success: '#4caf4f',
    warning: '#ff9900',
    error: '#f44336',
    light: '#adb5bd',
    dark: '#404040'
  },

  inputBorderColor: '#d2d6da',

  tabs: {
    indicator: { boxShadow: '#ddd' }
  }
};

export default colors;
