import React from 'react';

import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const DeleteConfirmModal = ({ isOpen, toggle }) => {
  const { userStore } = useStore();

  return (
    <Modal className='account-delete-modal' {...{ isOpen, toggle }}>
      <ModalHeader toggle={() => toggle('isOpenDeleteConfirmModal')}>
        <div className='modal-title'>Delete Account</div>
      </ModalHeader>

      <ModalBody>
        <div className='delete-account-body'>
          Deleting your account will remove all of your information from our database. This is cannot be undone
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          className='btn cancel-btn'
          onClick={() => {
            toggle('isOpenDeleteConfirmModal');
          }}
        >
          Cancel
        </Button>
        <Button
          className='btn delete-btn'
          onClick={() => {
            userStore.deleteCurrentUser().then(() => {
              toastr.success('Your success delete user with all data');
              toggle('isOpenDeleteConfirmModal');
            });
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
