import { action, observable, runInAction } from 'mobx';

import { renewalDetailApi } from '@packs/apis';
import api from '@packs/apis/smartReminder';
import { toastr } from '@packs/lib/helpers';

import dayjs from 'dayjs';
import filter from 'lodash-es/filter';
import omit from 'lodash-es/omit';
import size from 'lodash-es/size';
import sortBy from 'lodash-es/sortBy';

export class SmartRemindersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  smartReminders = [];
  @observable
  businessSmartReminders = [];
  @observable
  isLoading = false;
  @observable
  isBusinessLoading = false;
  @observable
  smartRemindersCount = 0;

  @action
  fetchSmartReminders(attrs, onSuccess, onError) {
    this.setLoading(true);

    api.fetchSmartReminders(attrs).then(res => {
      if (size(res?.errors)) {
        onError && onError(res.errors);
        this.setLoading(false);
      } else if (res) {
        runInAction(() => {
          this.smartReminders = res;
          this.setLoading(false);
          onSuccess && onSuccess(res);
        });
      }
    });
  }

  @action
  fetchSmartRemindersCount(attrs) {
    api.fetchSmartRemindersCount(attrs).then(res => {
      if (res) {
        runInAction(() => {
          this.smartRemindersCount = size(
            sortBy(
              filter(res, item => !dayjs(item?.triggeredAt).isBefore()),
              ev => new Date(ev.triggeredAt)
            )
          );
        });
      }
    });
  }
  @action
  resetSmartReminders() {
    this.smartReminders = [];
  }
  @action
  fetchBusinessSmartReminders(attrs, onSuccess, onError) {
    this.setBusinessLoading(true);

    api.fetchSmartReminders(attrs).then(res => {
      if (size(res?.errors)) {
        onError && onError(res.errors);
        this.setLoading(false);
      } else if (res) {
        runInAction(() => {
          this.businessSmartReminders = res;
          this.setBusinessLoading(false);
          onSuccess && onSuccess(res);
        });
      }
    });
  }

  @action
  create(attrs, onSuccess, onError) {
    this.setLoading(true);
    this.setBusinessLoading(true);

    api.createSmartReminder(attrs).then(res => {
      runInAction(() => {
        if (size(res?.errors)) {
          onError && onError(res.errors);
          this.setLoading(false);
          this.setBusinessLoading(false);
        } else if (res) {
          const smartReminder = res?.smartReminder || {};

          runInAction(() => {
            this.smartReminders = [...this.smartReminders, smartReminder].sort((a, b) => a.triggeredAt - b.triggeredAt);
            this.businessSmartReminders = [...this.businessSmartReminders, smartReminder].sort(
              (a, b) => a.triggeredAt - b.triggeredAt
            );
            this.smartRemindersCount = this.smartRemindersCount + 1;
          });
          if (smartReminder?.renewalDetail?.id === this.rootStore.currentPolicy?.id) {
            this.rootStore.policyStore.setCurrentPolicy({
              ...this.rootStore.currentPolicy,
              smartReminder
            });
          }
          this.rootStore.userStore.setProfileReminderCount(this.smartReminders.length);
          this.rootStore.notificationsStore.pushNotificationAndOrder(res.notification);
          this.setLoading(false);
          this.setBusinessLoading(false);
          onSuccess && onSuccess(res);
        }
      });
    });
  }

  @action
  update(attrs, onSuccess, onError) {
    this.setLoading(true);
    this.setBusinessLoading(true);

    api.updateSmartReminder(attrs).then(res => {
      if (size(res?.errors)) {
        onError && onError(res.errors);
        this.setLoading(false);
        this.setBusinessLoading(false);
      } else if (res) {
        runInAction(() => {
          const smartReminder = res?.smartReminder || {};

          this.smartReminders = [...this.smartReminders]
            .map(item => {
              if (smartReminder?.id === item.id) {
                return smartReminder;
              }
              return item;
            })
            .sort((a, b) => a.triggeredAt - b.triggeredAt);

          this.businessSmartReminders = [...this.businessSmartReminders]
            .map(item => {
              if (smartReminder?.id === item.id) {
                return smartReminder;
              }
              return item;
            })
            .sort((a, b) => a.triggeredAt - b.triggeredAt);

          if (smartReminder?.renewalDetail?.id === this.rootStore.currentPolicy?.id) {
            this.rootStore.policyStore.setCurrentPolicy({
              ...this.rootStore.currentPolicy,
              smartReminder
            });
          }
          this.setLoading(false);
          this.setBusinessLoading(false);
          this.rootStore.notificationsStore.pushNotificationAndOrder(res.notification);
          onSuccess && onSuccess(res);
        });
      }
    });
  }

  @action
  toggleIsAutoQuote({ isAutoQuote, smartReminder }) {
    const smartReminderId = smartReminder?.id;

    renewalDetailApi.updateIsAutoQuote({ id: smartReminderId, isAutoQuote }).then(res => {
      if (!res?.errors?.length) {
        runInAction(() => {
          this.smartReminders = [...this.smartReminders]
            .map(item => {
              if (item.id === smartReminderId) {
                return {
                  ...item,
                  isAutoQuote
                };
              }
              return item;
            })
            .sort((a, b) => a.triggeredAt - b.triggeredAt);
        });
        toastr.success(`Auto. quote is turned ${isAutoQuote ? 'on' : 'off'}`);
      }
    });
  }

  @action
  destroy(attrs, onSuccess, onError) {
    this.setLoading(true);
    this.setBusinessLoading(true);

    api.destroySmartReminders(omit(attrs, ['policyId'])).then(res => {
      if (size(res?.errors)) {
        onError && onError(res.errors);
        this.setLoading(false);
        this.setBusinessLoading(false);
      } else if (res) {
        runInAction(() => {
          this.smartReminders = [...this.smartReminders].filter(item => !attrs?.ids.includes(item?.id));
          this.businessSmartReminders = [...this.businessSmartReminders].filter(item => !attrs?.ids.includes(item?.id));

          if (attrs?.policyId === this.rootStore.currentPolicy?.id) {
            this.rootStore.policyStore.setCurrentPolicy({
              ...this.rootStore.currentPolicy,
              smartReminder: {
                ...this.rootStore.smartReminder,
                id: null
              }
            });
          }
          this.smartRemindersCount = this.smartRemindersCount - 1;
          this.setLoading(false);
          this.setBusinessLoading(false);
          // SET_USER_PROFILE_STATS
          this.rootStore.userStore.setProfileReminderCount(this.smartReminders.length);

          onSuccess && onSuccess(res);
        });
      }
    });
  }

  @action
  setLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setBusinessLoading(loading) {
    this.isBusinessLoading = loading;
  }
}
