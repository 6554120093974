export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  },
  imgContainer: (filled = false) => ({
    position: 'relative',
    minHeight: '200px',
    pt: '47%',
    borderRadius: '16px',
    backgroundColor: '#fff',
    overflow: 'hidden',
    ...(filled
      ? {
          '& img': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1
          }
        }
      : {
          '& img': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 1
          }
        })
  })
});
