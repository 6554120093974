import React, { useRef, useState } from 'react';
import moment from 'moment';

import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SignInModalLink } from '@packs/components';
import { commentableType, helpfulTypes } from '@packs/lib/constants';
import { shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { AnswerForm } from '@shared/components/QA/AnswerForm/AnswerForm';
import { RepliesList } from '@shared/components/QA/RepliesList';
import { avatarPlaceholder, stringToColor } from '@shared/helpers/additionalHelpers';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import AnimateHeight from 'react-animate-height';

export const AnswerItem = ({ answer, postId, withoutComments, questionsStore, currentUser, toggleSignUpModal }) => {
  const styles = useStyles();
  const answerItemRef = useRef(null);
  const { isMobile } = useWindowSize();
  const [showReplies, setShowReplies] = useState(false);

  const { id, user, message, updatedAt, isHelpful, businessUser, commentsCount, helpfulsCount } = answer;

  const companyAssigned = !!businessUser?.company;
  const avatarUrl = businessUser?.company?.avatarUrl || user?.avatarUrl || '';
  const displayName = businessUser?.businessName || user?.displayName || '';

  const toggleReplies = () => {
    setShowReplies(!showReplies);
  };

  const onToggleHelpful = () => {
    questionsStore.toggleHelpful({
      attrs: {
        helpfulableType: commentableType.comment,
        helpfulableId: id
      },
      postId,
      helpfulType: isHelpful ? helpfulTypes.destroy : helpfulTypes.create
    });
  };

  const submitCallback = () => {
    window.scrollTo({
      top: answerItemRef?.current?.offsetTop + answerItemRef?.current?.clientHeight - 100,
      behavior: 'smooth'
    });
  };

  return (
    <Box sx={styles.wrapper} ref={answerItemRef}>
      <Box sx={{ pt: 2 }}>
        <Avatar
          sx={{ width: '40px', height: '40px', backgroundColor: stringToColor(displayName) }}
          src={avatarUrl}
          variant='rounded'
        >
          {avatarPlaceholder(displayName)}
        </Avatar>
      </Box>
      <Box sx={styles.bodyWrapper}>
        <Box sx={styles.bodyContainer}>
          <Box sx={{ mb: 1 }}>
            <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {companyAssigned && <ReplyRoundedIcon sx={{ fontSize: '14px' }} />}
              {companyAssigned && <Typography variant='caption'>Replay from</Typography>}
              <span>{companyAssigned ? displayName : shortSecondName(displayName)}</span>
            </Typography>
            <Typography variant='body3' sx={{ color: 'rgba(0, 0, 0, 0.60)' }}>
              {(updatedAt && moment(updatedAt).fromNow()) || '-'}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1'>{message}</Typography>
          </Box>
        </Box>
        <Box sx={styles.actionPanelWrapper}>
          <Button
            variant='text'
            color='dark'
            size='smallToMedium'
            startIcon={<CommentRoundedIcon />}
            onClick={toggleReplies}
          >
            {!isMobile && 'Replies '}
            {commentsCount}
          </Button>
          {currentUser?.id ? (
            <Button
              variant='text'
              color={isHelpful ? 'primary' : 'dark'}
              size='smallToMedium'
              startIcon={<ThumbUpAltRoundedIcon />}
              onClick={onToggleHelpful}
            >
              {!isMobile && 'Helpful '}
              {helpfulsCount || 0}
            </Button>
          ) : (
            <SignInModalLink variant='text' color='dark' size='smallToMedium' startIcon={<ThumbUpAltRoundedIcon />}>
              {!isMobile && 'Helpful '}
              {helpfulsCount || 0}
            </SignInModalLink>
          )}
        </Box>
        {!withoutComments && (
          <AnimateHeight duration={250} height={showReplies ? 'auto' : 0}>
            <Box>
              <AnswerForm
                form={`qa-from-${id}-${postId}`}
                submitBtnText='Add reply'
                placeholder='Reply...'
                commentableType={commentableType.comment}
                commentableId={id}
                postId={postId}
                submitCallback={submitCallback}
                {...{ questionsStore, currentUser, toggleSignUpModal }}
              />
              <RepliesList
                {...{ questionsStore, currentUser, toggleSignUpModal, postId }}
                commentId={id}
                isOpen={showReplies}
              />
            </Box>
          </AnimateHeight>
        )}
      </Box>
    </Box>
  );
};
