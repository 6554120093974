import React from 'react';

import Box from '@mui/material/Box';

export const IconBox = ({
  borderRadius = '4px',
  backgroundColor = '#fff',
  color = '#fff',
  containerSize = '24px',
  iconSize = '75%',
  icon,
  onClick = null,
  rootIcon = false,
  cursor = 'auto'
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: containerSize,
        minWidth: containerSize,
        height: containerSize,
        borderRadius,
        backgroundColor,
        cursor: onClick ? 'pointer' : cursor,
        '& svg, & img': {
          width: `${iconSize} !important`,
          height: `${iconSize} !important`,
          fill: color
        },
        ...(rootIcon && { '& path': { fill: color } })
      }}
      onClick={() => onClick && onClick()}
    >
      {icon}
    </Box>
  );
};
