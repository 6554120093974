import React, { useEffect, useMemo, useState } from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { requestsApi } from '@packs/apis';
import { FiltersDrawer } from '@packs/components/MaterialUi/FiltersDrawer/FiltersDrawer';
import { usePath } from '@packs/layouts/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { Filter } from '@packs/screens/MyQuoteRequests/components/Filter/Filter';
import { QuoteRequestsTable } from '@packs/screens/MyQuoteRequests/components/QuoteRequestsTable/QuoteRequestsTable';
import useLeaderboardStyles from '@packs/styles/Leaderboard/leaderboardWrapper';
import { websitePageUrl } from '@shared/helpers/urlHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import dayjs from 'dayjs';
import _isEqual from 'lodash-es/isEqual';
import { Helmet } from 'react-helmet/es/Helmet';

export const defaultPolicy = { value: 'all', label: 'All policy' };
export const defaultYear = { value: 'all', label: 'All years' };
export const requestsPerPage = 10;

export interface IQuote {
  id: number;
  status: string;
  createdAt: string;
  renewalDetail: {
    smartReminder: {
      aliasName: string;
    };
  };
  quoteAgent: {
    id: number;
    company: {
      id: number;
      name: string;
      url: string;
      urlId: string;
      avatarUrl: string;
    };
  };
}

export interface IQuoteRequest {
  list: IQuote[];
  totalCount: number;
  createdAtFirstYear: number;
}

export interface IQuoteRequestFilters {
  renewalDetailId: { value: string | number | null; label: string };
  page: number;
  perPage: number;
  categoryId: number;
  years: { value: string; label: string };
}

export const MyQuoteRequests = () => {
  const { DASHBOARD_MY_RESULTS_PATH } = usePath();
  const { currentCategory } = useCategories();
  const { isDesktop } = useWindowSize();
  const stylesLeaderboard = useLeaderboardStyles();
  const styles = useStyles();

  const INITIAL_FILTERS = {
    renewalDetailId: defaultPolicy,
    page: 1,
    perPage: requestsPerPage,
    categoryId: currentCategory?.id,
    years: defaultYear
  };
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IQuoteRequestFilters>(INITIAL_FILTERS);
  const [requestData, setRequestData] = useState<IQuoteRequest>({
    list: [],
    totalCount: 0,
    createdAtFirstYear: dayjs().year()
  });

  const disabledRemove = useMemo(() => {
    return _isEqual(filters, INITIAL_FILTERS);
  }, [filters]);

  const fetchData = async variables => {
    const newFilters = { ...filters, ...variables };
    const requestFilters = {
      ...newFilters,
      renewalDetailId:
        newFilters?.renewalDetailId?.value !== defaultPolicy.value ? newFilters?.renewalDetailId?.value : null,
      years: newFilters?.years?.value !== defaultYear.value ? newFilters?.years?.value : null
    };
    setFilters(newFilters);
    setLoading(true);

    const res = await requestsApi.quoteRequestsList(requestFilters);

    setRequestData(res);
    setLoading(false);
  };

  const onResetFilters = () => {
    setFilters(INITIAL_FILTERS);
  };

  useEffect(() => {
    fetchData(filters);
  }, []);

  return (
    <>
      <Helmet>
        <link rel='canonical' href={websitePageUrl() + DASHBOARD_MY_RESULTS_PATH.replace('/', '')} />
      </Helmet>
      <Box sx={styles.pageWrapper}>
        <Box sx={stylesLeaderboard.container}>
          {isDesktop && (
            <Box sx={stylesLeaderboard.filterContainer}>
              <Box sx={stylesLeaderboard.filterContainerInner}>
                <Box sx={stylesLeaderboard.filterTitle}>
                  <Typography variant='body4'>Filter by:</Typography>
                  <Button
                    variant='text'
                    size='small'
                    color='dark'
                    sx={{ minWidth: '44px' }}
                    disabled={disabledRemove}
                    onClick={onResetFilters}
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
              <Filter
                filters={filters}
                createdAtFirstYear={requestData.createdAtFirstYear}
                withTitle
                fetchData={fetchData}
              />
            </Box>
          )}

          <Box sx={{ ...stylesLeaderboard.content, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {isDesktop && <Typography variant='body3'>{`${requestData.totalCount} Quote Requests found`}</Typography>}
            <Typography variant='h6'>Quote Requests</Typography>
            {!isDesktop && (
              <Box sx={styles.actionSectionWrapper}>
                <Box sx={styles.actionSection}>
                  <FiltersDrawer
                    buttonProps={{
                      variant: 'filledTonal',
                      color: 'primary',
                      startIcon: <FilterAltIcon />,
                      sx: { width: { xs: '100%', lg: 'auto' } }
                    }}
                    handleResetFilters={onResetFilters}
                    // disabledResetFilters={disabledResetFilters}
                    showResultText={`Show ${requestData?.totalCount} quotes`}
                  >
                    <Filter
                      filters={filters}
                      sidebarFilter={false}
                      createdAtFirstYear={requestData?.createdAtFirstYear}
                      fetchData={fetchData}
                    />
                  </FiltersDrawer>
                </Box>
              </Box>
            )}
            {!isDesktop && <Typography variant='body3'>{`${requestData.totalCount} Quote Requests found`}</Typography>}
            <QuoteRequestsTable
              filters={filters}
              list={requestData.list}
              totalCount={requestData.totalCount}
              loading={loading}
              fetchData={fetchData}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
