import React from 'react';

import { SentimentAnalysisMetric } from '@packs/components/MaterialUi/CompanyPage/components/Sidebar/components/SentimentAnalysisMetric/SentimentAnalysisMetric';
import { SidebarTitle } from '@packs/components/MaterialUi/CompanyPage/components/Sidebar/components/SidebarTitle/SidebarTitle';
import SentimentIcon from '@shared/images/company/sentiment-icon.svg';

import isNumber from 'lodash-es/isNumber';

export const SentimentAnalysis = ({ insightsCompanyStore }) => {
  const sentimentAnalysis = insightsCompanyStore?.object?.sentimentAnalysis;

  if (!isNumber(sentimentAnalysis?.score)) {
    return null;
  }

  return (
    <SidebarTitle icon={<img src={SentimentIcon} alt='Chart' />} title='Sentiment Analysis'>
      <SentimentAnalysisMetric sentimentAnalysis={sentimentAnalysis} />
    </SidebarTitle>
  );
};
