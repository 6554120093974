import React, { FC } from 'react';
import { Form, Formik } from 'formik';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import FormField from '@packs/components/MaterialUi/FormField';
import { CommunityBlock } from '@packs/components/MaterialUi/Landing/Hero/components/CommunityBlock/CommunityBlock';
import { PoweredBlock } from '@packs/components/MaterialUi/Landing/Hero/components/PoweredBlock/PoweredBlock';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import get from 'lodash-es/get';
import isNull from 'lodash-es/isNull';
import isUndefined from 'lodash-es/isUndefined';
import size from 'lodash-es/size';
import startCase from 'lodash-es/startCase';
import { useNavigate } from 'react-router-dom';

type Props = {
  initialValues: any;
  handleSubmit: (data: any) => void;
  fields: any[];
  onChangeSelectedCategory?: (data: any) => void;
  isRootLandingPage?: boolean;
};

export const LandingCriteria: FC<Props> = ({
  initialValues = {},
  handleSubmit,
  fields = [],
  onChangeSelectedCategory,
  isRootLandingPage = false
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { isDesktop } = useWindowSize();
  const { currentCategory } = useStore();
  const { DASHBOARD_LANDING_PATH_FN } = usePath();

  const handleChangeField = (data, name) => {
    if (name === 'category') {
      typeof onChangeSelectedCategory === 'function' && onChangeSelectedCategory(data);
    } else if (name === 'city' && !isRootLandingPage) {
      const scrollPosition = window.scrollY;
      navigate(DASHBOARD_LANDING_PATH_FN(currentCategory, data.label));
      if (scrollPosition && !isDesktop) {
        setTimeout(() => {
          window.scrollTo({ top: scrollPosition, left: 0, behavior: 'smooth' });
        }, 100);
      }
    }
  };

  const areAllFieldsFilled = (fields, values) => {
    const filledFields = fields.filter(item => {
      const value = get(values, `${item.name}`);
      return !isNull(value) && !isUndefined(value);
    });

    return size(fields) === size(filledFields);
  };

  return (
    <Zoom in={true} timeout={500}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.container}>
          <Typography variant='body2' sx={styles.formTitle}>
            Personalize your results!
          </Typography>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, setFieldValue }) => {
              return (
                <Form autoComplete='off'>
                  <Box sx={styles.formContainer}>
                    {fields.map(field => {
                      const value = get(values, `${field?.name}`, null);

                      field.label = field?.label.replace(':category_name', startCase(currentCategory.name));
                      if (field.type === 'radio') {
                        field.type = 'select';
                      }

                      return (
                        <Box key={field.name} sx={styles.formItem}>
                          <FormField
                            field={field}
                            setFieldValue={setFieldValue}
                            value={value}
                            options={field?.options}
                            disableClearable
                            mobileSelect
                            filter
                            onChange={(_, data) => handleChangeField(data, field?.name)}
                          />
                        </Box>
                      );
                    })}
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      size='large'
                      fullWidth
                      disabled={!areAllFieldsFilled(fields, values)}
                    >
                      See Results!
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
        {!isDesktop && (
          <Box sx={styles.footer}>
            <CommunityBlock currentCategoryId={currentCategory.id} />
            <PoweredBlock {...{ currentCategory }} variant='body4' />
          </Box>
        )}
      </Box>
    </Zoom>
  );
};
