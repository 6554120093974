import React, { useRef, useState } from 'react';
import cx from 'classnames';

// Components
import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { Avatar } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
// Images
import SendMessageIcon from '@packs/images/icons/UI/SendMessageIcon';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { runFormSubmit } from '@packs/lib/helpers';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Field, reduxForm } from 'redux-form';

const InsightForm = ({
  handleSubmit,
  submitBtnText = 'Add a comment',
  placeholder = 'Add a comment...',
  commentableType,
  commentableId,
  postId,
  change,
  submitCallback,
  createComment
}) => {
  const { currentUser } = useUserSelectors();
  const { isDesktop } = useWindowSize();
  const { toggleSignUpModal } = useModalsContext();
  const formRef = useRef();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const avatarUrl = currentUser?.company?.avatarUrl || currentUser?.avatarUrl || '';
  const displayName = currentUser?.company?.name || currentUser?.displayName || '';

  const onSubmit = formAttributes => {
    const onSuccess = () => {
      setIsLoading(false);
      change('message', null);
      submitCallback && submitCallback();
    };

    const attrs = {
      message: formAttributes.message,
      renewalDetailId: postId,
      replayCommentId: commentableId,
      authorId: currentUser.id
    };

    const doSubmit = () => {
      if (isLoading && createComment) {
        return false;
      }

      setIsLoading(true);

      createComment(attrs, postId, commentableType)
        .then(onSuccess)
        .catch(() => setIsLoading(false));
    };

    if (currentUser?.id) {
      doSubmit();
    } else {
      toggleSignUpModal({ onSignUpSuccess: doSubmit });
    }
  };

  return (
    <>
      <form className='add-comment-form add-qa-form' ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        {isDesktop && currentUser?.id && (
          <div className='sender-avatar'>
            <Avatar avatarUrl={avatarUrl} placeholder={displayName} size={40} fullSize withBorder withBG />
          </div>
        )}
        <Field
          name='message'
          wrapperClassName='textarea-wrapper'
          className={cx('m-0', { filled: message })}
          placeholder={placeholder}
          componentType='textarea'
          component={FieldWithErrors}
          disableFocusReset
          hidePlaceholder
          onEnter={() => {
            runFormSubmit(formRef.current);
          }}
          onChange={value => setMessage(value.target.value)}
        />
        {!isDesktop ? (
          <button className={cx('btn empty black add-comment-btn with-out-animation', { disabled: isLoading })}>
            <SendMessageIcon />
          </button>
        ) : (
          <button
            className={cx('btn empty black add-comment-btn with-out-animation', {
              active: message,
              disabled: isLoading
            })}
          >
            {isLoading && <span className='spinner-border spinner-border-sm mr-3' role='status' aria-hidden='true' />}
            <span>{submitBtnText}</span>
          </button>
        )}
      </form>
    </>
  );
};

const createReduxForm = reduxForm({ form: 'answerForm' });

export const AnswerFormComment = createReduxForm(InsightForm);
