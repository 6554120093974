import React, { useMemo } from 'react';
import moment from 'moment';

import { getCurrency } from '@packs/lib/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';

import { RequestDetailsItem } from './components/RequestDetailsItem';

import camelCase from 'lodash-es/camelCase';
import isNull from 'lodash-es/isNull';
import isUndefined from 'lodash-es/isUndefined';
import map from 'lodash-es/map';
import { observer } from 'mobx-react';

export const RequestDetails = observer(() => {
  const { countryCode } = useStore();
  const { currentPolicy, formOptions } = useDetails();

  const options = formOptions;

  const criteriaQuestions = useMemo(() => {
    return currentPolicy?.criteriaQuestions || {};
  }, [currentPolicy?.criteriaQuestions]);

  const pricesQuestions = useMemo(() => {
    return currentPolicy?.pricesQuestions?.price || {};
  }, [currentPolicy?.pricesQuestions?.price]);

  const currency = useMemo(() => {
    return getCurrency(countryCode);
  }, [countryCode]);

  return (
    <div>
      {currentPolicy?.id && <RequestDetailsItem label='My Details' answer={currentPolicy?.smartReminder?.name} />}
      {currentPolicy?.criteriaQuestions?.location && (
        <RequestDetailsItem label='County' answer={currentPolicy?.criteriaQuestions?.location} />
      )}
      {currentPolicy?.anyCompany && (
        <RequestDetailsItem label='Current business' answer={currentPolicy?.anyCompany?.name} />
      )}
      {currentPolicy?.sourceProviderCompany && (
        <RequestDetailsItem label='Current provider' answer={currentPolicy?.sourceProviderCompany?.name} />
      )}
      {map(options, item => {
        const answer =
          Array.isArray(item?.options) && item?.options?.length
            ? item?.options?.find(option => option?.value === criteriaQuestions[camelCase(item.name)])?.label
            : criteriaQuestions[camelCase(item.name)];

        return <RequestDetailsItem key={item.id} label={item.label} answer={answer} />;
      })}
      {!(isNull(pricesQuestions?.value) || isUndefined(pricesQuestions?.value)) && (
        <RequestDetailsItem
          label='Price'
          answer={`${currency} ${pricesQuestions?.value}/${
            pricesQuestions?.value?.period === 'per_month' ? 'Per Month' : 'Per Year'
          }`}
        />
      )}
      {currentPolicy?.smartReminder && (
        <RequestDetailsItem
          label='Do you wish to set a reminder'
          answer={moment(currentPolicy?.updatedAt).format('DD MMMM YYYY')}
        />
      )}
    </div>
  );
});
