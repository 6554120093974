import React, { useState } from 'react';

import { Box, Stack, Typography, Zoom } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { MidWayCategoriesList } from '@packs/components/UI/CategoriesNavigation/components/MidWayCategoriesList';
import { SubCategoriesList } from '@packs/components/UI/CategoriesNavigation/components/SubCategoriesList';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { sortSubcategoriesDesktop } from '@packs/lib/helpers/sortSubcategoriesDesktop';
import { COMING_SOON_CATEGORIES } from '@packs/models/category/constants';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { rootCategoriesLanding } from '@shared/constants/categories';
import { local_services } from '@shared/constants/offers';
import { ICONS } from '@shared/helpers/categoriesIconsHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { BannerWithAction } from './Banners/BannerWithAction';
import { SimpleBanner } from './Banners/SimpleBanner';
import useStyles from './styles';

import size from 'lodash-es/size';
import startCase from 'lodash-es/startCase';
import { MacScrollbar } from 'mac-scrollbar';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

const { text, secondary } = colors;

const count = 10;

export const CategoriesMenu = observer(({ initialRootCategory, showAllCategories = null, index = 0, children }) => {
  const styles = useStyles();
  const { windowSize } = useWindowSize();
  const { categoryStore, companiesStore } = useStore();
  const { categories } = useCategories();
  const { currentUser } = useUserSelectors();
  const { DASHBOARD_LANDING_PATH_FN, DASHBOARD_ROOT_LANDING_PATH_FN, DASHBOARD_MY_RESULTS_PATH_FN } = usePath();

  const [selectedRootCategory, setSelectedRootCategory] = useState(initialRootCategory ? initialRootCategory : {});
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const subCategories = sortSubcategoriesDesktop(selectedRootCategory || {}, count);
  const isPopularRootCategory = rootCategoriesLanding.some(
    popular => popular === (showAllCategories ? selectedRootCategory.code : initialRootCategory.code)
  );
  const isComingSoonCategory = COMING_SOON_CATEGORIES.includes(selectedRootCategory?.code);

  const onCloseMenu = () => {
    setOnMouseEnter(false);
    if (showAllCategories) {
      setTimeout(() => {
        setSelectedRootCategory(initialRootCategory ? initialRootCategory : {});
      }, 500);
    }
  };

  const handleOnMouseEnter = () => {
    setOnMouseEnter(true);
  };

  const handleOnMouseLeave = () => {
    onCloseMenu();
  };

  const onSelectRootCategory = root => () => {
    setSelectedRootCategory(root);
  };

  const onClickCategory = category => () => {
    onCloseMenu();
    setTimeout(() => {
      categoryStore.setCurrentCategory(category);
      companiesStore.clearFilters();
    }, 50);
  };

  const categoryUrl = category => {
    let url = DASHBOARD_LANDING_PATH_FN(category);

    if (categoryHavePolicy(category, categoryStore.categoriesWithPolicy, currentUser)) {
      url = DASHBOARD_MY_RESULTS_PATH_FN(category);
    } else if (isPopularRootCategory) {
      url = DASHBOARD_ROOT_LANDING_PATH_FN(category).concat(category.code ? `?initialCategory=${category.code}` : '');
    }

    return url;
  };

  const columnCount = categories => {
    let initCount = categories?.length;

    if (windowSize <= 1100) {
      initCount = 1;
    } else if (windowSize <= 1480 && initCount >= 2) {
      initCount = 2;
    } else if (categories?.length >= 3) {
      initCount = 3;
    }

    return initCount;
  };

  return (
    <Box position='relative' onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      {children}
      <Zoom in={onMouseEnter} timeout={200}>
        <Box
          position='absolute'
          top='100%'
          m={0}
          zIndex={20}
          sx={{
            left: {
              xs: index ? `-${index * 90}px` : '-20px',
              xl: index ? `-${index * 80}px` : '-20px'
            }
          }}
        >
          <Box mt={2}>
            <Stack direction='row' spacing={1} alignItems='flex-start'>
              {showAllCategories && (
                <Box p={3} borderRadius='16px' sx={styles.showAllWrapper}>
                  <Stack direction='column' spacing={0.25}>
                    {categories.map(root => (
                      <Stack
                        key={root.code}
                        direction='row'
                        alignItems='center'
                        spacing={1}
                        sx={styles.showAllColumn(selectedRootCategory, root)}
                        onMouseEnter={onSelectRootCategory(root)}
                      >
                        <IconBox
                          containerSize='32px'
                          iconSize='24px'
                          borderRadius='8px'
                          backgroundColor={secondary.transparent}
                          color={secondary.main}
                          onClick={() => {}}
                          icon={ICONS[root.code]}
                          rootIcon
                        />
                        <Typography variant='body4' sx={styles.categoryName}>
                          {startCase(root.name)}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Box>
              )}
              {size(selectedRootCategory) !== 0 && (
                <Box sx={styles.subCategoriesWrapper(showAllCategories)}>
                  <MacScrollbar
                    style={{ flex: 1, height: '498px' }}
                    trackStyle={horizontal => ({
                      [horizontal ? 'height' : 'width']: 0,
                      right: 2,
                      border: 0
                    })}
                    thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
                    suppressAutoHide
                  >
                    {selectedRootCategory?.code === local_services ? (
                      <MidWayCategoriesList
                        selectedRootCategory={selectedRootCategory}
                        midwayCategories={selectedRootCategory?.children}
                        isComingSoonCategory={isComingSoonCategory}
                        onClickCategory={onClickCategory}
                        categoryUrl={categoryUrl}
                        columnCount={columnCount}
                      />
                    ) : (
                      size(subCategories) > 0 && (
                        <SubCategoriesList
                          columnCount={columnCount}
                          subCategories={subCategories}
                          isComingSoonCategory={isComingSoonCategory}
                          onClickCategory={onClickCategory}
                          categoryUrl={categoryUrl}
                        />
                      )
                    )}
                  </MacScrollbar>

                  {rootCategoriesLanding.includes(selectedRootCategory.code) ? (
                    <BannerWithAction category={selectedRootCategory} onCloseMenu={onCloseMenu} />
                  ) : (
                    <SimpleBanner category={selectedRootCategory} />
                  )}
                </Box>
              )}
            </Stack>
          </Box>
        </Box>
      </Zoom>
    </Box>
  );
});
