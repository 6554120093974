import colors from '@packs/assets/theme/base/colors';

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: {
      xs: '100%',
      sm: '264px'
    },
    borderRadius: '8px',
    border: `2px solid ${colors.borderColor.main}`,
    p: 2,
    backgroundColor: colors.white.main,
    color: colors.dark.main,
    transition: 'all 0.2s'
  },
  title: {
    minHeight: '42px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical'
  },
  categoryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    height: '24px',
    borderRadius: '4px',
    px: 1,
    backgroundColor: 'rgba(224, 224, 224, 1)'
  },
  locationWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    mb: '2px'
  },
  locationIcon: {
    fontSize: '16px',
    color: colors.text.secondary
  }
});
