import React from 'react';
import cx from 'classnames';

import CheckIcon from '@packs/images/icons/CheckboxIcon';

import { DEFAULT_OPTIONS } from './constants';

const defaultProps = { onChange: () => {} };
const RadioButtonGroup = ({ name, onChange, multiple, options, value, theme, buttonType, className }) => {
  return (
    <div
      className={cx('radio-button-ui-list', className, {
        'button-type': buttonType,
        theme,
        multiple
      })}
    >
      {options.map((option, index) => {
        option = { ...DEFAULT_OPTIONS, ...option };

        return (
          <label
            key={multiple ? `${option.value.length}-${index}` : option.value}
            className='radio-button-ui'
            style={option.visible ? {} : { display: 'none' }}
          >
            {option.title && <h5>{option.title}</h5>}
            {multiple ? (
              <input
                type='checkbox'
                name={name}
                value={option.value}
                checked={value?.includes(option.value)}
                disabled={option.disabled}
                onChange={() => {
                  onChange(option.value);
                }}
              />
            ) : (
              <input
                type='radio'
                name={name}
                value={option.value}
                checked={value === option.value}
                disabled={option.disabled}
                onChange={() => onChange(option.value)}
              />
            )}
            <span className='checkmark'>{multiple && <CheckIcon />}</span>
            <div className='label'>{option.label}</div>
          </label>
        );
      })}
    </div>
  );
};
RadioButtonGroup.defaultProps = defaultProps;

export default RadioButtonGroup;
