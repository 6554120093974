import React, { Fragment } from 'react';

import RadioButtonGroup from '@packs/components/RadioButtonGroup';

import isUndefined from 'lodash-es/isUndefined';

export const RadioButtonField = ({
  meta: { touched, error },
  input,
  multiple,
  change,
  options,
  disabled = false,
  controlError,
  theme,
  handleChange = null,
  customValue = null,
  buttonType = false,
  className
}) => {
  const { name, onChange } = input;
  if (disabled) options = options.map(option => ({ ...option, disabled: true }));
  let value = !isUndefined(input.value) ? input.value : customValue;

  if (multiple) {
    if (value && typeof value === 'string') {
      value = [value];
    } else if (!value) {
      value = [];
    }
  }

  const handleOnChange = val => {
    if (multiple) {
      if (value.includes(val)) {
        value = value.filter(v => v !== val);
      } else {
        value = [...value, val];
      }

      onChange(value, name);
      change && change(name, value);
    } else {
      onChange(val, name);
      change && change(name, val);
    }

    handleChange && handleChange(val);
  };

  const spanError = errors => errors && <span className='error-hint'>{errors}</span>;
  return (
    <Fragment>
      <RadioButtonGroup
        onChange={handleOnChange}
        {...{
          name,
          options,
          multiple,
          theme,
          buttonType,
          className
        }}
        value={value}
      />
      {controlError === undefined ? touched && spanError(error) : spanError(controlError)}
    </Fragment>
  );
};
