import React, { useMemo, useState } from 'react';

import {
  BusinessCenterRounded as BusinessCenterRoundedIcon,
  KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon
} from '@mui/icons-material/';
import { Box, Button, Divider, Link, Menu, MenuItem, Stack, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { SignInModalLink } from '@packs/components/SignInModalLink/signInModalLink';
import { SignUpModalLink } from '@packs/components/SignUpModalLink';
import { CategoriesNavigation, SearchBusinessCategory, UserMenu } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import LogoIcon from '@packs/images/icons/LogoBillWinner';
import LogoBlackIcon from '@packs/images/icons/LogoBillWinnerBlack';
import { usePath } from '@packs/layouts/constants';
// import { useStore } from '@packs/stores/base';
import { businessPageUrl } from '@shared/helpers/urlHelper';

import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

const { white, dark, borderColor, primary } = colors;

export const DesktopHeader = observer(({ toggleDrawer, openDrawer, bgFilled }) => {
  // const { countryCode, currentCategory } = useStore();
  const { currentUser } = useUserSelectors();
  const { ROOT_PATH, VOTE_PATH, MY_HOME_PATH, PROFILE_MY_REMINDERS_PATH, MY_OFFERS_PATH, GROUPS_GENERAL_PATH } =
    usePath();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const mainColor = bgFilled ? dark.main : white.main;
  const currentPage = location.pathname.split('/')[4];
  const disableCategoryNav =
    ['charge', 'charge-complete'].includes(currentPage) || location.pathname.match('request-quotes');
  const links = useMemo(() => {
    return [
      {
        to: MY_HOME_PATH,
        label: 'Dashboard'
      },
      {
        to: GROUPS_GENERAL_PATH,
        label: 'Groups'
      },
      {
        to: MY_OFFERS_PATH,
        label: 'Offers'
      },
      {
        to: PROFILE_MY_REMINDERS_PATH,
        label: 'Reminders'
      }
    ].filter(item => !(item.label === 'Dashboard' && !currentUser.id));
  }, [currentUser.id]);

  // const categoryName = currentCategory?.root?.enName;

  const handleToggleDrawer = () => {
    if (!openDrawer) {
      return null;
    }

    toggleDrawer();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      component='header'
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        minHeight: '98px',
        backgroundColor: bgFilled ? white.main : 'transparent',
        color: bgFilled ? dark.main : white.main,
        transition: 'background-color .2s',
        zIndex: 50,
        ...(bgFilled ? { boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)' } : {})
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{ py: 1, px: 4, borderBottom: `1px solid ${bgFilled ? 'rgba(0, 0, 0, 0.16)' : 'transparent'}` }}
      >
        <Stack direction='row' alignItems='center' spacing={3} sx={{ flexGrow: 1 }}>
          <NavLink to={ROOT_PATH}>{bgFilled ? <LogoBlackIcon /> : <LogoIcon />}</NavLink>
          <SearchBusinessCategory overlaySearch bgFilled={bgFilled} fullWidth />
        </Stack>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {currentUser?.id ? (
              <>
                {links.map(item => (
                  <Typography
                    key={item.label}
                    variant='body4'
                    component={NavLink}
                    to={item.to}
                    onClick={toggleDrawer}
                    sx={{
                      position: 'relative',
                      color: mainColor,
                      px: 2,
                      '&[aria-current="page"]': {
                        '&:after': {
                          content: '""',
                          position: 'absolute',
                          top: 'calc(100% + 4px)',
                          left: '50%',
                          width: '24px',
                          height: '4px',
                          borderRadius: '4px',
                          backgroundColor: primary.main,
                          transform: 'translateX(-50%)'
                        }
                      },
                      '&:not([aria-current="page"]):hover': {
                        opacity: 0.7
                      }
                    }}
                  >
                    {item.label}
                  </Typography>
                ))}
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{
                    alignSelf: 'center',
                    borderColor: bgFilled ? borderColor.secondary : white.main,
                    height: '16px',
                    width: '2px',
                    borderRadius: '2px',
                    mx: 2
                  }}
                />
                <Typography
                  variant='body4'
                  component={NavLink}
                  to={VOTE_PATH}
                  onClick={toggleDrawer}
                  sx={{
                    position: 'relative',
                    color: mainColor,
                    px: 2,
                    '&[aria-current="page"]': {
                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        top: 'calc(100% + 4px)',
                        left: '50%',
                        width: '24px',
                        height: '4px',
                        borderRadius: '4px',
                        backgroundColor: primary.main,
                        transform: 'translateX(-50%)'
                      }
                    },
                    '&:not([aria-current="page"]):hover': {
                      opacity: 0.7
                    }
                  }}
                >
                  Write a review!
                </Typography>
              </>
            ) : (
              <>
                <Button
                  color={bgFilled ? 'dark' : 'white'}
                  size='smallToMedium'
                  endIcon={<KeyboardArrowDownRoundedIcon />}
                  onClick={handleClick}
                >
                  Groups and More
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  id='account-menu'
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      minWidth: '168px',
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      // px: 1,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                      }
                    }
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {links.map(item => (
                    <MenuItem key={item.label} component={NavLink} to={item.to}>
                      <Typography variant='body4'>{item.label}</Typography>
                    </MenuItem>
                  ))}
                  <MenuItem key='write-review' component={NavLink} to={VOTE_PATH}>
                    <Typography variant='body4'>Write a review!</Typography>
                  </MenuItem>
                </Menu>
              </>
            )}

            {!currentUser?.id && (
              <Button
                component={Link}
                size='smallToMedium'
                color={bgFilled ? 'secondary' : 'white'}
                variant='outlined'
                href={businessPageUrl()}
                target='_blank'
                startIcon={<BusinessCenterRoundedIcon />}
                sx={{
                  mx: 2
                }}
              >
                For Business
              </Button>
            )}
            {currentUser?.id ? (
              <UserMenu />
            ) : (
              <Stack direction='row' alignItems='center' spacing={1}>
                <SignInModalLink
                  onClick={handleToggleDrawer}
                  variant='filledTonal'
                  color={bgFilled ? 'primary' : 'white'}
                  size='smallToMedium'
                >
                  Log in
                </SignInModalLink>
                <SignUpModalLink onClick={handleToggleDrawer} size='smallToMedium'>
                  Sign Up
                </SignUpModalLink>
              </Stack>
            )}
          </Box>
        </Stack>
      </Stack>
      {!disableCategoryNav && <CategoriesNavigation bgFilled={bgFilled} />}
    </Box>
  );
});
