import React, { FC, ReactElement } from 'react';

import { Home as HomeIcon } from '@mui/icons-material';
import { Box, Breadcrumbs, Stack, Typography, TypographyProps } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';

import { Link } from 'react-router-dom';

const { dark } = colors;

export type BreadcrumbRoute = {
  to: string;
  label: string;
  icon?: ReactElement;
  onClick?: () => void;
};

type BreadcrumbsMUIProps = {
  routes?: BreadcrumbRoute[];
  currentPage?: string;
  homeIsLanding?: boolean;
  color?: string;
  maxItems?: number;
};
export const BreadcrumbsMUI: FC<BreadcrumbsMUIProps> = ({
  routes = [],
  currentPage = '',
  homeIsLanding,
  color = dark.main,
  maxItems = 3
}) => {
  const { countryCode } = useStore();
  const { currentUser } = useUserSelectors();
  const { MY_HOME_PATH } = usePath();

  const myHomeUrl = currentUser?.id && !homeIsLanding ? MY_HOME_PATH : `/${countryCode}`;

  const BreadcrumbItem = ({ to, icon, label, onClick }) => (
    <Box
      component={Link}
      color='inherit'
      to={to}
      sx={{ opacity: '0.6', '&:hover': { opacity: 1, color: `${color} !important` } }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <Stack direction='row' alignItems='center' spacing={1}>
        {icon}
        <span>{label}</span>
      </Stack>
    </Box>
  );

  return (
    <Breadcrumbs aria-label='breadcrumb' sx={{ color }} maxItems={maxItems}>
      <BreadcrumbItem to={myHomeUrl} icon={<HomeIcon sx={{ fontSize: '16px' }} />} label='Home' onClick={null} />
      {routes.map(({ to, icon, label, onClick }) => (
        <BreadcrumbItem key={to + label} {...{ to, icon, label, onClick }} />
      ))}
      <Typography variant={'body1' as TypographyProps['variant']} color='inherit'>
        {currentPage}
      </Typography>
    </Breadcrumbs>
  );
};
