export const CLOSED_ALL_MODALS = {
  isOpenSignUpModal: false,
  isOpenSignInModal: false,
  isOpenDoubleModal: false,
  isOpenReminderFormModal: false,
  isOpenForgotPasswordModal: false,
  isOpenResendConfirmationModal: false,
  isOpenCategoriesModal: false,
  isChangePasswordModalOpen: false,
  isNewCompanyModalOpen: false
};

// eslint-disable-next-line max-len
export const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
