import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OfferPlaceholderIcon from '@packs/images/svg/ui/offer-placeholder-icon.svg';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { generalIcon } from '@shared/helpers/offerGeneralIconsHelper';

import useStyles from './styles';

export const OfferContent = ({ offer }) => {
  const styles = useStyles();

  const { bannerUrl, offerDetails, generalPointAttributes, terms } = offer;

  return (
    <Box sx={styles.wrapper}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={styles.imgContainer(!!bannerUrl)}>
          <img src={bannerUrl || OfferPlaceholderIcon} alt='Offer image' />
        </Box>
        {offerDetails && <Typography variant='body1'>{offerDetails}</Typography>}
      </Box>
      {generalPointAttributes?.length > 0 && (
        <Box>
          <Typography variant='h6' sx={{ mb: 1 }}>
            General point for new customer.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {generalPointAttributes.map(({ text, icon }) => {
              const imgSrc = generalIcon(icon);
              return (
                <Box key={icon} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {imgSrc && (
                    <IconBox
                      containerSize='40px'
                      iconSize='24px'
                      backgroundColor='rgba(0, 0, 0, 0.08)'
                      icon={<img src={imgSrc} alt={icon} />}
                    />
                  )}
                  <Box variant='body1' dangerouslySetInnerHTML={{ __html: text }} />
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      {terms && (
        <Box>
          <Typography variant='h6' sx={{ mb: 1 }}>
            Terms and conditions.
          </Typography>
          <Box variant='body3' dangerouslySetInnerHTML={{ __html: terms }} />
        </Box>
      )}
    </Box>
  );
};
