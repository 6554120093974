import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { categoryApi } from '@packs/apis/category';
import { CategoriesTree } from '@packs/components';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

export const CategoriesModal = observer(({ isOpen, toggle, filterCategory }) => {
  const { homeStore, categoryStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { isDesktop } = useWindowSize();
  const categories = categoryStore.categories;

  const onClickUpdate = () => {
    toastr.success('Success');
    toggle();
    homeStore.fetchUserCategories({
      categoryCode: null
    });
  };

  const handleUpdateCategory = data => {
    categoryStore.markCategorySelect(data);
    categoryApi.categorySubscribe({ categoryId: data.id, selected: data.selected });
  };

  useEffect(() => {
    if (!isOpen && currentUser.id) {
      homeStore.fetchUserCategories({ categoryCode: null, location: null });
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={toggle}
      maxWidth='xl'
      fullWidth={!isDesktop}
      sx={{
        '@media(max-width: 991px)': {
          '& .MuiDialog-paper': {
            width: '100vh',
            height: '100vh',
            maxHeight: '100vh',
            m: 0,
            overflow: 'hidden'
          }
        }
      }}
    >
      <CategoriesTree
        {...{ isOpen, toggle, categories }}
        handleUpdateCategory={handleUpdateCategory}
        toggleShowCategoriesRegister={() => toggle()}
        customActions={({ lastCategoryIsActive, handleClickNext }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 1,
                width: '100%',
                px: { xs: 2, lg: 0 }
              }}
            >
              {lastCategoryIsActive ? (
                <Box />
              ) : (
                <Button variant='text' color='dark' size='smallToMedium' onClick={handleClickNext}>
                  Next category
                </Button>
              )}

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Button variant='filledTonal' size='smallToMedium' color='secondary' onClick={toggle}>
                  Cancel
                </Button>
                <Button variant='contained' size='smallToMedium' color='secondary' onClick={onClickUpdate}>
                  Save
                </Button>
              </Box>
            </Box>
          );
        }}
        filterCategory={filterCategory}
      />
    </Dialog>
  );
});
