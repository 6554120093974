import React, { useEffect, useState } from 'react';

import { RequestQuoteWrapper } from '@packs/components/MaterialUi/RequestQuoteWrapper/RequestQuoteWrapper';
import { RequestQuoteModal } from '@packs/components/RequestQuoteModal/requestQuoteModal';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

const RequestQuotes = observer(() => {
  const { city, requestBusinesses, initialStep } = queryString.parse(location.search);
  const { companiesStore, currentCategory, leaderboardStore, policiesListStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(initialStep ? Number(initialStep) : 1);

  const fetchCategoryAgents = () => {
    leaderboardStore.fetchCategoryAgents(
      {
        categoryId: currentCategory.id,
        city: city || companiesStore.filters.city || null,
        criteriaQuestions: companiesStore.filters.criteriaQuestions,
        page: 1,
        perPage: 5
      },
      requestBusinesses ? requestBusinesses.split(',') : []
    );
  };

  const onChangeStep = index => {
    setActiveStep(index);
  };

  useEffect(() => {
    setIsLoading(true);
    if (currentCategory.id) {
      policiesListStore
        .loadPolicyData({
          categoryId: currentCategory?.id
        })
        .then(() => {
          fetchCategoryAgents();
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const list = [
      ...(leaderboardStore?.suggestedList?.provider || []),
      ...(leaderboardStore?.suggestedList?.broker || [])
    ];
    if (list.length && requestBusinesses) {
      if (requestBusinesses) {
        requestBusinesses.split(',')?.forEach(item => {
          leaderboardStore.addRequestBusiness(item);
        });
      }
    }
  }, [leaderboardStore?.suggestedList]);

  return (
    <>
      <Helmet>
        <title>Request quotes</title>
      </Helmet>
      <RequestQuoteWrapper activeStep={activeStep} onChangeStep={onChangeStep}>
        <RequestQuoteModal detailsIsLoading={isLoading} {...{ activeStep, setActiveStep }} />
      </RequestQuoteWrapper>
    </>
  );
});

export default RequestQuotes;
