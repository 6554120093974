import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';

const { white } = colors;

export const TitleBox = ({ title, children, last = false }) => {
  return (
    <Box mb={last ? 0 : 3} sx={{ color: white.main }}>
      <Box>
        <Typography variant='body5' mb={1} sx={{ opacity: '0.6' }}>
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography variant='body3'>{children}</Typography>
      </Box>
    </Box>
  );
};
