import colors from '@packs/assets/theme/base/colors';

const { grey } = colors;
export default () => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(1, 1fr)',
      md: 'repeat(2, 1fr)',
      lg: 'repeat(3, 1fr)'
    },
    gap: 1
  },
  item: {
    borderRadius: '8px',
    p: 1.5,
    backgroundColor: grey[100]
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    mt: 2
  }
});
