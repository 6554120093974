import React, { useEffect, useState } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { usePath } from '@packs/layouts/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { criteriaFiltersUnAuthorized } from '@packs/lib/helpers/category';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const GetMatchedAlert = observer(() => {
  const { currentPolicy } = useDetails();
  const { currentCategory } = useStore();
  const { ADD_DETAILS_PATH } = usePath();
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const showCriteriaForUnAuthorized =
    criteriaFiltersUnAuthorized.includes(currentCategory?.code) ||
    criteriaFiltersUnAuthorized.includes(currentCategory?.root?.code);
  const personalized = hasPolicyCriteria(currentPolicy);

  const onCloseAlert = () => {
    setAlertIsOpen(false);
  };

  useEffect(() => {
    if (!personalized && showCriteriaForUnAuthorized) {
      setAlertIsOpen(true);
    } else {
      setAlertIsOpen(false);
    }
  }, [currentCategory?.id, currentPolicy?.id]);

  if (!alertIsOpen) {
    return null;
  }

  return (
    <Alert
      severity='info'
      action={
        <IconButton aria-label='close' color='inherit' size='small' onClick={onCloseAlert}>
          <CloseRoundedIcon />
        </IconButton>
      }
      sx={{ alignItems: 'center' }}
    >
      <Typography>
        <Link className='primary-text cursor-pointer ml-4 mr-4' to={ADD_DETAILS_PATH}>
          Get matched!
        </Link>
        so that we can connect you with the best businesses to quote you!
      </Typography>
    </Alert>
  );
});
