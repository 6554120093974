import React, { useEffect, useRef, useState } from 'react';

import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import companyApi from '@packs/apis/company';
import { BadgesGroupList } from '@packs/components/MaterialUi/Badges/BadgesGroupList/BadgesGroupList';
import { Ratings } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/components/Ratings/Ratings';
import { Questions } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useStore } from '@packs/stores';
import { PreviewBadges } from '@shared/components/CompanyPage/components/PreviewBadges/PreviewBadges';
import { RatingsBusiness } from '@shared/components/CompanyPage/components/RatingsBusiness/RatingsBusiness';
import { Reviews } from '@shared/components/CompanyPage/components/Review/Reviews/Reviews';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { businessPageUrl } from '@shared/helpers/urlHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import CoverBgPlaceholder from '@shared/images/empty-cover-bg.svg';

import { Sidebar } from './components/Sidebar/Sidebar';
import useStyles from './styles';

const tabs = [
  {
    label: 'Reviews',
    value: 'reviews'
  },
  {
    label: 'All Posts',
    value: 'allPosts'
  },
  {
    label: 'Badges',
    value: 'badges'
  }
];

export const CompanyPage = ({
  IS_BUSINESS_PAGE,
  IS_CRM,
  breadcrumbs,
  colors,
  company = {},
  insightsCompanyStore,
  withOutInsightCompany,
  handleVoteNowClick,
  category
}) => {
  const wrapperNodeRef = useRef(null);
  const { isMobile, windowSize } = useWindowSize();
  const styles = useStyles({ colors });
  const { companiesStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { toggleSignUpModal, toggleShareSocialModal } = useModalsContext();
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [previewBadges, setPreviewBadges] = useState([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [wrapperSize, setWrapperSize] = useState(0);

  const firstCategory = IS_BUSINESS_PAGE ? (company?.categories ? company?.categories[0] : null) : category;
  const isPartnered = company?.quoteAgents?.some(agent => agent?.isPartner);
  const offerLink = company?.partnerProvider?.customWebLink || '';
  const affinityLink = company?.affinityLink?.url || '';
  const websiteLink = affinityLink || offerLink || company.url;
  const { productPageVisitCount = 0, businessPageVisitCount = 0 } = company?.additionalData || {};

  const aspectRatio = dimensions.width / dimensions.height;
  const displayedHeight = wrapperSize.width / aspectRatio;

  const BusinessButton = () => (
    <Button
      component={Link}
      href={businessPageUrl()}
      target='_blank'
      variant='outlined'
      color='dark'
      size='large'
      startIcon={<BusinessCenterRoundedIcon />}
      endIcon={
        <TooltipMUI
          title='Claim this business to manage your business on Billwinner. Receive quotes from prospective customers, create personalised offers, post tips to our community , invite your customers to vote for your business and win awards!'
          arrow
          placement='top-end'
        >
          <InfoOutlinedIcon fontSize='medium' />
        </TooltipMUI>
      }
    >
      Is this your Business?
    </Button>
  );

  const handleChangeTab = (_, data) => {
    setActiveTab(data);
  };

  const handleClickWebsiteLink = () => {
    companiesStore.companyUpdateClicks({ companyId: +company.id, categoryId: firstCategory?.id });
  };

  const fetchPreviewBadges = async () => {
    const res = await companyApi.fetchInsightCompanyBadges({ companyId: company.id, categoryId: category?.id });
    if (res) {
      setPreviewBadges(res);
    }
  };

  useEffect(() => {
    if (company.id && ((IS_BUSINESS_PAGE && !category?.id) || (!IS_BUSINESS_PAGE && category?.id))) {
      fetchPreviewBadges();
    }
  }, [company.id, category?.id, IS_BUSINESS_PAGE]);

  useEffect(() => {
    const img = new Image();
    img.src = company?.coverBgUrl;

    img.onload = () => {
      setDimensions({
        width: img.width,
        height: img.height
      });
    };
  }, [company?.coverBgUrl]);

  useEffect(() => {
    setWrapperSize({
      width: wrapperNodeRef?.current?.clientWidth,
      height: wrapperNodeRef?.current?.offsetHeight
    });
  }, [windowSize]);

  return (
    <Box sx={styles.wrapper}>
      {!IS_CRM && breadcrumbs && <Box sx={{ ...styles.container, ...styles.breadcrumbsWrapper }}>{breadcrumbs}</Box>}
      <Box sx={[styles.container, styles.coverImageSectionWrapper]} ref={wrapperNodeRef}>
        {company?.coverBgUrl ? (
          <Box
            sx={[
              styles.coverImageSection,
              {
                height: `${displayedHeight}px` || '100%',
                backgroundImage: `url(${company?.coverBgUrl})`,
                transform: `translate(${company?.coverBgX}px, ${company?.coverBgY}px)`
              }
            ]}
          />
        ) : (
          <Box
            sx={[
              styles.coverImageSection,
              {
                height: '100%',
                backgroundImage: `url(${CoverBgPlaceholder})`
              }
            ]}
          />
        )}

        <Box
          sx={styles.actionWrapper}
          onClick={() => toggleShareSocialModal({ modalProps: { category: firstCategory } })}
        >
          <ShareRoundedIcon />
        </Box>
      </Box>
      <Box sx={styles.container}>
        <Box sx={styles.companySection}>
          <Box sx={styles.companySectionInfo(!!previewBadges.length)}>
            <Box sx={styles.companyAvatarWrapper}>
              <Avatar variant='rounded' src={company?.avatarUrl} sx={styles.companyAvatar} />
              {!isMobile && <PreviewBadges badges={previewBadges} />}
            </Box>
            <Typography variant='h3' sx={styles.companyName}>
              {company?.name}
            </Typography>
            <Box sx={styles.additionalInfo}>
              {isPartnered && (
                <>
                  <Box sx={styles.claimWrapper}>
                    <CheckCircleRoundedIcon sx={{ color: '#2E7D32' }} />
                    <Typography variant='body2'>Claimed</Typography>
                  </Box>
                  <Divider orientation='vertical' flexItem />
                </>
              )}
              {category?.id && (
                <>
                  <Box sx={styles.categorySection}>
                    {SUB_ICONS({ category, fill: colors.dark.main, size: '16px' })}
                    <Typography variant='caption'>{category.name}</Typography>
                  </Box>
                  <Divider orientation='vertical' flexItem />
                </>
              )}
              <Box>{productPageVisitCount || businessPageVisitCount || 0} page views</Box>
              {websiteLink && (
                <>
                  <Divider orientation='vertical' flexItem />
                  <Link
                    href={websiteLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={handleClickWebsiteLink}
                    sx={{ color: colors.dark.main, textDecoration: 'none' }}
                  >
                    <Typography variant='body2'>Go to website</Typography>
                  </Link>
                </>
              )}
            </Box>
            <Typography varoant='body1'>{company?.description || company?.metaDescription}</Typography>
            <Box sx={{ mt: { xs: 2, md: 4 } }}>
              {isMobile && (
                <Box sx={{ mb: 2 }}>
                  <BusinessButton />
                </Box>
              )}
              <Button variant='contained' color='primary' size='large' onClick={handleVoteNowClick}>
                Write a review!
              </Button>
            </Box>
          </Box>
          <Box sx={styles.companySectionRating}>
            <RatingsBusiness {...{ company, colors }}>
              <Ratings {...{ company }} withOutTotal withOutDivider />
            </RatingsBusiness>
          </Box>
          {isMobile && <PreviewBadges badges={previewBadges} />}
        </Box>
      </Box>
      <Box sx={styles.tabsWrapper}>
        <Box sx={{ ...styles.container, ...styles.tabsContainer }}>
          <Tabs value={activeTab} onChange={handleChangeTab} variant='standard'>
            {tabs.map(tab => (
              <Tab key={tab.value} value={tab} label={tab.label} variant='standard' />
            ))}
          </Tabs>
          {!isMobile && (
            <Box sx={{ mb: 2 }}>
              <BusinessButton />
            </Box>
          )}
        </Box>
        <Divider />
      </Box>
      <Box sx={{ ...styles.tabsContentWrapper, ...styles.container }}>
        <Box sx={styles.tabsContent}>
          {activeTab.value === 'reviews' && company?.id && (
            <Reviews
              {...{
                insightsCompanyStore,
                company,
                colors,
                IS_CRM,
                withOutInsightCompany,
                handleVoteNowClick,
                currentUser,
                toggleSignUpModal,
                category
              }}
            />
          )}
          {activeTab.value === 'allPosts' && (
            <Questions
              companyId={company?.id}
              category={category || null}
              initialTab='q_a'
              typeFilter
              withOutCategoryMode={IS_BUSINESS_PAGE}
              isSingleButton
              showAllTypes
            />
          )}
          {activeTab.value === 'badges' && (
            <BadgesGroupList companyId={company.id} categoryId={category?.id} company={company} />
          )}
        </Box>
        <Box sx={styles.sidebarWrapper}>
          <Sidebar {...{ insightsCompanyStore, company, IS_CRM }} category={firstCategory} />
        </Box>
      </Box>
    </Box>
  );
};
