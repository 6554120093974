import React, { useEffect, useState } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import { usePath } from '@packs/layouts/constants';
import { autoQuoteTooltip, autoQuoteTooltipNoPolicy } from '@packs/lib/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { loadState } from '@packs/lib/utils/localStorage';
import { CategoriesPolicy } from '@packs/screens/MyHome/components/CategoriesPolicy';
import { Actions } from '@packs/screens/MyHome/components/MyRenewals/components/ActionsDropDown/Actions';
import { ActionsDropDown } from '@packs/screens/MyHome/components/MyRenewals/components/ActionsDropDown/ActionsDropDown';
import { AdditionalSection } from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/AdditionalSection';
import { LeaderboardCTA } from '@packs/screens/MyHome/components/MyRenewals/components/LeaderboardCTA';
import { RenderRenewalPrice } from '@packs/screens/MyHome/components/MyRenewals/components/RenderRenewalPrice';
import { RenewalAutoQuote } from '@packs/screens/MyHome/components/MyRenewals/components/RenewalAutoQuote';
import { RenewalSmartReminder } from '@packs/screens/MyHome/components/MyRenewals/components/RenewalSmartReminder';
import { useStore } from '@packs/stores';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

import useStyles from './styles';

import _clone from 'lodash-es/clone';
import AnimateHeight from 'react-animate-height';
import { NavLink } from 'react-router-dom';

const { secondary, dark, text } = colors;

export const MobileItem = ({
  item,
  notLinkedCategory,
  category,
  options,
  renewalDetail,
  onClickDetails,
  categoryItem,
  toggleMyBill,
  disabledCategory,
  selectedPolicy,
  setSelectedPolicy,
  selectedCost,
  currency,
  onClickEditReminder,
  toggleReminderFormModal,
  handleCreateReminderCallback,
  toggleIsAutoQuote,
  filterCategory,
  autoQuoteEnabled,
  userNotConfirmed,
  categoryRedirect,
  showPotentialSavings,
  setShowPotentialSavings
}) => {
  const styles = useStyles();
  const { categoryStore, homeStore } = useStore();
  const { DASHBOARD_MY_RESULTS_PATH_FN } = usePath();
  const defaultAdditionalOpen = loadState('openDashboardItemId');
  const [showAdditional, setShowAdditional] = useState(defaultAdditionalOpen === item.id);

  const toggleAdditional = () => {
    setShowAdditional(prev => !prev);
  };

  useEffect(() => {
    if (renewalDetail?.id) {
      homeStore.fetchUserCategoryByPolicy({ renewalDetailId: renewalDetail?.id }, item.id);
    }
  }, [renewalDetail?.id, item.id]);

  if (item?.hide) {
    return null;
  }

  return (
    <Box sx={[styles.mobileItemWrapper(showAdditional)]}>
      <Box sx={styles.categoryBox}>
        <CategoryIconBox
          category={item}
          backgroundColor={secondary.transparent}
          color={secondary.main}
          containerSize='40px'
          iconSize='32px'
          borderRadius='8px'
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          {!notLinkedCategory ? (
            <NavLink
              to={DASHBOARD_MY_RESULTS_PATH_FN(category)}
              onClick={() => {
                categoryStore.setCurrentCategory(category);
              }}
            >
              <Typography variant='body4' sx={{ color: dark.main }}>
                {item.name || '-'}
              </Typography>
            </NavLink>
          ) : (
            <Typography variant='body4'>{item.name || '-'}</Typography>
          )}
        </Box>
        <Box sx={{ ml: 'auto' }}>
          <ActionsDropDown
            category={category}
            item={item}
            renewalDetail={renewalDetail}
            toggleMyBill={toggleMyBill}
            onClickEditReminder={onClickEditReminder}
            toggleReminderFormModal={toggleReminderFormModal}
            handleCreateReminderCallback={handleCreateReminderCallback}
            disabledCategory={disabledCategory}
            toggleIsAutoQuote={toggleIsAutoQuote}
            filterCategory={filterCategory}
          />
        </Box>
      </Box>
      <Divider />
      <CategoriesPolicy
        {...{
          category,
          item,
          options,
          renewalDetail,
          onClickDetails,
          categoryItem,
          toggleMyBill
        }}
        disabled={disabledCategory(item?.root?.code)}
        onSelect={val => {
          selectedPolicy[item.id] = val.value;
          setSelectedPolicy(_clone(selectedPolicy));
          if (showPotentialSavings) {
            setShowPotentialSavings(false);
          }
        }}
      />
      <Divider />
      <RenderRenewalPrice
        {...{
          item,
          selectedCost,
          selectedPolicy,
          toggleMyBill,
          currency,
          category
        }}
      />

      {renewalDetail?.id && !disabledCategory(item?.root?.code) && (
        <>
          <AnimateHeight duration={250} height={showAdditional ? 'auto' : 0}>
            <Divider sx={{ mb: 0.5 }} />
            {showAdditional && (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                  <RenewalSmartReminder
                    {...{
                      item,
                      category,
                      renewalDetail,
                      onClickEditReminder,
                      toggleReminderFormModal,
                      handleCreateReminderCallback
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <RenewalAutoQuote
                      {...{
                        item,
                        autoQuoteEnabled,
                        renewalDetail,
                        disabledCategory,
                        toggleReminderFormModal,
                        category,
                        handleCreateReminderCallback,
                        toggleIsAutoQuote,
                        onClickEditReminder
                      }}
                    />
                    <TooltipMUI
                      title={
                        <Typography variant='caption'>
                          {hasPolicyCriteria(renewalDetail) ? autoQuoteTooltip : autoQuoteTooltipNoPolicy}
                        </Typography>
                      }
                      arrow
                      placement='top'
                    >
                      <InfoOutlinedIcon sx={{ color: text.secondary }} />
                    </TooltipMUI>
                  </Box>
                  <Box sx={{ width: '100%', pt: 1.5 }}>
                    <LeaderboardCTA
                      {...{
                        item,
                        userNotConfirmed,
                        disabledCategory,
                        selectedPolicy,
                        categoryRedirect,
                        category
                      }}
                    />
                  </Box>
                </Box>
                <AdditionalSection
                  key={renewalDetail?.id}
                  item={item}
                  category={category}
                  renewalDetail={renewalDetail}
                  selectedCost={selectedCost}
                  currency={currency}
                />
                <Box sx={{ my: 1 }}>
                  <Actions
                    item={item}
                    renewalDetail={renewalDetail}
                    category={category}
                    toggleMyBill={toggleMyBill}
                    onClickEditReminder={onClickEditReminder}
                    toggleReminderFormModal={toggleReminderFormModal}
                    handleCreateReminderCallback={handleCreateReminderCallback}
                    disabledCategory={disabledCategory}
                    toggleIsAutoQuote={toggleIsAutoQuote}
                    filterCategory={filterCategory}
                    paddingHorizontal={0}
                  />
                </Box>
                <Divider />
              </>
            )}
          </AnimateHeight>
          {!showAdditional && <Divider />}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {showAdditional ? (
              <Button
                key='hide'
                size='small'
                color='dark'
                fullWidth
                onClick={toggleAdditional}
                endIcon={showAdditional ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
              >
                Hide ways to save money!
              </Button>
            ) : (
              <Button
                key='show'
                size='small'
                variant='filledTonal'
                color='secondary'
                fullWidth
                onClick={toggleAdditional}
                endIcon={showAdditional ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
              >
                Explore ways to save money!
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
