import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import InfoBgLeftIcon from '@packs/images/svg/ui/info-box-left.svg';
import InfoBgLeftMobileIcon from '@packs/images/svg/ui/info-box-left-mobile.svg';
import InfoBgRightIcon from '@packs/images/svg/ui/info-box-right.svg';
import InfoBgRightMobileIcon from '@packs/images/svg/ui/info-box-right-mobile.svg';

const { white, text } = colors;

export const InfoBox = ({
  position = 'left',
  title = '',
  description = '',
  infoIcon,
  infoIconWidth = '100%',
  infoIconPosition = '155px',
  withoutMobileTitle = false,
  children
}) => {
  const leftPosition = position === 'left';

  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', md: '1128px' },
        margin: '0 auto',
        pt: { xs: 5, md: '175px' },
        px: { xs: 2, md: 0 }
      }}
    >
      {!withoutMobileTitle && (
        <Box sx={{ display: { xs: 'block', md: 'none' }, textAlign: 'center' }}>
          <Typography variant='h3' mb={2}>
            {title}
          </Typography>
          <Typography variant='subtitle2' color={text.secondary}>
            {description}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          height: {
            xs: '324px',
            md: '400px'
          },
          mt: { xs: '115px', md: 0 },
          backgroundImage: {
            xs: `url(${!leftPosition ? InfoBgLeftMobileIcon : InfoBgRightMobileIcon})`,
            md: `url(${!leftPosition ? InfoBgLeftIcon : InfoBgRightIcon})`
          },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
          backgroundSize: { xs: 'contain', md: 'cover' },
          padding: {
            xs: 0,
            md: '97px 65px 0'
          },
          color: white.main
        }}
      >
        <Box width='53%' order={leftPosition ? 2 : 1} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography variant='h3' mb={2} sx={{ color: 'inherit' }}>
            {title}
          </Typography>
          <Typography variant='subtitle1'>{description}</Typography>
        </Box>
        <Box position='relative' order={leftPosition ? 1 : 2} sx={{ width: { xs: '100%', md: '47%' } }}>
          <Box
            position='absolute'
            sx={{
              [leftPosition ? 'right' : 'left']: {
                xs: '50%',
                md: 0
              },
              bottom: {
                xs: infoIconPosition,
                md: 0
              },
              width: '100%',
              maxWidth: {
                xs: '340px',
                md: infoIconWidth
              },
              transform: {
                xs: leftPosition ? 'translateX(50%)' : 'translateX(-50%)',
                md: 'translateX(0)'
              }
            }}
          >
            <img src={infoIcon} alt='info' />
          </Box>
        </Box>
      </Box>
      <Box sx={{ px: { xs: 2, md: 0 } }}>{children}</Box>
    </Box>
  );
};
