import React from 'react';
import cx from 'classnames';

import { usePath } from '@packs/layouts/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { getWindow } from '@shared/lib/utils';

import _find from 'lodash-es/find';
import { NavLink } from 'react-router-dom';

export const AssociationsList = ({ list }) => {
  const { DASHBOARD_MY_OFFERS_PATH_FN } = usePath();
  const { categoryStore } = useStore();
  const { flattenCategories } = useCategories();

  const categoryRedirect = categoryId => {
    return () => {
      const category = _find(flattenCategories, { id: categoryId });

      categoryStore.setCurrentCategory(category);

      if (getWindow().scrollY !== 0) {
        window.scroll(0, 0);
      }
    };
  };

  if (list.length === 0) {
    return (
      <div className='empty-associations-list'>
        <div className='title'>Member offers coming very soon!</div>
      </div>
    );
  }

  return (
    <div className='associations-list'>
      {list.map(({ id, slag, code, root, name, enName, partnerProviders }) => {
        const category = {
          id,
          slag,
          code,
          name,
          enName,
          root
        };

        const offers = partnerProviders?.length || 0;
        return (
          <div key={id} className={cx('associations-item', root?.code)}>
            <div className='association-category-wrapper d-flex align-items-center'>
              <div className='icon-container d-flex align-items-center justify-content-center'>
                {SUB_ICONS({ category })}
              </div>
              <div className='fs-14 lh-5'>{name}</div>
            </div>
            <div className='fs-14 lh-5 fw-600'>My Association Offers ({offers})</div>
            <div>
              <NavLink
                className={cx('btn empty black')}
                to={DASHBOARD_MY_OFFERS_PATH_FN(category)}
                onClick={categoryRedirect(id)}
              >
                <span>See OFFERS</span>
              </NavLink>
            </div>
          </div>
        );
      })}
    </div>
  );
};
