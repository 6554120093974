import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';

import { Avatar } from '@packs/components/UI';
import CheckIcon from '@packs/images/icons/CheckboxIcon';
import UserPlusIcon from '@packs/images/icons/UI/UserPlusIcon';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';

const perPage = 8;

export const FriendsList = observer(() => {
  const { isMobile } = useWindowSize();
  const { userStore } = useStore();
  const [page, setPage] = useState(1);

  const list = userStore.trustNetworkList;
  const totalCount = userStore.trustNetworkListTotal;

  const fetchFriends = (page, more = false) => {
    userStore.trustNetworkFriendList({ page, perPage }, more);
  };

  const loadInfinityScroll = () => {
    fetchFriends(page + 1, true);
    setPage(page + 1);
  };

  useEffect(() => {
    fetchFriends(page);
  }, []);

  return (
    <div className='mt-24'>
      <div className='d-flex align-items-center justify-content-between mb-8'>
        <div className={cx('fw-700 lh-9', isMobile ? 'fs-16' : 'fs-23')}>Your friends</div>
        <div className='d-flex align-items-center gap-4'>
          <div className='add-friends-icon'>
            <UserPlusIcon />
          </div>
          <div className={cx('lh-5 secondary-text', isMobile ? 'fs-13' : 'fs-16')}>{totalCount} Friends</div>
        </div>
      </div>
      {list.length > 0 ? (
        <div className='scrollable-table' id='scrollable-trust-network-table'>
          <InfiniteScroll
            dataLength={list.length}
            next={loadInfinityScroll}
            hasMore={list.length < totalCount}
            scrollableTarget='scrollable-trust-network-table'
          >
            <table className='trust-network-table'>
              <thead>
                <tr>
                  <th>NAME & DATE CONNECTED:</th>
                  <th className='status-cell'>STATUS:</th>
                </tr>
              </thead>
              <tbody>
                {list.map(({ friend, confirmed, createdAt }) => {
                  if (!friend?.id) {
                    return null;
                  }
                  const { id, email, avatarUrl, displayName } = friend;

                  return (
                    <tr key={id}>
                      <td>
                        <div className='d-flex align-items-center gap-8'>
                          <Avatar
                            avatarUrl={avatarUrl}
                            size={isMobile ? 32 : 48}
                            placeholder={displayName || email}
                            fullSize
                            withBorder
                          />
                          <div>
                            <div className={cx('lh-5', isMobile ? 'fs-11' : 'fs-16 mb-4')}>{displayName || email}</div>
                            <div className={cx('lh-5 secondary-text', isMobile ? 'fs-11' : 'fs-13')}>
                              {createdAt ? moment(createdAt).format('MMM DD, YYYY') : '-'}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center gap-8'>
                          <div className={cx('reminder-status', confirmed ? 'green' : 'amber')} />
                          <span className={cx('lh-5', isMobile ? 'fs-11' : 'fs-16')}>
                            {confirmed ? 'Active' : 'Pending'}
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      ) : (
        <div className='empty-friends-container d-flex align-items-center justify-content-center'>
          <div>
            <div className='text-center fs-16 fw-700 mb-16'>Invite your friends</div>
            <div className='empty-friends-item'>
              <CheckIcon />
              <span>Get trusted insights and recommendations!</span>
            </div>
            <div className='empty-friends-item'>
              <CheckIcon />
              <span>Get network offers!</span>
            </div>
            <div className='empty-friends-item'>
              <CheckIcon />
              <span>Help them save time and money!</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
