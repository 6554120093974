import React, { FC } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Box, Button, FormControl, FormHelperText, Grid, Stack, Typography, TypographyProps } from '@mui/material';
import { categoryApi } from '@packs/apis/category';
import { SignInModalLink } from '@packs/components';
import { EstimateCostInput } from '@packs/components/EstimateCostInput/EstimateCostInput';
import { CompaniesAutocomplete } from '@packs/components/MaterialUi/CompaniesAutocomplete/CompaniesAutocomplete';
import { CriteriaInfo } from '@packs/components/MaterialUi/Leaderboard/BlockedLeaderboard/components/CriteriaInfo/CriteriaInfo';
import SwitcherText from '@packs/components/MaterialUi/SwitcherText/SwitcherText';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import MoneyIcon from '@packs/images/ui/money.webp';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { CATEGORY_PER_MONTH } from '@packs/models/category/constants';
import { clearAllCategoryCriteria, getCategoryCriteria } from '@packs/models/category/helpers';
import { cantRemember } from '@packs/models/company/constants';
import { useStore } from '@packs/stores';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import toNumber from 'lodash-es/toNumber';
import queryString from 'query-string';
import AnimateHeight from 'react-animate-height';
import { useLocation } from 'react-router-dom';

type CreateSimplePolicyProps = {
  onText?: string;
  offText?: string;
};
const CreateSimplePolicy: FC<CreateSimplePolicyProps> = ({ onText = '', offText = '' }) => {
  const { isMobile } = useWindowSize();
  const { currentCategory, policyStore, categoryStore, companiesStore, userStore } = useStore();
  const { toggleSignUpModal } = useModalsContext();
  const { currentUser } = useUserSelectors();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const exactCategory = currentCategory?.code || currentCategory?.root?.code;

  const estimateCostsDisabled = ['accounts'].includes(exactCategory);

  const estimateCostsRangeDisabled = ![
    'credit_cards',
    'accounts',
    'income_protection',
    'investments',
    'loans',
    'mortgages_protection',
    // 'mortgages',
    'pensions',
    'savings'
  ].includes(exactCategory);

  const defaultPeriod = CATEGORY_PER_MONTH.some(
    item => item === currentCategory?.code || item === currentCategory?.root?.code
  )
    ? 'per_month'
    : 'per_year';

  const validationScheme = Yup.object().shape({
    providerOnly: Yup.boolean(),
    haveProvider: Yup.boolean(),
    anyCompany: Yup.object()
      .nullable()
      .when('haveProvider', { is: true, then: inst => inst.required() })
      .label('Provider'),
    price: Yup.number()
      .nullable()
      .when('haveProvider', { is: true, then: inst => inst.required() })
      .label('Estimate cost'),
    period: Yup.string(),
    anyCompanyRate: Yup.number()
      .nullable()
      .when('haveProvider', { is: true, then: inst => inst.min(0.5).required() })
  });

  const formik = useFormik({
    validationSchema: validationScheme,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
    initialValues: {
      providerOnly: !estimateCostsRangeDisabled,
      haveProvider: true,
      anyCompany: null,
      price: null,
      period: defaultPeriod,
      anyCompanyRate: 0
    }
  });

  const haveProvider = formik.values.haveProvider;
  const period = formik.values.period;
  const price = formik.values.price;

  function onHaveProviderChange(val) {
    formik.setFieldValue('haveProvider', val);
    if (!val) {
      formik.setFieldValue('anyCompany', null);
      formik.setFieldValue('anyCompanyRate', 0);
    }
  }

  function onSubmit(data) {
    const values = { ...data };

    if (values.anyCompany?.value === cantRemember.value) {
      values.anyCompany.value = null;
    }

    const anyCompanyId = values.anyCompany?.value;
    const anyCompanyRate = anyCompanyId ? toNumber((values.anyCompanyRate * 2).toFixed(0)) : null;
    const buyingType = values.anyCompany?.companyType || '';

    const criteriaQuestions = getCategoryCriteria(currentCategory.id);

    const params = {
      policyType: 'simple',
      advancedPrice: { electricity: {}, gas: {} },
      renewalDetailId: undefined,
      feedbackType: 'review',
      lastBuy: null,
      overallRate: null,
      buyingType,
      // id, rate , rate comment
      anyCompanyId,
      anyCompanyRate,
      criteriaQuestions,
      pricesQuestions: {
        price: {
          value: (values?.price ? parseFloat(values?.price) : 0) ?? 0,
          period: values?.period
        }
      },
      smartReminder: {
        id: null
      }
    };

    const doSubmit = async () => {
      const res = await policyStore.createRenewalDetail({ categoryId: currentCategory.id, params });

      if (res) {
        clearAllCategoryCriteria();
        await companiesStore.load({ prefillCriteria: true });
        categoryStore.setCategoryWithPolicy(currentCategory.id);
        categoryStore.markCategorySelect({ id: currentCategory?.id, selected: true });
        categoryApi.categorySubscribe({ categoryId: currentCategory.id, selected: true });

        if (query.joinCampaignPageId) {
          userStore.joinToTheGroup({ campaignPageId: +query.joinCampaignPageId });
        }
      }
    };

    if (currentUser?.id) {
      doSubmit();
    } else {
      toggleSignUpModal({ onSignUpSuccess: doSubmit });
    }
  }

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid>
          <Grid item xs={12} sx={{ mb: formik.values.haveProvider ? 0 : 1 }}>
            <SwitcherText
              onText={onText}
              offText={offText}
              value={formik.values.haveProvider}
              onChange={onHaveProviderChange}
            />
          </Grid>
          <AnimateHeight duration={250} height={formik.values.haveProvider ? 'auto' : 0}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <CompaniesAutocomplete
                  label='Current provider'
                  categoryId={currentCategory?.id}
                  value={formik.values.anyCompany}
                  onSelect={data => !!data && formik.setFieldValue('anyCompany', data)}
                  disabled={!haveProvider}
                  error={formik.errors.anyCompany as string}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                {!estimateCostsDisabled && (
                  <EstimateCostInput
                    value={formik.values.price}
                    period={period}
                    onChange={formik.handleChange}
                    disabled={!haveProvider}
                    error={formik.errors.price as string}
                    name={'price'}
                    estimateCostsEnabled={!estimateCostsRangeDisabled}
                    fullWidth
                    directOnChange={val => formik.setFieldValue('period', val)}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl error={!!formik.errors.anyCompanyRate}>
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                    spacing={1}
                  >
                    <Typography variant={'body3' as TypographyProps['variant']}>Rate Current Provider:</Typography>
                    <RatingMUI
                      value={formik.values.anyCompanyRate}
                      max={5}
                      size='large'
                      readOnly={false}
                      onChange={val => formik.setFieldValue('anyCompanyRate', val)}
                      disabled={!haveProvider}
                    />
                  </Stack>
                  <FormHelperText>
                    {formik.errors.anyCompanyRate ? 'Please rate your current provider' : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {((price && period) || !haveProvider) && (
                <Grid item xs={12}>
                  <CriteriaInfo categoryId={currentCategory?.id} period={period} price={price} />
                </Grid>
              )}
            </Grid>
          </AnimateHeight>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              alignItems={{ xs: 'flex-start', lg: 'center' }}
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Button
                type='submit'
                variant='contained'
                color='primary'
                size='large'
                sx={{ minWidth: currentUser?.id ? 234 : 140 }}
                fullWidth={isMobile}
              >
                {currentUser.id ? 'Save' : 'Sign up!'}
              </Button>
              {!currentUser.id && (
                <SignInModalLink type='submit' variant='text' color='dark' size='large'>
                  Log in
                </SignInModalLink>
              )}
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='body1'>& see how much you can save!</Typography>
                <Box sx={{ width: '24px' }}>
                  <img src={MoneyIcon} alt='Money' />
                </Box>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CreateSimplePolicy;
