import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import useStyles from '@packs/components/UI/CategoriesNavigation/components/styles';
import { usePath } from '@packs/layouts/constants';

import { NavLink } from 'react-router-dom';

export const SubCategoriesList = ({
  columnCount,
  subCategories,
  isComingSoonCategory,
  onClickCategory,
  categoryUrl
}) => {
  const styles = useStyles();
  const { DASHBOARD_LANDING_PATH_FN } = usePath();

  return (
    <Box sx={styles.subCategoryColumnList(columnCount(subCategories))}>
      {subCategories.map((column, index) => (
        <Stack key={index} direction='column' spacing={3} sx={styles.column}>
          {column.map(item => (
            <Stack direction='row' key={item.id}>
              <Box
                component={NavLink}
                to={isComingSoonCategory ? DASHBOARD_LANDING_PATH_FN(item) : categoryUrl(item)}
                onClick={onClickCategory(item)}
                sx={styles.categoryLink}
              >
                <CategoryIconBox
                  containerSize='16px'
                  iconSize='16px'
                  category={item}
                  backgroundColor='transparent'
                  color={colors.text.secondary}
                />
                <Typography variant='body2' sx={styles.categoryName}>
                  {item.name}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Stack>
      ))}
    </Box>
  );
};
