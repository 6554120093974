import React, { useEffect, useState } from 'react';

import { isLandingPage } from '@packs/lib/helpers';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { getWindow } from '@shared/lib/utils';

import { DesktopHeader } from './components/DesktopHeader';
import { MobileHeader } from './components/MobileHeader';

import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

export const AppHeader = observer(() => {
  const { pathname } = useLocation();
  const { windowSize } = useWindowSize();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bgFilled, setBgFilled] = useState(false);
  const isInnerPages = !isLandingPage(pathname);

  const toggleDrawer = () => {
    setOpenDrawer(prevState => !prevState);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = getWindow().scrollY > 0;

      if (scrolled !== bgFilled) {
        setBgFilled(scrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [bgFilled]);

  if (windowSize < 1100) {
    return <MobileHeader {...{ openDrawer, toggleDrawer }} bgFilled={isInnerPages || bgFilled} />;
  }

  return <DesktopHeader {...{ openDrawer, toggleDrawer }} bgFilled={isInnerPages || bgFilled} />;
});
