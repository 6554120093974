import React, { FC } from 'react';

import { Box, Chip, Typography, TypographyProps } from '@mui/material';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

import useStyles from './styles';

import isNumber from 'lodash-es/isNumber';

const capitalizeFirstLetter = str => {
  if (isNumber(str)) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

type Props = {
  filters: { title: string; label: string; value: string | number }[];
};

export const AppliedFilters: FC<Props> = ({ filters }) => {
  const styles = useStyles();
  const filledFilters = filters.filter(item => item?.label);

  if (filledFilters.length === 0) {
    return null;
  }

  return (
    <Box sx={styles.wrapper}>
      {filledFilters.map((item, index) => (
        <TooltipMUI key={item.title + index} title={item.title} placement='top' arrow>
          <Chip
            label={Array.isArray(item?.label) ? item?.label.join(', ') : capitalizeFirstLetter(item?.label)}
            sx={{ fontSize: '12px', lineHeight: 1.5 }}
          />
        </TooltipMUI>
      ))}
      <Typography variant={'body3' as TypographyProps['variant']}>{`${filledFilters.length} tags applied`}</Typography>
    </Box>
  );
};
