import React from 'react';

export const ClothingAndAccessories = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M3 3C2.448 3 2 3.448 2 4V7C2 7.552 2.448 8 3 8H5V16C5 16.552 5.448 17 6 17H18C18.552 17 19 16.552 19 16V8H21C21.552 8 22 7.552 22 7V4C22 3.448 21.552 3 21 3H19H17H15.4453C14.7523 4.191 13.477 5 12 5C10.523 5 9.24769 4.191 8.55469 3H7H5H3ZM6 19C5.448 19 5 19.448 5 20C5 20.552 5.448 21 6 21H18C18.552 21 19 20.552 19 20C19 19.448 18.552 19 18 19H6Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
