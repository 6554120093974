import React, { useState } from 'react';
import cx from 'classnames';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { SvgIcons } from '@packs/components';
import { DrawerSelect } from '@packs/components/CustomReactSelect/DrawerSelect';
import { NativeSelect } from '@packs/components/CustomReactSelect/NativeSelect';
import { useStore } from '@packs/stores';
import { ICONS } from '@shared/helpers/categoriesIconsHelper';
import { getCountryFlag } from '@shared/helpers/flagIconHelper';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import _get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// todo use in ts later
interface IOption {
  code: string;
  label: string | object | number;
  options: IOption[];
}

interface CustomReactSelect {
  value: {
    label: string | object | number;
    value: string | number;
  };
  className: string;
  classNamePrefix: string;
  options: IOption[];
  loadOptions?: Promise<IOption[]>;
  onBlur: () => void;
  input: {
    onBlur: () => void;
  };
  formatGroupLabel: () => void;
  formatOptionLabel: () => void;
  onChange: () => void;
  native: boolean;
  mobileDrawer: boolean;
  name: string;
  placeholder: string;
  defaultValue?: string;
  categoryName?: string;
  title?: string;
  center?: string;
}

export const CustomReactSelect = (props: CustomReactSelect) => {
  const {
    input,
    value,
    defaultValue,
    name,
    categoryName,
    title,
    center,
    countrySelect,
    withIcon,
    onChange,
    options,
    native,
    placeholder,
    searchable = true,
    disabled = false,
    onBlur,
    className,
    transparent,
    loadOptions,
    mobileDrawer,
    useFalseValue,
    touched,
    meta,
    defaultOptions
  } = props;
  const [isOpen, setOpen] = useState<boolean>(false);
  const { countryCode } = useStore();

  const onClose = () => setOpen(false);
  const { isTablet } = useWindowSize();

  let newValue = {};
  const propsValue =
    _get(value, 'value') ||
    _get(defaultValue, 'value') ||
    defaultValue ||
    value ||
    _get(input, 'value.value') ||
    _get(input, 'value');

  options?.forEach(obj => {
    if (obj.value === propsValue || (useFalseValue && obj.value === propsValue?.value)) {
      newValue = obj;
    }
    obj.options?.forEach(objD => {
      if (objD.value === propsValue) {
        newValue = objD;
      }
    });
  });

  if (native) {
    return <NativeSelect value={newValue} {...{ disabled, options }} />;
  }

  const handleChange = val => {
    if (!disabled) {
      input ? input.onChange(val) : onChange(val);
      onClose();
    }
  };

  const handleBlur = () => {
    const callee = input ? input.onBlur : onBlur;
    callee && callee();
  };

  const enableDrawerSelectLogic = mobileDrawer && isTablet;

  const ReactSelect = loadOptions ? AsyncSelect : Select;

  return (
    <>
      <ReactSelect
        defaultOptions={defaultOptions}
        formatGroupLabel={({ code, label }) => (
          <Typography variant='body4' sx={{ px: 1, color: colors.category[code].main }}>
            {label}
          </Typography>
        )}
        formatOptionLabel={({ code, rootCode, label, className, iconType, countryCode, icon }) => (
          <div
            className={cx('inner-option-wrapper', className, rootCode, code, {
              ['category-option']: !rootCode,
              ['d-flex align-items-center gap-8']: icon
            })}
          >
            {withIcon && code && (
              <div className='icon-container'>
                {rootCode ? SUB_ICONS({ category: { code, root: { enName: rootCode } } }) : ICONS[code]}
              </div>
            )}
            {countryCode && (
              <div className='icon-container'>
                <img src={getCountryFlag(countryCode === 'uk' ? 'gb' : countryCode)} alt={countryCode} />
              </div>
            )}
            {iconType && <SvgIcons {...{ iconType }} />}
            {icon && icon}
            {label}
          </div>
        )}
        options={props.options}
        {...props}
        className={cx('select-ui', className, { transparent, disabled })}
        value={isEmpty(newValue) ? undefined : newValue === 'all' ? '' : newValue}
        placeholder={placeholder}
        menuIsOpen={!enableDrawerSelectLogic && isOpen}
        searchable={searchable && !disabled}
        disabled={disabled}
        onChange={v => handleChange(v)}
        onMenuOpen={() => {
          if (!disabled) {
            setOpen(true);
          }
        }}
        onMenuClose={() => {
          !enableDrawerSelectLogic && setOpen(false);
        }}
        onBlur={() => handleBlur()}
      />

      {!enableDrawerSelectLogic && (touched || meta?.touched) && <span className='error-hint lh-5'>{meta?.error}</span>}

      {enableDrawerSelectLogic && (
        <DrawerSelect
          objValue={newValue}
          {...{
            name,
            isOpen,
            onClose,
            options,
            handleChange,
            categoryName,
            title,
            center,
            disabled,
            withIcon,
            countrySelect
          }}
        />
      )}
    </>
  );
};
