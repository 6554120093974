import { action, observable, runInAction } from 'mobx';

import { questionsApi } from '@packs/apis';
import { commentableType, helpfulTypes } from '@packs/lib/constants';

export class QuestionsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable questions = [];
  @observable questionsTotalCount = 0;
  @observable answers = {};
  @observable loading = false;

  @action load = async (attrs, loadMore) => {
    this.loading = true;
    if (!loadMore) {
      this.questions = [];
    }

    const res = await questionsApi.list(attrs);

    runInAction(() => {
      this.loading = false;
    });

    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }

    runInAction(() => {
      if (loadMore) {
        this.questions = [...this.questions, ...(res?.list || [])];
      } else {
        this.questions = res?.list;
      }

      this.questionsTotalCount = res?.totalCount;
    });

    return res;
  };

  @action loadAnswers = async attrs => {
    const res = await questionsApi.listAnswers(attrs);

    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }

    runInAction(() => {
      this.answers = {
        ...this.answers,
        [res.post.id]: res.post.comments
      };
    });

    return res;
  };

  @action create = async (attrs, postTypeFilter, filters) => {
    const res = await questionsApi.create(attrs);
    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }

    if (
      postTypeFilter.includes(attrs.postType) &&
      (!filters?.countryRegionName?.value || filters?.countryRegionName?.value === 'all'
        ? true
        : filters?.countryRegionName?.value === attrs?.countryRegionName) &&
      (!filters?.locality?.value || filters?.locality?.value === 'all'
        ? true
        : filters?.locality?.value === attrs?.locality)
    ) {
      runInAction(() => {
        this.questions = [res.post, ...this.questions];
        this.questionsTotalCount = this.questionsTotalCount + 1;
      });
    }

    return res;
  };

  @action update = async attrs => {
    const res = await questionsApi.update(attrs);
    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }
    runInAction(() => {
      this.questions = this.questions.map(post => {
        if (post.id === attrs.id) {
          return res.post;
        }

        return post;
      });
    });

    return res;
  };

  @action createComment = async (attrs, postId) => {
    const res = await questionsApi.createComment(attrs);

    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }
    runInAction(() => {
      const answers = { ...this.answers };

      if (attrs.commentableType === commentableType.post) {
        answers[postId] = [...answers[postId], res.comment];

        this.questions = this.questions.map(item => {
          const newData = { ...item };
          if (item.id === postId) {
            newData.commentsCount = ++newData.commentsCount;
          }

          return newData;
        });
      } else if (attrs.commentableType === commentableType.comment) {
        answers[postId] = answers[postId].map(item => {
          if (item.id === attrs.commentableId) {
            item.commentsCount = ++item.commentsCount;
            item.comments = [...item.comments, res.comment];

            return item;
          }
          return item;
        });
      }

      this.answers = answers;
    });

    return res;
  };

  @action toggleHelpful = async ({ attrs, postId, rootCommentId = null, helpfulType = helpfulTypes.create }) => {
    const isCreate = helpfulType === helpfulTypes.create;
    const res = isCreate ? await questionsApi.createHelpful(attrs) : await questionsApi.destroyHelpful(attrs);

    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }

    runInAction(() => {
      if (attrs.helpfulableType === commentableType.post) {
        this.questions = this.questions.map(post => {
          if (post.id === postId) {
            post.helpfulsCount = isCreate ? ++post.helpfulsCount : post.helpfulsCount - 1;
            post.isHelpful = res?.isHelpful;

            return post;
          }

          return post;
        });
      } else {
        const answers = { ...this.answers };

        answers[postId] = answers[postId].map(comment => {
          if (comment.id === rootCommentId) {
            comment.comments = comment.comments.map(reply => {
              if (reply.id === attrs?.helpfulableId) {
                if (!reply.helpfulsCount) {
                  reply.helpfulsCount = 0;
                }

                reply.helpfulsCount = isCreate ? ++reply.helpfulsCount : reply.helpfulsCount - 1;
                reply.isHelpful = res?.isHelpful;
              }

              return reply;
            });
          } else if (comment.id === attrs?.helpfulableId) {
            if (!comment.helpfulsCount) {
              comment.helpfulsCount = 0;
            }
            comment.helpfulsCount = isCreate ? ++comment.helpfulsCount : comment.helpfulsCount - 1;
            comment.isHelpful = res?.isHelpful;
          }

          return comment;
        });

        this.answers = answers;
      }
    });
  };

  @action report = async ({ qaPostId, onSuccess, onError }) => {
    const res = await questionsApi.report({ qaPostId });

    if (res?.errors?.length) {
      onError && onError(res?.errors);
      return Promise.reject(res.errors);
    }

    runInAction(() => {
      this.questions = this.questions.map(post => {
        if (post.id === qaPostId) {
          post.isAbused = true;
        }

        return post;
      });
    });

    onSuccess && onSuccess(res);
    return res;
  };

  @action destroy = async ({ id, onSuccess, onError }) => {
    const res = await questionsApi.destroy({ id });

    if (res?.errors?.length) {
      onError && onError(res?.errors);
      return Promise.reject(res.errors);
    }
    runInAction(() => {
      this.questions = [...this.questions].filter(item => item.id !== id);
    });

    onSuccess && onSuccess(res);
    return res;
  };
}
