import React from 'react';
import cx from 'classnames';

import { usePath } from '@packs/layouts/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import startCase from 'lodash-es/startCase';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

const CategoryPage = () => {
  const { categories } = useCategories();
  const { root_code } = useParams();
  const { companiesStore, categoryStore, countryCode } = useStore();
  const { DASHBOARD_MY_RESULTS_PATH_FN } = usePath();

  const currentCategories = categories.find(category => category.code === root_code).leafChildren;
  const metaTitle = `Compare ${startCase(root_code)} deals`;
  const title = `Save on your ${startCase(root_code)} bills with Billwinner.com!`;
  const description = 'Compare the best deals from providers, brokers and price comparison sites in one place!';

  const handleRedirect = subCategory => {
    return () => {
      categoryStore.setCurrentCategory(subCategory);
      companiesStore.clearFilters();
    };
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name='description' content={description} />
        <meta name='og:title' content={metaTitle} />
        <meta name='og:description' content={description} />
        <link rel='canonical' href={`${location?.origin}/${countryCode}/${root_code}/compare`} />
      </Helmet>
      <div className='category-page'>
        <h1 className='fs-28 fw-700 lh-5 mb-8'>{title}</h1>
        <div className='fs-16 secondary-text'>{description}</div>
        <div className='categories-box'>
          <div className='fs-23 fw-700 text-center mb-32'>Where do you want to start?</div>
          <div className='categories-list'>
            {currentCategories.map(item => (
              <div className='category d-flex flex-column align-items-center' key={item.id}>
                <div className={cx('icon-container mb-16', root_code)}>{SUB_ICONS({ category: item })}</div>
                <div className='fs-16 fw-700 mb-24 lh-5 text-center'>{item.name}</div>
                <div>
                  <Link
                    to={DASHBOARD_MY_RESULTS_PATH_FN(item)}
                    onClick={handleRedirect(item)}
                    className='btn full black'
                  >
                    <span>Get cheaper bills</span>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryPage;
