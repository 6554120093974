import React from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import useStyles from '@packs/components/UI/CategoriesNavigation/components/styles';
import { usePath } from '@packs/layouts/constants';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { ICONS } from '@shared/helpers/categoriesIconsHelper';

import { NavLink } from 'react-router-dom';

export const MidWayCategoriesList = ({
  midwayCategories,
  isComingSoonCategory,
  categoryUrl,
  onClickCategory,
  columnCount,
  selectedRootCategory
}) => {
  const styles = useStyles();
  const { DASHBOARD_LANDING_PATH_FN } = usePath();

  return (
    <Box>
      {midwayCategories.map(midwayCat => {
        const subCategories = midwayCat?.children.eachSlice(10);

        return (
          <Box key={midwayCat.id} sx={styles.midWayWrapper}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
              <IconBox
                containerSize='32px'
                iconSize='24px'
                borderRadius='8px'
                color={colors.secondary.main}
                backgroundColor='rgba(1, 103, 205, 0.08)'
                icon={ICONS[midwayCat.code]}
                rootIcon
              />
              <Typography variant='body4' sx={{ color: colors.dark.main }}>
                {midwayCat.name}
              </Typography>
            </Box>
            <Box sx={styles.subCategoryColumnList(columnCount(subCategories))}>
              {subCategories.map((column, index) => (
                <Stack key={index} direction='column' spacing={3} sx={styles.column}>
                  {column.map(item => (
                    <Stack direction='row' key={item.id}>
                      <Box
                        component={NavLink}
                        to={
                          isComingSoonCategory
                            ? DASHBOARD_LANDING_PATH_FN(item)
                            : categoryUrl({ ...item, root: selectedRootCategory })
                        }
                        onClick={onClickCategory(item)}
                        sx={styles.categoryLink}
                      >
                        <Typography variant='body2' sx={styles.categoryName}>
                          {item.name}
                        </Typography>
                      </Box>
                    </Stack>
                  ))}
                </Stack>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
