import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { PageTitleWrapper } from '@packs/components/MaterialUi/PageTitleWrapper/PageTitleWrapper';
import { SelectMUI } from '@packs/components/MaterialUi/SelectMUI/SelectMUI';
import { usePath } from '@packs/layouts/constants';
import { groupsTypes } from '@packs/models/groups/constants';
import { GroupItem } from '@packs/screens/MyGroups/components/GroupItem/GroupItem';
import { useStore } from '@packs/stores';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useItemStyles from './components/GroupItem/syles';
import useStyles from './syles';

import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

const MyGroups = observer(() => {
  const { GROUPS_GENERAL_PATH } = usePath();
  const styles = useStyles();
  const itemStyles = useItemStyles();
  const { userStore } = useStore();
  const { isMobile } = useWindowSize();
  const [groupType, setGroupType] = useState(groupsTypes[0]);

  const groups = useMemo(() => {
    if (!groupType.value || groupType.value === 'all') {
      return userStore?.currentUserGroups;
    }
    return userStore?.currentUserGroups.filter(group => group?.campaignType === groupType.value);
  }, [userStore?.currentUserGroups, groupType]);

  const onChangeGroupType = (_, data) => {
    setGroupType(data);
  };

  return (
    <Box>
      <PageTitleWrapper title='My Groups' currentPage='My Groups' />

      <Box sx={styles.container}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
          <Box sx={{ width: '100%', maxWidth: '180px' }}>
            <SelectMUI
              value={groupType}
              options={groupsTypes}
              onChange={onChangeGroupType}
              size='small'
              sx={{ width: '180px' }}
              label='Group type'
            />
          </Box>
          <Button component={NavLink} to={GROUPS_GENERAL_PATH} variant='contained' color='primary' size='smallToMedium'>
            Search groups
          </Button>
        </Box>
        {!isMobile && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: {
                xs: 2,
                md: 3
              },
              p: 0,
              pb: 1
            }}
          >
            <Box sx={[itemStyles.nameContainer, { width: { xs: '100%', lg: '38%' }, pl: 2 }]}>
              <Typography variant='body6'>Group name</Typography>
            </Box>
            <Box sx={{ width: { xs: '100%', lg: '18%' } }}>
              <Typography variant='body6'>Group Type</Typography>
            </Box>

            <Box sx={[itemStyles.locationContainer, { width: { xs: '100%', lg: '32%' } }]}>
              <Typography variant='body6'>Location</Typography>
            </Box>
            <Box sx={{ width: { xs: '100%', lg: '22%' }, minWidth: '200px' }} />
          </Box>
        )}
        {groups?.length > 0 ? (
          <Box sx={styles.list}>
            {groups?.map(group => (
              <GroupItem key={group.id} group={group} />
            ))}
          </Box>
        ) : (
          <Box sx={styles.emptyContainer}>
            <EmptyPlaceholder>
              <Button
                component={NavLink}
                to={GROUPS_GENERAL_PATH}
                variant='contained'
                color='primary'
                size='smallToMEdium'
              >
                Search groups
              </Button>
            </EmptyPlaceholder>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default MyGroups;
