import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { MaterialRadioGroup } from '@packs/components/MaterialUi/MaterialRadioGroup/MaterialRadioGroup';
import { SelectMUI } from '@packs/components/MaterialUi/SelectMUI/SelectMUI';
import { DashboardCriteriaFilters, DashboardLocationFilter, DashboardPolicyFilter } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { dateFilterOptions, energyAdvancedPricesList } from '@packs/lib/constants';
import { toastr } from '@packs/lib/helpers';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { sortOptions } from '@packs/lib/helpers/sortSubcategoriesDesktop';
import { useCategories } from '@packs/models/category/useCategories';
import { COMPANY_TYPE_OPTIONS, defaultLocality, TIME_OPTIONS } from '@packs/models/company/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { AssociationFilter } from '@packs/screens/MaterialUi/MyStats/components/AssociationFilter';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import _get from 'lodash-es/get';
import _isNull from 'lodash-es/isNull';
import _isUndefined from 'lodash-es/isUndefined';
import { observer } from 'mobx-react';

const { text } = colors;

export const MyStatsFilters = observer(
  ({
    chartType,
    chartTypes,
    handleChangeChartType,
    handleSetAssociation,
    associationFilter,
    unitFilters,
    handleChangeAdvancedPrices,
    tabFilters,
    handleChangeDate,
    sidebarFilter = false
  }) => {
    const { companiesStore, policyStore, currentCountry } = useStore();
    const { currentUser } = useUserSelectors();
    const { currentCategory } = useCategories();
    const { currentPolicy } = useDetails();
    const { isMobile } = useWindowSize();

    const onLoadStats = (filters = null) => {
      companiesStore.setFilters(filters);
    };

    const isPersonalized = hasPolicyCriteria(currentPolicy);
    const isInsuranceCategory =
      ['insurance'].includes(currentCategory.root?.enName) || currentCategory.code?.includes('insurance');
    const sortList = sortOptions({ isInsuranceCategory, isPersonalized, currentUser });

    const onLoadCompanies = (filters = null) => {
      if (filters) {
        companiesStore.load(filters);
      } else {
        companiesStore.load();
      }
    };

    const onChangeLocationFilter = newStateOption => {
      const locationData = {
        countryCode: currentCountry?.isoA2Code,
        address: `${(newStateOption || {}).value}`
      };

      if (locationData.countryCode || locationData.address) {
        const filters = {
          locality: defaultLocality
        };

        if (_get(newStateOption, 'value') === 'All') {
          filters.city = undefined;
          filters.country = undefined;
        } else {
          filters.city = locationData.address;
          filters.country = locationData.countryCode;
        }

        onLoadStats(filters);
      } else {
        toastr.warning('no any location detected');
      }
    };

    const onChangePolicy = async ({ object: policy }) => {
      policyStore.setCurrentPolicy(policy);

      if (policy.id) {
        await policyStore.loadPolicy(policy.id);
      }
    };

    const onChangeFilter = key => {
      return data => {
        onLoadStats({ [key]: typeof data === 'object' ? data?.value : data });
      };
    };

    const handleChangeCriteriaQuestions = (value, key) => {
      let criteriaQuestions = {};

      let newValue = value;
      if (!_isNull(value) && !_isUndefined(value)) {
        if (value === 'all') {
          newValue = '';
        }
        criteriaQuestions = {
          ...companiesStore.filters.criteriaQuestions,
          [key]: newValue
        };
      } else {
        criteriaQuestions = companiesStore.filters.criteriaQuestions;
      }
      onLoadStats({ criteriaQuestions });
    };

    const onChangeMaterialFilter = key => {
      return data => {
        onLoadCompanies({ filters: { [key]: typeof data === 'object' ? data?.value : data } });
      };
    };

    const onChangeSort = (_, data) => {
      if (data?.value) {
        onLoadCompanies({ filters: { orderColumn: data.value } });
      }
    };

    const companyTypeOptions = useMemo(() => {
      if (currentCategory?.root?.code === 'subscriptions') {
        return [{ value: 'provider', label: 'Top Providers' }];
      }

      return COMPANY_TYPE_OPTIONS;
    }, [currentCategory]);

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <AutocompleteMUI
          value={chartType}
          options={chartTypes}
          onChange={handleChangeChartType}
          label='Chart Types'
          size={sidebarFilter ? 'small' : 'medium'}
          withTitle={sidebarFilter}
          mobileSelect
          filter
          disableClearable
        />

        {chartType.value === 'unit_prices' && (
          <AutocompleteMUI
            value={dateFilterOptions.find(item => item.value === unitFilters.costType)}
            options={energyAdvancedPricesList}
            onChange={handleChangeAdvancedPrices}
            label='Period Rate'
            size={sidebarFilter ? 'small' : 'medium'}
            withTitle={sidebarFilter}
            mobileSelect
            filter
          />
        )}
        {chartType.value === 'price' && (
          <>
            <AutocompleteMUI
              value={dateFilterOptions.find(item => item.value === tabFilters?.date)}
              options={dateFilterOptions}
              onChange={handleChangeDate}
              label='Period'
              size={sidebarFilter ? 'small' : 'medium'}
              withTitle={sidebarFilter}
              mobileSelect
              filter
            />
          </>
        )}
        {chartType?.value === 'businesses' && (
          <Box>
            <AssociationFilter
              category={currentCategory}
              {...{
                currentUser,
                associationFilter,
                handleSetAssociation
              }}
              size={sidebarFilter ? 'small' : 'medium'}
              withTitle={sidebarFilter}
              className='select-ui gray-bg medium'
            />
          </Box>
        )}

        <Box>
          <DashboardPolicyFilter
            initialExpanded={false}
            onChange={onChangePolicy}
            size={sidebarFilter ? 'small' : 'medium'}
            withTitle={sidebarFilter}
          />
        </Box>
        {!sidebarFilter && <Divider />}
        <Box>
          <DashboardLocationFilter
            {...{ currentCountry }}
            onChange={onChangeLocationFilter}
            size={sidebarFilter ? 'small' : 'medium'}
            withTitle={sidebarFilter}
          />
        </Box>
        {!sidebarFilter && <Divider />}
        {companiesStore?.filters?.orderColumn !== 'wiki_price' && (
          <>
            <Box>
              <AutocompleteMUI
                value={TIME_OPTIONS.find(item => item.value === companiesStore.filters.lastBuy)}
                options={TIME_OPTIONS}
                onChange={onChangeMaterialFilter('lastBuy')}
                label='Please select time'
                size={sidebarFilter ? 'small' : 'medium'}
                withTitle={sidebarFilter}
                mobileSelect
              />
            </Box>
            {!sidebarFilter && <Divider />}
          </>
        )}
        <Box>
          <Typography variant='body6' sx={{ color: text.secondary }}>
            Source type
          </Typography>
          <MaterialRadioGroup
            row={false}
            name='feedbackType'
            options={companyTypeOptions}
            value={companyTypeOptions.find(item => item.value === companiesStore?.filters?.companyType)}
            onChange={onChangeFilter('companyType')}
          />
        </Box>
        {!sidebarFilter && <Divider />}
        <Box>
          <DashboardCriteriaFilters onChange={handleChangeCriteriaQuestions} {...{ sidebarFilter }} />
        </Box>
        {isMobile && (
          <>
            <Box>
              <SelectMUI
                value={sortList.find(item => item.value === companiesStore?.filters?.orderColumn) || sortList[0]}
                options={sortList}
                onChange={onChangeSort}
                label='Sort by'
                size={sidebarFilter ? 'small' : 'medium'}
              />
            </Box>
          </>
        )}
      </Box>
    );
  }
);
