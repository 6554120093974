import React, { useEffect } from 'react';
import cx from 'classnames';

import { PriceField } from '@packs/components/PriceField';
import { SelectField } from '@packs/components/SelectField';
import { countriesCoinsCurrency, perMonthCategories, rateCostList } from '@packs/lib/constants';
import { toastr } from '@packs/lib/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';

import { getPeriodsList } from './constants/constants';
import { useHandleFormSubmit } from './useHandleFormSubmit';

import _get from 'lodash-es/get';
import _isNull from 'lodash-es/isNull';
import startCase from 'lodash-es/startCase';
import { observer } from 'mobx-react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Field } from 'redux-form';

export const cantRemember = {
  id: 'i_cant_remember',
  label: "I can't remember",
  name: "I can't remember",
  value: 'i_cant_remember'
};

export const UnitPricesModal = observer(props => {
  const { reset, change, toggle, isModalOpen, renewalDetail, formAttributes, selectedWinner, successCallback } = props;
  const { currentCountry, policiesListStore } = useStore();
  const { currentCategory } = useCategories();
  const { mixOptions } = policiesListStore;

  const { createSmartReminder, triggeredAt } = formAttributes || {};

  const winnerCompany = mixOptions.find(s => s.value === formAttributes?.winnerId);
  const coinCurrency =
    countriesCoinsCurrency.find(item => item.code === currentCountry?.isoA2Code)?.coin ||
    countriesCoinsCurrency[0]?.coin;
  const coinCurrencyName =
    countriesCoinsCurrency.find(item => item.code === currentCountry?.isoA2Code)?.name ||
    countriesCoinsCurrency[0]?.name;
  const energyCategory = currentCategory?.root?.code === 'energy';
  const electricity = currentCategory?.code === 'electricity';
  const gas = currentCategory?.code === 'gas';
  const electricityAndGas = currentCategory?.code === 'gas_and_electricity';

  const handleFormSubmit = useHandleFormSubmit(
    props,
    res => {
      if (renewalDetail?.id) {
        toastr.success('Updated');
      } else {
        toastr.success('Updated');
      }
      reset();
      successCallback({
        winnerCompany,
        createSmartReminder,
        res,
        triggeredAt
      });
    },
    resp => {
      if (resp?.errors) {
        toastr.error(resp?.errors);
      }
    }
  );

  useEffect(() => {
    if (isModalOpen) {
      if (_get(renewalDetail, 'pricesQuestions.price.period', null)) {
        change(
          'pricesQuestions.price.period',
          getPeriodsList.find(item => item.value === renewalDetail?.pricesQuestions?.price?.period).value
        );
      } else {
        if (
          perMonthCategories.includes(currentCategory?.code) ||
          perMonthCategories.includes(currentCategory?.root?.code)
        ) {
          change('pricesQuestions.price.period', getPeriodsList[0].value);
        } else {
          change('pricesQuestions.price.period', getPeriodsList[1].value);
        }
      }

      if (_get(renewalDetail, 'pricesQuestions.price.value', null)) {
        change('pricesQuestions.price.value', renewalDetail?.pricesQuestions?.price?.value || 0);
      }

      if (_get(renewalDetail, 'advancedPrice.electricity', null)) {
        const includingTaxes = renewalDetail?.advancedPrice?.electricity?.includingTaxes;

        change('advancedPrice.electricity.includingTaxes', _isNull(includingTaxes) ? true : includingTaxes);
        change('advancedPrice.electricity.dayPrice', _get(renewalDetail, 'advancedPrice.electricity.dayPrice', 0));
        change('advancedPrice.electricity.nightPrice', _get(renewalDetail, 'advancedPrice.electricity.nightPrice', 0));
        change(
          'advancedPrice.electricity.standingPrice',
          _get(renewalDetail, 'advancedPrice.electricity.standingPrice', 0)
        );
      } else {
        if (electricity || electricityAndGas) {
          change('advancedPrice.electricity.includingTaxes', true);
        }
      }

      if (_get(renewalDetail, 'advancedPrice.gas', null)) {
        const includingTaxes = renewalDetail?.advancedPrice?.gas?.includingTaxes;
        change('advancedPrice.gas.includingTaxes', _isNull(includingTaxes) ? true : includingTaxes);
        change('advancedPrice.gas.dayPrice', _get(renewalDetail, 'advancedPrice.gas.dayPrice', 0));
        change('advancedPrice.gas.nightPrice', _get(renewalDetail, 'advancedPrice.gas.nightPrice', 0));
        change('advancedPrice.gas.standingPrice', _get(renewalDetail, 'advancedPrice.gas.standingPrice', 0));
      } else {
        if (gas || electricityAndGas) {
          change('advancedPrice.gas.includingTaxes', rateCostList[0].value);
        }
      }
    }
  }, [isModalOpen, selectedWinner]);

  const categoryName = currentCategory?.name;

  const energyAdvancedFields = () => (
    <>
      {(gas || electricityAndGas) && (
        <div>
          <div className='label contract-length-label d-flex align-items-center'>
            <div className='contract-length-wrapper d-flex align-items-center'>
              <span>
                {electricityAndGas && <span className='type-name'>GAS </span>}
                Unit rate cost per (KWh)
              </span>
              <Field
                name='advancedPrice.gas.includingTaxes'
                className='reminder-select'
                classNamePrefix='Select'
                isSearchable={false}
                options={rateCostList}
                component={SelectField}
                useFalseValue
              />
            </div>
          </div>
          <div className='form-row-list'>
            <div className='input-field'>
              <div className='label'>Cost per day ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.gas.dayPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='price-input with-border transparent'
                withDot
              />
            </div>
            <div className='input-field'>
              <div className='label'>Cost per night ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.gas.nightPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='price-input with-border transparent'
                withDot
              />
            </div>
          </div>
          <div className='form-row-list'>
            <div className='input-field'>
              <div className='label'>Standing charge ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.gas.standingPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='price-input with-border transparent'
                withDot
              />
            </div>
          </div>
        </div>
      )}
      {(electricity || electricityAndGas) && (
        <div>
          <div className='label contract-length-label d-flex align-items-center'>
            <div className='contract-length-wrapper d-flex align-items-center'>
              <span>
                {electricityAndGas && <span className='type-name'>Electricity </span>}
                Unit rate cost per (KWh)
              </span>
              <Field
                name='advancedPrice.electricity.includingTaxes'
                className='reminder-select'
                classNamePrefix='Select'
                isSearchable={false}
                options={rateCostList}
                component={SelectField}
                useFalseValue
              />
            </div>
          </div>
          <div className='form-row-list'>
            <div className='input-field'>
              <div className='label'>Cost per day ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.electricity.dayPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='price-input with-border transparent'
                withDot
              />
            </div>
            <div className='input-field'>
              <div className='label'>Cost per night ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.electricity.nightPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='price-input with-border transparent'
                withDot
              />
            </div>
          </div>
          <div className='form-row-list'>
            <div className='input-field'>
              <div className='label'>Standing charge ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.electricity.standingPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='price-input with-border transparent'
                withDot
              />
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={() => toggle()}
      className={cx('unit-price-modal', currentCategory?.root?.enName)}
    >
      <ModalHeader toggle={() => toggle('isNewAssociationModalOpen')}>Add your unit prices</ModalHeader>
      <ModalBody>
        <div className='fs-16 mb-20'>{categoryName}</div>
        <form className='form' onSubmit={props.handleSubmit(handleFormSubmit)}>
          <div className='form-row-list'>
            <div className='advanced-price'>
              <div className='fs-20 fw-700 mb-25'>
                <span>{energyCategory ? 'Track your unit prices (Optional)' : 'Advanced Price Settings'}</span>
              </div>
              {energyAdvancedFields()}
            </div>
          </div>

          <div className='btn-container d-flex justify-content-end'>
            <div className='btn cancel' onClick={toggle}>
              Cancel
            </div>
            <button type='submit' className='btn full white theme'>
              <span>Save my unit prices</span>
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
});
