import React from 'react';

export const WeddingServices = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_4928_5966)'>
        <g>
          <path
            d='M12.0032 7.12606C12.3079 7.12606 12.8954 6.76659 13.7494 6.05747C14.3393 5.56781 15.0766 4.88179 15.3768 4.42525C15.6044 4.07924 15.8608 3.59257 15.8235 2.8953C15.7615 1.73614 14.8262 0.828125 13.6943 0.828125C12.8874 0.828125 12.3215 1.42364 12.0081 1.76509C11.7108 1.42904 11.14 0.828125 10.3058 0.828125C9.17397 0.828125 8.23869 1.73614 8.17662 2.89536C8.13889 3.60175 8.41358 4.10696 8.62354 4.42551C9.08737 5.12961 11.3285 7.12606 12.0032 7.12606Z'
            fill='white'
          />
          <path
            d='M16.3918 7.95503C14.7573 7.95503 13.2415 8.47332 12 9.35394C10.7586 8.47325 9.24281 7.95503 7.60824 7.95503C3.41306 7.95503 0 11.3681 0 15.5632C0 19.7583 3.41306 23.1714 7.60817 23.1714C9.24274 23.1714 10.7585 22.6531 12 21.7725C13.2414 22.6531 14.7572 23.1714 16.3918 23.1714C20.5869 23.1714 23.9999 19.7584 23.9999 15.5632C23.9999 11.368 20.5869 7.95503 16.3918 7.95503ZM12 19.1233C11.2099 18.1504 10.7355 16.9112 10.7355 15.5632C10.7355 14.2152 11.2098 12.9759 12 12.003C12.7902 12.9759 13.2645 14.2151 13.2645 15.5632C13.2645 16.9113 12.7902 18.1505 12 19.1233ZM1.95188 15.5632C1.95188 12.4443 4.48933 9.90684 7.60817 9.90684C8.67585 9.90684 9.67528 10.2043 10.5282 10.7205C9.43897 12.0371 8.78366 13.725 8.78366 15.5631C8.78366 17.4013 9.43897 19.0892 10.5282 20.4057C9.67528 20.922 8.67585 21.2194 7.60817 21.2194C4.48933 21.2195 1.95188 18.6821 1.95188 15.5632ZM16.3918 21.2195C15.3242 21.2195 14.3247 20.922 13.4718 20.4058C14.561 19.0893 15.2163 17.4013 15.2163 15.5632C15.2163 13.725 14.561 12.0371 13.4718 10.7206C14.3247 10.2044 15.3242 9.90691 16.3918 9.90691C19.5107 9.90691 22.0481 12.4444 22.0481 15.5633C22.0481 18.6822 19.5107 21.2195 16.3918 21.2195Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_4928_5966'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
