import colors from '@packs/assets/theme/base/colors';

const { secondary, white, dark } = colors;

export default () => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    minHeight: { lg: '800px' },
    backgroundImage: secondary.gradient,
    color: white.main
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexGrow: 1,
    width: '100%',
    maxWidth: '1162px',
    mx: 'auto',
    px: 2,
    pt: {
      xs: 2,
      lg: 4
    },
    pb: {
      xs: 2,
      lg: 3
    },
    zIndex: 2
  },
  breadcrumbsContainer: {
    mb: { xs: 5, lg: 0 }
  },
  decorWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '45%',
    zIndex: 1,
    display: {
      xs: 'none',
      lg: 'block'
    }
  },
  decorSection: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 2
  },
  imageSection: {
    position: 'relative',
    width: '100%',
    height: '100%',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: dark.tonal
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  shareContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 2,
    maxWidth: '788px',
    mt: 3
  },
  cornerIcon: {
    position: 'absolute',
    bottom: 24,
    right: 24,
    background: colors.white.main,
    border: 1,
    borderRadius: 1,
    zIndex: 2,
    maxWidth: '230px',
    width: 1,
    height: '70px',
    img: {
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: 'inherit',
      objectFit: 'scale-down'
    }
  }
});
