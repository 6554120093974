import { campaignGroupJoin, userCampaignGroups } from '@packs/graphql/queries/groups';
import { graphQLRequest } from '@packs/lib/utils';

const api = {
  campaignGroupJoin: variables =>
    graphQLRequest({
      query: campaignGroupJoin,
      variables: { input: variables }
    }),
  userCampaignGroups: variables =>
    graphQLRequest({
      query: userCampaignGroups,
      variables
    })
};

export default api;
