import React, { useEffect, useState } from 'react';

import { I18n } from '@packs/components/I18n';
import { Loader } from '@packs/components/Loader';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

import { Form } from './components';

import first from 'lodash-es/first';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';

const ResendConfirmation = observer(({ setFormErrorDispatch }) => {
  const { currentUser } = useUserSelectors();
  const { userStore } = useStore();
  const { ROOT_PATH, USERS_SIGN_IN_PATH, PROFILE_MY_REMINDERS_PATH } = usePath();
  const [inProcess, setInProcess] = useState(false);
  const { defaultMessages } = useTranslationMessages();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      userStore.setCurrentUser({});
      userStore.setCurrentUserConfirmationInstructionsErrors([]);
    };
  }, []);

  const submitHandler = ({ email }) => {
    const callback = () => {
      if (currentUser.id) {
        navigate(ROOT_PATH);
      } else {
        navigate(USERS_SIGN_IN_PATH);
      }
      toastr.success('We have sent you a new confirmation email.');
    };

    const errorCallback = err => {
      toastr.error(first(err) || '');
      setInProcess(false);
    };

    if (
      userStore.resendConfirmationInstructionsFormErrors &&
      userStore.resendConfirmationInstructionsFormErrors.length &&
      currentUser.email === email
    ) {
      return setFormErrorDispatch(userStore.resendConfirmationInstructionsFormErrors[0]);
    }

    setInProcess(true);
    userStore.resendCurrentUserConfirmationInstructions({ email, callback, errorCallback });
  };

  return (
    <section className='auth-container'>
      {inProcess && <Loader />}
      <div className='auth-block resend-confirmation'>
        <h3 className='auth-title'>
          <I18n text={defaultMessages.resendConfirmationInstructions} />
        </h3>
        <Form initialValues={{ email: currentUser.email }} onSubmit={submitHandler} />
        <div>
          <Link to={USERS_SIGN_IN_PATH}>
            <I18n text={defaultMessages.login} />
          </Link>
        </div>
        <div>
          <Link to={PROFILE_MY_REMINDERS_PATH}>
            <I18n text={defaultMessages.forgotPassword} />
          </Link>
        </div>
      </div>
    </section>
  );
});

export default ResendConfirmation;
