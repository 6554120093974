import React, { useMemo, useState } from 'react';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CompanyItem } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/CompanyItem';
import { DashboardSkeleton } from '@packs/components/UI/Dashboard/DashboardSkeleton';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import { observer } from 'mobx-react';
import Slider from 'react-slick';

export const ShortListSlider = observer(({ companyType, title, setActiveStep }) => {
  const styles = useStyles();
  const { leaderboardStore } = useStore();
  const { isMobile } = useWindowSize();
  const [activeSlide, setActiveSlide] = useState(0);

  const list = useMemo(() => {
    return leaderboardStore.suggestedList[companyType];
  }, [leaderboardStore.suggestedList, companyType]);

  const handleSetActiveStep = () => {
    setActiveStep(2);
  };

  const SampleNextArrow = props => {
    const { onClick } = props;
    return (
      <IconButton sx={{ ...styles.sliderArrow, ...styles.nextArrow }} onClick={onClick}>
        <ChevronRightRoundedIcon />
      </IconButton>
    );
  };

  const SamplePrevArrow = props => {
    const { onClick } = props;
    return (
      <IconButton sx={{ ...styles.sliderArrow, ...styles.prevArrow }} onClick={onClick}>
        <KeyboardArrowLeftRoundedIcon />
      </IconButton>
    );
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: isMobile,
    arrows: !isMobile,
    beforeChange: (current, next) => setActiveSlide(next),
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  if (leaderboardStore?.isLoadingAgents) {
    return (
      <div className='short-list-slider-item'>
        <div className='d-flex align-items-center mb-8 pl-16'>
          <div className='short-list-slider-title fs-11 fw-600 tt-uc'>{title}</div>
        </div>
        <div className='pl-16 pr-16 pt-8 pb-16'>
          <DashboardSkeleton {...{ count: 1 }} extended={false} />
        </div>
      </div>
    );
  }

  if (list.length === 0) {
    return null;
  }

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.titleWrapper}>
        <Typography variant='h6'>{title}</Typography>
        {list.length > 1 && !isMobile && <Typography variant='body4'>{`${activeSlide + 1}/${list.length}`}</Typography>}
      </Box>
      <Slider {...settings}>
        {list.map((company, index) => (
          <Box key={company.id} sx={{ px: 1, pb: 1 }}>
            <CompanyItem {...{ company, index }} requestButtonCallback={handleSetActiveStep} showPlaceLabel />
          </Box>
        ))}
      </Slider>
    </Box>
  );
});
