import colors from '@packs/assets/theme/base/colors';
import OffersDecor from '@packs/images/svg/ui/landing-offers-decor.svg';

const { grey, primary, secondary } = colors;

export default () => ({
  wrapper: {
    py: { xs: 6, md: 15 },
    background: `url(${OffersDecor}), ${secondary.gradient}`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  title: {
    px: 2,
    textAlign: { xs: 'center', md: 'left' },
    mb: 3
  },
  sliderWrapper: {
    '& .slick-dots': {
      left: '50%',
      bottom: '-42px',
      width: 'auto',
      transform: 'translateX(-50%)',
      '& li': {
        '& button': {
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          backgroundColor: grey[500],
          '&:before': {
            content: 'none'
          }
        },
        '&.slick-active button': {
          backgroundColor: primary.main
        }
      }
    }
  },
  viewMoreBtn: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    mt: { xs: 10, md: 4 }
  },
  footerGap: {
    display: { xs: 'none', md: 'block' },
    height: '269px'
  }
});
