import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BadgesSlider } from '@packs/components/MaterialUi/Badges/BadgesGroupList/components/BadgesSlider/BadgesSlider';
import { SelectMUI } from '@packs/components/MaterialUi/SelectMUI/SelectMUI';
import { useCategories } from '@packs/models/category/useCategories';
import { seasonOrder } from '@shared/constants/bages';

import useStyles from './styles';

const defaultOption = {
  value: 'all',
  label: 'All categories'
};

export const BadgesList = ({ year, seasons, categoriesIds }) => {
  const { flattenCategories } = useCategories();
  const styles = useStyles();

  const [category, setCategory] = useState(defaultOption);

  const categoriesOptions = useMemo(() => {
    const list = categoriesIds?.map(id => {
      const category = flattenCategories.find(c => c.id === id);

      return {
        value: id,
        label: category?.name
      };
    });

    return [defaultOption, ...list];
  }, [categoriesIds, flattenCategories]);

  const sortedSeasons = useMemo(() => {
    return seasonOrder.map(season => ({
      key: season,
      badges: seasons[season]
    }));
  }, [seasonOrder, seasons]);

  const onChange = (_, data) => {
    setCategory(data);
  };

  return (
    <Box sx={styles.wrapper}>
      <Box
        sx={{
          ...styles.container,
          ...{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: { xs: 3, lg: 4 } }
        }}
      >
        <Typography variant='h6'>{year}</Typography>
        {categoriesOptions.length > 2 && (
          <SelectMUI value={category} label='' size='small' options={categoriesOptions} onChange={onChange} />
        )}
      </Box>
      {sortedSeasons.map(({ key, badges }) => {
        if (!badges) {
          return null;
        }
        return <BadgesSlider key={key} badges={badges} season={key} category={category} year={year} />;
      })}
    </Box>
  );
};
