import React, { Fragment, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import AttachedDocument from '@packs/components/UI/MyNotes/components/attachedDocument';
import { DocumentActions } from '@packs/components/UI/MyNotes/components/DocumentActions';

import useStyles from './styles';

import moment from 'moment/moment';

const { secondary } = colors;

export const NoteItem = ({ note, general, openLightbox, handleDownload }) => {
  const styles = useStyles();
  const [showMore, setShowMore] = useState(false);

  const splitByN = fullText => {
    const textArrs = (fullText || '').trim().split(/\n|\r/);

    return textArrs.map((text, ind) => (
      <Fragment key={ind}>
        {text}
        {ind !== textArrs.length - 1 && <br />}
      </Fragment>
    ));
  };

  const toggleShowMore = () => {
    setShowMore(prevState => !prevState);
  };

  return (
    <Box key={note.id} sx={styles.noteItem}>
      <Box sx={styles.noteHeader}>
        <Box sx={styles.categoryWrapper}>
          <CategoryIconBox
            category={note.category}
            color={secondary.main}
            backgroundColor={secondary.transparent}
            containerSize='40px'
            iconSize='32px'
            borderRadius='8px'
          />
          <Box>
            <Typography variant='body2'>{note.category?.name}</Typography>
            <Typography variant='body3'>{(note.createdAt && moment(note.createdAt).fromNow()) || 'Unknown'}</Typography>
          </Box>
        </Box>
        <DocumentActions {...{ note, general }} />
      </Box>
      <Box sx={styles.noteBody}>
        <Typography variant='h6'>{note.title}</Typography>
        {showMore ? (
          <Typography variant='body1'> {splitByN(note.body)} </Typography>
        ) : (
          <Typography variant='body1'>
            {splitByN((note.body || '').slice(0, 200)).slice(0, 1)}

            {((note.body || '').length > 200 || splitByN(note.body || '').length > 3) && (
              <span>
                ... &nbsp;
                <span className='see-more-text' onClick={toggleShowMore}>
                  See more
                </span>
              </span>
            )}
          </Typography>
        )}
      </Box>
      {note.files.length > 0 && (
        <div className='attached-docs'>
          {note.files.map((file, index) => (
            <AttachedDocument
              key={index}
              file={file}
              openLightbox={openLightbox}
              handleDownload={handleDownload}
              hideName
            />
          ))}
        </div>
      )}
    </Box>
  );
};
