import colors from '@crm/assets/theme/base/colors';

export default () => ({
  container: {
    width: '100%',
    maxWidth: '1160px',
    margin: 'auto',
    px: 2,
    pb: 8
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: {
      xs: 'column',
      lg: 'row'
    },
    gap: 2,
    mb: 2
  },
  untilDate: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      lg: 'row'
    },
    gap: 1,
    borderRadius: '8px',
    py: 0.5,
    pl: 1,
    pr: 0.5,
    backgroundColor: colors.background.default,
    whiteSpace: 'nowrap'
  },
  timerWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    minWidth: '240px',
    borderRadius: '4px',
    py: 0.5,
    px: 0.5,
    backgroundColor: '#fff'
  },
  categoryWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    flexWrap: 'wrap'
  },
  categoryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    borderRadius: '4px',
    pl: 0.5,
    pr: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    whiteSpace: 'nowrap'
  },
  contentWrapper: {
    width: {
      xs: '100%',
      lg: 'calc(100% - 338px - 48px)'
    }
  },
  sidebar: {
    width: {
      xs: '100%',
      lg: '338px'
    },
    minWidth: {
      xs: '100%',
      lg: '338px'
    }
  }
});
