import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { categoryApi } from '@packs/apis/category';
import api from '@packs/apis/currentUser';
import { Loader } from '@packs/components';
import { BreadcrumbsMUI } from '@packs/components/MaterialUi/BreadcrumbsMUI/BreadcrumbsMUI';
import { VerificationForm } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';
import useContainerStyles from '@packs/styles/Global/container';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { ThankYouModal } from './components/ThankYouModal';
import { Form } from './components';
import useStyles from './styles';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import omit from 'lodash-es/omit';
import size from 'lodash-es/size';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const RequestQuoteModal = observer(({ isOpen, hideModal, setActiveStep, activeStep, detailsIsLoading }) => {
  const styles = useStyles();
  const containerStyles = useContainerStyles();
  const { companiesStore, shoppingCartStore, categoryStore, leaderboardStore, userStore } = useStore();
  const { currentCategory } = useCategories();
  const { currentPolicy } = useDetails();
  const { DASHBOARD_MY_RESULTS_PATH, LEADERBOARD_PATH } = usePath();
  const { isMobile } = useWindowSize();
  const { currentUser } = useUserSelectors();

  const [loading, setLoading] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [formData, setFormData] = useState<{ data: { phoneNumber?: string }; businesses: Record<string, string>[] }>({
    data: {},
    businesses: []
  });

  const shoppingCart = shoppingCartStore.shoppingCart;
  const shoppingCartItemsList: any[] = shoppingCart?.cartItems || [];
  const isBusinessesAdded = size(leaderboardStore.requestBusinesses) > 0;

  const addToShoppingCart = companiesIds => {
    const opts = {
      categoryId: +currentCategory?.id,
      companyIds: companiesIds,
      policyId: currentPolicy?.id ? +currentPolicy?.id : null
    };

    shoppingCartStore.createShoppingCartItems(opts).catch(() => {});
  };

  const subscribeCategory = () => {
    categoryApi.categorySubscribe({ categoryId: currentCategory.id, selected: true });
    categoryStore.markCategorySelect({ id: currentCategory.id, selected: true });
  };

  const submitRequestQuote = (data = {}, businesses = []) => {
    const newData: { categoryId: number; renewalDetailId?: number | null } = {
      ...formData.data,
      ...data,
      categoryId: currentCategory.id
    };
    const newBusinesses = [...formData.businesses, ...businesses];

    setLoading(true);
    companiesStore.createQuote({ input: omit(newData, ['attached']) }).then(res => {
      if (res && isEmpty(res.errors)) {
        setSubmittedForm(true);
        userStore.updateCurrentUser({});

        const companiesIds = newBusinesses
          .filter(company => !shoppingCartItemsList.includes(item => item?.company?.id === company?.id))
          .map(company => +company.id);

        if (companiesIds?.length) {
          addToShoppingCart(companiesIds);
        }

        leaderboardStore.resetRequestBusinesses();
        subscribeCategory();
      }
      setLoading(false);
    });
  };

  const submitModal = async (data, businesses) => {
    setLoading(true);
    setFormData({ data, businesses });

    const phoneVerification =
      data?.phoneNumber &&
      get(currentUser, 'verifiedNumbers', []).some(item => item === data.phoneNumber.replace(/\s+/g, ''));

    if (phoneVerification) {
      submitRequestQuote(data, businesses);
    } else {
      const resp = await api.phoneVerify({ phoneNumber: data?.phoneNumber.replace(/\s+/g, '') });
      if (resp?.errors) {
        toastr.error(resp?.errors);
        setLoading(false);
      } else {
        setShowVerificationForm(true);
        setLoading(false);
      }
    }
  };

  const handleSubmitVerification = () => {
    setShowVerificationForm(false);
    submitRequestQuote();
  };

  const closeVerification = () => {
    setShowVerificationForm(false);
  };

  useEffect(() => {
    setSubmittedForm(false);

    return () => {
      leaderboardStore.resetRequestBusinesses();
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          ...containerStyles.container,
          pt: {
            xs: isBusinessesAdded ? '164px' : '0',
            md: isBusinessesAdded ? '100px' : '0'
          },
          transition: 'padding .3s'
        }}
      >
        <Box sx={styles.breadcrumbsWrapper}>
          <BreadcrumbsMUI
            routes={[{ to: DASHBOARD_MY_RESULTS_PATH, label: currentCategory.name }]}
            currentPage='Request a quote!'
          />
          {/* {!isMobile && ( */}
          <Box sx={styles.btnWrapper}>
            <IconButton sx={!isMobile ? styles.btn : {}} component={Link} to={LEADERBOARD_PATH}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          {/* )} */}
        </Box>
      </Box>

      <Box sx={{ ...containerStyles.container, ...styles.wrapper }}>
        <div className='request-quote-wrapper d-flex'>
          <div
            className={cx('request-quote-modal-flow', currentCategory?.root?.enName || '', {
              'full-width': showVerificationForm || submittedForm
            })}
          >
            {loading && <Loader />}
            <div>
              {showVerificationForm && (
                <VerificationForm
                  handleSubmitVerification={handleSubmitVerification}
                  closeVerification={closeVerification}
                  phoneNumber={formData?.data?.phoneNumber || ''}
                />
              )}
              <div className={cx({ 'd-none': showVerificationForm })}>
                {submittedForm ? (
                  <ThankYouModal phoneNumber={formData?.data?.phoneNumber || ''} />
                ) : (
                  <div className={cx({ 'd-none': loading })}>
                    <Form
                      category={currentCategory}
                      {...{
                        submitModal,
                        isOpen,
                        hideModal,
                        showVerificationForm,
                        submittedForm,
                        setActiveStep,
                        activeStep,
                        detailsIsLoading
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
});
