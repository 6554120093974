import React, { FC, ReactNode } from 'react';

import { Box, Typography, TypographyProps } from '@mui/material';
import { SxProps } from '@mui/system';
import colors from '@packs/assets/theme/base/colors';
import { BreadcrumbRoute, BreadcrumbsMUI } from '@packs/components/MaterialUi/BreadcrumbsMUI/BreadcrumbsMUI';
import useContainerStyles from '@packs/styles/Global/container';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

const { white } = colors;

type Props = {
  breadcrumbsRoutes?: BreadcrumbRoute[];
  currentPage?: string;
  title: string;
  subTitle?: string;
  homeIsLanding?: boolean;
  sx?: SxProps;
  children?: ReactNode;
};

export const PageTitleWrapper: FC<Props> = ({
  breadcrumbsRoutes,
  currentPage,
  title,
  subTitle,
  homeIsLanding = false,
  sx = {},
  children
}) => {
  const { isMobile } = useWindowSize();
  const styles = useStyles();
  const stylesContainer = useContainerStyles();

  return (
    <Box sx={{ ...styles.wrapper, ...sx }}>
      <Box sx={styles.innerWrapper}>
        <Box sx={{ ...stylesContainer.container, ...styles.container }}>
          <BreadcrumbsMUI
            routes={breadcrumbsRoutes}
            currentPage={currentPage}
            homeIsLanding={homeIsLanding}
            color={white.main}
          />
          <Box sx={styles.contentWrapper}>
            <Box>
              <Typography variant={isMobile ? 'h4' : 'h5'} sx={styles.title}>
                {title}
              </Typography>
              {subTitle && (
                <Box sx={{ mt: 1 }}>
                  <Typography
                    variant={(isMobile ? 'body3' : 'subtitle1') as TypographyProps['variant']}
                    sx={styles.subTitle}
                  >
                    {subTitle}
                  </Typography>
                </Box>
              )}
            </Box>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
