import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { BillBuddy, BillBuddyButton, GetMatchedAlert, PolicyAlert } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { isWikiTableCategory } from '@packs/models/category/functions';
import { INSURANCE_ORDER_OPTIONS, mostPopularDefaultCategories, ORDER_OPTIONS } from '@packs/models/company/constants';
import { useStore } from '@packs/stores';
import useContainerStyles from '@packs/styles/Global/container';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { getWindow } from '@shared/lib/utils';

import { LeaderboardNavigation } from './components/LeaderboardNavigation/LeaderboardNavigation';

import { observer } from 'mobx-react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { Outlet, useNavigate } from 'react-router-dom';

const DashboardLayout = observer(() => {
  const stylesContainer = useContainerStyles();
  const { policyId } = queryString.parse(location?.search);
  const { isMobile } = useWindowSize();
  const { currentUser } = useUserSelectors();
  const navigate = useNavigate();
  const {
    currentCategory,
    policiesListStore,
    userStore,
    companiesStore,
    leaderboardStore,
    policyStore,
    countryCode,
    currentCountry
  } = useStore();
  const { DASHBOARD_ROOT_LANDING_PATH_FN } = usePath();

  const category = currentCategory || {};

  const [isLoading, setIsLoading] = useState(true);
  const [isCategoriesLoaded, setCategoriesLoaded] = useState({});

  useEffect(() => {
    setIsLoading(true);
    if (getWindow().scrollY !== 0) {
      getWindow().scroll(0, 0);
    }
    if (category.id) {
      userStore.setRegisterEvent(`/${countryCode}/categories/${category.root.code}/${category.slag}/leaderboard`);
      leaderboardStore.resetRequestBusinesses();

      policiesListStore
        .loadPolicyData({
          categoryId: category?.id,
          renewalDetailId: policyId ? +policyId : undefined
        })
        .then(res => {
          const isPersonalized = hasPolicyCriteria(res?.renewalDetail);

          if (isPersonalized) {
            let companyType =
              category.activePersonalisedFilter || (isWikiTableCategory(currentCategory) ? 'all' : 'all_types');
            if (category.activePersonalisedFilter === 'source') {
              companyType = 'all_types';
            }

            if (!category.activePersonalisedFilter && category?.root?.code === 'subscriptions') {
              companyType = 'provider';
            }
            const locality = res?.renewalDetail?.criteriaQuestions?.locality;
            companiesStore.setFilters({
              companyType,
              city: res?.renewalDetail?.criteriaQuestions?.location,
              locality: locality ? { value: locality, label: locality } : null
            });
          }
          const isWikiTable = isWikiTableCategory(currentCategory);
          const orderList = isWikiTable ? ORDER_OPTIONS : INSURANCE_ORDER_OPTIONS;
          let orderColumn = orderList[isWikiTable ? 0 : 1].value;
          if (mostPopularDefaultCategories.some(item => item === currentCategory?.code)) {
            orderColumn = 'most_popular';
          }

          companiesStore.loadRequestQuotesCount({
            categoryId: category?.id,
            criteriaQuestions: policyStore?.searchCriteria,
            city: res?.renewalDetail?.criteriaQuestions?.location,
            orderColumn
          });

          setCategoriesLoaded({ [category.id]: true });
          setIsLoading(false);
        })
        .catch(() => {
          setCategoriesLoaded({ [category.id]: true });
          setIsLoading(false);
        });

      if (leaderboardStore.isBillBuddyOpen) {
        leaderboardStore.showBillBuddy(false);
      }

      leaderboardStore.fetchLeaderboardCounter({ categoryId: category.id });
      companiesStore.loadGlobalRequestQuotesCount(category.id);
    }
  }, [category.id]);

  useEffect(() => {
    if (!currentUser.id && category?.root?.code === 'energy') {
      navigate(
        DASHBOARD_ROOT_LANDING_PATH_FN(category).concat(category.code ? `?initialCategory=${category.code}` : '')
      );
    }
  }, [category?.root?.code, currentUser.id]);

  if (!category?.id) {
    return null;
  }

  const dashboardOutletContext = {
    isDashboardLoading: isLoading,
    isCurrentCategoryLoaded: isCategoriesLoaded[category.id]
  };

  const title = ['broadband', 'energy', 'mobile'].includes(currentCategory.root.code)
    ? `Cowered by the ${currentCategory.root.code} community of ${currentCountry.name}!`
    : `Powered by the ${currentCategory.name} of ${currentCountry.name}!`;

  return (
    <>
      <Helmet>
        <title>{currentCategory.name}</title>
        <meta name='description' content={title} />
        <meta name='og:description' content={title} />
      </Helmet>
      <Box>
        <LeaderboardNavigation isLoading={isLoading} />

        {!isLoading && (
          <Box
            sx={{
              ...stylesContainer.container,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 4,
              mb: 2,
              '&:empty': { display: 'none' }
            }}
          >
            <PolicyAlert />
            <GetMatchedAlert />
          </Box>
        )}

        <div className='dashboard-container'>
          <Outlet context={dashboardOutletContext} />
        </div>
        <BillBuddy />
        {!isMobile && <BillBuddyButton />}
      </Box>
    </>
  );
});

export default DashboardLayout;
