import colors from '@packs/assets/theme/base/colors';

const { text, borderColor } = colors;

export default () => ({
  searchFilterWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 2,
    mb: 2
  },
  actionSectionWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    width: { xs: '100%', lg: 'auto' }
  },
  actionSection: {
    width: '100%',
    maxWidth: '398px',
    mx: { xs: 'auto', lg: '0' }
  },
  resultsInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'space-between',
      md: 'flex-start'
    }
  },
  actionPanelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 2,
    mb: 2
  },
  container: {
    display: 'flex',
    alignItems: {
      lg: 'center'
    },
    justifyContent: 'space-between',
    flexDirection: {
      xs: 'column',
      lg: 'row'
    },
    gap: 1
  },
  resultsTitle: {
    display: 'flex',
    alignItems: 'center',
    minHeight: {
      xs: '20px',
      lg: '40px'
    },
    whiteSpace: 'nowrap'
  },
  switcherWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  },
  mobileSortTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5
  },
  switcherLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    cursor: 'pointer'
  },
  createButtonWrapper: {
    maxWidth: {
      lg: '218px'
    }
  },
  appliedFiltersTitleWrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    alignItems: {
      xs: 'center',
      md: 'flex-end'
    },
    justifyContent: 'space-between',
    gap: 2,
    mt: 2
  },
  appliedFiltersTitle: {
    color: text.secondary,
    mr: 1
  },
  divider: {
    alignSelf: 'center',
    height: '20px',
    width: '4px',
    mr: 1
  },
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      lg: 'row'
    },
    gap: 1,
    flexGrow: 1
  },
  searchButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '4px',
    border: `1px solid ${borderColor.secondary}`,
    cursor: 'pointer'
  }
});
