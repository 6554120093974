import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { ReviewsList } from '@packs/components/UI/Business/ReviewsList';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useProfileContext } from '@packs/layouts/ProfileLayout/context';
import { ProfileFilters } from '@packs/layouts/ProfileLayout/ProfileFilters';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';

import _size from 'lodash-es/size';
import { observer } from 'mobx-react';
import Select from 'react-select';

const allYears = { value: 'all', label: 'All years' };

export const ProfileMyReviews = observer(() => {
  const context = useProfileContext();
  const { insightsCompanyStore, policiesListStore, currentCategory } = useStore();
  const { flattenCategories } = useCategories();
  const isLoading = policiesListStore.isLoading;

  const yearsList = useMemo(() => {
    const yearRangeCount =
      moment().format('YYYY') - (insightsCompanyStore?.createdAtFirstYear || moment().format('YYYY'));
    if (yearRangeCount > 0) {
      return [
        allYears,
        ...[...Array(yearRangeCount + 1)].map((_, index) => {
          return {
            value: moment().format('YYYY') - index,
            label: moment().format('YYYY') - index
          };
        })
      ];
    }

    return [allYears];
  }, [insightsCompanyStore.createdAtFirstYear]);

  const [renewalDetailsFormDataLoaded, setRenewalDetailsFormDataLoaded] = useState(false);
  const [selectedYear, setSelectedYear] = useState(yearsList[0]);
  const { currentUser } = useUserSelectors();
  const insightsCompany = insightsCompanyStore.object;

  const filter = useMemo(() => {
    return {
      categories: context?.categoriesFilter || [],
      years: selectedYear.value === 'all' ? null : [selectedYear.value]
    };
  }, [context?.categoriesFilter, selectedYear]);

  const handleChangeYear = data => {
    setSelectedYear(data);
  };

  useEffect(() => {
    if (!currentCategory) {
      return;
    }

    if (!renewalDetailsFormDataLoaded && _size(flattenCategories) > 0) {
      if (currentCategory.code === 'car_insurance') {
        setRenewalDetailsFormDataLoaded(true);
        policiesListStore.loadPolicyData({
          categoryId: currentCategory.id
        });
      }
    }
  }, [currentCategory?.code]);

  return (
    <>
      <div className='profile-wrapper'>
        <div className='profile-page-content'>
          <ReviewsList
            emptyTitle='You have not voted for any business yet!'
            emptyDescription='Your votes will appear here…'
            isProfile
            withInsight
            category={currentCategory}
            {...{
              isLoading,
              filter,
              company: insightsCompany,
              currentUser
            }}
          />
        </div>
        <div className='profile-filters'>
          <ProfileFilters />
          <div className='fs-11 fw-700 tt-uc mb-16 mt-24'>Year:</div>
          <Select
            classNamePrefix='Select'
            className='select-ui gray-bg'
            placeholder='Select'
            options={yearsList}
            value={selectedYear}
            isSearchable={false}
            onChange={handleChangeYear}
          />
          {/* <div className='fs-11 fw-700 tt-uc mb-16 mt-24'>Insights Type:</div>*/}
          {/* <RadioButtonGroup*/}
          {/*  name='feedbackType'*/}
          {/*  className='flex-column align-items-start'*/}
          {/*  options={ feedbackTypesList }*/}
          {/*  value={ feedbackType }*/}
          {/*  onChange={ onChangeFeedbackType }*/}
          {/*/ >*/}
        </div>
      </div>
    </>
  );
});
