export const OFFERS_PER_PAGE = 9;

export const offersTabs = [
  {
    value: 'all',
    label: 'All offers'
  },
  {
    value: 'local',
    label: 'Local offers'
  }
];
