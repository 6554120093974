export default () => ({
  pageWrapper: {
    width: '100%',
    maxWidth: '1160px',
    mx: 'auto',
    px: 2,
    py: {
      xs: 4,
      lg: 6
    }
  },
  pageContainer: {}
});
