import React from 'react';

export const LegalServices = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <g>
          <path
            d='M9.9112 18.9635C9.9112 18.5105 9.54397 18.1434 9.09107 18.1434H2.89733C2.44442 18.1434 2.0772 18.5106 2.0772 18.9635C2.0772 19.1063 2.11374 19.2404 2.17793 19.3573H1V21.0578H10.9884V19.3573H9.81047C9.87462 19.2404 9.9112 19.1063 9.9112 18.9635Z'
            fill='white'
          />
          <path
            d='M8.85724 4.9401L14.0906 8.26332C14.4975 8.47071 15.0031 8.33827 15.2529 7.94487L15.504 7.54955C15.7693 7.13171 15.6457 6.57799 15.2279 6.31265L10.139 3.08115C9.72124 2.81586 9.16752 2.93946 8.90218 3.35729L8.65105 3.75266C8.40135 4.14602 8.4964 4.65994 8.85724 4.9401Z'
            fill='white'
          />
          <path
            d='M22.9976 17.6361L22.9939 17.557L22.9874 17.4823C22.9841 17.4313 22.9769 17.3859 22.9707 17.3392C22.9456 17.1526 22.9027 16.9939 22.8504 16.8502C22.7442 16.565 22.5941 16.349 22.4097 16.1871C22.3638 16.1463 22.3147 16.1106 22.2659 16.0742L22.1198 15.9651C22.0228 15.8917 21.9238 15.8213 21.8256 15.75C21.7275 15.6783 21.6282 15.6084 21.5288 15.5388C21.4297 15.4686 21.3302 15.3992 21.2299 15.331C21.0303 15.193 20.8286 15.0585 20.6264 14.9244L20.3221 14.725L20.0162 14.5282C19.8116 14.3982 19.6067 14.2685 19.4004 14.1411C19.1944 14.0131 18.9876 13.8863 18.7797 13.7615L18.1552 13.388L17.5267 13.0208L16.8946 12.6592L16.2595 12.3024L15.6213 11.9503L14.9801 11.6031C14.7656 11.4885 14.5513 11.3736 14.3354 11.2612C14.12 11.1483 13.9047 11.0347 13.6878 10.9241L13.4913 11.2335L12.501 10.7668L13.608 9.02352L8.37469 5.70035L5.65237 9.98744L10.8857 13.3106L11.9926 11.5673L12.8363 12.2652L12.6398 12.5747C12.8323 12.724 13.0265 12.8703 13.2204 13.0174C13.4139 13.165 13.6089 13.3101 13.8039 13.4555L14.3908 13.8881L14.981 14.3159L15.5739 14.7391L16.1701 15.1573L16.7696 15.5701L17.3733 15.9764C17.5748 16.1115 17.7773 16.2448 17.9809 16.3768C18.184 16.5093 18.3884 16.6395 18.5932 16.7695L18.9013 16.9627L19.2111 17.1532C19.4185 17.2791 19.6261 17.4045 19.8358 17.5265C19.9402 17.5883 20.0453 17.6489 20.151 17.7087C20.2563 17.769 20.3618 17.8292 20.4684 17.8874C20.5748 17.946 20.6806 18.0056 20.7882 18.0621L20.9491 18.148C21.0028 18.1766 21.0561 18.2059 21.1125 18.23C21.3374 18.328 21.5967 18.3721 21.9 18.3468C22.0523 18.3332 22.2142 18.3044 22.3938 18.2478C22.4387 18.2336 22.4827 18.2207 22.5305 18.2021L22.6008 18.1762L22.674 18.1458C22.7716 18.1052 22.8761 18.0537 22.9893 17.9885C23.0002 17.8582 23.0023 17.7418 22.9976 17.6361Z'
            fill='white'
          />
          <path
            d='M4.0329 12.6969L9.12171 15.9283C9.53955 16.1936 10.0933 16.07 10.3586 15.6522L10.6096 15.2568C10.8594 14.8633 10.7643 14.3495 10.4035 14.0694L5.17022 10.7462C4.76324 10.5388 4.25763 10.6712 4.00784 11.0647L3.7568 11.46C3.49142 11.8778 3.61506 12.4316 4.0329 12.6969Z'
            fill='white'
          />
        </g>
      </g>
    </svg>
  );
};
