import React, { useMemo } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { avatarPlaceholder, stringToColor } from '@shared/helpers/additionalHelpers';
import { postTypes } from '@shared/lib/constants';

import useStyles from './styles';

export const QuestionsCreteButton = ({
  category = null,
  companyId = null,
  myProfile = null,
  selectedType = [],
  postType,
  initialTab,
  postTypeFilter,
  withOutCategoryMode = false,
  isSingleButton = false,
  size = 'medium',
  filters = {}
}) => {
  const styles = useStyles();
  const { toggleCreateQuestionModal } = useModalsContext();
  const { currentUser } = useUserSelectors();

  const isTips = postType === 'tip';
  const userCompanyId = currentUser?.company?.id;
  const avatarUrl = currentUser?.company?.avatarUrl || currentUser?.avatarUrl || '';
  const displayName = currentUser?.company?.name || currentUser?.displayName || '';

  const onShowModal = () => {
    toggleCreateQuestionModal({
      postType,
      category,
      myProfile,
      initialTab,
      postTypeFilter,
      withOutCategoryMode,
      companyId: companyId || userCompanyId || null,
      filters
    });
  };

  const postTypePlaceholder = useMemo(() => {
    if (isTips) {
      return 'Create a great tip!';
    }

    if (selectedType.value === 'question') {
      return 'Ask a Question!';
    }

    if (selectedType.value === 'recommendation') {
      return 'Ask for a Recommendation!';
    }

    if (selectedType.value !== 'all') {
      return `Create a ${postTypes.find(({ value }) => selectedType.value === value)?.label || ''}!`;
    }

    return 'What do you want to ask?';
  }, [postType, selectedType]);

  const postTypeButton = useMemo(() => {
    if (isTips) {
      return 'Create a tip!';
    }

    if (selectedType.value === 'question') {
      return 'Ask a Question';
    }

    if (selectedType.value === 'recommendation') {
      return 'Ask for a Recommendation';
    }

    if (selectedType.value !== 'all') {
      return `Add ${postTypes.find(({ value }) => selectedType.value === value)?.label || ''}`;
    }

    return 'Ask a Question';
  }, [postType, selectedType]);

  if (isSingleButton) {
    return (
      <Button variant='contained' color='primary' fullWidth size={size} onClick={onShowModal}>
        {postTypeButton}
      </Button>
    );
  }

  return (
    <Box sx={styles.wrapper} onClick={onShowModal}>
      <Avatar
        sx={{ width: 40, height: 40, backgroundColor: stringToColor(displayName) }}
        variant='rounded'
        src={avatarUrl}
      >
        {avatarPlaceholder(displayName)}
      </Avatar>
      <Box sx={styles.textAreaWrapper}>
        <Typography variant='body3'>{postTypePlaceholder}</Typography>
      </Box>
      <Button variant='contained' color='primary' size='smallToMedium' sx={styles.button}>
        {postTypeButton}
      </Button>
    </Box>
  );
};
