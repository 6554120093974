// Array.flat
Object.defineProperty(Array.prototype, 'flat', {
  value(depth = 1) {
    return this.reduce(function (flat, toFlatten) {
      return flat.concat(Array.isArray(toFlatten) && depth - 1 ? toFlatten.flat(depth - 1) : toFlatten);
    }, []);
  }
});

// Array.eachSlice
Object.defineProperty(Array.prototype, 'eachSlice', {
  value(size, callback = null) {
    this.arr = [];
    for (let i = 0, l = this.length; i < l; i += size) {
      const sliced = this.slice(i, i + size);
      if (callback) callback(sliced);
      this.arr.push(sliced);
    }
    return this.arr;
  }
});

// String.queryObj
Object.defineProperty(String.prototype, 'queryObj', {
  value() {
    return this.slice(1)
      .split('&')
      .map(p => p.split('='))
      .reduce((obj, pair) => {
        const [key, value] = pair.map(decodeURIComponent);
        return { ...obj, [key]: value };
      }, {});
  }
});
