import React, { useState } from 'react';

import {
  KeyboardArrowDownRounded as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon
} from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { useStore } from '@packs/stores';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { rootCategoriesLanding } from '@shared/constants/categories';
import { ICONS } from '@shared/helpers/categoriesIconsHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import _size from 'lodash-es/size';
import { MacScrollbar } from 'mac-scrollbar';
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom';

const { white, dark, secondary, text } = colors;

export const CategoryItem = ({ category }) => {
  const { isMobile } = useWindowSize();
  const { currentUser } = useUserSelectors();
  const { DASHBOARD_LANDING_PATH_FN, DASHBOARD_ROOT_LANDING_PATH_FN, DASHBOARD_MY_RESULTS_PATH_FN } = usePath();
  const { categoryStore, companiesStore } = useStore();
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);

  const categoryIsRootLanding = rootCategoriesLanding.some(item => item === category.code);

  const categoryUrl = category => {
    let url = DASHBOARD_LANDING_PATH_FN(category);
    if (categoryIsRootLanding) {
      url = DASHBOARD_ROOT_LANDING_PATH_FN(category);
    } else if (categoryHavePolicy(category, categoryStore.categoriesWithPolicy, currentUser)) {
      url = DASHBOARD_MY_RESULTS_PATH_FN(category);
    }

    return url;
  };

  const handleOnMouseEnter = () => {
    setOnMouseEnter(true);
  };

  const handleOnMouseLeave = () => {
    setOnMouseEnter(false);
  };

  const onToggleCategory = () => {
    setOpenCategory(prev => !prev);
  };

  const onClickCategory = category => () => {
    if (!categoryIsRootLanding) {
      categoryStore.setCurrentCategory(category);
      companiesStore.clearFilters();
    }
  };

  const subList = () => {
    return (
      <Box>
        <MacScrollbar
          style={{ flex: 1, ...(isMobile ? { height: 'auto', maxHeight: 228 } : { height: 276 }) }}
          trackStyle={horizontal => ({
            [horizontal ? 'height' : 'width']: 0,
            right: 2,
            border: 0
          })}
          thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
        >
          <Stack direction='column' spacing={1}>
            <>
              {category?.leafChildren.map(cat => (
                <Stack
                  key={cat.id}
                  component={Link}
                  to={categoryUrl(cat)}
                  onClick={onClickCategory(cat)}
                  color='inherit'
                  direction='row'
                  alignItems='center'
                  spacing={1}
                  sx={{
                    py: 0.5,
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  {isMobile ? (
                    <CategoryIconBox category={cat} backgroundColor='rgba(1, 103, 205, 0.08)' color={secondary.main} />
                  ) : (
                    <CategoryIconBox
                      category={cat}
                      backgroundColor={onMouseEnter ? 'rgba(1, 103, 205, 0.08)' : 'rgba(255, 255, 255, 0.16)'}
                      color={onMouseEnter ? secondary.main : white.main}
                    />
                  )}

                  <Typography variant='body1' color={isMobile ? 'text.secondary' : 'dark'}>
                    {cat.name}
                  </Typography>
                </Stack>
              ))}
            </>
          </Stack>
        </MacScrollbar>
      </Box>
    );
  };

  if (isMobile) {
    return (
      <Box
        key={category.id}
        sx={{
          mx: 'auto',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid rgba(255, 255, 255, 0.16)',
          backgroundColor: openCategory ? white.main : white.tonal,
          color: openCategory ? dark.main : white.main,
          '& .root-landing-button': {
            display: openCategory ? 'block' : 'none'
          }
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          py={1}
          pr={1}
          onClick={onToggleCategory}
        >
          <Stack direction='row' alignItems='center' spacing={1} px={1}>
            <IconBox
              backgroundColor={openCategory ? 'rgba(1, 103, 205, 0.08)' : white.tonal}
              color={openCategory ? colors.secondary.main : white.main}
              icon={ICONS[category.enName]}
              containerSize='40px'
              borderRadius='8px'
              rootIcon
            />
            <Stack direction='column'>
              <Typography variant='body2'>{category.name}</Typography>
              <Typography variant='caption' sx={{ opacity: '0.6' }}>
                {_size(category?.leafChildren)} subcategories
              </Typography>
            </Stack>
          </Stack>
          {openCategory ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </Stack>
        <AnimateHeight duration={250} height={openCategory ? 'auto' : 0}>
          <Box px={1} py={1}>
            {subList()}
          </Box>
        </AnimateHeight>
      </Box>
    );
  }

  return (
    <Box
      key={category.id}
      sx={{
        px: 2,
        width: '330px',
        height: '420px'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '8px',
          p: 2,
          border: '1px solid rgba(255, 255, 255, 0.16)',
          backgroundColor: 'rgba(255, 255, 255, 0.20)',
          color: white.main,
          transition: 'all .2s',
          '&:hover': {
            color: dark.main,
            backgroundColor: white.main,
            '& .disabled-categories': {
              color: text.main
            },
            '& .root-landing-button': {
              display: 'block'
            }
          }
        }}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center' mb={4}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '32px',
              height: '32px',
              borderRadius: '8px',
              background: onMouseEnter ? 'rgba(1, 103, 205, 0.08)' : 'rgba(255, 255, 255, 0.16)',
              '& svg path': {
                fill: onMouseEnter ? secondary.main : white.main
              }
            }}
          >
            {ICONS[category.enName]}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '32px',
              px: 1,
              borderRadius: '8px',
              border: `1px solid ${onMouseEnter ? 'rgba(0, 0, 0, 0.16)' : 'rgba(255, 255, 255, 0.16)'}}`
            }}
          >
            <Typography variant='caption' sx={{ opacity: '0.6', fontWeight: 700 }}>
              {_size(category?.leafChildren)} subcategories
            </Typography>
          </Box>
        </Stack>
        <Typography variant='h6' sx={{ color: 'inherit !important' }} mb={1}>
          {category.name}
        </Typography>
        {subList()}
      </Box>
    </Box>
  );
};
