import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Switcher } from '@packs/components';
import { autoQuoteTooltip, autoQuoteTooltipNoPolicy } from '@packs/lib/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

import _clone from 'lodash-es/clone';

export const RenewalAutoQuote = ({
  item,
  autoQuoteEnabled,
  renewalDetail,
  disabledCategory,
  toggleReminderFormModal,
  category,
  handleCreateReminderCallback,
  toggleIsAutoQuote,
  onClickEditReminder
}) => {
  const { smartReminder } = renewalDetail;
  const quoteRequest = smartReminder?.quoteRequest;
  const isAutoQuote = smartReminder?.isAutoQuote;

  const handleOnChange = () => {
    if (!disabledCategory(item?.root?.code) && !quoteRequest) {
      if (renewalDetail?.smartReminder?.id) {
        onClickEditReminder(_clone(smartReminder), renewalDetail, item, category);
      } else {
        toggleReminderFormModal({
          renewalDetail,
          category,
          disabledCategory: true,
          shouldShowDetails: true,
          onSuccess: data => handleCreateReminderCallback(data, category)
        });
      }
    } else {
      toggleIsAutoQuote({
        userRenewalId: item?.id,
        policyId: renewalDetail?.id,
        isAutoQuote,
        quoteRequest,
        smartReminderId: smartReminder?.id,
        category
      });
    }
  };

  return (
    <TooltipMUI
      title={
        <Typography variant='caption'>
          {hasPolicyCriteria(renewalDetail) ? autoQuoteTooltip : autoQuoteTooltipNoPolicy}
        </Typography>
      }
      arrow
      placement='top'
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Switcher
          value={isAutoQuote}
          onChange={handleOnChange}
          disabled={disabledCategory(item?.root?.code) || autoQuoteEnabled || !hasPolicyCriteria(renewalDetail)}
        />
        <Typography variant='body3' sx={{ whiteSpace: 'nowrap' }}>
          Auto. quote
        </Typography>
      </Box>
    </TooltipMUI>
  );
};
