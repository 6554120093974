import React, { useState } from 'react';
import cx from 'classnames';

import {
  KeyboardArrowLeftRounded as KeyboardArrowLeftRoundedIcon,
  KeyboardArrowRightRounded as KeyboardArrowRightRoundedIcon
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { CompanyItem } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/CompanyItem';
import { LeaderboardListSkeleton } from '@packs/components/MaterialUi/Leaderboard/LeaderboardListSkeleton/LeaderboardListSkeleton';

import useStyles from './styles';

import size from 'lodash-es/size';
import Slider from 'react-slick';

export const SliderList = ({ title, list, isLoading, onChangeFilter, filterType, btnText }) => {
  const styles = useStyles();

  const [activeSlide, setActiveSlide] = useState(0);

  const SampleNextArrow = props => {
    const { className, onClick } = props;
    return (
      <Box className={cx(className, 'arrow')} sx={{ ...styles.sliderArrow, ...styles.nextArrow }} onClick={onClick}>
        <KeyboardArrowRightRoundedIcon />
      </Box>
    );
  };

  const SamplePrevArrow = props => {
    const { className, onClick } = props;
    return (
      <Box className={cx(className, 'arrow')} sx={{ ...styles.sliderArrow, ...styles.prevArrow }} onClick={onClick}>
        <KeyboardArrowLeftRoundedIcon />
      </Box>
    );
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: list.length > 1,
    beforeChange: (current, next) => setActiveSlide(next),
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  if (!isLoading && size(list) === 0) {
    return null;
  }

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.titleWrapper}>
        <Typography variant='h6'>{title}</Typography>
        {list.length > 1 && <Typography variant='body4'>{`${activeSlide + 1}/${list.length}`}</Typography>}
      </Box>
      {isLoading ? (
        <Box sx={{ pt: 1 }}>
          <LeaderboardListSkeleton count={1} />
        </Box>
      ) : (
        <Slider {...settings}>
          {list.map(company => (
            <Box key={company.id} sx={styles.item}>
              <CompanyItem company={company} />
            </Box>
          ))}
        </Slider>
      )}
      <Box sx={styles.btnContainer}>
        <Button variant='filledTonal' size='smallToMedium' color='primary' onClick={() => onChangeFilter(filterType)}>
          {btnText}
        </Button>
      </Box>
    </Box>
  );
};
