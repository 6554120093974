export default () => ({
  companyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
    px: {
      xs: 1,
      lg: 3
    },
    mb: 4
  },
  clipboardWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    borderRadius: '4px',
    p: 2,
    backgroundColor: '#F4F4F4'
  }
});
