export const groupsTypes = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'residential',
    label: 'Residential Groups'
  },
  {
    value: 'association',
    label: 'Associations Groups'
  },
  {
    value: 'custom_campaign',
    label: 'Affinity Groups'
  }
];
