import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { pathGen, usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';

import useStyles from './styles';

import { useNavigate } from 'react-router-dom';

export const RequestQuoteWrapper = ({ children, activeStep = 0, onChangeStep }) => {
  const { currentCategory, countryCode } = useStore();
  const navigate = useNavigate();
  const styles = useStyles();
  const { ADD_DETAILS_PATH } = usePath();

  const steps = [
    { label: 'Personalized', to: ADD_DETAILS_PATH },
    { label: 'Choose business', to: pathGen('REQUEST_QUOTES', { countryCode, category: currentCategory }) },
    { label: 'Request a quote', to: pathGen('REQUEST_QUOTES', { countryCode, category: currentCategory }) }
  ];

  const onClickStep = (to, index) => () => {
    onChangeStep && onChangeStep(index);
    navigate(to);
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.header}>
        <Box sx={styles.stepperWrapper}>
          <Stepper activeStep={activeStep} sx={styles.stepWrapper}>
            {steps.map((item, index) => (
              <Step key={item.label} sx={styles.stepButtonWrapper}>
                <StepButton color='inherit' sx={styles.stepButton} onClick={onClickStep(item.to, index)}>
                  {item.label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>

      <Box sx={styles.contentWrapper} className='request-quote-scroll-container'>
        {children}
      </Box>
    </Box>
  );
};
