import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { PageTitleWrapper } from '@packs/components/MaterialUi/PageTitleWrapper/PageTitleWrapper';
import { ConfirmEmail } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import PlusCircleIcon from '@packs/images/icons/UI/PlusCircleIcon';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useStore } from '@packs/stores/base';
import useContainerStyles from '@packs/styles/Global/container';

import { AssociationCode } from './components/AssociationCode';
import { AssociationsList } from './components/AssociationsList';

import { observer } from 'mobx-react';
import queryString from 'query-string';
import Select from 'react-select';

const mapOptions = arr =>
  arr.map(ob => ({
    value: ob.id,
    label: ob.name,
    object: ob
  }));

const MyAssociations = observer(() => {
  const stylesContainer = useContainerStyles();
  const [associationValue, setAssociationValue] = useState('');
  const { toggleMyAssociationsModalOpen } = useModalsContext();
  const { currentUser } = useUserSelectors();
  const { associationStore, userStore, currentCountry } = useStore();

  const { associationId = null } = queryString.parse(location.search);

  const fetchUserAssociationsCategories = () => {
    associationStore.fetchUserAssociations({}, () =>
      associationStore.fetchUserAssociationsCategories({ clientId: +associationValue?.value || null })
    );
  };

  const onChangeAssociation = value => {
    setAssociationValue(value);
  };

  const onAssociationsModalOpen = () => {
    toggleMyAssociationsModalOpen({
      modalProps: {
        onSuccess: res => {
          if (res) {
            const client = res.client;
            setAssociationValue({
              value: client.id,
              label: client.name,
              object: client
            });
          }
        }
      }
    });
  };

  const onSuccessActivationCode = res => {
    if (res) {
      const client = res.client;
      setAssociationValue({
        value: client.id,
        label: client.name,
        object: client
      });
    }
    associationStore.fetchUserAssociations();
  };

  const partneredAssociation = associationValue?.object?.partnered;
  const userAssociationVerified =
    associationValue?.object?.clientUsers.find(item => +item?.user?.id === +currentUser?.id)?.verified || false;

  useEffect(() => {
    const onSuccess = res => {
      if (res?.length) {
        if (associationId) {
          setAssociationValue(mapOptions(res).find(item => item.value === associationId));
        } else {
          setAssociationValue(mapOptions(res)[0]);
        }
      }
    };
    if (currentCountry.isoA2Code && currentUser.id) {
      associationStore.setParam({ countryCode: currentCountry.isoA2Code, userId: +currentUser.id });
      associationStore.fetchUserAssociations({}, onSuccess);
    }
  }, [currentCountry.isoA2Code, currentUser.id]);

  useEffect(() => {
    if (associationValue) {
      fetchUserAssociationsCategories();
    }
  }, [associationValue]);

  useEffect(() => {
    if (currentUser.registerEvent !== 'my-associations') {
      userStore.setRegisterEvent('my-associations');
    }
  }, [currentUser]);

  return (
    <>
      <PageTitleWrapper homeIsLanding title='My Associations' currentPage='My Associations' />
      <Box sx={{ ...stylesContainer.container, pb: 5 }}>
        <div className='my-associations-page'>
          <div className='my-associations-wrapper'>
            <div className='associations-filter d-flex align-items-center'>
              <Select
                classNamePrefix='Select'
                className='select-ui no-scale'
                placeholder='Select'
                value={associationValue}
                options={mapOptions(associationStore.myAssociations)}
                onChange={onChangeAssociation}
              />
              <div className='btn empty black' onClick={onAssociationsModalOpen}>
                Add an association
                <PlusCircleIcon />
              </div>
            </div>
            <ConfirmEmail />
            <div className='blur-container'>
              {partneredAssociation && !userAssociationVerified && (
                <div className='verified-container d-flex justify-content-center'>
                  <AssociationCode association={associationValue} onSuccess={onSuccessActivationCode} />
                </div>
              )}
              <AssociationsList list={associationStore.myAssociationsCategories} />
            </div>
          </div>
        </div>
      </Box>
    </>
  );
});

export default MyAssociations;
