import colors from '@packs/assets/theme/base/colors';

export default () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
    mt: 8,
    mb: 6,
    borderRadius: '8px',
    border: '1px rgba(255, 255, 255, 0.16)',
    px: 3,
    py: 2,
    backgroundColor: colors.white.tonal,
    color: colors.white.main,
    transaction: 'all 1s',
    '&:hover': {
      backgroundColor: colors.white.main
    }
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
});
