export const updateOrCreateRenewalDetail = `
mutation updateOrCreateRenewalDetailBillPart($input: UpdateOrCreateRenewalDetailBillPartInput!) {
  updateOrCreateRenewalDetailBillPart(input: $input) {
    renewalDetail {
      id
      buyingType
      renewalCostMonthly
      renewalCostYearly
      dataMonthPrices
      criteriaQuestions
      alertPickWinner
      smartReminder {
        id
        name
        recurringTime
        status
        aliasName
        triggeredAt
      }
      pricesQuestions {
        price {
          period
          value
        }
      }
    }
    errors
  }
}`;
