import React, { useMemo } from 'react';

import { ReplyItemInsight } from '@packs/components/UI/Insights/ReplyItemInsight';
import { useStore } from '@packs/stores';

import get from 'lodash-es/get';
import { observer } from 'mobx-react';

export const RepliesListInsight = observer(({ postId, commentId }) => {
  const { insightsCompanyStore } = useStore();

  const replies = useMemo(() => {
    const comments = get(insightsCompanyStore.answers, `${postId}`, []);
    const comment = comments.find(item => item?.id === commentId);
    return get(comment, 'replies', []);
  }, [insightsCompanyStore.answers]);

  return (
    <div className='comments-list-wrapper answers-list replies-list'>
      {replies.map(reply => (
        <ReplyItemInsight key={reply?.id} postId={postId} answer={reply} commentId={commentId} />
      ))}
    </div>
  );
});
