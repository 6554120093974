import React from 'react';

import { BusinessSidebarBox } from '@packs/components/UI/Business/BusinessSidebarBox';
import RatingsIcon from '@packs/images/svg/ui/ratings-performance.svg';
import { divideStarRating } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';

import map from 'lodash-es/map';

const ratingList = [
  { label: 'Review ratings', key: 'reviewRating' },
  { label: 'Purchase ratings', key: 'purchaseRating' }
];

export const RatingPerformance = () => {
  const { insightsCompanyStore } = useStore();
  const ratingsPerformanceData = insightsCompanyStore?.object?.ratingsPerformanceData;

  return (
    <BusinessSidebarBox title='Ratings Performance' icon={RatingsIcon}>
      {map(ratingList, item => {
        const rating = divideStarRating(ratingsPerformanceData && ratingsPerformanceData[item.key]);

        return (
          <div key={item.key} className='company-item d-flex align-items-center'>
            <span className='business-name'>{item.label}</span>
            <div className='d-flex align-items-center ml-a'>
              <span className='rating-count fs-14 fw-400 ml-10'>{rating ? rating.toFixed(1) : '0.0'}</span>
              <RatingMUI value={1} max={1} />
            </div>
          </div>
        );
      })}
    </BusinessSidebarBox>
  );
};
