import {
  autocomplete,
  autocompleteWiki,
  campaignPagesSearch,
  companiesAutocompleateListSelectQuery,
  companiesByCountry,
  companiesSearch,
  companyCreatePaymentMutation,
  companyFeaturedBusinesses,
  companyUpdateClicks,
  companyVisitCounter,
  createCompany,
  fetchAllBadges,
  fetchCategoryAgents,
  fetchCategoryAgentsWiki,
  fetchCityOptions,
  fetchCompaniesList,
  fetchCompaniesListProduct,
  fetchInsightCompanyBadges,
  fetchInsightsCompany,
  fetchProductsCountQuery,
  fetchRegionOptions,
  fetchWikiPricesCompanies,
  quoteRequestCreate,
  wikiTableList,
  winnerCompanies
} from '@packs/graphql/queries/company';
import { graphQLRequest } from '@packs/lib/utils';

const api = {
  fetchCompaniesList: variables =>
    graphQLRequest({
      query: fetchCompaniesList,
      variables
    }),
  fetchWikiPricesCompanies: variables =>
    graphQLRequest({
      query: fetchWikiPricesCompanies,
      variables
    }),
  fetchCompaniesAllTypes: (variables, types) =>
    graphQLRequest({
      queries: [
        {
          query: fetchCompaniesList,
          variables: { ...variables, companyType: 'provider' },
          responseKey: 'provider'
        },
        {
          query: fetchCompaniesList,
          variables: { ...variables, companyType: 'broker' },
          responseKey: 'broker'
        },
        {
          query: fetchCompaniesList,
          variables: { ...variables, companyType: 'comparison_site' },
          responseKey: 'comparison_site'
        }
      ].filter(c => types.includes(c.responseKey))
    }),
  fetchWikiPricesCompaniesAllTypes: (variables, types) =>
    graphQLRequest({
      queries: [
        {
          query: fetchWikiPricesCompanies,
          variables: { ...variables, companyType: 'provider' },
          responseKey: 'provider'
        },
        {
          query: fetchWikiPricesCompanies,
          variables: { ...variables, companyType: 'broker' },
          responseKey: 'broker'
        },
        {
          query: fetchWikiPricesCompanies,
          variables: { ...variables, companyType: 'comparison_site' },
          responseKey: 'comparison_site'
        }
      ].filter(c => types.includes(c.responseKey))
    }),
  fetchInsightsCompany: variables =>
    graphQLRequest({
      query: fetchInsightsCompany,
      variables
    }),
  autocomplete: variables =>
    graphQLRequest({
      query: autocomplete,
      variables
    }),
  autocompleteWiki: variables =>
    graphQLRequest({
      query: autocompleteWiki,
      variables
    }),
  fetchCityOptions: variables =>
    graphQLRequest({
      query: fetchCityOptions,
      variables
    }),
  fetchRegionOptions: variables =>
    graphQLRequest({
      query: fetchRegionOptions,
      variables
    }),
  companyUpdateClicks: variables =>
    graphQLRequest({
      query: companyUpdateClicks,
      variables: { input: variables }
    }),
  createCompany: variables =>
    graphQLRequest({
      query: createCompany,
      variables: { input: variables }
    }),
  companyCreatePayment: attrs =>
    graphQLRequest({
      query: companyCreatePaymentMutation,
      variables: { input: attrs }
    }),
  createQuoteRequest: ({
    input: { files, address, comment, categoryId, postalCode, phoneNumber, quoteAgentIds, renewalDetailId }
  }) =>
    graphQLRequest({
      query: quoteRequestCreate,
      type: 'multipart',
      data: [
        { key: 'variables[input][categoryId]', value: categoryId },
        { key: 'variables[input][comment]', value: comment },
        { key: 'variables[input][phoneNumber]', value: phoneNumber },
        { key: 'variables[input][renewalDetailId]', value: renewalDetailId },
        { key: 'variables[input][postalCode]', value: postalCode },
        { key: 'variables[input][address]', value: address },
        ...quoteAgentIds.map(agentId => ({ key: 'variables[input][quoteAgentIds][]', value: agentId })),
        ...files.map(file => ({ key: 'variables[input][files][]', value: file }))
      ]
    }),
  fetchCategoryAgents: variables =>
    graphQLRequest({
      query: fetchCategoryAgents,
      variables
    }),
  fetchAgentsAllTypes: variables =>
    graphQLRequest({
      queries: [
        {
          query: fetchCategoryAgents,
          variables: { ...variables, companyType: 'provider' },
          responseKey: 'provider'
        },
        {
          query: fetchCategoryAgents,
          variables: { ...variables, companyType: 'broker' },
          responseKey: 'broker'
        }
      ]
    }),
  fetchCategoryAgentsWiki: variables =>
    graphQLRequest({
      query: fetchCategoryAgents,
      variables
    }),
  fetchAgentsAllTypesWiki: variables =>
    graphQLRequest({
      queries: [
        {
          query: fetchCategoryAgentsWiki,
          variables: { ...variables, companyType: 'provider' },
          responseKey: 'provider'
        },
        {
          query: fetchCategoryAgentsWiki,
          variables: { ...variables, companyType: 'broker' },
          responseKey: 'broker'
        }
      ]
    }),
  fetchCompaniesProduct: variables =>
    graphQLRequest({
      query: fetchCompaniesListProduct,
      variables
    }),
  fetchCompaniesByCountry: variables =>
    graphQLRequest({
      query: companiesByCountry,
      variables
    }),
  fetchCompaniesSearch: variables =>
    graphQLRequest({
      query: companiesSearch,
      variables
    }),
  campaignPagesSearch: variables =>
    graphQLRequest({
      query: campaignPagesSearch,
      variables
    }),
  companiesAutocompleateListSelect: variables =>
    graphQLRequest({
      query: companiesAutocompleateListSelectQuery,
      variables
    }),
  fetchProducts: variables =>
    graphQLRequest({
      query: wikiTableList,
      variables
    }),
  fetchProductsCount: variables =>
    graphQLRequest({
      query: fetchProductsCountQuery,
      variables
    }),
  winnerCompanies: variables =>
    graphQLRequest({
      query: winnerCompanies,
      variables
    }),
  companyFeaturedBusinesses: variables =>
    graphQLRequest({
      query: companyFeaturedBusinesses,
      variables
    }),
  companyVisitCounter: variables =>
    graphQLRequest({
      query: companyVisitCounter,
      variables: { input: variables }
    }),
  fetchAllBadges: variables =>
    graphQLRequest({
      query: fetchAllBadges,
      variables
    }),
  fetchInsightCompanyBadges: variables =>
    graphQLRequest({
      query: fetchInsightCompanyBadges,
      variables
    })
};

export default api;
