import React, { Fragment } from 'react';
import cx from 'classnames';

import { CustomReactSelect } from '@packs/components';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';

import isNull from 'lodash-es/isNull';
import isUndefined from 'lodash-es/isUndefined';
import _orderBy from 'lodash-es/orderBy';

// TODO use in TS
// const propTypes = {
//   input: shape({ onChange: func.isRequired }).isRequired,
//   className: string,
//   formatOptionLabel: func,
//   classNamePrefix: string,
//   meta: object.isRequired,
//   options: array.isRequired,
//   placeholder: string,
//   optionsType: string,
//   disabled: bool,
//   isClearable: bool,
//   fieldValue: any,
//   loadOptions: any,
//   orderDirection: string
// };

export const SelectField = ({
  input: { onChange, value },
  meta: { touched, error },
  options,
  disabled = false,
  className,
  isClearable = false,
  placeholder,
  optionsType = 'default',
  loadOptions,
  mobileDrawer = false,
  isSearchable,
  useFalseValue,
  orderDirection,
  classNamePrefix,
  formatOptionLabel,
  renderOption,
  returnObjectValue = false,
  formatGroupLabel,
  menuPlacement = 'auto',
  material = false
}) => {
  const onChangeHandler = fieldValue => {
    if (returnObjectValue) {
      onChange(fieldValue);
    } else {
      onChange(fieldValue && fieldValue.value);
    }
  };
  let selectedValue;
  let sortedOptions = options;

  if (orderDirection) {
    sortedOptions = _orderBy(options, 'value', orderDirection);
  }

  const valueIsObject = typeof value === 'object' && value !== null;

  if (optionsType === 'nested') {
    options.find(rootOption => (selectedValue = rootOption.options.find(option => option.value === value)));
  } else {
    selectedValue = options.find(option => option.value === (valueIsObject ? value.value : value));
  }

  if (material) {
    return (
      <>
        <AutocompleteMUI
          value={!isNull(selectedValue) || !isUndefined(selectedValue) ? selectedValue : null}
          options={sortedOptions}
          label={placeholder}
          onChange={data => onChangeHandler(data)}
          disabled={disabled}
          {...(formatOptionLabel ? { getOptionLabel: formatOptionLabel } : null)}
          {...(renderOption ? { renderOption } : null)}
          mobileSelect
          filter
        />
        {touched && error && <span className='error-hint'>{error}</span>}
      </>
    );
  }

  return (
    <Fragment>
      <CustomReactSelect
        value={!isNull(selectedValue) || !isUndefined(selectedValue) ? selectedValue : null}
        className={cx(className, { 'error-input': touched && error })}
        {...(formatOptionLabel ? { formatOptionLabel } : null)}
        {...{
          classNamePrefix,
          options: sortedOptions,
          loadOptions,
          placeholder,
          isClearable,
          isSearchable,
          useFalseValue,
          mobileDrawer,
          formatGroupLabel,
          menuPlacement
        }}
        isDisabled={disabled}
        onChange={onChangeHandler}
      />
      {touched && error && <span className='error-hint'>{error}</span>}
    </Fragment>
  );
};
