import React, { useMemo, useState } from 'react';

import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import { Switcher } from '@packs/components';
import { AppliedFilters } from '@packs/components/MaterialUi/Leaderboard/AppliedFilters/AppliedFilters';
import { SelectMUI } from '@packs/components/MaterialUi/SelectMUI/SelectMUI';
import { SearchCompanies } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { sortOptions } from '@packs/lib/helpers/sortSubcategoriesDesktop';
import { isWikiTableCategory } from '@packs/models/category/functions';
import {
  appliedFiltersLabels,
  COMPANY_TYPE_EACH_TYPE,
  COMPANY_TYPE_OPTIONS,
  TIME_OPTIONS
} from '@packs/models/company/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';
import { categoriesWithoutPersonalizeFlow, subCategoriesWithoutPersonalizeFlow } from '@shared/constants/categories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

const fields = ['companyType', 'lastBuy', 'city', 'locality', 'criteriaQuestions', 'campaignGroupId'];

export const MyResultsActionPanel = observer(({ showFilters, toggleShowFilters }) => {
  const styles = useStyles();
  const { isDesktop } = useWindowSize();
  const { companiesStore, currentCategory, userStore } = useStore();
  const { currentPolicy, formOptions } = useDetails();
  const { currentUser } = useUserSelectors();
  const isPersonalized = hasPolicyCriteria(currentPolicy);

  const [showSearch, setShowSearch] = useState(false);

  const criteriaFilters = formOptions;
  const isCategoriesWithoutPersonalizeFlow = categoriesWithoutPersonalizeFlow.includes(currentCategory?.root?.code);
  const isSubCategoriesWithoutPersonalizeFlow = subCategoriesWithoutPersonalizeFlow.includes(currentCategory.code);

  const enabledPersonalizeFlow = !isCategoriesWithoutPersonalizeFlow && !isSubCategoriesWithoutPersonalizeFlow;

  const isInsuranceCategory =
    ['insurance'].includes(currentCategory.root?.enName) || currentCategory.code?.includes('insurance');
  const sortList = sortOptions({ isInsuranceCategory, isPersonalized, currentUser });
  const personalized = hasPolicyCriteria(currentPolicy);
  const companyTypeOptions = useMemo(() => {
    let list = isWikiTableCategory(currentCategory)
      ? [...COMPANY_TYPE_OPTIONS, COMPANY_TYPE_EACH_TYPE]
      : [COMPANY_TYPE_EACH_TYPE, ...COMPANY_TYPE_OPTIONS];

    if (!isWikiTableCategory(currentCategory) && !personalized) {
      list = list.filter(item => item.value !== 'all_types');
    }

    if (currentCategory?.root?.code === 'subscriptions') {
      return [{ value: 'provider', label: 'Top Providers' }];
    }

    return list;
  }, [currentCategory]);

  // const appliedFilters = fields.reduce((acc, item) => {
  //   const filterItem = companiesStore.filters[item];
  //
  //   if (item === 'criteriaQuestions') {
  //     const criteriaQuestions = { ...filterItem };
  //     delete criteriaQuestions.location;
  //
  //     const criteriaCount = Object.values(criteriaQuestions).filter(Boolean).length;
  //     return acc + criteriaCount;
  //   }
  //
  //   const isObjectFilter = typeof filterItem === 'object' && filterItem?.value && filterItem?.value !== 'all';
  //   const isStringFilter = typeof filterItem === 'string' && INIT_FILTERS[item] !== filterItem && filterItem !== 'all';
  //
  //   if (isObjectFilter || isStringFilter) {
  //     return acc + 1;
  //   }
  //
  //   return acc;
  // }, 0);

  const onLoadCompanies = (filters = null, noCriteria = false) => {
    if (filters) {
      companiesStore.load(filters);
    } else {
      companiesStore.load({ noCriteria });
    }
  };

  const onChangeSort = (_, data) => {
    if (data?.value) {
      onLoadCompanies({ filters: { orderColumn: data.value } });
    }
  };

  const toggleShowSearch = () => {
    setShowSearch(prevState => !prevState);
  };

  const value = sortList.find(item => item.value === companiesStore?.filters?.orderColumn) || sortList[0];

  return (
    <Box sx={styles.actionPanelWrapper}>
      <Box sx={styles.container}>
        <Box sx={styles.searchWrapper}>
          {isDesktop &&
            (!showSearch ? (
              <Box sx={styles.searchButton} onClick={toggleShowSearch}>
                <SearchRoundedIcon />
              </Box>
            ) : (
              <SearchCompanies width='auto' maxWidth='300px' />
            ))}
          <Typography variant='body3' sx={styles.resultsTitle}>
            {`${companiesStore?.total} businesses found`}
          </Typography>
        </Box>

        {isDesktop && (!!currentPolicy?.id || !enabledPersonalizeFlow) && (
          <Box sx={styles.switcherWrapper}>
            <FormLabel sx={styles.switcherLabel}>
              <Typography variant='body1'>{showFilters ? 'Hide' : 'Show'} Filters</Typography>
              <Switcher textOff='off' textOn='on' primary value={showFilters} onChange={toggleShowFilters} />
            </FormLabel>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              <SelectMUI
                width='200px'
                value={value}
                options={sortList}
                onChange={onChangeSort}
                label='Sort by'
                size='small'
              />
            </Box>
          </Box>
        )}
      </Box>
      <AppliedFilters
        filters={appliedFiltersLabels(companiesStore?.filters, fields, criteriaFilters, [
          ...TIME_OPTIONS,
          ...[...companyTypeOptions, COMPANY_TYPE_EACH_TYPE],
          ...(userStore?.currentUserGroups || []).map(item => ({ label: item.name, value: item.id }))
        ])}
      />
      {!isDesktop && (!!currentPolicy?.id || !enabledPersonalizeFlow) && (
        <Box sx={{ ...styles.switcherWrapper, alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <Box sx={styles.mobileSortTitleWrapper}>
            <FilterListRoundedIcon />
            <Typography variant='body3'>Sort by:</Typography>
          </Box>
          <Box>
            <SelectMUI
              value={value}
              options={sortList}
              onChange={onChangeSort}
              size='small'
              variant='standard'
              mobileSelect
            />
          </Box>
        </Box>
      )}
    </Box>
  );
});
