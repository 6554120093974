import React, { useState } from 'react';

import { I18n } from '@packs/components/I18n';
import { Loader } from '@packs/components/Loader';
import { SignInModalLink } from '@packs/components/SignInModalLink';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

import { Form } from './components';

import first from 'lodash-es/first';
import { observer } from 'mobx-react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export const ResendConfirmationModal = observer(({ isOpen, toggle }) => {
  const [inProcess, setInProcess] = useState(false);
  const { currentCategory } = useStore();
  const { userStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { defaultMessages } = useTranslationMessages();

  const submitHandler = ({ email }) => {
    const callback = () => {
      setInProcess(false);
      toggle();
      toastr.success('We have sent you a new confirmation email.');
    };

    const errorCallback = err => {
      toastr.error(first(err) || '');

      setInProcess(false);
    };

    setInProcess(true);
    userStore.resendCurrentUserConfirmationInstructions({
      email,
      callback,
      errorCallback
    });
  };

  return (
    <Modal className={`signup-modal ${(currentCategory.root || {}).enName}`} {...{ isOpen, toggle }}>
      <ModalHeader {...{ toggle }} charCode='x' />
      <ModalBody>
        {inProcess && <Loader />}
        <div className='auth-block forgot-password '>
          <h3 className='forgot-title'>
            <I18n text={defaultMessages.resendConfirmationPageTitle} />
          </h3>
          <p className='forgot-password-text'>
            <I18n text={defaultMessages.resendConfirmationPageP1} />
          </p>
          <Form onSubmit={submitHandler} />
          {!currentUser.id && (
            <div className='forgot-text'>
              <I18n text={defaultMessages.resendConfirmationPageRemeberPassword} />
              &nbsp;
              <SignInModalLink>
                <I18n text={defaultMessages.resendConfirmationPageLogin} />
              </SignInModalLink>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
});
