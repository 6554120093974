import React from 'react';

export const MedicalServices = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M20 6H16V4C16 2.9 15.1 2 14 2H10C8.9 2 8 2.9 8 4V6H4C2.9 6 2 6.9 2 8V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V8C22 6.9 21.1 6 20 6ZM10 4H14V6H10V4ZM15 15H13V17C13 17.55 12.55 18 12 18C11.45 18 11 17.55 11 17V15H9C8.45 15 8 14.55 8 14C8 13.45 8.45 13 9 13H11V11C11 10.45 11.45 10 12 10C12.55 10 13 10.45 13 11V13H15C15.55 13 16 13.45 16 14C16 14.55 15.55 15 15 15Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
