import React from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { ReminderStatus } from '@packs/components/MaterialUi/ReminderStatus/ReminderStatus';
import EditIcon from '@packs/images/icons/EditIcon/editIcon';
import { VIEW_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';

import dayjs from 'dayjs';
import _clone from 'lodash-es/clone';

const { text } = colors;

export const RenewalSmartReminder = ({
  item,
  category,
  renewalDetail,
  onClickEditReminder,
  toggleReminderFormModal,
  handleCreateReminderCallback
}) => {
  const { smartReminder } = renewalDetail;
  const reminderStatus = smartReminder?.status;
  const reminderDate = smartReminder?.triggeredAt;

  if (!smartReminder?.id) {
    return (
      <Button
        variant='text'
        size='small'
        color='dark'
        onClick={() => {
          toggleReminderFormModal({
            renewalDetail,
            category,
            disabledCategory: true,
            shouldShowDetails: true,
            onSuccess: data => handleCreateReminderCallback(data, category)
          });
        }}
        startIcon={<AddRoundedIcon />}
      >
        Add Reminder
      </Button>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        width: '100%',
        '&:hover .updateReminderCTA': { opacity: 1 }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <ReminderStatus color={reminderStatus} />
        <Typography variant='body3' sx={{ whiteSpace: 'nowrap' }}>
          {dayjs(reminderDate).format(VIEW_DATE_FORMAT)}
        </Typography>
        <IconButton
          sx={{ ml: { lg: 0, xs: 'auto' }, opacity: { lg: 0 } }}
          onClick={() => {
            onClickEditReminder(_clone(smartReminder), renewalDetail, item, category);
          }}
          className='updateReminderCTA'
        >
          <EditIcon />
        </IconButton>
      </Box>
      <Typography variant='body5' sx={{ color: text.secondary }}>
        Renews every year
      </Typography>
    </Box>
  );
};
