import { useMemo } from 'react';
import { toJS } from 'mobx';

import Button from '@mui/material/Button';
import { WebsiteLink } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/components/WebsiteLink/WebsiteLink';
import { pathGen } from '@packs/layouts/constants';
import { maxRequestQuotes } from '@packs/lib/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

export const RequestButton = observer(({ company, requestButtonCallback }) => {
  const { companiesStore, leaderboardStore, countryCode, currentCategory } = useStore();
  const navigate = useNavigate();
  const { currentPolicy } = useDetails();

  const isBusinessChecked = leaderboardStore.requestBusinesses.some(item => item.id === company.id);
  const disabledCheckbox = leaderboardStore.requestBusinesses.length >= maxRequestQuotes && !isBusinessChecked;

  const inRegion = useMemo(() => {
    const filterCity = companiesStore.filters.city || null;
    return !filterCity || toJS(company?.quoteAgent?.regions)?.includes(filterCity);
  }, [company?.quoteAgent?.regions]);
  const isPersonalized = hasPolicyCriteria(currentPolicy);

  const handleAddRequestBusiness = () => {
    if (!disabledCheckbox) {
      if (!isBusinessChecked) {
        leaderboardStore.addRequestBusiness(company);
      }
      if (requestButtonCallback) {
        requestButtonCallback();
      } else {
        navigate(
          pathGen(isPersonalized ? 'REQUEST_QUOTES' : 'ADD_DETAILS', { countryCode, category: currentCategory }).concat(
            isPersonalized ? '?initialStep=2' : ''
          )
        );
      }
    }
  };

  if (!(company?.quoteAgent?.id && inRegion)) {
    return <WebsiteLink {...{ company }} variant='contained' size='medium' color='primary' fullWidth />;
  }

  return (
    <Button variant='contained' size='medium' fullWidth disabled={disabledCheckbox} onClick={handleAddRequestBusiness}>
      Request a Quote!
    </Button>
  );
});
