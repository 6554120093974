import React from 'react';

export const ArtsAndEntertainment = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M12 2C6.47776 2 2 6.47776 2 12C2 17.5222 6.47776 22 12 22C12.9222 22 13.6667 21.2556 13.6667 20.3333C13.6667 19.9 13.5056 19.5111 13.2333 19.2167C12.9722 18.9222 12.8167 18.5389 12.8167 18.1111C12.8167 17.1889 13.5611 16.4444 14.4833 16.4444H16.4444C19.5111 16.4444 22 13.9555 22 10.8889C22 5.97776 17.5222 2 12 2ZM5.88891 12C4.96667 12 4.22224 11.2556 4.22224 10.3333C4.22224 9.41109 4.96667 8.66667 5.88891 8.66667C6.81115 8.66667 7.55557 9.41109 7.55557 10.3333C7.55557 11.2556 6.81109 12 5.88891 12ZM9.22224 7.55557C8.3 7.55557 7.55557 6.81115 7.55557 5.88891C7.55557 4.96667 8.3 4.22224 9.22224 4.22224C10.1445 4.22224 10.8889 4.96667 10.8889 5.88891C10.8889 6.81115 10.1444 7.55557 9.22224 7.55557ZM14.7778 7.55557C13.8555 7.55557 13.1111 6.81115 13.1111 5.88891C13.1111 4.96667 13.8555 4.22224 14.7778 4.22224C15.7 4.22224 16.4444 4.96667 16.4444 5.88891C16.4444 6.81115 15.7 7.55557 14.7778 7.55557ZM18.1111 12C17.1889 12 16.4444 11.2556 16.4444 10.3333C16.4444 9.41109 17.1889 8.66667 18.1111 8.66667C19.0333 8.66667 19.7778 9.41109 19.7778 10.3333C19.7778 11.2556 19.0333 12 18.1111 12Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
