import React, { useEffect, useRef, useState } from 'react';

import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { AISummary } from '@shared/components/AISummary/AISummary';
import { ReviewItem } from '@shared/components/CompanyPage/components/Review/ReviewItem/ReviewItem';
import { ReviewItemSkeleton } from '@shared/components/CompanyPage/components/Review/ReviewItem/ReviewItemSkeleton';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { Pagination } from '@shared/components/Pagination/Pagination';
import { REVIEWS_PER_PAGE, satisfactionOptions } from '@shared/constants/company';
import ReviewsEmptyIcon from '@shared/images/reviews-empty.webp';

import useStyles from './styles';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const satisfactionColors = {
  excellent: '#67BB6A',
  great: '#9DCC65',
  good: '#FFEE58',
  fair: '#FDA625',
  poor: '#F05450'
};

export const Reviews = observer(
  ({
    insightsCompanyStore,
    company,
    category,
    IS_BUSINESS_PAGE,
    isProfile,
    colors,
    IS_CRM,
    withOutInsightCompany,
    handleVoteNowClick,
    currentUser,
    toggleSignUpModal
  }) => {
    const styles = useStyles();
    const wrapperRef = useRef(null);

    const companyId = company?.id;

    const [filters, setFilters] = useState({
      page: 1,
      feedbackType: null,
      satisfaction: satisfactionOptions.map(({ value }) => value)
    });

    const { page, feedbackType, satisfaction } = filters;

    const groupByPercentInsights = insightsCompanyStore?.groupByPercentInsights;

    const list = insightsCompanyStore?.posts;
    const totalCount = insightsCompanyStore?.postsTotalCount;
    const isLoading = insightsCompanyStore?.loading;
    const count = Math.ceil(totalCount / REVIEWS_PER_PAGE);

    const handleScrollToBegin = () => {
      window.scrollTo({
        top: wrapperRef?.current?.offsetTop - 98,
        behavior: 'smooth'
      });
    };

    const loadRenewalDetailsList = (page = page) => {
      const opts = { companyId, categoryId: !IS_BUSINESS_PAGE || IS_CRM ? category?.id : null };

      if (isProfile) {
        insightsCompanyStore.loadProfilePosts({ page });
      } else {
        insightsCompanyStore.loadPosts({
          filter: { feedbackType: feedbackType === 'all' ? null : feedbackType, satisfaction },
          page,
          perPage: REVIEWS_PER_PAGE,
          ...opts
        });
      }
    };

    const changeRateFilter = (value, single) => {
      let newSatisfaction;

      if (value === 'all_reviews') {
        newSatisfaction = [];
      } else if (single) {
        newSatisfaction = [value];
      } else {
        newSatisfaction = [...satisfaction];

        if (satisfaction.includes(value)) {
          newSatisfaction = newSatisfaction.filter(item => item !== value);
        } else {
          newSatisfaction.push(value);
        }
      }

      setFilters(prevState => ({
        ...prevState,
        page: 1,
        satisfaction: newSatisfaction
      }));
    };

    useEffect(() => {
      loadRenewalDetailsList(page);
    }, [filters, category?.id, companyId]);

    const changeFilter = value => {
      return () => {
        changeRateFilter(value);
      };
    };

    const handlePagination = page => {
      setFilters(prevState => ({
        ...prevState,
        page
      }));
      handleScrollToBegin();
    };

    return (
      <Box sx={{ flexGrow: 1 }} ref={wrapperRef}>
        <AISummary {...{ colors, company }} />
        <Box sx={styles.filtersWrapper}>
          <Box sx={styles.filtersTitleWrapper}>
            <Typography variant='h5'>{category?.name ? category.name : 'General'} filter reviews </Typography>
            {!IS_CRM && (
              <Button variant='contained' color='primary' size='smallToMedium' onClick={handleVoteNowClick}>
                Write a review!
              </Button>
            )}
          </Box>
          <Divider />
          <Box>
            {satisfactionOptions.map(({ value, label }) => {
              const checked = satisfaction.includes(value);
              const percent = (groupByPercentInsights && groupByPercentInsights[value]) || 0;

              return (
                <Box key={value} sx={styles.filterItem}>
                  <FormControlLabel
                    control={<Checkbox checked={checked} onChange={changeFilter(value)} />}
                    label={label}
                    sx={styles.filterCheckbox}
                  />
                  <Box sx={{ flexGrow: 1 }}>
                    <LinearProgress
                      value={percent}
                      variant='determinate'
                      sx={{
                        ...styles.filterProgress,
                        '& .MuiLinearProgress-bar': {
                          background: satisfactionColors[value]
                        }
                      }}
                    />
                  </Box>
                  <Box sx={styles.filterPercent}>
                    <Typography variant='body3'>{`${percent}%`}</Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>

        {!isLoading && list.length === 0 ? (
          <EmptyPlaceholder
            title='Your business has no reviews!'
            description={
              IS_CRM ? 'Invite your customers!' : 'There are no reviews yet, be the first to create a review!'
            }
            icon={ReviewsEmptyIcon}
          >
            {/* {IS_CRM && (*/}
            {/*  <Button*/}
            {/*    component={Link}*/}
            {/*    to='/invite-customers'*/}
            {/*    variant='contained'*/}
            {/*    color='primary'*/}
            {/*    size='medium'*/}
            {/*    startIcon={<PersonAddRoundedIcon />}*/}
            {/*  >*/}
            {/*    INVITE CUSTOMERS*/}
            {/*  </Button>*/}
            {/* )}*/}
          </EmptyPlaceholder>
        ) : (
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              {isLoading ? (
                <>
                  {[...Array(REVIEWS_PER_PAGE)].map(item => (
                    <ReviewItemSkeleton key={item} />
                  ))}
                </>
              ) : (
                <>
                  {list.map((insight, index) => (
                    <ReviewItem
                      key={insight.id}
                      {...{
                        index,
                        insightsCompanyStore,
                        colors,
                        insight,
                        IS_CRM,
                        withOutInsightCompany,
                        currentUser,
                        toggleSignUpModal
                      }}
                    />
                  ))}
                </>
              )}
            </Box>
            <Box sx={{ mt: 4 }}>
              <Pagination count={count} page={page} onChange={handlePagination} />
            </Box>
          </Box>
        )}
      </Box>
    );
  }
);
