import React from 'react';

import { BusinessSidebarBox } from '@packs/components/UI';
import ContactIcon from '@packs/images/svg/ui/contact.svg';

import size from 'lodash-es/size';

export const Contact = ({ company }) => {
  if (size(company?.companyInfo) === 0) {
    return null;
  }

  const { email, address, phoneNumber } = company?.companyInfo || { email: '', address: '', phoneNumber: '' };

  return (
    <BusinessSidebarBox title='CONTACT' icon={ContactIcon}>
      {address && <div className='fs-14 lh-5 mb-15'>{address}</div>}
      {email && (
        <div className='mb-15'>
          <a className='fs-14 lh-5 link-color' href={`mailto:${email}`}>
            {email}.
          </a>
        </div>
      )}
      {phoneNumber && (
        <div>
          <div className='fs-14 lh-5 mb-15'>{company?.name} Head Office No :</div>
          <div>
            <a className='fs-14 lh-5 link-color' href={`tel:${phoneNumber}`}>
              {phoneNumber}
            </a>
          </div>
        </div>
      )}
    </BusinessSidebarBox>
  );
};
