import colors from '@packs/assets/theme/base/colors';

const { grey, dark, primary } = colors;

export default () => ({
  wrapper: {
    borderRadius: '24px',
    pt: 3,
    px: 2,
    pb: 3,
    backgroundColor: grey[100],
    '& .slick-dots': {
      left: '50%',
      bottom: '-21px',
      width: 'auto',
      transform: 'translateX(-50%)',
      '& li': {
        '& button': {
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          backgroundColor: grey[500],
          '&:before': {
            content: 'none'
          }
        },
        '&.slick-active button': {
          backgroundColor: primary.main
        }
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 1,
    pl: {
      xs: 1,
      sm: 2
    },
    pr: {
      xs: 6,
      sm: 7
    }
  },
  sliderArrow: {
    position: 'absolute',
    top: '-42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    color: `${dark.main} !important`,
    cursor: 'pointer',
    '&:before': {
      content: 'none'
    }
  },
  prevArrow: {
    left: {
      xs: 'calc(100% - 108px)',
      sm: 'calc(100% - 120px)'
    }
  },
  nextArrow: {
    right: {
      xs: '8px',
      sm: '16px'
    }
  }
});
