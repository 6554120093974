import React, { FC } from 'react';

import { ButtonProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { OfferItem } from '@packs/components/MaterialUi/Offers/OfferItem/OfferItem';
import { OfferItemSkeleton } from '@packs/components/MaterialUi/Offers/OfferItemSkeleton/OfferItemSkeleton';
import { OFFERS_PER_PAGE } from '@packs/models/offer/constants';
import { useStore } from '@packs/stores';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { Pagination } from '@shared/components/Pagination/Pagination';

import useStyles from './styles';

import size from 'lodash-es/size';
import { observer } from 'mobx-react';

type Props = {
  openFilters: boolean;
  disabledRemove: boolean;
  onResetFilters?: () => void;
};

export const OffersList: FC<Props> = observer(({ openFilters, disabledRemove = false, onResetFilters }) => {
  const { offersStore } = useStore();
  const styles = useStyles();

  const offers = offersStore?.offers;
  const totalCount = offersStore?.totalCount || 0;
  const loader = offersStore?.loader;
  const filters = offersStore?.filters;
  const count = Math.ceil(totalCount / filters.perPage);

  const handlePagination = page => {
    offersStore.load({ page }, false);
  };

  if (!loader && size(offers) === 0) {
    return (
      <EmptyPlaceholder description='Special offers are coming soon!'>
        {!disabledRemove && onResetFilters && (
          <Button
            onClick={onResetFilters}
            size={'smallToMedium' as ButtonProps['size']}
            color='primary'
            variant='contained'
          >
            Reset
          </Button>
        )}
      </EmptyPlaceholder>
    );
  }

  if (loader) {
    return (
      <Box sx={styles.offersList(openFilters)}>
        {[...Array(OFFERS_PER_PAGE)].map(item => (
          <OfferItemSkeleton key={item} />
        ))}
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.offersList(openFilters)}>
        {offers.map((offer, index) => (
          <OfferItem key={offer.id} index={index} offer={offer} darkMode isSliderType={false} />
        ))}
      </Box>
      <Pagination count={count} page={filters.page} onChange={handlePagination} />
    </>
  );
});
