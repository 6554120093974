import React from 'react';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, FormHelperText } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import PhoneInput from '@packs/components/PhoneInput';
import { useStore } from '@packs/stores';
import { getCountryFlag } from '@shared/helpers/flagIconHelper';

import orderBy from 'lodash-es/orderBy';
import toUpper from 'lodash-es/toUpper';
import { observer } from 'mobx-react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  businessName: yup.string().required('Business Name is required'),
  body: yup.string().required('Your message is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  country: yup.object().nullable().required('Country is required')
});

export const Form = observer(({ inProcess, handleSubmit }) => {
  const { countriesSelectOptions, currentUser } = useStore();
  const options = orderBy(countriesSelectOptions.slice(), ['label'], ['asc']);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      businessName: '',
      body: '',
      country: currentUser?.country?.isoA2Code
        ? { value: currentUser?.country?.isoA2Code, label: currentUser?.country?.name }
        : options.find(item => item.value === 'ie')
    }
  });

  const onSubmit = data => {
    handleSubmit(data);
  };

  return (
    <Box sx={{ maxWidth: '400px', mx: 'auto', mt: 5 }}>
      <FormProvider {...methods}>
        <Box
          component='form'
          onSubmit={methods.handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Controller
            name='name'
            render={({ field, fieldState: { error } }) => (
              <Box>
                <TextField label='Name' value={field.value} onChange={field.onChange} fullWidth />
                <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
              </Box>
            )}
          />
          <Controller
            name='businessName'
            render={({ field, fieldState: { error } }) => (
              <Box>
                <TextField label='Business Name' value={field.value} onChange={field.onChange} fullWidth />
                <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
              </Box>
            )}
          />
          <Controller
            name='email'
            render={({ field, fieldState: { error } }) => (
              <Box>
                <TextField label='Email' value={field.value} onChange={field.onChange} fullWidth />
                <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
              </Box>
            )}
          />
          <Controller
            name='country'
            render={({ field, fieldState: { error } }) => (
              <Box>
                <AutocompleteMUI
                  value={field.value}
                  options={options}
                  onChange={field.onChange}
                  label='Country'
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  getOptionLabel={option => option.label}
                  renderOption={(props, { value: code, label }) => (
                    <Box {...props} sx={{ display: 'flex', alignItems: 'center', gap: 1, '& img': { width: '20px' } }}>
                      {code && <img src={getCountryFlag(code === 'uk' ? 'gb' : code)} alt={code} />}
                      <span>{window.innerWidth < 992 ? toUpper(code) : label}</span>
                    </Box>
                  )}
                />
                <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
              </Box>
            )}
          />
          <Controller
            name='phone'
            // control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl fullWidth error={!!error?.message}>
                  <PhoneInput
                    meta={{ touched: false, error: error?.message || '' }}
                    input={{ onChange: field.onChange }}
                    placeholder='Phone (ex. +353 123...)'
                    countryCallingCodeEditable={false}
                    defaultCountry='ie'
                  />
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            name='body'
            render={({ field, fieldState: { error } }) => (
              <Box>
                <TextField
                  label='Your message…'
                  value={field.value}
                  onChange={field.onChange}
                  fullWidth
                  multiline
                  minRows={5}
                />
                <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
              </Box>
            )}
          />
          <Button type='submit' variant='contained' size='smallToMedium' disabled={inProcess}>
            Submit
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
});
