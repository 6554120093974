import React from 'react';

import _map from 'lodash-es/map';

export const NativeSelect = ({ value, disabled, options, onChange }) => {
  return (
    <select
      name='categories'
      className='default-select'
      value={value.value}
      disabled={disabled}
      onChange={event => {
        onChange({ value: event.target.value });
      }}
    >
      {_map(options, item => {
        return (
          <option key={item.value} value={item.value} disabled={item.disabled || false}>
            {item.label}
          </option>
        );
      })}
    </select>
  );
};
