import colors from '@packs/assets/theme/base/colors';

const { text } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '530px',
    width: '100%',
    mx: 'auto',
    textAlign: 'center'
  },
  imgWrapper: {
    width: '124px',
    mb: 3,
    '& img': {
      width: '100%'
    }
  },
  title: {
    mb: 1
  },
  description: {
    mb: 2,
    color: text.secondary
  }
});
