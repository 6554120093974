import React, { Fragment, lazy, Suspense } from 'react';

import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { DeleteConfirmModal, MyBillDetailsModal, ResendConfirmationModal } from '@packs/layouts/AppLayer/components';
import { useModalsContext } from '@packs/layouts/AppLayer/context';

import { observer } from 'mobx-react';

const BusinessVoteModal = lazy(() =>
  import('@packs/components/BusinessVoteModal/BusinessVoteModal')
    .then(({ BusinessVoteModal }) => ({ default: BusinessVoteModal }))
    .catch(() => ({ default: <div /> }))
);
const ChangePasswordModal = lazy(() =>
  import('@packs/components/ChangePasswordModal/ChangePasswordModal')
    .then(({ ChangePasswordModal }) => ({ default: ChangePasswordModal }))
    .catch(() => ({ default: <div /> }))
);
const CreateQuestionModal = lazy(() =>
  import('@packs/components/CreateQuestionModal/CreateQuestionModal')
    .then(({ CreateQuestionModal }) => ({ default: CreateQuestionModal }))
    .catch(() => ({ default: <div /> }))
);
const MyAssociationsModal = lazy(() =>
  import('@packs/components/MyAssociationsModal/MyAssociationsModal')
    .then(({ MyAssociationsModal }) => ({ default: MyAssociationsModal }))
    .catch(() => ({ default: <div /> }))
);
const NewAssociationModal = lazy(() =>
  import('@packs/components/NewAssociationModal/NewAssociationModal')
    .then(({ NewAssociationModal }) => ({ default: NewAssociationModal }))
    .catch(() => ({ default: <div /> }))
);
const NewProviderModal = lazy(() =>
  import('@packs/components/NewProviderModal/NewProviderModal')
    .then(({ NewProviderModal }) => ({ default: NewProviderModal }))
    .catch(() => ({ default: <div /> }))
);
const PersonalizationFlowModal = lazy(() =>
  import('@packs/components/PersonalizationFlowModal/PersonalizationFlowModal')
    .then(({ PersonalizationFlowModal }) => ({ default: PersonalizationFlowModal }))
    .catch(() => ({ default: <div /> }))
);
const PrivacyModal = lazy(() =>
  import('@packs/components/PrivacyModal/PrivacyModal')
    .then(({ PrivacyModal }) => ({ default: PrivacyModal }))
    .catch(() => ({ default: <div /> }))
);
const ConfirmModal = lazy(() =>
  import('@packs/components/ConfirmModal/ConfirmModal')
    .then(({ ConfirmModal }) => ({ default: ConfirmModal }))
    .catch(() => ({ default: <div /> }))
);
const ReminderFormModal = lazy(() =>
  import('@packs/components/ReminderFormModal/ReminderFormModal')
    .then(({ ReminderFormModal }) => ({ default: ReminderFormModal }))
    .catch(() => ({ default: <div /> }))
);
const AcceptInviteModal = lazy(() =>
  import('@packs/components/UI/AcceptInviteModal/AcceptInviteModal')
    .then(({ AcceptInviteModal }) => ({ default: AcceptInviteModal }))
    .catch(() => ({ default: <div /> }))
);
const TermsModal = lazy(() =>
  import('@packs/components/TermsModal/TermsModal')
    .then(({ TermsModal }) => ({ default: TermsModal }))
    .catch(() => ({ default: <div /> }))
);
const AuthModal = lazy(() =>
  import('@packs/layouts/AppLayer/components/AuthModal/AuthModal')
    .then(({ AuthModal }) => ({ default: AuthModal }))
    .catch(() => ({ default: <div /> }))
);
const SettingsModal = lazy(() =>
  import('@packs/layouts/AppLayer/components/SettingsModal/SettingsModal')
    .then(({ SettingsModal }) => ({ default: SettingsModal }))
    .catch(() => ({ default: <div /> }))
);
const ThankVoteModal = lazy(() =>
  import('@packs/components/ThankVoteModal/ThankModal')
    .then(({ ThankVoteModal }) => ({ default: ThankVoteModal }))
    .catch(() => ({ default: <div /> }))
);
const WinnerModal = lazy(() =>
  import('@packs/components/WinnerModal')
    .then(({ WinnerModal }) => ({ default: WinnerModal }))
    .catch(() => ({ default: <div /> }))
);
const CreateNoteModal = lazy(() =>
  import('@packs/components/UI/MyNotes/components/CreateNoteModal/CreateNoteModal')
    .then(({ CreateNoteModal }) => ({ default: CreateNoteModal }))
    .catch(() => ({ default: <div /> }))
);
const ConnectSocialModal = lazy(() =>
  import('@packs/components/UI/ConnectSocialModal/ConnectSocialModal')
    .then(({ ConnectSocialModal }) => ({ default: ConnectSocialModal }))
    .catch(() => ({ default: <div /> }))
);
const ShareSocialModal = lazy(() =>
  import('@packs/components/UI/ShareSocialModal/ShareSocialModal')
    .then(({ ShareSocialModal }) => ({ default: ShareSocialModal }))
    .catch(() => ({ default: <div /> }))
);
const VerificationModal = lazy(() =>
  import('@packs/components/UI/VerificationModal/VerificationModal')
    .then(({ VerificationModal }) => ({ default: VerificationModal }))
    .catch(() => ({ default: <div /> }))
);

export const AllModals = observer(({ category, modalState }) => {
  const {
    toggleReminderFormModal,
    toggleResendConfirmationModal,
    toggleTermsModal,
    togglePrivacyModal,
    toggleChangePasswordModal,
    toggleSignInModal,
    toggleSignUpModal,
    toggleNewCompanyModal,
    toggleNewAssociationModal,
    toggleSettingsModal,
    toggleDeleteConfirmModal,
    onSignUpSuccess,
    toggleMyBillDetailsModal,
    togglePersonalizationFlowModal,
    toggleBusinessVoteModal,
    toggleThankVoteModal,
    toggleCreateQuestionModal,
    toggleMyAssociationsModalOpen,
    toggleConfirmModalOpen,
    toggleNoteModalOpen,
    toggleConnectSocialModalOpen,
    toggleAcceptInviteModalOpen,
    toggleWinnerModal,
    toggleVerificationModalOpen,
    toggleShareSocialModal
  } = useModalsContext();

  const { currentUser } = useUserSelectors();

  return (
    <Suspense fallback={<div />}>
      <Fragment>
        {modalState.isOpenDoubleModal && (
          <AuthModal
            isOpen={modalState.isOpenDoubleModal}
            {...{
              currentUser,
              onSignUpSuccess,
              toggleSignUpModal,
              toggleSignInModal
            }}
            modalAuthProps={modalState.modalAuthProps}
          />
        )}

        <ResendConfirmationModal
          isOpen={modalState.isOpenResendConfirmationModal}
          toggle={toggleResendConfirmationModal}
        />
        <TermsModal
          isOpen={modalState.isOpenTermsModal || false}
          toggle={toggleTermsModal}
          className={category.root && category.root.enName}
        />

        <PrivacyModal
          isOpen={modalState.isOpenPrivacyModal || false}
          toggle={togglePrivacyModal}
          className={category.root && category.root.enName}
        />

        <ChangePasswordModal
          isOpen={modalState.isChangePasswordModalOpen || false}
          toggle={toggleChangePasswordModal}
        />

        {modalState.isOpenReminderFormModal && (
          <ReminderFormModal
            isOpen={modalState.isOpenReminderFormModal || false}
            currentUser={currentUser}
            {...modalState.reminderFormModalProps}
            selectedCategory={modalState.reminderFormModalProps?.category}
            toggle={() => toggleReminderFormModal()}
          />
        )}

        <NewProviderModal
          isNewCompanyModalOpen={modalState.isNewCompanyModalOpen || false}
          toggle={() => toggleNewCompanyModal()}
          {...modalState.newCompanyModalProps}
        />

        <NewAssociationModal
          isNewAssociationModalOpen={modalState.isNewAssociationModalOpen || false}
          toggle={() => toggleNewAssociationModal()}
          {...modalState.newAssociationModalProps}
        />

        {/* AUTH modals */}

        {modalState.isOpenSettingsModal && (
          <SettingsModal
            isOpen={modalState.isOpenSettingsModal || false}
            toggle={() => toggleSettingsModal()}
            toggleDeleteConfirmModal={toggleDeleteConfirmModal}
          />
        )}

        <DeleteConfirmModal
          isOpen={modalState.isOpenDeleteConfirmModal || false}
          toggle={() => toggleDeleteConfirmModal()}
        />
        {modalState.isOpenMyBillDetails && (
          <MyBillDetailsModal
            isOpen={modalState.isOpenMyBillDetails}
            toggle={() => toggleMyBillDetailsModal()}
            modalProps={modalState.modalProps}
          />
        )}
        {modalState.isPersonalizationFlowModalOpen && (
          <PersonalizationFlowModal
            isOpen={modalState.isPersonalizationFlowModalOpen}
            toggle={togglePersonalizationFlowModal}
            modalProps={modalState.modalProps}
          />
        )}

        {modalState.isBusinessVoteModalOpen && (
          <BusinessVoteModal
            isOpen={modalState.isBusinessVoteModalOpen}
            toggle={() => toggleBusinessVoteModal(!modalState.isBusinessVoteModalOpen)}
            modalProps={modalState.modalPropsVote}
            hideModal={modalState?.isOpenDoubleModal}
          />
        )}
        {modalState.isCreateQuestionOpen && (
          <CreateQuestionModal
            isOpen={modalState.isCreateQuestionOpen}
            toggle={() => toggleCreateQuestionModal(!modalState.isCreateQuestionOpen)}
            modalProps={modalState.modalProps}
            hideModal={modalState?.isOpenDoubleModal || modalState.connectSocialModalOpen}
          />
        )}
        {modalState.isThankVoteModalOpen && (
          <ThankVoteModal
            isOpen={modalState.isThankVoteModalOpen}
            modalProps={modalState.modalProps}
            toggle={() => toggleThankVoteModal(!modalState.isThankVoteModalOpen)}
          />
        )}
        {modalState.myAssociationsModalOpen && (
          <MyAssociationsModal
            isOpen={modalState.myAssociationsModalOpen}
            modalProps={modalState.modalProps}
            toggle={() => toggleMyAssociationsModalOpen(!modalState.myAssociationsModalOpen)}
          />
        )}
        {modalState.connectSocialModalOpen && (
          <ConnectSocialModal
            isOpen={modalState.connectSocialModalOpen}
            modalProps={modalState.connectModalProps}
            toggle={() => toggleConnectSocialModalOpen(!modalState.connectSocialModalOpen)}
          />
        )}
        {modalState.confirmModalOpen && (
          <ConfirmModal
            isOpen={modalState.confirmModalOpen}
            modalProps={modalState.modalProps}
            toggle={() => toggleConfirmModalOpen(!modalState.confirmModalOpen)}
          />
        )}
        {modalState.noteModalIsOpen && (
          <CreateNoteModal
            isOpen={modalState.noteModalIsOpen}
            hideModal={modalState?.isOpenDoubleModal}
            toggle={() => toggleNoteModalOpen(!modalState.noteModalIsOpen)}
            modalProps={modalState.modalProps}
          />
        )}
        {modalState.isOpenAcceptInviteModal && (
          <AcceptInviteModal
            isOpen={modalState.isOpenAcceptInviteModal}
            toggle={() => toggleAcceptInviteModalOpen(!modalState.isOpenAcceptInviteModal)}
            modalProps={modalState.modalProps}
          />
        )}
        {modalState.isOpenVerificationModal && (
          <VerificationModal
            isOpen={modalState.isOpenVerificationModal}
            toggle={() => toggleVerificationModalOpen(!modalState.isOpenVerificationModal)}
            modalProps={modalState.modalProps}
          />
        )}

        {modalState.winnerModalIsOpen && (
          <WinnerModal
            toggle={() => toggleWinnerModal({ modalProps: {} })}
            isModalOpen={modalState.winnerModalIsOpen}
            hideModal={modalState?.isOpenDoubleModal}
          />
        )}
        {modalState.shareSocialModalIsOpen && (
          <ShareSocialModal
            toggle={() => toggleShareSocialModal({ modalProps: {} })}
            isOpen={modalState.shareSocialModalIsOpen}
            modalProps={modalState.modalProps}
          />
        )}
      </Fragment>
    </Suspense>
  );
});
