import React, { FC, useEffect, useMemo, useState } from 'react';

import { Place as PlaceIcon } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Container, Divider, Stack, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { Loader, SignUpModalLink } from '@packs/components';
import { Community } from '@packs/components/MaterialUi/Landing/Community/Community';
import { GetStarted } from '@packs/components/MaterialUi/Landing/GetStarted/GetStarted';
import { Hero } from '@packs/components/MaterialUi/Landing/Hero/Hero';
import { LandingCriteria } from '@packs/components/MaterialUi/Landing/LandingCriteria/LandingCriteria';
import { MembersLove } from '@packs/components/MaterialUi/Landing/MembersLove/MembersLove';
import { LandingOffers } from '@packs/components/MaterialUi/Landing/Offers/Offers';
import useSignUpModal from '@packs/components/SignUpModalLink/useSignUpModal';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import GetStartedIcon from '@packs/images/ui/category-landing-get-started.webp';
import { usePath } from '@packs/layouts/constants';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { setCategoryCriteria } from '@packs/models/category/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import membersData from '@packs/screens/RootCategoryLanding/membersSection/membersData';
import { useStore } from '@packs/stores';
import { businessPageUrl } from '@shared/helpers/urlHelper';

import camelCase from 'lodash-es/camelCase';
import mapKeys from 'lodash-es/mapKeys';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

type CustomCampaignProps = {
  targetGroupPage?: {
    id: number;
    residentialAreaType?: string;
    residentialAreaName?: string;
    campaignType: 'residential' | 'association' | 'group';
    formType: string;
    route: string;
    streetName: string;
    businessOffer?: {
      id: number;
      companyId: number;
    };
    afterRegistrationLinkType:
      | 'leaderboard'
      | 'offers_page'
      | 'offers_specific_business'
      | 'dashboard'
      | 'subcategory_page'
      | 'business_signup_crm'
      | 'raq_area';
    numberOfRegistered: number;
    country?: { id: number; enName: string; isoA2Code: string; name: string };
    city?: { id: number; name: string };
    countryRegion?: { id: number; name: string };
    airCodeAlias?: { id: number; name: string };
    category?: {
      id: number;
      code: string;
      name: string;
      root: { id: number; code: string; name: string };
    };
    brandPartnerLogos?: { url: string }[];
    mediaPartnerLogos?: { url: string }[];
    pageBlock?: {
      title: string;
      description: string;
      hidden: boolean;
      options?: { title: string; description: string }[];
    };
    criteriaQuestions?: {
      id: number;
      name: string;
      label: string;
      qType: string;
      options: { value: string; label: string }[];
    }[];
    backgroundImage?: { url: string };
    associationLogo?: { url: string };
    blockLearnMore?: {
      title: string;
      description: string;
      imageUrl: string;
      options: { title: string; description?: string }[];
      url?: string;
    };
    campaignGroup?: {
      id?: number;
      members_count?: number;
    };
    members_count?: number;
    eirCodeAlias?: {
      alias?: string;
    };
  };
};

const CustomCampaign: FC<CustomCampaignProps> = () => {
  const { categoryStore, currentCategory, countryCode, userStore } = useStore();
  const { currentUser } = useUserSelectors();
  const navigate = useNavigate();
  const { flattenCategories } = useCategories();
  const location = useLocation();

  const [pageData, setPageData] = useState<CustomCampaignProps['targetGroupPage']>();

  const pagePath = location.pathname.replace(/^\/[A-z-]{5}\//i, '');

  const [isLoading, setIsLoading] = useState(false);

  const isJoined = userStore?.currentUserGroups.some(item => item.id === pageData?.campaignGroup?.id);

  const handleJoinToTheGroup = () => {
    if (!joinLink.external && !isJoined) {
      userStore.joinToTheGroup({ campaignPageId: pageData?.id });
    }
  };

  useEffect(() => {
    async function getData() {
      const response = await fetch(`/${countryCode}/campaign_pages?route=${pagePath}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });

      return await response.json();
    }

    setIsLoading(true);
    getData().then(data => {
      const newObj = mapKeys(data, (value, key) => camelCase(key)) as CustomCampaignProps['targetGroupPage'];
      setPageData(newObj);
      setIsLoading(false);
    });
  }, [countryCode, pagePath]);

  const {
    ADD_DETAILS_PATH_FN,
    MY_OFFERS_PATH,
    DASHBOARD_MY_OFFERS_PATH_FN,
    DASHBOARD_MY_RESULTS_PATH_FN,
    MY_OFFERS_DETAILED_PATH_FN,
    LEADERBOARD_PATH,
    MY_HOME_PATH
  } = usePath();

  const joinLink = useMemo((): { url: string; external?: boolean; skipSignUp?: boolean } => {
    switch (pageData?.afterRegistrationLinkType) {
      case 'offers_page':
        return {
          url: pageData?.category ? DASHBOARD_MY_RESULTS_PATH_FN(pageData?.category) : MY_OFFERS_PATH,
          skipSignUp: true
        };
      case 'leaderboard':
        return {
          url: pageData?.category ? DASHBOARD_MY_RESULTS_PATH_FN(pageData?.category) : LEADERBOARD_PATH,
          skipSignUp: true
        };
      case 'dashboard':
        return { url: MY_HOME_PATH, skipSignUp: false };
      case 'offers_specific_business':
        return { url: MY_OFFERS_DETAILED_PATH_FN(pageData?.businessOffer?.id), skipSignUp: true };
      case 'subcategory_page':
        return { url: ADD_DETAILS_PATH_FN(pageData?.category, null) };
      case 'raq_area':
        return { url: ADD_DETAILS_PATH_FN(pageData?.category, null), skipSignUp: true };
      case 'business_signup_crm':
        return {
          url: `${businessPageUrl()}sign-up?joinCampaignPageId=${pageData?.id}`,
          external: true,
          skipSignUp: true
        };
      default:
        return { url: '/' };
    }
  }, [
    ADD_DETAILS_PATH_FN,
    DASHBOARD_MY_OFFERS_PATH_FN,
    LEADERBOARD_PATH,
    MY_HOME_PATH,
    MY_OFFERS_DETAILED_PATH_FN,
    MY_OFFERS_PATH,
    pageData?.afterRegistrationLinkType,
    pageData?.businessOffer?.id,
    pageData?.category
  ]);

  const fields = (pageData?.criteriaQuestions || []).map(item => ({
    ...item,
    name: `criteriaQuestions.${item.name}`
  }));

  const handleToggleSignUpModal = useSignUpModal({ skipCategories: true, skipRedirect: false });

  const categoryWithPolicy = categoryHavePolicy(pageData?.category, categoryStore.categoriesWithPolicy, currentUser);

  useEffect(() => {
    if (pageData?.category) {
      categoryStore.setCurrentCategory(pageData?.category);
    }
  }, [categoryStore, pageData?.category]);

  function onSubmitCriteria(data) {
    async function sendData(options) {
      const criteria = options?.criteriaQuestions || {};
      const extractedValues = {};
      Object.keys(criteria).forEach(key => {
        if (typeof criteria[key] === 'object') {
          extractedValues[key] = criteria[key].value;
        } else {
          extractedValues[key] = criteria[key];
        }
      });

      setCategoryCriteria(pageData?.category?.id, extractedValues);

      if (pageData?.category) {
        const category = flattenCategories.find(item => item.id === pageData?.category?.id);
        categoryStore.setCurrentCategory(category);
      }

      if (joinLink.external) {
        window.location.replace(joinLink.url);
      } else {
        navigate(joinLink.url.concat(`?joinCampaignPageId=${pageData?.id}`));
      }
    }

    if (!currentUser?.id && !joinLink.skipSignUp) {
      handleToggleSignUpModal(() => sendData(data));
    } else {
      sendData(data);
    }
  }

  const placeName = [
    pageData?.countryRegion?.name || null,
    pageData?.city?.name === 'Dublin' ? pageData?.eirCodeAlias?.alias : pageData?.city?.name,
    pageData?.residentialAreaName || null
  ]
    .filter(el => el !== null)
    .join(', ');

  if (isLoading) {
    return <Loader />;
  }
  if (!pageData) {
    return null;
  }

  return (
    <Box sx={{ bgcolor: colors.white.main }}>
      <Hero
        breadcrumbLabel={`${pageData.city?.name} BillWinner Group`}
        heroBackgroundImg={pageData.backgroundImage?.url}
        cornerIcon={pageData.associationLogo?.url}
        endContent={
          <Stack direction='row' spacing={1} alignItems='center' sx={{ pt: { xs: 2, md: 0 } }}>
            <Stack direction='row' spacing={1}>
              <PlaceIcon />
              <Typography variant='body1'>{placeName}.</Typography>
            </Stack>
            {pageData?.campaignGroup?.members_count && pageData?.campaignGroup?.members_count > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 1,
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  borderColor: colors.white.tonal,
                  minHeight: 40
                }}
              >
                {pageData?.campaignGroup?.members_count} members
              </Box>
            )}
          </Stack>
        }
      >
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5}>
          <Box sx={{ maxWidth: { xs: 'auto', md: 693 }, width: 1 }}>
            <Typography variant='h2' color='inherit' mb={3}>
              {pageData.pageBlock?.title}
            </Typography>
            {pageData.pageBlock?.options && pageData.pageBlock?.options.length > 0 && (
              <Stack spacing={1} mb={{ xs: 0, lg: 3 }}>
                {pageData.pageBlock?.options.map(el => (
                  <Stack key={el.title} direction='row' spacing={1}>
                    <CheckIcon />
                    <Typography variant='body1'>{el.title}</Typography>
                  </Stack>
                ))}
              </Stack>
            )}
            {pageData.pageBlock?.description && (
              <Typography
                variant='subtitle1'
                dangerouslySetInnerHTML={{ __html: pageData.pageBlock?.description || '' }}
                mb={{ xs: 0, lg: 3 }}
              />
            )}
            {(!pageData?.category || categoryWithPolicy) && (
              <Box sx={{ pt: 3, pb: { xs: 2, lg: 0 } }}>
                {currentUser?.id || joinLink.skipSignUp ? (
                  <Button
                    variant='contained'
                    size='large'
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    component={RouterLink}
                    to={joinLink.url}
                    onClick={handleJoinToTheGroup}
                  >
                    {isJoined ? 'Joined' : 'Join now'}
                  </Button>
                ) : (
                  <SignUpModalLink
                    size='large'
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    onSignUpSuccess={() => {
                      if (joinLink.external) {
                        window.location.replace(joinLink.url);
                      } else {
                        handleJoinToTheGroup();
                        navigate(joinLink.url);
                      }
                    }}
                  >
                    Join now
                  </SignUpModalLink>
                )}
              </Box>
            )}
          </Box>
          {pageData?.category && !categoryWithPolicy && (
            <Box>
              <LandingCriteria
                fields={fields}
                initialValues={{ criteriaQuestions: {} }}
                handleSubmit={onSubmitCriteria}
              />
            </Box>
          )}
        </Stack>
      </Hero>

      {pageData.mediaPartnerLogos && pageData.mediaPartnerLogos.length > 0 && (
        <>
          <Container sx={{ py: 8 }}>
            <Box>
              <Typography variant='h6' textAlign='center' sx={{ mb: 4, textTransform: 'uppercase' }}>
                In partnership with:
              </Typography>
              <Stack direction='row' spacing={2} rowGap={2} justifyContent='center' flexWrap='wrap'>
                {pageData.mediaPartnerLogos.map(el => (
                  <Box key={el.url} sx={{ height: 80 }}>
                    <img src={el.url} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt='' />
                  </Box>
                ))}
              </Stack>
            </Box>
          </Container>
          <Divider sx={{ maxWidth: '60%', mx: 'auto' }} />
        </>
      )}

      {pageData.brandPartnerLogos && pageData.brandPartnerLogos.length > 0 && (
        <>
          <Container sx={{ py: 8 }}>
            <Box>
              <Typography variant='h6' textAlign='center' sx={{ mb: 4, textTransform: 'uppercase' }}>
                Brand partners:
              </Typography>
              <Stack direction='row' spacing={2} rowGap={2} justifyContent='center' flexWrap='wrap'>
                {pageData.brandPartnerLogos.map(el => (
                  <Box key={el.url} sx={{ height: 80 }}>
                    <img src={el.url} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt='' />
                  </Box>
                ))}
              </Stack>
            </Box>
          </Container>
          <Divider />
        </>
      )}

      {pageData.blockLearnMore && (
        <Box sx={{ py: { xs: 8, lg: 15 } }}>
          <Container>
            <Typography variant='h2' textAlign='center' sx={{ mb: 4 }}>
              {pageData.blockLearnMore.title}
            </Typography>
            <Box sx={{ width: 1, mb: 4, borderRadius: 8 }}>
              <img
                src={pageData.blockLearnMore?.url}
                style={{ width: '100%', height: 'auto', borderRadius: 'inherit' }}
                alt=''
              />
            </Box>
            <Box>
              <Typography
                variant='body1'
                gutterBottom
                sx={{ mb: 3 }}
                dangerouslySetInnerHTML={{ __html: pageData.blockLearnMore.description }}
              />
              {pageData.blockLearnMore.options?.length > 0 && (
                <Box
                  sx={{
                    display: 'grid',
                    gap: {
                      xs: 2,
                      sm: 3
                    },
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      md: 'repeat(3, 1fr)'
                    },
                    mt: 2
                  }}
                >
                  {pageData.blockLearnMore.options.map((el, index) => {
                    return (
                      <Box
                        key={el.title + index}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          borderRadius: '24px',
                          border: `1px solid ${colors.borderColor.secondary}`,
                          py: { xs: 2, lg: 4 },
                          px: 2,
                          textAlign: 'center'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: {
                              xs: '60px',
                              lg: '88px'
                            },
                            height: {
                              xs: '60px',
                              lg: '88px'
                            },
                            borderRadius: '16px',
                            mb: {
                              xs: 2,
                              lg: 3
                            },
                            backgroundColor: 'rgba(1, 103, 205, 0.08)'
                          }}
                        >
                          <Typography variant='h2' sx={{ color: colors.secondary.main }}>
                            {index + 1}
                          </Typography>
                        </Box>
                        <Typography variant='h6' sx={{ mb: 1 }}>
                          {el.title}
                        </Typography>
                        {el?.description && <Typography variant='body1'>{el.description}</Typography>}
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          </Container>
        </Box>
      )}

      <Box>
        <LandingOffers categoryId={pageData?.businessOffer?.companyId} />
      </Box>

      <Box>
        <Community currentCategoryId={currentCategory?.id} />
      </Box>

      <Box sx={{ pt: { xs: 8, lg: 15 }, pb: { xs: 8, md: 34 } }}>
        <MembersLove title={'Why members like Bill Winner!'} members={membersData['general']} />
      </Box>

      <Box>
        <GetStarted
          blocksAboveEmpty={!pageData.blockLearnMore}
          icon={GetStartedIcon}
          iconWidth='579px'
          title='Join our Community!'
          description='Join our community to manage and make smarter decisions on your bills!'
        />
      </Box>
    </Box>
  );
};

export default CustomCampaign;
