import React from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { SidebarTitle } from '@packs/components/MaterialUi/CompanyPage/components/Sidebar/components/SidebarTitle/SidebarTitle';
import { addHttps } from '@shared/helpers/urlHelper';
import ContactIcon from '@shared/images/company/contact.svg';

import useStyles from './styles';

import size from 'lodash-es/size';

export const Contact = ({ company }) => {
  const styles = useStyles();

  const companyContact = company?.additionalData?.companyContact || {};

  if (size(companyContact) === 0) {
    return null;
  }

  const { email, address, phoneNumber, region, postalCode, websiteUrl, locality } = companyContact;

  const ContactItem = ({ label, value, link, children }) => (
    <Box>
      <Typography variant='body5' sx={{ display: 'block', mb: 0.5, color: 'rgba(0, 0, 0, 0.60)' }}>
        {label}
      </Typography>
      {children ? (
        children
      ) : link ? (
        <Link href={addHttps(value)} target='_blank' sx={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }}>
          <Typography variant='body1'>{value}</Typography>
        </Link>
      ) : (
        <Typography variant='body1'>{value}</Typography>
      )}
    </Box>
  );

  return (
    <SidebarTitle icon={<img src={ContactIcon} alt='Phone' />} title='Contact'>
      <Box sx={styles.listWrapper}>
        {region && <ContactItem label='County:' value={region} />}
        {locality && <ContactItem label='Locality:' value={locality} />}
        {address && <ContactItem label='Address:' value={address} />}
        {postalCode && <ContactItem label='Eircode:' value={postalCode} />}
        {email && (
          <ContactItem label='Email:'>
            <Link href={`mailto:${email}`} color='secondary' sx={{ display: 'inline-block' }}>
              <Typography variant='body1'>{email}</Typography>
            </Link>
          </ContactItem>
        )}
        {phoneNumber && (
          <ContactItem label='Mobile phone:'>
            <Link href={`tel:${phoneNumber}`} color='secondary' sx={{ display: 'inline-block' }}>
              <Typography variant='body1'>{phoneNumber}</Typography>
            </Link>
          </ContactItem>
        )}
        {websiteUrl && <ContactItem label='Website:' value={websiteUrl} link />}
      </Box>
    </SidebarTitle>
  );
};
