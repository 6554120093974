export default () => ({
  sidebarBox: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    px: {
      xs: 1,
      lg: 3
    },
    pb: 4,
    mb: 4
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mb: 1
  }
});
