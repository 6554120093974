export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    width: '100%',
    maxWidth: '934px',
    mx: 'auto'
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: {
      xs: 'center',
      md: 'flex-end'
    }
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
});
