import React, { FC, useCallback, useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useStore } from '@packs/stores';
import { INIT_FILTERS } from '@packs/stores/OffersStore';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import debounce from 'lodash-es/debounce';
import { observer } from 'mobx-react';

type Props = {
  defaultCategory?: number | string;
};

export const OffersSearch: FC<Props> = observer(({ defaultCategory }) => {
  const { isMobile } = useWindowSize();
  const { offersStore } = useStore();
  const styles = useStyles();
  const filters = offersStore?.filters;
  const [value, setValue] = useState(filters?.keyWord || '');

  const changeFilter = e => {
    const data = e.target.value;
    fetchData(data);
    setValue(data);
  };

  const fetchData = useCallback(
    debounce(keyWord => {
      offersStore.load(
        { ...INIT_FILTERS, keyWord, ...(defaultCategory ? { categoryId: defaultCategory } : {}) },
        false
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (!filters?.keyWord && value) {
      setValue('');
    }
  }, [filters?.keyWord]);

  return (
    <Box sx={styles.wrapper}>
      <TextField
        fullWidth
        value={value}
        variant='outlined'
        placeholder='Search by category or business name...'
        size={isMobile ? 'medium' : 'small'}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          )
        }}
        onChange={changeFilter}
      />
    </Box>
  );
});
