import React, { useMemo } from 'react';
import cx from 'classnames';

import { paths, usePath } from '@packs/layouts/constants';
import { isWikiTableCategory } from '@packs/models/category/functions';
import { useStore } from '@packs/stores';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

export const NavigationInnerPages = observer(
  ({ pageIcon, pageTitle, withOutTheme = false, withNavigation = false }) => {
    const { isDesktop } = useWindowSize();
    const { leaderboardStore, currentCategory, countryCode } = useStore();
    const category = currentCategory || {};

    const { DASHBOARD_MY_RESULTS_PATH, DASHBOARD_MY_STATS_PATH, DASHBOARD_MY_OFFERS_PATH, MY_NOTES_DASHBOARD_PATH } =
      usePath();

    const dashboardList = useMemo(() => {
      return [
        {
          label: 'My Results',
          url: DASHBOARD_MY_RESULTS_PATH,
          type: 'MY_RESULTS'
        },
        {
          label: 'My Stats',
          url: DASHBOARD_MY_STATS_PATH,
          type: 'MY_STATS'
        },
        {
          label: 'My Offers',
          url: DASHBOARD_MY_OFFERS_PATH,
          type: 'MY_OFFERS'
        },
        {
          label: 'My Community',
          url: paths.LEADERBOARD_QA,
          type: 'QA_GENERAL'
        },
        {
          label: 'My Docs',
          url: MY_NOTES_DASHBOARD_PATH,
          type: 'MY_NOTES'
        }
      ];
    }, [category.id, leaderboardStore?.myProductsTotalCount]);

    if (!category?.id) {
      return null;
    }

    return (
      <div
        className={cx(
          'pages-nav-container d-flex align-items-center',
          isDesktop ? 'justify-content-between' : 'justify-content-center'
        )}
      >
        {isDesktop && (
          <div className='d-flex align-items-center'>
            {withOutTheme ? (
              <>
                <div className='img-container'>{pageIcon}</div>
                <div className='title'>{pageTitle}</div>
              </>
            ) : (
              <>
                <div className='img-container theme-bg'>{SUB_ICONS({ category })}</div>
                <div className='title'>{category.name}</div>
              </>
            )}
          </div>
        )}

        {withNavigation && (
          <div className='d-flex tabs-ui-scroll'>
            <div className='tabs-ui with-scroll'>
              {dashboardList.map(({ url, label, type, count }) => {
                if (
                  (!isWikiTableCategory(category) && type === 'MY_PRODUCTS') ||
                  (category?.root?.code === 'subscriptions' && type === 'MY_PRODUCTS')
                ) {
                  return null;
                }

                return (
                  <NavLink
                    key={url}
                    className={cx('tab-item-ui')}
                    to={url
                      .replace(':country', countryCode)
                      .replace(':slag', category?.slag)
                      .replace(':root_code', category?.root?.code)}
                  >
                    {label}
                    {!!count && (
                      <span className='count d-flex align-items-center justify-content-center fs-11 fw-500'>
                        {count}
                      </span>
                    )}
                  </NavLink>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
);
