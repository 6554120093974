import React, { FC } from 'react';

import { AutocompleteProps } from '@mui/material';
import Divider from '@mui/material/Divider';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { getGroupsOptions } from '@packs/lib/helpers/groupsHelper';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';

interface IProps extends Partial<AutocompleteProps<any, any, any, any>> {
  onChange: (val: any) => void;
  disabled?: boolean;
  withTitle: boolean;
}

export const DashboardGroupsFilter: FC<IProps> = observer(
  ({ onChange, size = 'medium', value, withTitle, ...props }) => {
    const { userStore } = useStore();
    const { currentCategory } = useCategories();

    const options = getGroupsOptions(userStore?.currentUserGroups, currentCategory);

    return (
      <>
        <AutocompleteMUI
          value={options.find(item => item.value === value)}
          options={options}
          onChange={onChange}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          label='Groups'
          placeholder='Please select Group'
          size={size}
          withTitle={withTitle}
          mobileSelect
          filter
          {...props}
        />
        {!withTitle && <Divider />}
      </>
    );
  }
);
