import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AppliedFilters } from '@packs/components/MaterialUi/Leaderboard/AppliedFilters/AppliedFilters';
import { appliedFiltersLabels } from '@packs/models/company/constants';
import { useStore } from '@packs/stores';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';

import _omit from 'lodash-es/omit';
import { observer } from 'mobx-react';

export const QuestionsActionPanel = observer(({ filters, createButton }) => {
  const styles = useStyles();
  const { questionsStore } = useStore();

  return (
    <Box sx={styles.actionPanelWrapper}>
      <Box sx={styles.container}>
        <Typography variant='body3' sx={styles.resultsTitle}>
          {`${questionsStore.questionsTotalCount} posts found`}
        </Typography>
        {createButton && <Box sx={styles.createButtonWrapper}>{createButton}</Box>}
      </Box>
      <AppliedFilters filters={appliedFiltersLabels(filters, Object.keys(_omit(filters, ['page'])))} />
    </Box>
  );
});
