import React, { useEffect, useMemo, useState } from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Switcher } from '@packs/components';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { FiltersDrawer } from '@packs/components/MaterialUi/FiltersDrawer/FiltersDrawer';
import { PageTitleWrapper } from '@packs/components/MaterialUi/PageTitleWrapper/PageTitleWrapper';
import { SelectMUI } from '@packs/components/MaterialUi/SelectMUI/SelectMUI';
import { SignUpModalLink } from '@packs/components/SignUpModalLink';
import { ConfirmEmail } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import RenewalEmptyIcon from '@packs/images/svg/renewal-empty.svg';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { All_CATEGORIES_INDEX, COST_OPTIONS, getCurrency } from '@packs/lib/constants';
import { toastr } from '@packs/lib/helpers';
import { COMING_SOON_CATEGORIES, EXCLUDED_VIEW_CATEGORIES } from '@packs/models/category/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { DesktopItem } from '@packs/screens/MyHome/components/MyRenewals/components/DesktopItem/DesktopItem';
import { DesktopSkeleton } from '@packs/screens/MyHome/components/MyRenewals/components/DesktopItem/DesktopSkeleton';
import { EmptyBills } from '@packs/screens/MyHome/components/MyRenewals/components/EmptyBills/EmptyBills';
import { MobileItem } from '@packs/screens/MyHome/components/MyRenewals/components/MobileItem/MobileItem';
import { MobileSkeleton } from '@packs/screens/MyHome/components/MyRenewals/components/MobileItem/MobileSkeleton';
import { MyRenewalsTotal } from '@packs/screens/MyHome/components/MyRenewals/components/MyRenewalsTotal';
import useStyles from '@packs/screens/MyHome/components/MyRenewals/styles';
import { categoriesWithLowestPrice } from '@packs/screens/MyHome/constants';
import { useStore } from '@packs/stores';
import useContainerStyles from '@packs/styles/Global/container';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { getWindow } from '@shared/lib/utils';

import _filter from 'lodash-es/filter';
import _find from 'lodash-es/find';
import _get from 'lodash-es/get';
import _groupBy from 'lodash-es/groupBy';
import _orderBy from 'lodash-es/orderBy';
import _size from 'lodash-es/size';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

const ALL_VALUE = 'all';

const defaultCategoryOption = {
  label: 'All',
  value: ALL_VALUE
};

export const MyRenewals = observer(({ handleCategoryModal, filterCategory, setFilterCategory }) => {
  const styles = useStyles();
  const stylesContainer = useContainerStyles();
  const { homeStore, categoryStore, countryCode } = useStore();
  const { rootCategoriesOptions, flattenCategories } = useCategories();
  const { onEditReminder } = useModalsContext();
  const { toggleMyBillDetailsModal, toggleReminderFormModal, togglePersonalizationFlowModal } = useModalsContext();
  const { DASHBOARD_MY_RESULTS_PATH_FN, MY_DETAILS_PATH_FN } = usePath();
  const { currentUser } = useUserSelectors();
  const { isDesktop } = useWindowSize();
  const navigate = useNavigate();
  const renewalsPriceData = homeStore.renewalsPriceData;
  const userDetails = homeStore.userDetails;
  const userNotConfirmed = !currentUser.isConfirmed;

  const [selectedPolicy, setSelectedPolicy] = useState({});
  const [selectedCost, setSelectedCost] = useState(COST_OPTIONS[0] || null);
  const [showPotentialSavings, setShowPotentialSavings] = useState(false);

  const categoriesCollection = [defaultCategoryOption, ...rootCategoriesOptions]
    .filter(category => !EXCLUDED_VIEW_CATEGORIES.includes(category.code))
    .map(item => ({ label: item.label, value: item.code || item.value }));
  const currency = getCurrency(countryCode);
  const isLoading = homeStore.isLoadingRenewals;
  const disabledCategory = cat => {
    return ['waste_collection', 'general', 'government', ...COMING_SOON_CATEGORIES].includes(cat);
  };
  const autoQuoteEnabled = disabledCategory(filterCategory?.value);

  const getItemOption = item => {
    const category = _find(flattenCategories, { id: item.id });
    const localRenewalDetails = userDetails.filter(policy => policy.categoryId === category.id);
    const renewalDetail =
      localRenewalDetails.find(detail => detail?.id === selectedPolicy[item?.id]) ||
      (localRenewalDetails.length && localRenewalDetails[0]) ||
      {};
    const smartReminder = renewalDetail?.smartReminder || {};
    const reminderStatus = smartReminder?.status;
    const reminderDate = smartReminder?.triggeredAt;
    const quoteRequest = smartReminder?.quoteRequest;
    const isAutoQuote = smartReminder?.isAutoQuote;

    const rootCode = category?.root?.code;
    const notLinkedCategory = userNotConfirmed || disabledCategory(rootCode);

    const options = _orderBy(_filter(userDetails, { categoryId: item.id }, el => +el.id)).map(item => ({
      value: item.id,
      label: item.smartReminder.aliasName
    }));

    return {
      options,
      rootCode,
      category,
      renewalDetail,
      reminderDate,
      smartReminder,
      reminderStatus,
      notLinkedCategory,
      quoteRequest,
      isAutoQuote
    };
  };

  const renewalsMainList = useMemo(() => {
    let list = homeStore.renewals;

    if (filterCategory?.value && filterCategory?.value !== ALL_VALUE) {
      list = list.filter(item => {
        return item.root.code === filterCategory?.value;
      });
    }

    if (showPotentialSavings) {
      list = list.map(item => {
        const { renewalDetail, category } = getItemOption(item);
        const currentPrice = +renewalDetail[selectedCost.value] || 0;
        const isCategoryWithLowestPrice = categoriesWithLowestPrice.some(
          item => category?.root?.code === item || category?.code === item
        );
        const { averageYearly, averageMonthly } = renewalsPriceData[item.id]?.averagePriceData || {};
        const averagePrice = {
          renewalCostYearly: averageYearly,
          renewalCostMonthly: averageMonthly
        }[selectedCost.value];
        const { minMonthly, minYearly } = renewalsPriceData[item.id]?.minPriceData || {};
        const lowestPrice = selectedCost.value === 'renewalCostMonthly' ? minMonthly : minYearly;
        const averageLowestPrice = isCategoryWithLowestPrice ? lowestPrice : averagePrice;

        if (!averageLowestPrice || averageLowestPrice > currentPrice) {
          return {
            ...item,
            hide: true
          };
        }

        return item;
      });
    }

    return _orderBy(list, cat => All_CATEGORIES_INDEX[cat?.root?.code]);
  }, [
    showPotentialSavings,
    homeStore.renewals.map(item => item.isPinned).join('|'),
    getItemOption,
    selectedCost.value,
    renewalsPriceData,
    filterCategory
  ]);

  const renewalsCollection = useMemo(() => {
    return renewalsMainList.filter(item => !item?.isPinned);
  }, [renewalsMainList]);

  const renewalsCollectionPined = useMemo(() => {
    return renewalsMainList.filter(item => item?.isPinned);
  }, [renewalsMainList]);

  function resetFilters() {
    setFilterCategory(defaultCategoryOption);
    setSelectedCost(COST_OPTIONS[0]);
    setShowPotentialSavings(false);
  }

  const fetchData = () => {
    homeStore.fetchUserRenewalDetails();
    homeStore.fetchUserCategories(
      {
        categoryCode: null
      },
      res => {
        if (res?.length === 0) {
          handleCategoryModal();
        }
      }
    );
  };

  const handleChangCost = (_, data) => {
    if (data) {
      setSelectedCost(data);
    }
  };

  const handleChangCategory = data => {
    if (data) {
      setFilterCategory(data);
    }
  };

  const toggleIsAutoQuote = ({ userRenewalId, policyId, smartReminderId, isAutoQuote, quoteRequest, category }) => {
    if (_size(quoteRequest)) {
      homeStore.updateIsAutoQuote({ userRenewalId, policyId, smartReminderId, isAutoQuote: !isAutoQuote });
      const text = !isAutoQuote
        ? 'You have successfully set up Auto. quote! The best-matched business or businesses will contact you prior to your next renewal to quote you!'
        : `You have added your ${category?.name} details! Turn on Auto. quote to be contacted by the best matched business or businesses prior to your next renewal to quote you!`;
      toastr.success(text);
    }
  };

  const onClickDetails = (item, category, updateDetails) => {
    categoryStore.setCurrentCategory(category);

    const policyId = selectedPolicy[item.id];
    if (updateDetails) {
      navigate(MY_DETAILS_PATH_FN(policyId, category));
    } else {
      navigate(DASHBOARD_MY_RESULTS_PATH_FN(category));
      togglePersonalizationFlowModal();
    }
  };

  const setReminderCallback = attr => {
    homeStore.updateRenewalsSmartReminder(attr);
  };

  const onClickEditReminder = (smartReminder, renewalDetail, item, category) => {
    const newSmartReminder = { ...smartReminder };
    if (!smartReminder?.aliasName) newSmartReminder.aliasName = 'Policy 1';
    newSmartReminder.renewalDetailId = renewalDetail?.id;

    const onSuccess = (resp, renewalDetailId) => {
      homeStore.updateRenewalDetail({
        id: renewalDetailId,
        data: {
          smartReminder: resp.smartReminder
        }
      });
    };
    onEditReminder(
      { ...newSmartReminder, category },
      {
        parentType: 'my-renewals',
        selectedCategory: category,
        category,
        onSuccess,
        disabledCategory: true
      }
    );
  };

  const categoryRedirect = category => {
    categoryStore.setCurrentCategory(category);

    if (getWindow().scrollY !== 0) {
      getWindow().scroll(0, 0);
    }
  };

  const toggleMyBill = (categoryItem, renewalDetail = null) => {
    categoryStore.setCurrentCategory(categoryItem);

    const successCallback = (period, id, detail) => {
      homeStore.fetchUserCategoriesPricePerPeriod({
        categoryCode: null
      });
      homeStore.fetchUserRenewalDetails({}, null, null, true);
      handleChangCost(period === 'per_year' ? COST_OPTIONS[0] : COST_OPTIONS[1]);
      const initSelectedPolicy = { ...selectedPolicy };
      initSelectedPolicy[id] = detail.id;
      setSelectedPolicy(initSelectedPolicy);
    };

    toggleMyBillDetailsModal({
      renewalDetail,
      disabledCategory: disabledCategory(categoryItem?.root?.code),
      successCallback
    });
  };

  const handleCreateReminderCallback = async (resp, category) => {
    homeStore.fetchUserRenewalDetails({}, null, null, true);
    const initSelectedPolicy = { ...selectedPolicy };
    initSelectedPolicy[category.id] = resp.smartReminder.renewalDetail.id;
    setSelectedPolicy(initSelectedPolicy);

    setReminderCallback({
      id: category.id,
      policyId: resp?.smartReminder?.renewalDetail?.id ? +resp?.smartReminder?.renewalDetail?.id : null,
      resp
    });
  };

  const toggleShowPotentialSavings = () => {
    setShowPotentialSavings(prevState => !prevState);
  };

  const DashboardFilters = () => {
    return (
      <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
        <AutocompleteMUI
          value={filterCategory}
          options={categoriesCollection}
          onChange={handleChangCategory}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          label='Category'
          width={isDesktop ? '180px' : 'auto'}
          size='small'
          mobileSelect
          filter
        />
        <SelectMUI
          value={selectedCost}
          options={COST_OPTIONS}
          onChange={handleChangCost}
          label='My Costs'
          width={isDesktop ? '180px' : 'auto'}
          size='small'
          mobileSelect
        />
        <Box component='label' sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <Switcher value={showPotentialSavings} onChange={toggleShowPotentialSavings} />
          <Typography variant='body3'>Show only Potential savings </Typography>
        </Box>
      </Stack>
    );
  };

  useEffect(() => {
    if (homeStore.renewals.length && homeStore.userDetails.length) {
      const grDetails = _groupBy(homeStore.userDetails.toJS(), 'categoryId');
      const initSelectedPolicy = {};

      homeStore.renewals?.forEach(item => {
        const details = grDetails[item.id] || [];
        if (details.length) {
          initSelectedPolicy[item.id] = _get(
            _orderBy(details, el => +el.id),
            '0.id'
          );
        }
      });
      setSelectedPolicy(initSelectedPolicy);
    }
  }, [homeStore.renewals.length, homeStore.userDetails.length]);

  useEffect(() => {
    if (currentUser?.id) {
      fetchData();
    }
  }, [currentUser?.id]);

  useEffect(() => {
    return () => {
      homeStore.setRenewals([]);
    };
  }, []);

  return (
    <>
      <PageTitleWrapper
        homeIsLanding
        title='Dashboard'
        currentPage='Dashboard'
        subTitle='One place to manage all of your bills!'
      >
        <MyRenewalsTotal
          renewalsCollection={renewalsMainList}
          selectedPolicy={selectedPolicy}
          selectedCost={selectedCost}
          currency={currency}
        />
      </PageTitleWrapper>
      <Box sx={stylesContainer.container}>
        <Box sx={{ pb: { xs: 4, lg: 6 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
              width: '100%',
              maxWidth: isDesktop ? '100%' : '768px',
              mb: 3,
              mx: 'auto'
            }}
          >
            {currentUser.id && (
              <Box
                sx={{
                  order: {
                    xs: 2,
                    lg: 1
                  }
                }}
              >
                {isDesktop ? (
                  DashboardFilters()
                ) : (
                  <FiltersDrawer
                    buttonProps={{
                      variant: 'filledTonal',
                      color: 'primary',
                      size: 'smallToMedium',
                      startIcon: <FilterAltIcon />,
                      sx: {
                        width: { xs: '100%', lg: 'auto' },
                        px: '23px',
                        '& .MuiButton-startIcon': {
                          mr: { xs: 0, lg: 1 }
                        }
                      }
                    }}
                    handleResetFilters={resetFilters}
                    hideFilterText
                  >
                    {DashboardFilters()}
                  </FiltersDrawer>
                )}
              </Box>
            )}
            <Box
              sx={{
                order: {
                  xs: 1,
                  lg: 2
                },
                flexGrow: isDesktop ? 0 : 1
              }}
            >
              {currentUser.id ? (
                <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
                  <Button
                    variant='contained'
                    size='smallToMedium'
                    onClick={handleCategoryModal}
                    sx={{ width: { xs: '100%', lg: 'auto' } }}
                  >
                    Add / Manage Categories
                  </Button>
                </Stack>
              ) : (
                <SignUpModalLink variant='contained' color='primary' size='smallToMedium'>
                  Add / Manage Categories
                </SignUpModalLink>
              )}
            </Box>
          </Box>

          <Box>
            <ConfirmEmail />
            {currentUser.id ? (
              <>
                {isDesktop && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 1, px: 2 }}>
                    <Box sx={styles.categoryCell}>
                      <Typography variant='body6'>Bill category</Typography>
                    </Box>
                    <Box sx={styles.policyCell}>
                      <Typography variant='body6'>Policy</Typography>
                    </Box>
                    <Box sx={styles.costCell}>
                      <Typography variant='body6'>My Cost</Typography>
                    </Box>
                    <Box sx={styles.reminderCell}>
                      <Typography variant='body6'>Reminder</Typography>
                    </Box>
                  </Box>
                )}
                {isLoading ? (
                  <Box sx={styles.listWrapper}>
                    {[...Array(10)].map((_, index) => {
                      if (isDesktop) {
                        return <DesktopSkeleton key={index} />;
                      } else {
                        return <MobileSkeleton key={index} />;
                      }
                    })}
                  </Box>
                ) : (
                  <>
                    {renewalsMainList.filter(item => !item.hide).length > 0 ? (
                      <>
                        {renewalsCollectionPined.length > 0 && (
                          <>
                            <Box sx={styles.listWrapper}>
                              {renewalsCollectionPined.map(item => {
                                const { renewalDetail, notLinkedCategory, category, options } = getItemOption(item);

                                if (!isDesktop) {
                                  return (
                                    <MobileItem
                                      key={item.id}
                                      {...{
                                        item,
                                        userNotConfirmed,
                                        categoryRedirect,
                                        notLinkedCategory,
                                        options,
                                        category,
                                        renewalDetail,
                                        onClickDetails,
                                        toggleMyBill,
                                        disabledCategory,
                                        selectedPolicy,
                                        setSelectedPolicy,
                                        selectedCost,
                                        currency,
                                        onClickEditReminder,
                                        toggleReminderFormModal,
                                        handleCreateReminderCallback,
                                        autoQuoteEnabled,
                                        toggleIsAutoQuote,
                                        filterCategory,
                                        showPotentialSavings,
                                        setShowPotentialSavings
                                      }}
                                    />
                                  );
                                }

                                return (
                                  <DesktopItem
                                    key={item.id}
                                    {...{
                                      item,
                                      userNotConfirmed,
                                      categoryRedirect,
                                      notLinkedCategory,
                                      options,
                                      category,
                                      renewalDetail,
                                      onClickDetails,
                                      toggleMyBill,
                                      disabledCategory,
                                      selectedPolicy,
                                      setSelectedPolicy,
                                      selectedCost,
                                      currency,
                                      onClickEditReminder,
                                      toggleReminderFormModal,
                                      handleCreateReminderCallback,
                                      autoQuoteEnabled,
                                      toggleIsAutoQuote,
                                      filterCategory,
                                      showPotentialSavings,
                                      setShowPotentialSavings
                                    }}
                                  />
                                );
                              })}
                            </Box>
                            <Divider sx={{ my: 2 }}>
                              <Typography variant='caption'>Other bills</Typography>
                            </Divider>
                          </>
                        )}
                        <Box sx={styles.listWrapper}>
                          {renewalsCollection.map(item => {
                            const { renewalDetail, notLinkedCategory, category, options } = getItemOption(item);

                            if (!isDesktop) {
                              return (
                                <MobileItem
                                  key={item.id}
                                  {...{
                                    item,
                                    userNotConfirmed,
                                    categoryRedirect,
                                    notLinkedCategory,
                                    options,
                                    category,
                                    renewalDetail,
                                    onClickDetails,
                                    toggleMyBill,
                                    disabledCategory,
                                    selectedPolicy,
                                    setSelectedPolicy,
                                    selectedCost,
                                    currency,
                                    onClickEditReminder,
                                    toggleReminderFormModal,
                                    handleCreateReminderCallback,
                                    autoQuoteEnabled,
                                    toggleIsAutoQuote,
                                    filterCategory,
                                    showPotentialSavings,
                                    setShowPotentialSavings
                                  }}
                                />
                              );
                            }

                            return (
                              <DesktopItem
                                key={item.id}
                                {...{
                                  item,
                                  userNotConfirmed,
                                  categoryRedirect,
                                  notLinkedCategory,
                                  options,
                                  category,
                                  renewalDetail,
                                  onClickDetails,
                                  toggleMyBill,
                                  disabledCategory,
                                  selectedPolicy,
                                  setSelectedPolicy,
                                  selectedCost,
                                  currency,
                                  onClickEditReminder,
                                  toggleReminderFormModal,
                                  handleCreateReminderCallback,
                                  autoQuoteEnabled,
                                  toggleIsAutoQuote,
                                  filterCategory,
                                  showPotentialSavings,
                                  setShowPotentialSavings
                                }}
                              />
                            );
                          })}
                        </Box>
                      </>
                    ) : (
                      <EmptyBills handleCategoryModal={handleCategoryModal} />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <div className='empty-home-collection d-flex justify-content-center align-items-center'>
                  <div className='empty-collection-renewal d-flex flex-column align-items-center'>
                    <div className='empty-image'>
                      <img src={RenewalEmptyIcon} alt='empty' />
                    </div>
                    <div className='empty-title'>Manage all your bills in one place</div>
                    <div className='empty-sub-title'>
                      Keeping your renewal dates up to date is so important to saving you money.
                    </div>
                    <div className='status-list d-flex align-items-center justify-content-between'>
                      <div className='status-item d-flex align-items-center'>
                        <div className='reminder-status green' />4 weeks
                      </div>
                      <div className='status-item d-flex align-items-center'>
                        <div className='reminder-status amber' />2 weeks
                      </div>
                      <div className='status-item d-flex align-items-center'>
                        <div className='reminder-status red' />3 days
                      </div>
                    </div>
                    <p>
                      Once you select categories and set your renewal date on our application, we will send you a green,
                      amber and red notification prior to your next renewal with a link to your relevant leaderboard.{' '}
                    </p>
                    <div className='btn-container d-flex justify-content-center'>
                      <SignUpModalLink className='btn full btn'>
                        <span>Join us</span>
                      </SignUpModalLink>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
});
