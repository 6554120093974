import React, { useCallback, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/CloseRounded';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import api from '@packs/apis/company';
import colors from '@packs/assets/theme/base/colors';
import { BreadcrumbsMUI } from '@packs/components/MaterialUi/BreadcrumbsMUI/BreadcrumbsMUI';
import { CustomCampaignCardSkeleton } from '@packs/components/MaterialUi/Groups/CustomCampaignGroupCard/CustomCampaignCardSkeleton';
import { CustomCampaignGroupCard } from '@packs/components/MaterialUi/Groups/CustomCampaignGroupCard/CustomCampaignGroupCard';
import { ICompaniesSearchResponse } from '@packs/components/UI/SearchBusinessCategory/SearchBusinessCategory';
import { EXCLUDED_CATEGORIES } from '@packs/models/category/constants';
import { JoinedGroupsPreview } from '@packs/screens/Groups/components/JoinedGroupsPreview/JoinedGroupsPreview';
import { GroupsListSection } from '@packs/screens/Groups/GroupsListSection';
import { CategoryItemPopular } from '@packs/screens/Landing/components/Hero/components/Popular/components/CategoryItemPopular';
import { useStore } from '@packs/stores';
import useContainerStyles from '@packs/styles/Global/container';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import useStyles from './styles';

import debounce from 'lodash-es/debounce';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

const Groups = observer(() => {
  const { countryCode, userStore } = useStore();
  const styles = useStyles();
  const stylesContainer = useContainerStyles();

  const [options, setOptions] = useState<{ label: string; options: Record<string, any> }[]>([]);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const promiseOptions = (keyWord = '') => {
    setLoading(true);
    api
      .campaignPagesSearch({
        countryCode,
        keyWord,
        perPage: 4,
        excludedCategories: EXCLUDED_CATEGORIES
      })
      .then((res: ICompaniesSearchResponse) => {
        let list: typeof options = [];

        if (!res?.errors || res?.errors.length === 0) {
          const associations = res?.associations.map(item => ({
            ...item,
            value: item.code,
            label: item.name,
            countryRegion: item?.countryRegion?.name,
            city: item?.locality?.name,
            route: item.route,
            type: 'associations'
          }));

          const customCampaigns = res?.customCampaigns.map(item => ({
            ...item,
            value: item.code,
            label: item.name,
            countryRegion: item?.countryRegion?.name,
            city: item?.locality?.name,
            route: item.route,
            type: 'customCampaigns'
          }));

          const residentials = res?.residentials.map(item => ({
            ...item,
            value: item.code,
            label: item?.residentialAreaName || item?.name || 'Residential',
            countryRegion: item?.countryRegion?.name,
            city: item?.locality?.name,
            route: item.route,
            type: 'residentials'
          }));

          const businessResidentials = res?.businessResidentials.map(item => ({
            ...item,
            value: item.code,
            label: item?.residentialAreaName || item?.name || 'Local business',
            countryRegion: item?.countryRegion?.name,
            city: item?.locality?.name,
            route: item.route,
            type: 'businessResidentials'
          }));

          const categories = res?.categories.map(item => ({
            ...item,
            value: item.code,
            label: item.name,
            type: 'category'
          }));

          list = [
            ...[{ label: 'Local Residential Bill Groups', type: 'residentials', options: residentials }],
            ...[{ label: 'Association Groups', type: 'associations', options: associations }],
            ...[{ label: 'Affinity Groups', type: 'customCampaigns', options: customCampaigns }],
            ...[{ label: 'Products Groups', options: categories }],
            ...[{ label: 'Business Groups', options: businessResidentials }]
          ].filter(item => item.options.length > 0);
        }

        setOptions(list);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = useCallback(
    debounce(keyWord => {
      promiseOptions(keyWord);
    }, 1000),
    []
  );

  useEffect(() => {
    fetchData(inputValue);
  }, [inputValue]);

  return (
    <>
      <Helmet>
        <title>Groups</title>
        <meta name='og:title' content='Gtoups' />
      </Helmet>
      <Box sx={styles.wrapper}>
        <Box sx={styles.innerWrapper}>
          <Box sx={stylesContainer.container}>
            <BreadcrumbsMUI currentPage='Groups' color={colors.white.main} />
            <Box sx={{ mt: { xs: 5, lg: 8 }, textAlign: 'center' }}>
              <Typography variant='h2' sx={{ mb: 2, color: colors.white.main }}>
                Search over 44,000 groups today!
              </Typography>
              <Typography variant='subtitle1' sx={{ color: colors.white.main }}>
                Join Groups for Exclusive Offers…
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                maxWidth: '742px',
                mx: 'auto',
                mt: 3
              }}
            >
              <TextField
                variant='outlined'
                placeholder='Search local residential, association, affinity, product or business groups…'
                value={inputValue}
                size='medium'
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  backgroundColor: colors.white.main,
                  '& .MuiInputBase-root': {
                    gap: 1,
                    px: 2
                  },
                  '& input': {
                    minHeight: '32px',
                    fontSize: '16px',
                    fontWeight: '500'
                  },
                  '& input::placeholder': {
                    color: colors.text.secondary,
                    opacity: '1'
                  }
                }}
                InputProps={{
                  startAdornment: <SearchRoundedIcon sx={{ fontSize: '32px' }} />,
                  endAdornment: inputValue ? (
                    <CloseIcon sx={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => setInputValue('')} />
                  ) : null
                }}
                onChange={e => setInputValue(e.target.value)}
              />
            </Box>
            <JoinedGroupsPreview />
            <Box sx={styles.groupsSection}>
              {loading ? (
                <>
                  <GroupsListSection title='Residential Bill Groups'>
                    {[...Array(4)].map(item => (
                      <CustomCampaignCardSkeleton key={item} />
                    ))}
                  </GroupsListSection>
                  <GroupsListSection title='Associations'>
                    {[...Array(4)].map(item => (
                      <CustomCampaignCardSkeleton key={item} />
                    ))}
                  </GroupsListSection>
                  <GroupsListSection title='Affinity groups'>
                    {[...Array(4)].map(item => (
                      <CustomCampaignCardSkeleton key={item} />
                    ))}
                  </GroupsListSection>
                </>
              ) : options.length !== 0 ? (
                options.map(option => (
                  <GroupsListSection key={option.type} title={option.label}>
                    {option.options.map(group => {
                      if (group?.type === 'category') {
                        return (
                          <CategoryItemPopular
                            key={group.id}
                            categoryIcon={SUB_ICONS({ category: group })}
                            category={group}
                          />
                        );
                      }

                      const isJoined = userStore?.currentUserGroups.some(item => item.id === group?.campaignGroup?.id);

                      return <CustomCampaignGroupCard key={group.id} group={group} isJoined={isJoined} />;
                    })}
                  </GroupsListSection>
                ))
              ) : (
                <Box sx={styles.emptyWrapper}>
                  <Box sx={{ mb: 3 }}>
                    <IconBox
                      containerSize='96px'
                      iconSize='48px'
                      icon={<FindInPageRoundedIcon />}
                      borderRadius='8px'
                      color='rgba(255, 255, 255, 0.67)'
                      backgroundColor='rgba(255, 255, 255, 0.20)'
                      rootIcon
                    />
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography variant='h5' sx={{ color: colors.white.main }}>
                      No matching results
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='body3' sx={{ color: colors.white.main }}>
                      Try another search request or explore from the most popular suggested groups
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default Groups;
