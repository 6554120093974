export default () => ({
  container: {
    width: '100%',
    maxWidth: '1160px',
    mx: 'auto',
    pb: 6,
    px: 2
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    py: 4
  }
});
