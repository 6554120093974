import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useStore } from '@packs/stores/base';
import { getCountryFlag } from '@shared/helpers/flagIconHelper';

import useStyles from './styles';

export const PoweredBlock = ({ currentCategory, variant = 'subtitle1' }) => {
  const styles = useStyles();
  const { currentCountry } = useStore();
  const countryCode = currentCountry?.isoA2Code;

  return (
    <Typography variant={variant}>
      {`Powered by the ${currentCategory?.name?.toLowerCase()} community in`}
      <Box sx={styles.formFooterFlag}>
        <img src={getCountryFlag(countryCode)} alt={countryCode} />
      </Box>
      {currentCountry?.name}
    </Typography>
  );
};
