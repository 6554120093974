import { useEffect, useState } from 'react';

import { getWindow } from '@shared/lib/utils';

import debounce from 'lodash-es/debounce';
// bootstrap 4
// Extra small devices Phones (<768px)
// Small devices Tablets (≥768px)
// Medium devices Desktops (≥992px)
// Large devices Desktops (≥1200px)
export const useWindowSize = () => {
  const [windowSize, _setWindowSize] = useState(getWindow().innerWidth);
  const xxsMax = windowSize <= 600;
  const xsMax = windowSize <= 767;
  const smMin = windowSize >= 768;
  const smMax = windowSize <= 991;
  const mdMin = windowSize >= 992;

  const notMobile = smMin; // windowSize > 767;
  const isSmallMobile = xxsMax;
  const isMobile = xsMax;
  const isDesktop = mdMin; // windowSize > 991;
  const isTablet = smMax; // windowSize < 992;

  const setWindowSize = () => {
    _setWindowSize(getWindow().innerWidth);
  };

  useEffect(() => {
    getWindow().addEventListener('resize', debounce(setWindowSize, 200));

    return () => {
      getWindow().removeEventListener('resize', debounce(setWindowSize, 200));
    };
  }, []);

  return {
    windowSize,
    isSmallMobile,
    isMobile,
    notMobile,
    isDesktop,
    isTablet
  };
};
