import { action, observable, runInAction } from 'mobx';

import api from '@packs/apis/currentUser';
import groupsApi from '@packs/apis/groups';
import { contributionTypes } from '@packs/lib/constants';
import { toastr } from '@packs/lib/helpers';
import { refreshSessionToken, setError } from '@packs/lib/utils';
import { getWindow } from '@shared/lib/utils';

import pick from 'lodash-es/pick';
import { stopSubmit } from 'redux-form';

export class UserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  currentUser = {};
  @observable
  userProfileStats = {
    reviewsCount: 0,
    helpfullCount: 0,
    remindersCount: 0,
    reviewBonusPoints: 0,
    tipBonusPoints: 0,
    qaBonusPoints: 0
  };
  @observable
  contributionCounter = {
    [contributionTypes.totalCount]: 0,
    [contributionTypes.renewalDetailListCount]: 0,
    [contributionTypes.qaPostListCount]: 0,
    [contributionTypes.qaTipListCount]: 0,
    [contributionTypes.userCategoryQuoteRequestListCount]: 0
  };
  @observable
  resendUnlockInstructionsFormErrors;
  @observable
  resendConfirmationInstructionsFormErrors;
  @observable
  signUpModalType = 'customer';
  @observable
  authModalType = 'login';
  @observable
  authModalCall = '';
  @observable
  skipCategoriesModal = true;
  @observable
  trustNetworkList = [];
  @observable
  trustNetworkListTotal = 0;
  @observable
  currentUserGroups = [];

  @action
  setCurrentUser = currentUser => {
    this.currentUser = { ...currentUser };
  };
  @action
  setSignUpModalType = type => {
    this.signUpModalType = type;
  };
  @action
  setAuthModalType = type => {
    this.authModalType = type;
  };
  @action
  setAuthModalCall = type => {
    this.authModalCall = type;
  };
  @action
  setSkipCategoriesModal = value => {
    this.skipCategoriesModal = value;
  };

  getCategoriesAttributes = () => {
    return this.rootStore.categoryStore.categoriesAttributes;
  };

  @action
  currentUserSignUp = async () => {
    const createUserApi = this?.currentUser?.salesCode ? api.createUserByCode : api.createUser;

    const pickedProperties = [
      'email',
      'password',
      'firstName',
      'lastName',
      'businessName',
      'registerEvent',
      'website',
      'registerBusiness',
      'countryCode',
      'state',
      'cityName',
      'cityId',
      'eirCodeAliasId',
      'lat',
      'lng',
      'usersCategoriesAttributes',
      'provider',
      'googleUid',
      'facebookUid',
      'twitterUid',
      'linkedinUid'
    ];

    if (this?.currentUser?.salesCode) {
      pickedProperties.push('salesCode');
    }

    const { errors, user } = await createUserApi(
      pick(
        {
          ...this.currentUser,
          usersCategoriesAttributes: []
        },
        pickedProperties
      )
    );

    if (errors.length) {
      return Promise.reject(errors);
    }
    this.setCurrentUser(user);
    refreshSessionToken();
    return this.currentUser;
  };

  @action
  checkUserFieldsUniqueness = async attrs => {
    const resp = await api.checkUserFieldsUniqueness(attrs);
    const { emailValidation, displayNameValidation, businessNameValidation } = resp;

    if (
      emailValidation.isValid &&
      (attrs.registerBusiness ? businessNameValidation.isValid : displayNameValidation.isValid)
    ) {
      return resp;
    }
    const errors = {};
    if (!emailValidation.isValid) {
      errors.email = 'Email already been taken';
      toastr.error(errors.email);
    }

    if (attrs.registerBusiness) {
      if (!businessNameValidation.isValid) {
        errors.businessName = 'business name already been taken';
        toastr.error(errors.businessName);
      }
    } else {
      if (!displayNameValidation.isValid) {
        errors.displayName = 'Name already been taken';
        toastr.error(errors.displayName);
      }
    }
    return Promise.reject(errors);
  };

  @action
  inviteUsers = ({ attributes, errorCallback, callback }) => {
    api.inviteUsers(attributes).then(resp => {
      if (resp?.errors?.length) {
        setError(resp?.errors);
        if (errorCallback) errorCallback(resp?.errors);
      } else {
        if (callback) callback();
        runInAction(() => {
          const newUsers = attributes?.emails
            .map((item, index) => ({
              friend: {
                id: item + index,
                email: item
              }
            }))
            .reverse();

          this.trustNetworkList = [...newUsers, ...this.trustNetworkList];
          this.trustNetworkListTotal = this.trustNetworkListTotal + attributes?.emails?.length;
        });
      }
    });
  };
  @action
  setRegisterEvent = event => {
    this.currentUser = {
      ...this.currentUser,
      registerEvent: event
    };
  };

  @action
  logOut = async () => {
    getWindow().localStorage.removeItem('X-Authorization');

    const { errors } = await api.logOut();
    if (errors.length) {
      return Promise.reject(errors);
    }

    this.rootStore.policyStore.setCurrentPolicy(undefined);
    this.rootStore.policiesListStore.setUserRenewalDetails([]);

    runInAction(() => {
      this.currentUser = {};
    });
  };
  @action
  fetchProfileStats = payload => {
    const currentUser = this.currentUser;
    if (currentUser.id) {
      api.userProfileStats(payload).then(resp => {
        this.setUserProfileStats(resp);

        runInAction(() => {
          // SET_USER_PROFILE_STATS
          this.currentUser = {
            ...this.currentUser,
            reviewBonusPoints: resp.reviewBonusPoints,
            tipBonusPoints: resp.tipBonusPoints,
            qaBonusPoints: resp.qaBonusPoints
          };
        });
      });
    }
  };
  @action
  fetchProfileContributionCounter = () => {
    api.profileContributionCounter({}).then(resp => {
      runInAction(() => {
        this.contributionCounter = resp;
      });
    });
  };
  @action
  updateContributionCounter = (type, increase) => {
    const currentType = this.contributionCounter[type];
    const totalCount = this.contributionCounter[contributionTypes.totalCount];

    this.contributionCounter = {
      ...this.contributionCounter,
      [type]: increase ? currentType + 1 : currentType >= 0 ? currentType - 1 : 0,
      [contributionTypes.totalCount]: increase ? totalCount + 1 : totalCount >= 0 ? totalCount - 1 : 0
    };
  };

  @action
  signIn = async ({ email, password }) => {
    const { errors, user } = await api.signIn({ email, password });
    if (errors.length) {
      return Promise.reject(errors);
    }
    this.setCurrentUser(user);
    return user || {};
  };

  @action
  updateCurrentUser = async attrs => {
    const { user, errors } = await api.updateCurrentUser(attrs);

    if (errors?.length) {
      return Promise.reject(errors);
    }
    runInAction(() => {
      this.setCurrentUser({ ...this.currentUser, ...user });
    });

    return this.currentUser;
  };

  @action
  deleteCurrentUser = async () => {
    await api.deleteCurrentUser({});
    runInAction(() => {
      this.currentUser = {};
    });
  };

  @action
  usersResetPassword = ({ email, callback, errorCallback }) => {
    api.usersResetPassword({ email }).then(resp => {
      if (!resp.errors.length) {
        if (callback) callback();
      } else {
        setError(resp.errors);
        if (errorCallback) errorCallback(resp.errors);
      }
    });
  };
  @action
  changeUserResetedPassword = ({ password, passwordConfirmation, resetPasswordToken, callback, errorCallback }) => {
    api
      .changeUserResetedPassword({
        password,
        passwordConfirmation,
        resetPasswordToken
      })
      .then(resp => {
        if (resp.errors.length || !resp?.user) {
          setError(resp.errors);
          if (errorCallback) errorCallback(resp.errors);
        } else {
          this.setCurrentUser(resp.user);
          if (callback) callback();
        }
      });
  };
  @action
  resendCurrentUserConfirmationInstructions = ({ email, callback, errorCallback }) => {
    api.resendCurrentUserConfirmationInstructions({ email }).then(resp => {
      if (!resp.errors.length) {
        if (callback) callback();
      } else {
        // TODO stop submitting resendConfirmation form
        stopSubmit('resendConfirmation', { email: resp.errors[0] });

        runInAction(() => {
          this.currentUser = {
            ...this.currentUser,
            email: resp.email
          };
        });
        this.setCurrentUserConfirmationInstructionsErrors(resp.errors);
        if (errorCallback) errorCallback(resp.errors);
      }
    });
  };
  @action
  resendCurrentUserUnlockInstructions = ({ email, callback, errorCallback }) => {
    api.resendCurrentUserUnlockInstructions({ email }).then(resp => {
      if (!resp.errors.length) {
        if (callback) callback();
      } else {
        // TODO: call dispatch here to stop submitting resendUnlock form
        stopSubmit('resendUnlock', { email: resp.errors[0] });

        this.setCurrentUser({ ...this.currentUser, email: resp.email });
        this.setCurrentUserUnlockInstructionsErrors(resp.errors);
        if (errorCallback) errorCallback(resp.errors);
      }
    });
  };
  @action
  setCurrentUserConfirmationInstructionsErrors = errors => {
    this.resendConfirmationInstructionsFormErrors = errors;
  };
  @action
  setCurrentUserUnlockInstructionsErrors = errors => {
    this.resendUnlockInstructionsFormErrors = errors;
  };
  @action
  userAcceptInvitationByEmail = ({ callback, errorCallback }) => {
    const { ...restAttrs } = this.currentUser;

    api.userAcceptInvitationByEmail({ ...restAttrs }).then(resp => {
      if (!resp.errors.length) {
        const country = resp.user.country;
        delete resp.user.countryCode;
        this.setCurrentUser(resp.user);
        this.rootStore.setCurrentCountry(country);
        if (callback) callback();
      } else {
        setError(resp.errors);
        if (errorCallback) errorCallback(resp.errors);
      }
    });
  };

  @action
  userAcceptInvitationBySocial = ({ callback, errorCallback }) => {
    const { ...restAttrs } = this.currentUser;
    const variables = { ...restAttrs };
    delete variables.showSalesCode;

    api.userAcceptInvitationBySocial({ ...variables }).then(resp => {
      if (!resp.errors.length) {
        const country = resp.user.country;
        delete resp.user.countryCode;
        this.setCurrentUser(resp.user);
        this.rootStore.setCurrentCountry(country);
        if (callback) callback();
      } else {
        setError(resp.errors);
        if (errorCallback) errorCallback(resp.errors);
      }
    });
  };

  @action // SET_USER_PROFILE_STATS
  setUserProfileStats = data => {
    this.userProfileStats = data;
  };

  @action // SET_USER_PROFILE_STATS
  setProfileReminderCount = remindersCount => {
    this.setUserProfileStats({
      reviewsCount: this.userProfileStats.reviewsCount,
      helpfullCount: this.userProfileStats.helpfullCount,
      remindersCount
    });
  };

  @action // SET_CURRENT_USER_BONUS_POINTS
  setCurrentUserBonusPoints = ({ reviewBonusPoints, tipBonusPoints, qaBonusPoints }) => {
    this.currentUser = {
      ...this.currentUser,
      reviewBonusPoints,
      tipBonusPoints,
      qaBonusPoints
    };
  };

  @action
  connectSocial = async attrs => {
    const { errors } = await api.connectSocial(attrs);

    if (errors?.length) {
      return Promise.reject(errors);
    }
    runInAction(() => {
      this.setCurrentUser({
        ...this.currentUser,
        socialProviders: {
          ...this.currentUser?.socialProviders,
          [attrs.type]: true
        }
      });
    });

    return this.currentUser;
  };

  @action
  disconnectSocial = async attrs => {
    const { errors } = await api.disconnectSocial(attrs);

    if (errors?.length) {
      return Promise.reject(errors);
    }
    runInAction(() => {
      this.setCurrentUser({
        ...this.currentUser,
        socialProviders: {
          ...this.currentUser?.socialProviders,
          [attrs.type]: false
        }
      });
    });

    return this.currentUser;
  };

  @action
  trustNetworkFriendList = async (attrs, more) => {
    if (!more) {
      this.trustNetworkList = [];
      this.trustNetworkListTotal = 0;
    }

    const resp = await api.trustNetworkFriendList(attrs);

    if (resp?.errors?.length) {
      return Promise.reject(resp?.errors);
    }
    runInAction(() => {
      if (more) {
        this.trustNetworkList = [...this.trustNetworkList, ...(resp?.list || [])];
      } else {
        this.trustNetworkList = resp?.list || [];
      }

      this.trustNetworkListTotal = resp?.totalCount || 0;
    });

    return resp;
  };

  @action
  joinToTheGroup = async attrs => {
    const resp = await groupsApi.campaignGroupJoin(attrs);

    if (resp?.errors?.length) {
      return Promise.reject(resp?.errors);
    }
    runInAction(() => {
      toastr.success(`Congratulations you are now a member of the ${resp?.campaignGroup?.name || ''} Group`);
      this.currentUserGroups = [...this.currentUserGroups, resp?.campaignGroup];
    });

    return resp;
  };

  @action
  fetchUserGroups = async attrs => {
    const resp = await groupsApi.userCampaignGroups(attrs);

    if (!resp) {
      return Promise.reject(resp?.errors);
    }
    runInAction(() => {
      this.currentUserGroups = resp || [];
    });

    return resp;
  };
}
